import React from 'react';

const MicrosoftIdentityAssociation = () => (
  <div>
    <pre>
      {JSON.stringify(
        {
          associatedApplications: [
            {
              applicationId: '70f866d2-5c5f-44a2-9592-71f3da61dbb1',
            },
          ],
        },
        null,
        2,
      )}
    </pre>
  </div>
);

export default MicrosoftIdentityAssociation;
