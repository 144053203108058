import React from 'react';
import { IProps, IState } from './types';
import ChangePasswordSuccess from './components/change-password-success';
import ChangePasswordForm from './components/change-password-form';

class ChangePassword extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { showSuccessComponent: false };
    this.showSuccessComponent = this.showSuccessComponent.bind(this);
  }

  showSuccessComponent() {
    this.setState({ showSuccessComponent: true });
  }

  render() {
    const { showSuccessComponent } = this.state;

    return (
      <div className="forgot-right-inner">
        {showSuccessComponent ? (
          <ChangePasswordSuccess />
        ) : (
          <ChangePasswordForm
            showSuccessComponent={this.showSuccessComponent}
          />
        )}
      </div>
    );
  }
}

export default ChangePassword;
