import qs from 'qs';
import api from '../../../api';
import {
  AddContactsFromCSVRequestPayload,
  AddSequenceStepRequestPayload,
  AddSequenceStepVariantRequestPayload,
  CreateSequenceRequestPayload,
  DeleteSequencesRequestPayload,
  GetSequencesQueryParams,
  PauseResumeSequenceRequestPayload,
  RenameSequenceRequestPayload,
  UpdateSequenceSettingsRequestPayload,
  UpdateSequenceStepExecutionDayRequestPayload,
  UpdateSequenceStepRequestPayload,
  UpdateSequenceStepVariantDataRequestPayload,
  UpdateSequenceStepVariantRequestPayload,
  UpdateSequenceStepVariantStatusRequestPayload,
  ContactListParams,
  AddToStepParams,
} from '../types';
import { ResponseSuccess } from '../../../shared/types';
import { ContactStatusType } from '../enums';
import { PaginationQueryParams } from '../../../shared/types/request';
import { GetEmailRequestPayload } from '../types/emails';
import { GetTemplatesRequest } from '../../templates/types/request';
import { Source } from '../../../shared/enums/source';

// Sequence
export const createSequence = async (payload: CreateSequenceRequestPayload) =>
  api.post<ResponseSuccess>('/sequences', payload, {
    headers: { 'sh-source': Source.SequenceTab },
  });

export const getSequence = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}`);

export const getSequences = async (queryParams: GetSequencesQueryParams) =>
  api.get<ResponseSuccess>('/sequences', {
    params: queryParams,
    paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
  });

export const getSequenceStats = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}/stats`);

export const getSequencesStats = async (queryParams: GetSequencesQueryParams) =>
  api.get<ResponseSuccess>('/sequences/stats', {
    params: queryParams,
    paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
  });

export const renameSequence = async (
  sequenceId: number,
  payload: RenameSequenceRequestPayload,
) => api.patch<ResponseSuccess>(`/sequences/${sequenceId}/rename`, payload);

export const pauseResumeSequence = async (
  sequenceId: number,
  payload: PauseResumeSequenceRequestPayload,
) =>
  api.patch<ResponseSuccess>(`/sequences/${sequenceId}/pause-resume`, payload);

export const cloneSequence = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/clone/${sequenceId}`);

export const deleteSequence = async (sequenceId: number) =>
  api.delete<ResponseSuccess>(`/sequences/${sequenceId}`);

export const deleteSequences = async (payload: DeleteSequencesRequestPayload) =>
  api.post<ResponseSuccess>('/sequences/delete', payload);

// Step
export const addSequenceStep = async (
  sequenceId: number,
  payload: AddSequenceStepRequestPayload,
) => api.post<ResponseSuccess>(`/sequences/${sequenceId}/steps`, payload);

// Generic request.
export const updateSequenceStep = async (
  sequenceId: number,
  stepId: number,
  payload: UpdateSequenceStepRequestPayload,
) =>
  api.patch<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}`,
    payload,
  );

export const updateSequenceStepExecutionDay = async (
  sequenceId: number,
  stepId: number,
  payload: UpdateSequenceStepExecutionDayRequestPayload,
) => updateSequenceStep(sequenceId, stepId, payload);

export const deleteSequenceStep = async (sequenceId: number, stepId: number) =>
  api.delete<ResponseSuccess>(`/sequences/${sequenceId}/steps/${stepId}`);

// Variant
export const addSequenceStepVariant = async (
  sequenceId: number,
  stepId: number,
  payload: AddSequenceStepVariantRequestPayload,
) =>
  api.post<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}/variants`,
    payload,
  );

// Generic request.
export const updateSequenceStepVariant = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
  payload: UpdateSequenceStepVariantRequestPayload,
) =>
  api.patch<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}/variants/${variantId}`,
    payload,
  );

export const updateSequenceStepVariantData = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
  payload: UpdateSequenceStepVariantDataRequestPayload,
) => updateSequenceStepVariant(sequenceId, stepId, variantId, payload);

export const updateSequenceStepVariantStatus = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
  payload: UpdateSequenceStepVariantStatusRequestPayload,
) => updateSequenceStepVariant(sequenceId, stepId, variantId, payload);

export const deleteSequenceStepVariant = async (
  sequenceId: number,
  stepId: number,
  variantId: number,
) =>
  api.delete<ResponseSuccess>(
    `/sequences/${sequenceId}/steps/${stepId}/variants/${variantId}`,
  );

// Settings
export const updateSequenceSettings = async (
  sequenceId: string,
  payload: UpdateSequenceSettingsRequestPayload,
) => api.patch<ResponseSuccess>(`/sequences/${sequenceId}/settings`, payload);

export const getSequenceSteps = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}/steps`);

// Import contacts csv.
export const addContactsFromCSV = async (
  stepId: number,
  {
    file,
    mapConfig,
    conflictAction,
    includeRiskyContact,
    includeVerification,
    tags,
  }: AddContactsFromCSVRequestPayload,
) => {
  const data = new FormData();
  data.append('file', file);
  data.append('mapConfig', JSON.stringify(mapConfig));
  data.append('conflictAction', conflictAction);
  data.append('includeRiskyContacts', includeRiskyContact ? '1' : '0');
  data.append('verifyContacts', includeVerification ? '1' : '0');
  tags && data.append('tags', JSON.stringify(tags));
  return api.post<ResponseSuccess>(`/contacts/sequence/${stepId}`, data, {
    headers: { 'sh-source': Source.SequenceProspectImportCsv },
  });
};

export const addContactsFromCSVWithoutStep = async ({
  file,
  mapConfig,
  conflictAction,
  includeRiskyContact,
  includeVerification,
  tags,
}: AddContactsFromCSVRequestPayload) => {
  const data = new FormData();
  data.append('file', file);
  data.append('mapConfig', JSON.stringify(mapConfig));
  data.append('conflictAction', conflictAction);
  data.append('includeRiskyContacts', includeRiskyContact ? '1' : '0');
  data.append('verifyContacts', includeVerification ? '1' : '0');
  tags && data.append('tags', JSON.stringify(tags));
  return api.post<ResponseSuccess>(`/contacts/import`, data, {
    headers: { 'sh-source': Source.ProspectImportCsv },
  });
};

// Schedule.
export const getAllSchedules = async () => api.get('/schedules');

// Connected Email Accounts.
export const getConnectedEmails = async () =>
  api.get('/email-account/connected');

// Contact API

// Get count related to the step
export const getSequenceStepCount = async (
  sequenceId: string,
  params: ContactListParams,
) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/contacts/count`, { params });

// Get the filter list of steps
export const getSequenceContactList = async (
  sequenceId: string,
  params: ContactListParams,
) => api.get(`/sequences/${parseInt(sequenceId, 10)}/contacts`, { params });

// Get count of related to status
export const getContactStatusCount = async (
  sequenceId: string,
  params: ContactListParams,
) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/contacts/status/count`, {
    params,
  });

// Change Status of Contacts of Sequence.
export const changeStatusOfContactsOfSequence = async (
  status: ContactStatusType,
  contactAndStepIds: any[],
  pauseDelayInDays?: number,
) =>
  api.patch(`/contacts/status`, {
    contactAndStepIds,
    status,
    ...(pauseDelayInDays && { pauseDelayInDays }),
  });

// Remove Contacts From Sequence.
export const removeContactFromSequence = async (
  sequenceId: string,
  contactIds: number[],
) =>
  api.patch(`/sequences/${parseInt(sequenceId, 10)}/contacts`, {
    contactIds,
  });

// Get Contact Sequence List.
export const getContactSequenceList = async () =>
  api.get<ResponseSuccess>('/sequences/list?numberOfSteps=gt:0');

// Get Steps by Sequence for Contacts.
export const getContactStepList = async (sequenceId: number) =>
  api.get<ResponseSuccess>(`/sequences/${sequenceId}/steps`);

// Add Contact to sequence's step Request.
export const addContactsToStep = async ({
  stepId,
  contactIds,
  sequenceId,
}: AddToStepParams) =>
  api.post<ResponseSuccess>(`/sequences/${parseInt(sequenceId, 10)}/contacts`, {
    stepId,
    contactIds,
  });
export const getUsersList = async (queryParams?: PaginationQueryParams) =>
  api.get<ResponseSuccess>(`/team/members`, { params: queryParams });

export const uploadImage = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
) => api.post('/attachment-broker/upload', formData, { onUploadProgress });

export const getActiveUsersList = async (queryParams?: PaginationQueryParams) =>
  api.get<ResponseSuccess>(`/team/members/active`, { params: queryParams });

export const finishOnboarding = async () =>
  api.delete<ResponseSuccess>(`/user/onboarding`);

export const getAdminSettings = async () =>
  api.get<ResponseSuccess>(`/accounts`);

export const getSequenceMailSendingStatus = async (sequenceId: string) =>
  api.get<ResponseSuccess>(
    `/sequences/${parseInt(sequenceId, 10)}/send-status`,
  );

export const getSequenceConfig = async (sequenceId: string) =>
  api.get<ResponseSuccess>(`/sequences/${parseInt(sequenceId, 10)}/config`);

// Get first sequence created for user.
export const getFirstSequence = async () =>
  api.get<ResponseSuccess>('/sequences/first');

// Get sample sequence
export const getSampleSequence = async () =>
  api.get<ResponseSuccess>('sequences/sample');

export const getUserTimeZone = async () =>
  api.get<ResponseSuccess>('/user/time-zone');

// Create sample steps for sequence.
export const createSampleSteps = async (sequenceId: number) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/steps/sample`);

// Add sample prospects to step.
export const addSampleProspectsToStep = async (stepId: number) =>
  api.post<ResponseSuccess>(`/contacts/sequence/${stepId}/sample`);

export const getTemplates = async ({
  limit = 50,
  ...rest
}: GetTemplatesRequest) =>
  api.get(`templates`, {
    params: { limit, ...rest },
  });

export const getEmailStepsOfStatus = async ({ status, sequenceId, search }) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/emails/step-count`, {
    params: {
      status,
      search,
    },
  });

export const getEmailStatusOfStep = async ({ stepId, sequenceId, search }) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/emails/status-count`, {
    params: {
      stepId,
      search,
    },
  });

export const getEmailList = async ({
  sequenceId,
  ...rest
}: GetEmailRequestPayload) =>
  api.get(`sequences/${parseInt(sequenceId, 10)}/emails`, {
    params: rest,
  });

export const sendScheduledEmail = async ({
  sequenceId,
  sequenceContactHistoryId,
}) =>
  api.post<ResponseSuccess>(`/sequences/${sequenceId}/emails/send`, {
    sequenceContactHistoryId,
  });

export const changeEmailStatus = async ({
  sequenceId,
  sequenceContactHistoryId,
  status,
}) =>
  api.patch<ResponseSuccess>(`/sequences/${sequenceId}/emails/status`, {
    sequenceContactHistoryIds: [sequenceContactHistoryId],
    status,
  });

// Get the filter list of steps
export const getSequenceProspectActivity = async (
  sequenceId: string,
  prospectId: string,
) =>
  api.get(`/sequences/${parseInt(sequenceId, 10)}/prospects/activity`, {
    params: { prospectId },
  });

// Get user customDomain
export const getCustomDomains = async () => api.get('/custom-domain');

// Get sequence prospect verification stats
export const getSequenceProspectVerificationStats = async (
  sequenceId: string,
) => api.get(`/sequences/${sequenceId}/prospect-verification-stats`);

// Send sequence prospect verify start request
export const sendSequenceProspectVerifyStart = async (sequenceId: string) =>
  api.post(`sequences/${sequenceId}/verify-prospects`);

// Upload Attachment Request
export const uploadAttachment = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
  cancelToken,
) =>
  api.post('/attachment-broker/attachment', formData, {
    onUploadProgress,
    cancelToken,
  });

// Delete Attachment Request
export const deleteAttachment = async (
  attachmentId: number,
  deleteFromTemplate: boolean,
  sequenceStepVariantId: number,
) =>
  api.delete(`/attachment-broker/attachment/${attachmentId}`, {
    data: { deleteFromTemplate, sequenceStepVariantId },
  });

// Get sent email preview
export const getSentEmailPreview = async (sequenceContactHistoryId: string) =>
  api.get(
    `/sequences/sequence-contact-history/${sequenceContactHistoryId}/sent-email`,
  );
