import React, { useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import Login from './components/login';
import SignUp from './components/signup';
import ResetPassword from './components/reset-password';
import ChangePassword from './components/change-password';
import { IProps } from './type';
import AcceptInvitationSignup from './components/invitation-signup';
import OnBoarding from './components/on-boarding';
import ConnectEmailAccount from './components/connect-email-account';
import HelmetIcon from '../../shared/components/helmet-icon';
import HelpscoutBeaconEvents from '../../shared/enums/helpscout-beacon-events';
import UserOnboarding from './components/user-onboarding';
import AccountDetails from './components/account-details';
import LTDSignup from './components/ltd-signup';
import LTDCheckout from './components/ltd-checkout';
import HelmetNoIndex from '../../shared/components/helmet-no-index';
import { supportUrls } from '../../shared/utils/urls';

const Auth: React.FC<IProps> = ({
  sendGetAgencyConfigRequest,
  agencyConfig,
}) => {
  const agencyConfigPayload = {
    baseUrl: window.location.origin,
  };

  useEffect(() => {
    sendGetAgencyConfigRequest(agencyConfigPayload);
  }, []);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        api_base: supportUrls.intercomBaseUrl,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      });
    }
  }, []);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('event', {
        type: HelpscoutBeaconEvents.PAGE_VIEWED,
        url: document.location.href,
        title: document.title,
      });
      window.Intercom(HelpscoutBeaconEvents.SUGGEST);
    }
  }, [window.Intercom, document.location.href]);

  const containerClassName = classNames([
    'user-container',
    {
      'ltd-container':
        window.location.pathname?.toLowerCase() === '/ltd-signup' ||
        window.location.pathname?.toLowerCase() === '/ltd-checkout',
    },
  ]);

  return (
    <Container fluid className={containerClassName}>
      <HelmetNoIndex />
      {agencyConfig?.logo?.favicon !== '' && (
        <HelmetIcon icon={agencyConfig?.logo?.favicon} />
      )}
      <Row>
        <Col className="user-right">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/LTD-Signup" component={LTDSignup} />
            <Route path="/LTD-Checkout" component={LTDCheckout} />
            <Route path="/user-onboarding" component={UserOnboarding} />
            <Route path="/welcome" component={AccountDetails} />
            <Route path="/verify-account" component={UserOnboarding} />
            <Route exact path="/onboarding-form" component={OnBoarding} />
            <Route exact path="/set-password" component={ChangePassword} />
            <Route
              exact
              path="/connect-account"
              component={ConnectEmailAccount}
            />
            <Route
              exact
              path="/reset-password/:code"
              component={ChangePassword}
            />
            <Route
              exact
              path="/accept-invite/:token"
              component={AcceptInvitationSignup}
            />
            <Route exact path="/reset-password" component={ResetPassword} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default Auth;
