import React from 'react';

import Modal from '../../../../shared/design-system/components/atoms/modal';
import ConnectOption from './connect-option';

import type { IProps } from './types';
import { Images } from '../../../../shared/app-constants';
import { ChevronLeft, Cross } from '../../../../shared/svg';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';

const ConnectSMTPIMAPAccountsOptionsModal: React.FC<IProps> = ({
  show,
  onClose,
  onBack,
  onSMTPAccountSelect,
  showImportEmailAccountsModal,
}) => (
  <Modal
    show={show}
    onClose={onClose}
    backdrop="static"
    hideHeader
    hideFooter
    className="connect-smtp-imap-accounts-options-modal"
  >
    <div className="connect-smtp-imap-accounts-options-modal__header">
      <div className="header-inner">
        <div className="back-btn" {...accessibleOnClick(onBack)}>
          <ChevronLeft />
        </div>
        <h1>Connect SMTP/IMAP accounts</h1>
      </div>
      <div className="close-btn" {...accessibleOnClick(onClose)}>
        <Cross />
      </div>
    </div>
    <div className="connect-smtp-imap-accounts-options-modal__desc">
      <p>
        Choose an option to connect email accounts either in bulk or
        individually.
      </p>
    </div>

    <div className="connect-option-wrapper d-flex align-items-center">
      <ConnectOption
        logo={Images.Server30x30}
        optionLabel="Connect Single account"
        optionDesc="Connect single email account via  SMTP"
        onClick={() => {
          onClose();
          onSMTPAccountSelect();
        }}
      />

      <ConnectOption
        logo={Images.CSV}
        optionLabel="Bulk Import Via CSV"
        optionDesc="Import & connect multiple email accounts at once via CSV"
        onClick={showImportEmailAccountsModal}
      />
    </div>
  </Modal>
);

export default ConnectSMTPIMAPAccountsOptionsModal;
