import React, { useEffect } from 'react';
import { Overlay, OverlayProps } from 'react-bootstrap';
import { constants } from '../../../enums/constants';
import { Modify } from '../../../types';
import eventBus from '../../../utils/event-bus';
import { Placement } from './enums';

export type OverlayTargetProps = Modify<
  OverlayProps,
  {
    placement?: Placement;
    // Setting target type to 'any' as passing an Element selected
    // using getElementByClassnames, because displaying the popover
    // in for the 1st row in sequence table, and it has 'n' number
    // of methods like sorting, delete, clone which affects the rows,
    // so passing a 'ref', in this case it is now working.
    target: any;
  }
>;

export const OverlayTarget: React.FC<OverlayTargetProps> = ({
  children,
  target,
  show,
  onHide,
  ...rest
}) => {
  useEffect(() => {
    if (show) {
      eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: true });
    }
  }, [show]);

  const onHideHandler = (e: Event) => {
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
    onHide && onHide(e);
  };

  useEffect(
    () => () => {
      eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
    },
    [],
  );

  return (
    <Overlay
      target={target}
      show={show}
      onHide={onHideHandler}
      rootClose
      {...rest}
    >
      {children}
    </Overlay>
  );
};
