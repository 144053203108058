import React from 'react';
import { produce } from 'immer';
import Input from '../../../../../../shared/design-system/components/input';
import PasswordValidationChecklist from '../../../../../../shared/components/password-validation-checklist';
import { IState } from './types';
import { validate } from './validator';
import { IProps } from './change-password-modal-container';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

class ChangePasswordModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      errors: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      dirty: {
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
      currentPasswordVisible: false,
      newPasswordVisible: false,
      confirmNewPasswordVisible: false,
    };

    this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(
      this,
    );
    this.toggleCurrentPasswordVisibility = this.toggleCurrentPasswordVisibility.bind(
      this,
    );
    this.toggleNewPasswordVisibility = this.toggleNewPasswordVisibility.bind(
      this,
    );
  }

  toggleCurrentPasswordVisibility() {
    this.setState((state) => ({
      currentPasswordVisible: !state.currentPasswordVisible,
    }));
  }

  toggleNewPasswordVisibility() {
    this.setState((state) => ({
      newPasswordVisible: !state.newPasswordVisible,
    }));
  }

  toggleConfirmPasswordVisibility() {
    this.setState((state) => ({
      confirmNewPasswordVisible: !state.confirmNewPasswordVisible,
    }));
  }

  onInputChange = (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = event.target.name;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  };

  onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          if (name === 'currentPassword') {
            this.props.sendVerifyCurrentPasswordRequest(draft.values[name]);
          } else {
            draft.errors[name] = validate(
              name,
              draft.values[name],
              draft.values,
            );
            if (name === 'confirmNewPassword' && draft.dirty.newPassword) {
              draft.errors.confirmNewPassword = validate(
                'confirmNewPassword',
                draft.values.confirmNewPassword,
                draft.values,
              );
            }
          }
        }
      }),
    );
  };

  onSubmitHandler = () => {
    const dirty = { ...this.state.dirty };
    const dirtyKeys = Object.keys(dirty);

    for (const key of dirtyKeys) {
      dirty[key] = true;
    }

    const errors = { ...this.state.errors };
    const keys = Object.keys(errors);
    let isError = false;

    for (const key of keys) {
      const error = validate(key, this.state.values[key], this.state.values);
      errors[key] = error;
      isError = isError || !!error;
    }

    if (isError) {
      this.setState({ errors, dirty });
      return;
    }

    const { values } = this.state;
    const payload = {
      ...values,
    };

    delete payload.confirmNewPassword;

    this.props.onSubmit(payload);
  };

  componentDidUpdate(prevProps: IProps) {
    const {
      verifyCurrentPasswordRequestStatus,
      isCurrentPasswordValid,
    } = this.props;
    if (
      prevProps.verifyCurrentPasswordRequestStatus === RequestStatus.Pending &&
      verifyCurrentPasswordRequestStatus === RequestStatus.Succeeded
    ) {
      this.setState(
        produce((draft) => {
          draft.errors['currentPassword'] = validate(
            'currentPassword',
            isCurrentPasswordValid,
            draft.values,
          );
        }),
      );
    }
  }

  render() {
    const {
      values,
      errors,
      currentPasswordVisible,
      newPasswordVisible,
      confirmNewPasswordVisible,
    } = this.state;
    const { show, onClose, isLoading } = this.props;
    return (
      <Modal
        show={show}
        className="settings-general-modal"
        titleContent="Change Password"
        onClose={onClose}
        onSubmit={this.onSubmitHandler}
        submitButtonText="Update"
        isSubmitDisabled={isLoading}
        isSubmitLoading={isLoading}
        backdrop="static"
        extraModalProps={{
          'aria-labelledby': 'contained-modal-title-vcenter',
          centered: true,
        }}
      >
        <div className="modal-form-content">
          <Input
            type={currentPasswordVisible ? 'text' : 'password'}
            name="currentPassword"
            label="Current Password"
            placeholder="Enter your current password"
            value={values.currentPassword}
            variant={errors.currentPassword && Input.Variant.Error}
            caption={errors.currentPassword}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
            autoComplete="current-password"
            autoFocus
            icons={[
              {
                place: Input.IconPlace.Right,
                identifier: currentPasswordVisible ? 'eye-alt' : 'eye',
                className: 'pointer',
                onClick: this.toggleCurrentPasswordVisibility,
              },
            ]}
          />

          <Input
            type={newPasswordVisible ? 'text' : 'password'}
            name="newPassword"
            label="New Password"
            placeholder="Enter your new password"
            value={values.newPassword}
            variant={errors.newPassword && Input.Variant.Error}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
            autoComplete="current-new-password"
            icons={[
              {
                place: Input.IconPlace.Right,
                identifier: newPasswordVisible ? 'eye-alt' : 'eye',
                className: 'pointer',
                onClick: this.toggleNewPasswordVisibility,
              },
            ]}
          />

          <PasswordValidationChecklist password={values.newPassword} />

          <Input
            type={confirmNewPasswordVisible ? 'text' : 'password'}
            name="confirmNewPassword"
            label="Confirm Password"
            placeholder="Confirm new password"
            value={values.confirmNewPassword}
            variant={errors.confirmNewPassword && Input.Variant.Error}
            caption={errors.confirmNewPassword}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
            autoComplete="current-confirm-password"
            icons={[
              {
                place: Input.IconPlace.Right,
                identifier: confirmNewPasswordVisible ? 'eye-alt' : 'eye',
                className: 'pointer',
                onClick: this.toggleConfirmPasswordVisibility,
              },
            ]}
          />
        </div>
      </Modal>
    );
  }
}

export default ChangePasswordModal;
