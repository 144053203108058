import React, { useState } from 'react';
import { Button } from '@saleshandy/design-system';

import Modal from '../../../../shared/design-system/components/atoms/modal';
import ImageIcon from '../../../../shared/components/images/image-icon';
import SelectFile from './select-file';

import type { IProps } from './types';
import { Images } from '../../../../shared/app-constants';
import { supportUrls } from '../../../../shared/utils/urls';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { getIsRequestPending } from '../../../../shared/utils/get-request-status';

const ImportEmailAccountsModal: React.FC<IProps> = ({
  show,
  onClose,
  onBulkConnect,
  importEmailAccountCsvRequestStatus,
}) => {
  const [selectedFile, setSelectedFile] = useState<File>(null);

  const downloadSampleCSV = () => {
    window.open(supportUrls.connectBulkEmailDemoCSV, '_blank');
  };

  const openBulkImportGuide = () => {
    window.open(supportUrls.bulkImportCSVGuide, '_blank');
  };

  const handleConnect = () => {
    onBulkConnect(selectedFile);
  };

  const isRequestPending = getIsRequestPending(
    importEmailAccountCsvRequestStatus,
  );

  return (
    <Modal
      show={show}
      onClose={onClose}
      backdrop="static"
      hideHeader
      hideFooter
      className="import-email-accounts-modal"
    >
      <div className="import-email-accounts-modal__header">
        <h1>Import email accounts</h1>
        <ImageIcon src={Images.Youtube} onClick={() => null} />
      </div>

      <div className="import-email-accounts-modal__steps-wrapper">
        <div className="import-email-accounts-steps">
          <h2>
            <span role="img" aria-label="Tip">
              💡
            </span>{' '}
            Steps to import email accounts:
          </h2>
          <ol className="import-email-accounts-steps-list">
            <li>
              Download{' '}
              <span {...accessibleOnClick(downloadSampleCSV)}>Sample CSV</span>{' '}
              file.
            </li>
            <li>
              Fill details for all connection fields.(Email account, SMTP host,
              etc.)
            </li>
            <li>
              Fill details for email account settings. (Start from, Increase by,
              etc.)
            </li>
            <li>Email account field should be valid and not left blank.</li>
            <li>Import your CSV file and click on Next.</li>
            <li>
              Still have questions? Read step-by-step{' '}
              <span {...accessibleOnClick(openBulkImportGuide)}>guide</span>.
            </li>
          </ol>
        </div>
      </div>

      <SelectFile
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />

      <div className="import-email-accounts-modal__footer">
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={isRequestPending}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConnect}
          disabled={isRequestPending || !selectedFile}
        >
          Connect
        </Button>
      </div>
    </Modal>
  );
};

export default ImportEmailAccountsModal;
