import { Router } from 'react-router-dom';
import { Provider, ProviderProps } from 'react-redux';
import React from 'react';
import { RouterProps } from 'react-router';
import { toaster, ToastManager } from '@saleshandy/design-system';
import SHToaster, { ToastManager as SHToastManager } from './shared/toaster';
import notification, { NotificationManager } from './shared/notification';
import loader, { LoaderManager } from './shared/loader';
import planPermissionError, {
  PlanErrorManager,
} from './shared/utils/errors/plan-permission-error';
import App from './components/app/app';
import LoadingBar from './shared/loading-bar';
import PauseAccountManager from './shared/utils/errors/pause-account-error/pause-account-manager';
import pauseAccountError from './shared/utils/errors/pause-account-error';

type RootProps = RouterProps & ProviderProps;

const Root: React.FC<RootProps> = ({ store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      <LoadingBar showFastActions />
      <ToastManager bindActions={toaster.bindActions} />
      <SHToastManager bindActions={SHToaster.bindActions} />
      <NotificationManager bindActions={notification.bindActions} />
      <LoaderManager bindActions={loader.bindActions} />
      <PlanErrorManager bindActions={planPermissionError.bindActions} />
      <PauseAccountManager bindActions={pauseAccountError.bindActions} />
      <App />
    </Router>
  </Provider>
);

export default Root;
