import React from 'react';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';

const CustomDomainHeader = ({
  onClick: onAddDomainClick,
  show,
  subscriptionPlan,
}) => {
  return (
    <div className="custom-domain-header">
      <div className="custom-domain-title">Custom Domain</div>
      <div className="custom-domain-add-button">
        <Button
          variant={Button.Variant.Primary}
          onClick={onAddDomainClick}
          hidden={!show}
          disabled={subscriptionPlan === SubscriptionPlans.FREE}
          className="header-btn"
        >
          Add Custom Domain
        </Button>
      </div>
    </div>
  );
};

export default CustomDomainHeader;
