import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { setPasswordRequest } from './extra-action';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  setPasswordRequest: RequestState;
  canResendUserVerificationCode: boolean;
};

const initialState: State = {
  setPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  canResendUserVerificationCode: true,
};

const accountDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setPasswordRequest.pending, (state) => {
      state.setPasswordRequest.status = RequestStatus.Pending;
    });
    builder.addCase(setPasswordRequest.fulfilled, (state, action) => {
      state.setPasswordRequest.status = RequestStatus.Succeeded;
      state.setPasswordRequest.message = action.payload.message;
      state.canResendUserVerificationCode =
        action.payload.payload.canResendUserVerificationCode;
    });
    builder.addCase(setPasswordRequest.rejected, (state, action) => {
      state.setPasswordRequest.status = RequestStatus.Failed;
      state.setPasswordRequest.error = action.payload;
    });
  },
});

export { setPasswordRequest };
export default accountDetailsSlice.reducer;
