import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import StripeCheckout from 'react-stripe-checkout';
import { Images } from '../../../../shared/app-constants';
import { handle3dSecureCardPayment } from '../../../../shared/components/handle-3d-secure-card-payment/handle3dSecureCardPayment';
import ImageIcon from '../../../../shared/components/images/image-icon';
import Button from '../../../../shared/design-system/components/atoms/button';
import Input from '../../../../shared/design-system/components/input';
// import InputNumber from '../../../../shared/design-system/components/input-number';
import { InputIconConfig } from '../../../../shared/design-system/components/input/types';
// import {
//   OverlayTooltip,
//   Placement,
// } from '../../../../shared/design-system/components/overlay';
import Select from '../../../../shared/design-system/components/select';
import toaster, { Theme } from '../../../../shared/toaster';
import { UserSettingCode } from '../../../../shared/types/user-setting';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../shared/utils/execute-on-request-status';
import {
  getIsRequestFailed,
  getIsRequestPending,
} from '../../../../shared/utils/get-request-status';
import { handleOnboarding } from '../../../../shared/utils/handle-onboarding-routing';
// import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import FormSection from '../form-section';
import TopWelcome from '../top-welcome';
import {
  formatProspect,
  getSubTotalDiscountAmount,
  getTotalAmountToPay,
  setUserSelectedPlansAndSlotsOnLoad,
} from './helper';
import type { IProps } from './ltd-checkout-container';
import { PlanSelectDropdown } from './types';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';

const LTDCheckout: React.FC<IProps> = ({
  plans,
  sendGetPlansRequest,
  sendApplyCouponCodeRequest,
  sendApplyCouponCodeRequestStatus,
  sendApplyCouponCodeRequestError,
  sendPurchaseLtdPlanRequest,
  applyCouponCodeResponse,
  purchaseLtdPlanResponse,
  purchaseLtdPlanRequestStatus,
  resetPurchaseLtdPlan,
  meta,
  getUserSettingsRequestStatus,
  sendGetUserSettingsRequest,
  location,
}) => {
  const history = useHistory();

  const [selectedPlan, setSelectedPlan] = useState(null);
  // const [slots, setSlots] = useState('');
  // const [slotsError, setSlotsError] = useState(false);
  const [prospects, setProspects] = useState<PlanSelectDropdown[]>([]);

  const [haveDiscountCode, setHaveDiscountCode] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isCouponCodeInvalid, setIsCouponCodeInvalid] = useState(false);
  const [isCouponCodeValid, setIsCouponCodeValid] = useState(false);
  const [isSecure3dPending, setIsSecure3dPending] = useState(false);

  const handleHaveDiscountCode = () => {
    setHaveDiscountCode(true);
  };

  const onCouponCodeApplied = () => {
    if (couponCode && selectedPlan) {
      sendApplyCouponCodeRequest({
        couponCode,
        planId: selectedPlan.id,
        amount: selectedPlan.amount,
      });
    } else {
      setIsCouponCodeValid(false);
      setIsCouponCodeInvalid(true);
    }
  };

  const onCouponCodeDelete = () => {
    setCouponCode('');
    setIsCouponCodeValid(false);
    setIsCouponCodeInvalid(false);
  };

  const onPurchaseHandler = (token) => {
    if (token) {
      sendPurchaseLtdPlanRequest({
        planId: selectedPlan.id,
        // slots: 5,
        stripeCardToken: token.id,
        ...(couponCode && isCouponCodeValid && { couponCode }),
      });
    }
  };

  // const onSlotsChangeHandler = (value) => {
  //   // Check Plan Id Before If Check
  //   if (value > 20) {
  //     setSlotsError(true);
  //   } else {
  //     setSlotsError(false);
  //   }
  //   if (haveDiscountCode) {
  //     onCouponCodeDelete();
  //   }
  //   setSlots(value);
  // };

  const redirectToOnboardingFrom = () => {
    const search = new URLSearchParams('');

    search.set('redirectedFrom', 'ltd');

    history.push({
      pathname: '/onboarding-form',
      search: search.toString(),
    });
  };

  // Handle 3d secure card
  const handle3dSecureCard = async (response) => {
    const apiEndPoint = '/ltd/confirm-payment-intent';

    setIsSecure3dPending(true);

    const { isError } = await handle3dSecureCardPayment({
      response,
      apiEndPoint,
    });

    setIsSecure3dPending(false);

    if (isError) {
      toaster.error('Oh no! Something went wrong. Please try again.', {
        showCloseIcon: true,
        theme: Theme.New,
        delay: 10000,
      });
    } else {
      toaster.success(
        'Congratulations! Your deal has been activated successfully.',
        { theme: Theme.New },
      );
      redirectToOnboardingFrom();
    }
    resetPurchaseLtdPlan();
  };

  const openHelpscoutBeacon = () => {
    openChatSupport();
  };

  useEffect(() => {
    sendGetPlansRequest();
    sendGetUserSettingsRequest();
  }, []);

  useEffect(() => {
    setUserSelectedPlansAndSlotsOnLoad({
      plans,
      setSelectedPlan,
      setProspects,
      // setSlots,
      search: history.location.search,
    });
  }, [plans]);

  useEffect(() => {
    executeOnRequestStatus({
      status: sendApplyCouponCodeRequestStatus,
      onSuccess: () => {
        // DO something
        setIsCouponCodeInvalid(false);
        setIsCouponCodeValid(true);
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: sendApplyCouponCodeRequestError,
          onError: () => {
            setIsCouponCodeValid(false);
            setIsCouponCodeInvalid(true);
          },
        });
      },
    });
  }, [sendApplyCouponCodeRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: purchaseLtdPlanRequestStatus,
      onSuccess: () => {
        if (purchaseLtdPlanResponse.requires_action) {
          handle3dSecureCard(purchaseLtdPlanResponse);
        } else {
          toaster.success(
            'Congratulations! Your deal has been activated successfully.',
            { theme: Theme.New },
          );
          redirectToOnboardingFrom();
          resetPurchaseLtdPlan();
        }
      },
      onFailed: () => {
        toaster.error('Oh no! Something went wrong. Please try again..', {
          theme: Theme.New,
          showCloseIcon: true,
          delay: 1000000,
        });
      },
    });
  }, [purchaseLtdPlanRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onSuccess: () => {
        if (!isEmpty(meta)) {
          const onboardingStep = meta.find(
            (setting) => setting.code === UserSettingCode.Onboarding,
          );

          if (onboardingStep) {
            const currentPath = location.pathname + location.search;
            handleOnboarding(meta, currentPath, location.search);
            return;
          }

          history.push('/sequence');
        }
      },
    });
  }, [getUserSettingsRequestStatus]);

  const getCouponCodeInputIcon = (): InputIconConfig[] => {
    if (getIsRequestPending(sendApplyCouponCodeRequestStatus)) {
      return [
        {
          place: Input.IconPlace.Right,
          identifier: 'spinner-alt',
          colorDefault: true,
          className: 'icon-loading',
        },
      ];
    }

    if (isCouponCodeValid) {
      return [
        {
          place: Input.IconPlace.Right,
          identifier: 'trash',
          colorDefault: true,
          onClick: onCouponCodeDelete,
        },
      ];
    }

    return [];
  };

  // const getSlotInput = () => {
  //   const isEmailOutreachPlanSelected =
  //     selectedPlan &&
  //     selectedPlan.planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME;

  //   const slotInput = (
  //     <InputNumber
  //       className="plan-options--slots"
  //       value={slots}
  //       onChange={(val) => onSlotsChangeHandler(val)}
  //       max={20}
  //       min={1}
  //       variant={slotsError && InputNumber.Variant.Error}
  //       disabled={isEmailOutreachPlanSelected}
  //       {...(selectedPlan &&
  //         selectedPlan.planCode ===
  //           SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME && {
  //           max: 20,
  //           showUpHandlerWithTooltip: true,
  //           upHandlerTooltipText:
  //             'The maximum number of slots allowed in this plan is 20. You cannot exceed the limit from here.',
  //         })}
  //     />
  //   );

  //   if (isEmailOutreachPlanSelected) {
  //     return (
  //       <OverlayTooltip
  //         text="Want more Slots? Upgrade to Email Outreach Pro."
  //         placement={Placement.BottomEnd}
  //       >
  //         {slotInput}
  //       </OverlayTooltip>
  //     );
  //   }

  //   return slotInput;
  // };

  return (
    <div className="signup">
      <div>
        <TopWelcome>
          <TopWelcome.Header>Make a Payment</TopWelcome.Header>
          <TopWelcome.Body>
            One time payment, Lifetime access{' '}
            <span role="img" aria-label="celebration">
              🎉
            </span>
          </TopWelcome.Body>
        </TopWelcome>
      </div>
      <FormSection>
        <div className="plan-summary d-flex">
          <div className="plan-summary--logo mr-3">
            <ImageIcon src={Images.SHLogoSmall} />
          </div>
          <div className="plan-summary--description">
            <h3 className="font-12 m-0 mt-1 font-semibold gray-txt-15">
              {selectedPlan && <>Saleshandy {selectedPlan?.planName}</>}
            </h3>
            <p className="font-14 m-0 mt-1 blue-txt-15">
              {selectedPlan && (
                <>
                  <span className="font-semibold">${selectedPlan?.amount}</span>{' '}
                  {/* /slot */}
                </>
              )}
            </p>
          </div>
        </div>

        <div className="plan-options d-flex">
          <div className="plan-options--select">
            <p className="font-12 font-semibold gray-txt-15 m-0">Plan</p>
            <Input
              name="planName"
              value={selectedPlan?.planName}
              className="plan-options--name"
              disabled
            />
          </div>
          <div>
            {/* <p className="font-12 font-semibold gray-txt-15 m-0">Slots</p> */}
            {/* {getSlotInput()} */}
            <p className="font-12 font-semibold gray-txt-15 m-0">Prospects</p>
            <Select<PlanSelectDropdown>
              className="plan-options--slots"
              options={prospects}
              selectedOptionKey={selectedPlan?.id?.toString()}
              optionRenderer={(option) => (
                <span>{formatProspect(option?.planName)}</span>
              )}
              onChange={([option]) => {
                setSelectedPlan(option);
              }}
              selectedOptionRenderer={([option]) => (
                <span>{formatProspect(option?.planName)}</span>
              )}
            />
          </div>
        </div>
        <div className="plan-separator" />
        <div className="plan-total d-flex justify-content-between">
          <h1 className="m-0 font-14 font-semibold blue-txt-15">
            Total Amount
          </h1>
          {/* <h1 className="m-0 font-14 font-semibold blue-txt-15">
            {selectedPlan && Number(slots) <= 20 ? (
              <>${(selectedPlan.amount * Number(slots)).toFixed(2)}</>
            ) : (
              <>-</>
            )}
          </h1> */}
          <h1 className="m-0 font-14 font-semibold blue-txt-15">
            {selectedPlan ? <>${selectedPlan?.amount}</> : <>-</>}
          </h1>
        </div>
        {haveDiscountCode ? (
          <div className="coupon-code--wrapper mt-3">
            <Input
              name="couponCode"
              placeholder="Enter a coupon code"
              value={couponCode}
              variant={
                (isCouponCodeInvalid && Input.Variant.Error) ||
                (isCouponCodeValid && Input.Variant.Succeeded)
              }
              onChange={setCouponCode}
              className="discount-code--input"
              button={
                !isCouponCodeValid &&
                !getIsRequestPending(sendApplyCouponCodeRequestStatus) && {
                  buttonTitle: 'Apply',
                  onClick: onCouponCodeApplied,
                }
              }
              icons={getCouponCodeInputIcon()}
            />
            <div className="coupon-code--status">
              {isCouponCodeInvalid && (
                <span className="font-8 font-medium red-txt-12">
                  INVALID DISCOUNT CODE
                </span>
              )}
              {isCouponCodeValid && (
                <span className="font-8 font-medium green-txt-16">
                  DISCOUNT CODE APPLIED SUCCESSFULLY
                </span>
              )}
            </div>
            {isCouponCodeValid && (
              <>
                <div className="plan-total d-flex justify-content-between mt-3">
                  <h1 className="m-0 font-14 font-semibold blue-txt-15">
                    Discount
                  </h1>
                  <h1 className="m-0 font-14 font-semibold blue-txt-15">
                    $
                    {getSubTotalDiscountAmount({
                      discountType: applyCouponCodeResponse.discountType,
                      discountValue: applyCouponCodeResponse.discountValue,
                      // totalAmount: selectedPlan.amount * Number(slots),
                      totalAmount: selectedPlan.amount,
                    })}
                  </h1>
                </div>

                <div className="plan-separator" />

                <div className="plan-total d-flex justify-content-between mb-3">
                  <h1 className="m-0 font-14 font-semibold blue-txt-15">
                    Total Amount to pay
                  </h1>
                  <h1 className="m-0 font-14 font-semibold blue-txt-15">
                    $
                    {getTotalAmountToPay({
                      discountType: applyCouponCodeResponse.discountType,
                      discountValue: applyCouponCodeResponse.discountValue,
                      // totalAmount: selectedPlan.amount * Number(slots),
                      totalAmount: selectedPlan.amount,
                    })}
                  </h1>
                </div>
              </>
            )}
          </div>
        ) : (
          <p
            className="m-0 font-12 font-medium blue-txt-11 text-right mt-3 have-adiscount-code"
            {...accessibleOnClick(handleHaveDiscountCode)}
          >
            Have a discount code?
          </p>
        )}

        {!isCouponCodeValid && <div className="plan-separator" />}

        <StripeCheckout
          token={onPurchaseHandler}
          name="Saleshandy"
          image={Images.SHIcon70}
          stripeKey={process.env.REACT_APP_STRIPE_KEY}
          amount={
            selectedPlan &&
            (isCouponCodeValid
              ? Number(
                  getTotalAmountToPay({
                    discountType: applyCouponCodeResponse?.discountType,
                    discountValue: applyCouponCodeResponse?.discountValue,
                    // totalAmount: selectedPlan.amount * Number(slots),
                    totalAmount: selectedPlan.amount,
                  }),
                ) * 100
              : // selectedPlan.amount * Number(slots) * 100)
                selectedPlan.amount * 100)
          }
          zipCode
          billingAddress
          ComponentClass="div"
        >
          <Button
            isLoading={
              isSecure3dPending ||
              getIsRequestPending(purchaseLtdPlanRequestStatus)
            }
            disabled={
              // slotsError ||
              !selectedPlan ||
              isSecure3dPending ||
              getIsRequestPending(purchaseLtdPlanRequestStatus)
            }
            variant={Button.Variant.Primary}
            className="buy-now-btn"
          >
            Proceed to Pay
          </Button>
        </StripeCheckout>
      </FormSection>

      {getIsRequestFailed(purchaseLtdPlanRequestStatus) && (
        <p className="font-14 gray-txt-15 font-italic text-center plan-note m-0 mb-2 h-auto">
          Looking for an alternate payment method?{' '}
          <span
            className="blue-txt-11 text-decoration-underline"
            {...accessibleOnClick(openHelpscoutBeacon)}
          >
            Click here
          </span>
          .
        </p>
      )}
    </div>
  );
};

export default LTDCheckout;
