import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import type { IProps } from './setting-container';
import SettingNavigation from './components/setting-navigation';
import hasPermission from '../../shared/utils/access-control/has-permission';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';

const MyProfile = React.lazy(() => import('./components/my-profile'));
const EmailAccounts = React.lazy(() => import('./components/email-account'));
const UpdateEmailAccount = React.lazy(
  () => import('./components/email-account/components/update-email-account'),
);
const Schedule = React.lazy(() => import('./components/schedule'));
const Team = React.lazy(() => import('./components/team'));
const CustomField = React.lazy(() => import('./components/custom-fields'));
const CustomDomain = React.lazy(() => import('./components/custom-domain'));
const AdminSettings = React.lazy(() => import('./components/admin-settings'));
const BillingSubscription = React.lazy(
  () => import('./components/billing-subscription'),
);
const DomainBlacklisting = React.lazy(
  () => import('./components/domain-blacklisting'),
);
const APITokens = React.lazy(() => import('./components/api-tokens'));

const Setting: React.FC<IProps> = () => (
  <Container fluid className="setting-container">
    <Row>
      <Col>
        <Row className="setting-content">
          {hasPermission(Permissions.SETTING_READ) && false && (
            <div className="setting-navigation">
              <SettingNavigation />
            </div>
          )}
          <div className="setting-components">
            <ErrorBoundaryWrapper>
              <Suspense>
                <Switch>
                  <Route path="/settings/profile" component={MyProfile} />
                  <Route
                    exact
                    path="/settings/email-accounts"
                    component={EmailAccounts}
                  />
                  <Route
                    exact
                    path="/settings/email-accounts/:hashId/:tab"
                    component={UpdateEmailAccount}
                  />
                  {/* <Route
                  path="/settings/safety-settings"
                  component={SafetySettings}
                  /> */}
                  <Route
                    path="/settings/custom-fields"
                    component={CustomField}
                  />
                  <Route
                    path="/settings/custom-domain"
                    component={CustomDomain}
                  />
                  <Route path="/settings/schedules" component={Schedule} />
                  {hasPermission(Permissions.ACCOUNT_USER_READ) && (
                    <Route path="/settings/team" component={Team} />
                  )}
                  <Route path="/settings/schedules" component={Schedule} />
                  {hasPermission(Permissions.ACCOUNT_USER_READ) && (
                    <Route
                      path="/settings/admin-settings"
                      component={AdminSettings}
                    />
                  )}
                  <Route
                    path="/settings/blacklist-domains"
                    component={DomainBlacklisting}
                  />
                  <Route
                    path="/settings/billing"
                    component={BillingSubscription}
                  />
                  <Route
                    path="/settings/billing/upgrade-plan"
                    component={BillingSubscription}
                  />
                  <Route
                    path="/settings/billing/upgrade-new-plan"
                    component={BillingSubscription}
                  />

                  <Route path="/settings/api-keys" component={APITokens} />

                  {/* <Route path="/settings/stages" component={UnderConstruction} />
                    <Route path="/settings/billing" component={UnderConstruction} /> */}
                  <Redirect to="/settings/profile" />
                </Switch>
              </Suspense>
            </ErrorBoundaryWrapper>
          </div>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default Setting;
