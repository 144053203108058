import React, { useState } from 'react';
import { OverlayTarget, OverlayTargetProps } from '../overlay-target';
import { Tooltip, TooltipProps } from '../../overlay/tooltip';
import { Placement } from '../enums';
import { generateTooltipId } from '../helpers';

type OverlayTargetTooltipProps = Omit<
  OverlayTargetProps,
  'overlay' | 'trigger' | 'children'
> &
  Partial<Pick<TooltipProps, 'id'>> &
  Pick<TooltipProps, 'text' | 'rePositionOnChange' | 'className'>;

export const OverlayTargetTooltip: React.FC<OverlayTargetTooltipProps> = ({
  id: _id,
  placement,
  text,
  rePositionOnChange,
  className,
  ...rest
}) => {
  const [id] = useState(_id || generateTooltipId());
  const tooltip = (
    <Tooltip
      id={id}
      text={text}
      rePositionOnChange={rePositionOnChange}
      className={className}
    />
  );
  return (
    <OverlayTarget {...rest} placement={placement}>
      {tooltip}
    </OverlayTarget>
  );
};

OverlayTargetTooltip.defaultProps = {
  placement: Placement.Bottom,
};
