import React from 'react';
import { Helmet } from 'react-helmet';
import type { IProps } from './type';

const HelmetIcon: React.FC<IProps> = ({ icon }) =>
  icon ? (
    <Helmet>
      <link rel="icon" type="image/png" href={icon} sizes="16x16" />
      <link rel="icon" type="image/png" href={icon} sizes="32x32" />
      <link rel="icon" type="image/png" href={icon} sizes="180x180" />
    </Helmet>
  ) : (
    <></>
  );
export default HelmetIcon;
