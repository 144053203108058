import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { PaginationQueryParams } from '../../../../shared/types/request';
import {
  getUsersListRequest,
  inviteUserRequest,
  resendInvitationRequest,
  cancelInvitationRequest,
  deactivateUserRequest,
  reactivateUserRequest,
  deleteUserRequest,
  getAllUsersListRequest,
} from './extra-actions';
import { User } from '../../types/team';
import { constants } from '../../../../shared/enums/constants';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getUsersListRequest: RequestState;
  inviteUserRequest: RequestState;
  resendInvitationRequest: RequestState;
  cancelInvitationRequest: RequestState;
  deactivateUserRequest: RequestState;
  reactivateUserRequest: RequestState;
  deleteUserRequest: RequestState;
  getAllUsersListRequest: RequestState;

  users: User[];
  isLoading: boolean;
  pagination: {
    options: Required<PaginationQueryParams>;
    pagesCount: number;
    count: number;
  };
  totalUsers: number;
  allUsers: Pick<User, 'id' | 'firstName' | 'lastName'>[];
};

const initialState: State = {
  getUsersListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  inviteUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reactivateUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cancelInvitationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deactivateUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resendInvitationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAllUsersListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  pagination: {
    options: {
      page: 1,
      limit: constants.DEFAULT_PAGE_SIZE,
    },
    count: 0,
    pagesCount: 0,
  },
  totalUsers: 0,
  users: [],
  isLoading: false,
  allUsers: [],
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersListRequest.pending, (state: State, action) => {
      state.getUsersListRequest.status = RequestStatus.Pending;
      state.getUsersListRequest.error = null;
      const keys = Object.keys(state.pagination.options);
      for (const key of keys) {
        state.pagination.options[key] = action.meta.arg[key];
      }
      state.isLoading = true;
    });
    builder.addCase(getUsersListRequest.fulfilled, (state: State, action) => {
      state.getUsersListRequest.status = RequestStatus.Succeeded;
      state.getUsersListRequest.message = action.payload.message;
      state.users = action.payload.payload.users;
      state.pagination.options.page = action.payload.payload.meta.currentPage;
      state.totalUsers = action.payload.payload.meta.itemCount;
      state.pagination.count = action.payload.payload.meta.totalItems;
      state.isLoading = false;
    });
    builder.addCase(getUsersListRequest.rejected, (state: State, action) => {
      state.getUsersListRequest.status = RequestStatus.Failed;
      state.getUsersListRequest.error = action.payload;
      state.isLoading = false;
    });

    // Invite user
    builder.addCase(inviteUserRequest.pending, (state) => {
      state.inviteUserRequest.status = RequestStatus.Pending;
      state.inviteUserRequest.error = null;
    });
    builder.addCase(inviteUserRequest.fulfilled, (state, action) => {
      state.inviteUserRequest.status = RequestStatus.Succeeded;
      state.inviteUserRequest.message = action.payload.message;
    });
    builder.addCase(inviteUserRequest.rejected, (state, action) => {
      state.inviteUserRequest.status = RequestStatus.Failed;
      state.inviteUserRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Resend Invitation
    builder.addCase(resendInvitationRequest.pending, (state) => {
      state.resendInvitationRequest.status = RequestStatus.Pending;
      state.resendInvitationRequest.error = null;
    });
    builder.addCase(resendInvitationRequest.fulfilled, (state, action) => {
      state.resendInvitationRequest.status = RequestStatus.Succeeded;
      state.resendInvitationRequest.message = action.payload.message;
    });
    builder.addCase(resendInvitationRequest.rejected, (state, action) => {
      state.resendInvitationRequest.status = RequestStatus.Failed;
      state.resendInvitationRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Cancel Invitation
    builder.addCase(cancelInvitationRequest.pending, (state) => {
      state.cancelInvitationRequest.status = RequestStatus.Pending;
      state.cancelInvitationRequest.error = null;
    });
    builder.addCase(cancelInvitationRequest.fulfilled, (state, action) => {
      state.cancelInvitationRequest.status = RequestStatus.Succeeded;
      state.cancelInvitationRequest.message = action.payload.message;
    });
    builder.addCase(cancelInvitationRequest.rejected, (state, action) => {
      state.cancelInvitationRequest.status = RequestStatus.Failed;
      state.cancelInvitationRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Deactivate User
    builder.addCase(deactivateUserRequest.pending, (state) => {
      state.deactivateUserRequest.status = RequestStatus.Pending;
      state.deactivateUserRequest.error = null;
    });
    builder.addCase(deactivateUserRequest.fulfilled, (state, action) => {
      state.deactivateUserRequest.status = RequestStatus.Succeeded;
      state.deactivateUserRequest.message = action.payload.message;
    });
    builder.addCase(deactivateUserRequest.rejected, (state, action) => {
      state.deactivateUserRequest.status = RequestStatus.Failed;
      state.deactivateUserRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Reactivate User
    builder.addCase(reactivateUserRequest.pending, (state) => {
      state.reactivateUserRequest.status = RequestStatus.Pending;
      state.reactivateUserRequest.error = null;
    });
    builder.addCase(reactivateUserRequest.fulfilled, (state, action) => {
      state.reactivateUserRequest.status = RequestStatus.Succeeded;
      state.reactivateUserRequest.message = action.payload.message;
    });
    builder.addCase(reactivateUserRequest.rejected, (state, action) => {
      state.reactivateUserRequest.status = RequestStatus.Failed;
      state.reactivateUserRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete User
    builder.addCase(deleteUserRequest.pending, (state) => {
      state.deleteUserRequest.status = RequestStatus.Pending;
      state.deleteUserRequest.error = null;
    });
    builder.addCase(deleteUserRequest.fulfilled, (state, action) => {
      state.deleteUserRequest.status = RequestStatus.Succeeded;
      state.deleteUserRequest.message = action.payload.message;
    });
    builder.addCase(deleteUserRequest.rejected, (state, action) => {
      state.deleteUserRequest.status = RequestStatus.Failed;
      state.deleteUserRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // All Users
    builder.addCase(getAllUsersListRequest.pending, (state: State) => {
      state.getAllUsersListRequest.status = RequestStatus.Pending;
      state.getAllUsersListRequest.error = null;
    });
    builder.addCase(
      getAllUsersListRequest.fulfilled,
      (state: State, action) => {
        state.getUsersListRequest.status = RequestStatus.Succeeded;
        state.getUsersListRequest.message = action.payload.message;
        state.allUsers = action.payload.payload;
      },
    );
    builder.addCase(getAllUsersListRequest.rejected, (state: State, action) => {
      state.getAllUsersListRequest.status = RequestStatus.Failed;
      state.getAllUsersListRequest.error = action.payload;
    });
  },
});

export { getUsersListRequest, inviteUserRequest };
export default teamSlice.reducer;
