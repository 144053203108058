import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import {
  verifyUserVerificationCodeRequest,
  hideVerifyUserVerificationCodeRequestError,
} from '../../signup-slice';
import VerificationCodeForm from './verification-code-form';

const mapStateToProps = (state: RootState) => ({
  verifyUserVerificationCodeRequestStatus:
    state.signup.verifyUserVerificationCodeRequest.status,
  verifyUserVerificationCodeRequestMessage:
    state.signup.verifyUserVerificationCodeRequest.message,
  verifyUserVerificationCodeRequestError:
    state.signup.verifyUserVerificationCodeRequest.error,
  verifyUserVerificationCodeRequestShowError:
    state.signup.verifyUserVerificationCodeRequest.showError,
  token: state.signup.token,
  trackingId: state.signup.trackingId,
  firstName: state.signup.firstName,
  lastName: state.signup.lastName,
  canResendUserVerificationCode: state.signup.canResendUserVerificationCode,
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {
  hideVerifyUserVerificationCodeRequestError: () =>
    hideVerifyUserVerificationCodeRequestError(),
  sendVerifyUserVerificationCodeRequest: (email: string, code: number) =>
    verifyUserVerificationCodeRequest({ email, code }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  showSignUpQuestionsComponent: () => void;
  email: string;
};

export default connector(VerificationCodeForm);
