import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import ResetPasswordInvalidLinkSuccess from './reset-password-invalid-link-success';
import { ResetPasswordCallbackRouteProps } from '../../../reset-password-callback/reset-password-callback-container';

const mapStateToProps = (state: RootState) => ({
  message: state.resetPassword.message,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> &
  ResetPasswordCallbackRouteProps;

export default withRouter(connector(ResetPasswordInvalidLinkSuccess));
