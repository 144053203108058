import { createAsyncThunk } from '@reduxjs/toolkit';
import { Source } from '../../shared/enums/source';
import { TrialExtendSource } from '../../shared/enums/trial-extend-source';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  getFirstSequence,
  getSampleSequence,
} from '../sequence/helpers/sequence.api';
import {
  CreateSequenceRequestPayload,
  UpdateUserSettingsRequestPayload,
} from '../sequence/types';
import {
  getUserSettings,
  updateUserSettings,
  upgradeOnboardingStep,
  getUserNotifications,
  getUnreadNotifications,
  markNotificationsAsRead,
  createSequence,
  compareAuthToken,
  getAgencyConfig,
  getPostLoadMeta,
  extendTrialPeriod,
} from './helpers/meta-api';
import { CompareAuthTokenPayload, UpgradeOnboardingStepPayload } from './types';

type CreateSequenceType = CreateSequenceRequestPayload & { source: Source };

export const getUserSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('home/getUserSettingsRequest', async (args, thunkAPI) => {
  try {
    return await getUserSettings();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getPostLoadMetaRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('home/getPostLoadMetaRequest', async (args, thunkAPI) => {
  try {
    return await getPostLoadMeta();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAgencyConfigRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('home/getAgencyConfigRequest', async (args, thunkAPI) => {
  try {
    return await getAgencyConfig(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateUserSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateUserSettingsRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('home/updateUserSettingsRequest', async ({ ...rest }, thunkAPI) => {
  try {
    return await updateUserSettings(rest);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getFirstSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('home/getFirstSequenceRequest', async (_, thunkAPI) => {
  try {
    return await getFirstSequence();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const compareAuthTokenRequest = createAsyncThunk<
  ResponseSuccess,
  CompareAuthTokenPayload,
  { rejectValue: ResponseErrorWithHandled }
>('home/compareAuthTokenRequest', async (args, thunkAPI) => {
  try {
    return await compareAuthToken(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSampleSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('home/getSampleSequenceRequest', async (_, thunkAPI) => {
  try {
    return await getSampleSequence();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const upgradeOnboardingStepRequest = createAsyncThunk<
  ResponseSuccess,
  UpgradeOnboardingStepPayload,
  { rejectValue: ResponseErrorWithHandled }
>('home/upgradeOnboardingStepRequest', async (args, thunkAPI) => {
  try {
    return await upgradeOnboardingStep(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getUserNotificationsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('home/userNotifications', async (args, thunkAPI) => {
  try {
    return await getUserNotifications(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getUserUnreadNotificationsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('home/userUnreadNotification', async (args, thunkAPI) => {
  try {
    return await getUnreadNotifications();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const markNotificationsAsReadRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('home/markNotificationAsRead', async (args, thunkApi) => {
  try {
    return await markNotificationsAsRead(args);
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const createSequenceFromGettingStartedRequest = createAsyncThunk<
  ResponseSuccess,
  CreateSequenceType,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/createSequenceFromGettingStartedRequest',
  async ({ source, ...rest }, thunkAPI) => {
    try {
      return await createSequence(source, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const extendTrialPeriodRequest = createAsyncThunk<
  ResponseSuccess,
  TrialExtendSource,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/extendTrialPeriodRequest', async (args, thunkAPI) => {
  try {
    return await extendTrialPeriod(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
