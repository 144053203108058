import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getApiTokenListRequest,
  createApiTokenRequest,
  revokeApiTokenRequest,
} from './extra-actions';
import { ApiToken } from './types';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  getApiTokenListRequest: RequestState;
  createApiTokenRequest: RequestState;
  revokeApiTokenRequest: RequestState;
  apiTokenList: ApiToken[];
  generatedApiToken: string;
  isLoading: boolean;
}

const initialState: State = {
  getApiTokenListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createApiTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  revokeApiTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  apiTokenList: [],
  generatedApiToken: '',
  isLoading: false,
};

const apiTokensSlice = createSlice({
  name: 'apiToken',
  initialState,
  reducers: {
    resetCreateApiTokenRequest: (state) => {
      state.createApiTokenRequest.status = RequestStatus.Ideal;
      state.createApiTokenRequest.error = null;
      state.generatedApiToken = '';
    },
  },
  extraReducers: (builder) => {
    // get api token
    builder.addCase(getApiTokenListRequest.pending, (state) => {
      state.getApiTokenListRequest.status = RequestStatus.Pending;
      state.getApiTokenListRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getApiTokenListRequest.fulfilled, (state, action) => {
      state.getApiTokenListRequest.status = RequestStatus.Succeeded;
      state.getApiTokenListRequest.message = action.payload.message;
      state.apiTokenList = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getApiTokenListRequest.rejected, (state, action) => {
      state.getApiTokenListRequest.status = RequestStatus.Failed;
      state.getApiTokenListRequest.error = action.payload;
      state.isLoading = false;
    });

    // Create api token
    builder.addCase(createApiTokenRequest.pending, (state) => {
      state.createApiTokenRequest.status = RequestStatus.Pending;
      state.createApiTokenRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(createApiTokenRequest.fulfilled, (state, action) => {
      state.createApiTokenRequest.status = RequestStatus.Succeeded;
      state.createApiTokenRequest.message = action.payload.message;
      state.generatedApiToken = action.payload.payload.hashToken;
      state.isLoading = false;
    });
    builder.addCase(createApiTokenRequest.rejected, (state, action) => {
      state.createApiTokenRequest.status = RequestStatus.Failed;
      state.createApiTokenRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // revoke api token
    builder.addCase(revokeApiTokenRequest.pending, (state) => {
      state.revokeApiTokenRequest.status = RequestStatus.Pending;
      state.revokeApiTokenRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(revokeApiTokenRequest.fulfilled, (state, action) => {
      state.revokeApiTokenRequest.status = RequestStatus.Succeeded;
      state.revokeApiTokenRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(revokeApiTokenRequest.rejected, (state, action) => {
      state.revokeApiTokenRequest.status = RequestStatus.Failed;
      state.revokeApiTokenRequest.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { resetCreateApiTokenRequest } = apiTokensSlice.actions;

export default apiTokensSlice.reducer;
