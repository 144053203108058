import { RootState } from '../../../../../../store/root-reducer';
import { changePasswordRequest, hideError } from '../../change-password-slice';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import ChangePasswordForm from './change-password-form';
import { ResetPasswordCallbackRouteProps } from '../../../reset-password-callback/types';
import { setUserPasswordRequest } from '../../extra-actions';

const mapStateToProps = (state: RootState) => ({
  status: state.changePassword.status,
  message: state.changePassword.message,
  error: state.changePassword.error,
  showError: state.changePassword.showError,
  setPasswordStatus: state.setUserPassword.status,
  setPasswordMessage: state.setUserPassword.message,
  setPasswordError: state.setUserPassword.error,
  setPasswordShowError: state.setUserPassword.showError,
});

type IPropsWithRoute = ResetPasswordCallbackRouteProps & {
  showSuccessComponent: () => void;
};

const mapDispatchToProps = (dispatch, ownProps: IPropsWithRoute) => ({
  hideError: () => dispatch(hideError()),
  sendChangePasswordRequest: (password: string) =>
    dispatch(
      changePasswordRequest({ code: ownProps.match.params.code, password }),
    ),
  userSetPasswordRequest: (password: string) =>
    dispatch(setUserPasswordRequest({ newPassword: password })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & IPropsWithRoute;

export default withRouter(connector(ChangePasswordForm));
