import React from 'react';
import { withTranslation } from 'react-i18next';
import AlertInfoCircle from '../../../../../../shared/components/alert-info-circle';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import Modal from '../../../../../../shared/design-system/components/atoms/modal/modal';
import { IProps } from './types';

const DeleteEmailAccountModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
  isDefaultEmailAccount,
  t,
}) => (
  <Modal
    show={show}
    className="delete-modal"
    titleContent={
      <div className="d-flex">
        <AlertInfoCircle />
        <div className="pl-2"> {t('labels.delete_email')}</div>
      </div>
    }
    showCloseIcon={true}
    onClose={onClose}
    onHide={onClose}
    cancelButtonText={t('labels.cancel')}
    submitButtonText={t('labels.delete')}
    onSubmit={!isDefaultEmailAccount ? onSubmit : onClose}
    cancelButtonVarient={Variant.Outlined}
    isSubmitLoading={isRequestPending}
    isSubmitDisabled={isRequestPending}
  >
    <div>
      <p className="regular-2">
        If warm-up is enabled for this account, then it would be disabled and
        removed from the email account pool. Instead of deleting, you can
        disconnect your account.
      </p>
    </div>
  </Modal>
);

export default withTranslation()(DeleteEmailAccountModal);
