import React from 'react';
import ImageIcon from '../../../../shared/components/images/image-icon';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';

const ConnectOption = ({ logo, onClick, optionLabel, optionDesc }) => (
  <div
    className="connect-option d-flex flex-column"
    {...accessibleOnClick(onClick)}
  >
    <ImageIcon src={logo} />
    <span className="connect-option-label">{optionLabel}</span>
    <span className="connect-option-desc">{optionDesc}</span>
  </div>
);

export default ConnectOption;
