import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUsersList,
  inviteUser,
  resendInvitation,
  cancelInvitation,
  deactivateUser,
  reactivateUser,
  deleteUser,
  getAllUsersList,
} from '../../helpers/team-api';
import { PaginationQueryParams } from '../../../../shared/types/request';

import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';
import { InviteUserRequestPayload } from '../../types/request-payload';

export const getUsersListRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams,
  { rejectValue: ResponseErrorWithHandled }
>('team/getUsersListRequest', async (args, thunkAPI) => {
  try {
    return await getUsersList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const inviteUserRequest = createAsyncThunk<
  ResponseSuccess,
  InviteUserRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('team/inviteUserRequest', async (payload, thunkAPI) => {
  try {
    return await inviteUser(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resendInvitationRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('team/resendInvitationRequest', async (payload, thunkAPI) => {
  try {
    return await resendInvitation(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const cancelInvitationRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('team/cancelInvitationRequest', async (payload, thunkAPI) => {
  try {
    return await cancelInvitation(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deactivateUserRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('team/deactivateUserRequest', async (payload, thunkAPI) => {
  try {
    return await deactivateUser(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const reactivateUserRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('team/reactivateUserRequest', async (payload, thunkAPI) => {
  try {
    return await reactivateUser(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteUserRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('team/deleteUserRequest', async (payload, thunkAPI) => {
  try {
    return await deleteUser(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAllUsersListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('team/getAllUsersListRequest', async (args, thunkAPI) => {
  try {
    return await getAllUsersList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
