import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import UserOnboarding from './user-onboarding';

const mapStateToProps = (state: RootState) => ({
  accountDetailsRequestStatus: state.accountDetails.setPasswordRequest.status,
  accountDetailsRequestMessage: state.accountDetails.setPasswordRequest.message,
  canResendUserVerificationCode:
    state.accountDetails.canResendUserVerificationCode,
  verifyUserVerificationCodeRequestStatus:
    state.signup.verifyUserVerificationCodeRequest.status,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(UserOnboarding));
