import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import {
  BlacklistDomainPayload,
  DeleteBlacklistedDomainPayload,
} from './types';
import {
  blacklistDomain,
  getBlacklistDomains,
  deleteBlacklistedDomain,
} from '../../helpers/blacklist-domain.api';

export const createBlacklistedDomainRequest = createAsyncThunk<
  ResponseSuccess,
  BlacklistDomainPayload,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/createCustomDomainRequest', async ({ domains }, thunkAPI) => {
  try {
    return await blacklistDomain(domains);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getBlacklistedDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/getBlacklistedDomains', async (args, thunkAPI) => {
  try {
    return await getBlacklistDomains();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteBlacklistDomainRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteBlacklistedDomainPayload,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/deleteBlacklistDomain', async ({ domainId }, thunkAPI) => {
  try {
    return await deleteBlacklistedDomain(domainId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
