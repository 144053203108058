import React from 'react';
import SignupForm from '../signup-form';
import { IProps, IState } from './types';

class Signup extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.showSignUpQuestionsComponent = this.showSignUpQuestionsComponent.bind(
      this,
    );
  }

  showSignUpQuestionsComponent() {
    const { history } = this.props;

    history.push('/warmup');
  }

  render() {
    return <SignupForm />;
  }
}

export default Signup;
