export const supportUrls = {
  teamsOfService: 'https://www.saleshandy.com/terms/',
  scheduleCall:
    'https://www.saleshandy.com/demo/?utm_source=webapp&utm_medium=top_right_button&utm_campaign=product_demo_v3/',
  refundPolicy:
    'https://support.saleshandy.com/article/279-what-is-the-refund-policy-of-saleshandy',
  becomeOurAffiliate:
    'https://www.saleshandy.com/affiliate-program/?utm_source=inapp&utm_medium=direct&utm_campaign=affiliate',
  warmupFaq:
    'https://support.saleshandy.com/article/330-how-does-saleshandy-email-warm-up-work',
  outreachPlanViewFAQ:
    ' https://support.saleshandy.com/article/339-which-plans-are-available-in-saleshandy',
  updateCardDetails:
    'https://billing.stripe.com/p/login/14kbJC7H05AW8qk9AA?prefilled_email=',
  smtpImapFaq:
    'https://support.saleshandy.com/article/322-unable-to-connect-email-account-with-smtp-and-imap',
  smtpImapCouldNotAuth:
    'https://support.trulyinbox.com/en/articles/8516558-how-to-fix-smtp-imap-could-not-authenticate-error',
  smtpImapEmailNotFound:
    'https://support.trulyinbox.com/en/articles/8516581-how-to-fix-smtp-imap-email-not-found-error',
  smtpImapConnectionTimeOut:
    'https://support.trulyinbox.com/en/articles/8516584-how-to-fix-smtp-imap-connection-timeout-error',
  smtpImapOtherError:
    'https://support.trulyinbox.com/en/articles/8516585-how-to-fix-smtp-imap-other-error',
  pauseProspectInSequence:
    'https://support.saleshandy.com/article/344-how-to-pause-a-prospect-in-sequence?preview=63aad6391e9b0d75c7275db4',
  spintaxBlog:
    'https://support.saleshandy.com/article/345-what-is-spintax-and-how-to-use-it',
  coldEmailMasterClass:
    'https://www.saleshandy.com/cold-email-masterclass/?utm_source=inapp&utm_medium=inappsection&utm_campaign=coldemailmastery',
  intercomBaseUrl: 'https://api-iam.intercom.io',
  howToConnectGmailAccountWithSmtpImap:
    'https://support.trulyinbox.com/en/articles/8516510-how-to-connect-a-gmail-account-with-smtp-imap-in-trulyinbox',
  howToConnectGmailAccountWithSmtpImapStep3:
    'https://support.trulyinbox.com/en/articles/8516510-how-to-connect-a-gmail-account-with-smtp-imap-in-trulyinbox',
  howToConnectGmailAccountWithSmtpImapVideo: 'https://youtu.be/fUpKGwuncvQ',
  privacyPolicy:
    'https://support.trulyinbox.com/en/articles/8707865-privacy-policy-trulyinbox',
  termsOfServices:
    'https://support.trulyinbox.com/en/articles/8516536-terms-of-sevice-trulyinbox',
  sendColdEmail:
    'https://my.saleshandy.com/signup?utm_source=trulyinbox_app&utm_medium=inappTI&utm_campaign=trulyinbox_partner',
  connectBulkEmailDemoCSV:
    'https://v3artifacts.s3.us-west-2.amazonaws.com/Connect_Bulk_Email_TrulyInbox.csv',
  bulkImportCSVGuide:
    'https://support.trulyinbox.com/en/articles/8925626-how-to-import-your-email-account-in-bulk-via-smtp-imap',
};
