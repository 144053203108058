import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FormSection from '../../../form-section';
import BrokenLink from '../../../../../../shared/components/images/broken-link';
import Alert from '../../../../../../shared/design-system/components/alert';
import { IProps } from './types';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import ResetPasswordFormComponent from '../../../reset-password/components/reset-password-form-component';
import AlertContainer from '../../../alert-container';
import Logo from '../../../../../../shared/components/logo';

class ResetPasswordInvalidLinkForm extends React.Component<IProps, null> {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { status, showSuccessComponent } = this.props;
    if (status !== prevProps.status) {
      if (status === RequestStatus.Succeeded) {
        showSuccessComponent();
      }
    }
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError();
  }

  onFormSubmit(email: string) {
    const { sendResetPasswordRequest } = this.props;
    sendResetPasswordRequest(email);
  }

  render() {
    const { status, error, showError, hideError } = this.props;

    const isLoading = status === RequestStatus.Pending;

    return (
      <>
        <Row>
          <Col>
            <div className="invalid-msg-header">
              <Logo
                logoTextStyles={{
                  color: '#212529',
                  fontSize: '1.25rem',
                }}
                isTertiary
              />
            </div>
          </Col>
        </Row>
        <div className="invalid-msg-inner">
          <div className="top-welcome">
            <BrokenLink />
            <h3 className="semibold-7">Link not valid</h3>
            <p className="regular-2">
              This link is incorrect or might have expired. You need to request
              the reset password link again.
            </p>
          </div>
          {showError && (
            <AlertContainer>
              <Alert
                variant={Alert.Variant.Danger}
                dismissible={true}
                onClose={hideError}
                iconIdentifier="close-o"
                header="Whoops"
              >
                {error.message}
              </Alert>
            </AlertContainer>
          )}
          <FormSection>
            <ResetPasswordFormComponent
              isLoading={isLoading}
              buttonText="Request a new link"
              onSubmit={this.onFormSubmit}
            />
            <div className="login-link regular-2">
              <Link to="/login" className="pointer">
                Return to Login
              </Link>
            </div>
          </FormSection>
        </div>
      </>
    );
  }
}

export default ResetPasswordInvalidLinkForm;
