import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from '../../../../shared/design-system/components/atoms/button';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster from '../../../../shared/toaster';
import { showGeneralErrorNotification } from '../../../../shared/utils/errors';
import type { IProps } from './domain-blacklisting-container';
import HeaderBanner from '../header-banner';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';

const DomainBlacklisting: React.FC<IProps> = (props) => {
  // States
  const [domainInput, setDomainInput] = useState<string>('');

  const { t } = useTranslation();

  // https://regex101.com/r/YlyqJk/1 => Regular Expression working example
  const domainRegex = /^\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z,A-Z]+)\s*(?:,\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z,A-Z]+)\s*)*$/;
  // Loading Props
  const { hideLoading, showLoading, resetBlacklistDomains } = props;

  // Create blacklist domain
  const {
    sendCreateBlacklistDomainRequest,
    blacklistDomainRequestStatus,
    blacklistDomainRequestMessage,
  } = props;

  // Get Blacklist domains
  const { sendGetBlacklistDomainRequest, blacklistedDomains } = props;

  // Delete Blacklist domain
  const {
    sendDeleteBlacklistDomainRequest,
    deleteBlacklistDomainStatus,
    deleteBlacklistDomainMessage,
  } = props;

  // Subscription Plan
  const { subscriptionPlan } = props;

  // Use Effects

  // Fetching blacklist domains
  useEffect(() => {
    sendGetBlacklistDomainRequest();

    return () => {
      resetBlacklistDomains();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Adding Blacklist domain
  useEffect(() => {
    if (blacklistDomainRequestStatus === RequestStatus.Pending) {
      showLoading();
    }

    if (blacklistDomainRequestStatus === RequestStatus.Succeeded) {
      toaster.success(blacklistDomainRequestMessage);
      sendGetBlacklistDomainRequest();
      setDomainInput('');
      hideLoading();
    }

    if (blacklistDomainRequestStatus === RequestStatus.Failed) {
      showGeneralErrorNotification(blacklistDomainRequestMessage);
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blacklistDomainRequestStatus]);

  // Deleting blacklist domain
  useEffect(() => {
    if (deleteBlacklistDomainStatus === RequestStatus.Pending) {
      showLoading();
    }

    if (deleteBlacklistDomainStatus === RequestStatus.Succeeded) {
      toaster.success(deleteBlacklistDomainMessage);
      sendGetBlacklistDomainRequest();
      hideLoading();
    }

    if (deleteBlacklistDomainStatus === RequestStatus.Failed) {
      showGeneralErrorNotification(deleteBlacklistDomainMessage);
      hideLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBlacklistDomainStatus]);

  const saveDomains = () => {
    const valid = domainRegex.test(domainInput);

    if (!valid) {
      toaster.error(t('messages.valid_domain'));
    } else {
      sendCreateBlacklistDomainRequest({ domains: domainInput });
    }
  };

  const deleteDomain = (domainId: number) => {
    sendDeleteBlacklistDomainRequest({ domainId });
  };

  const setDomainInputValue = (e) => {
    setDomainInput(e.target.value);
  };

  const blackListClass = classnames([
    'black-list',
    {
      'black-list-free-plan-height':
        subscriptionPlan === SubscriptionPlans.FREE,
    },
    { 'black-list-height': subscriptionPlan !== SubscriptionPlans.FREE },
  ]);

  return (
    <Container fluid className="domain-blacklisting-container">
      <Row>
        <Col md={12}>
          <span className="semibold-3">{t('messages.blacklist_domains')}</span>
          <span className="regular-2 block-display">
            {t('messages.blacklist_domain_sub_header')}
          </span>
        </Col>
      </Row>
      <hr />
      {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
      <Row>
        <Col md={12} className="mt-2">
          <span className="semibold-3">List of blocked domains</span>
          <span className="regular-2 block-display grey-color">
            {t('messages.blacklist_domain_instruction')}
          </span>
        </Col>
        <Col md={12} className={blackListClass}>
          <div className="my-2">
            {blacklistedDomains.length > 0 &&
              blacklistedDomains.map((domain) => (
                <span className="bs-tag tag-color my-2" key={domain.id}>
                  {domain.domain}
                  <span
                    className="bs-tag-close-icon ml-2"
                    {...accessibleOnClick(() => deleteDomain(domain.id))}
                  >
                    <i className="sh-close" />
                  </span>
                </span>
              ))}
          </div>
          <div className="my-2">
            <textarea
              value={domainInput}
              placeholder="Enter domains here ..."
              className="bs-input pt-3"
              rows={4}
              onChange={setDomainInputValue}
            />
          </div>
          <div className="my-4">
            <Button
              variant={Button.Variant.Primary}
              type={Button.Type.Submit}
              onClick={saveDomains}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DomainBlacklisting;
