import { RootState } from '../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getUsersListRequest } from './team-slice';
import { PaginationQueryParams } from '../../../../shared/types/request';
import Team from './team';

const mapStateToProps = (state: RootState) => ({
  getUsersListRequestStatus: state.team.getUsersListRequest.status,
  getUsersListRequestMessage: state.team.getUsersListRequest.message,
  getUsersListRequestError: state.team.getUsersListRequest.error,
  pagination: state.team.pagination,
  isLoading: state.team.isLoading,
  users: state.team.users,
  isEmpty: state.team.users.length === 0,
});

const mapDispatchToProps = {
  sendGetUsersListRequest: (options: PaginationQueryParams) =>
    getUsersListRequest(options),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(Team);
