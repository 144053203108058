/* eslint-disable consistent-return */
import { ComponentType } from 'react';

const retryLazy = (
  componentToImport,
): Promise<{ default: ComponentType<any> }> =>
  new Promise((res, rej) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    const refreshCount = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refresh-count') || '0',
    );

    componentToImport()
      .then((component) => {
        console.log('lazy-retry ~ then');
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        window.sessionStorage.setItem('retry-lazy-refresh-count', '0');
        res(component);
      })
      .catch((error) => {
        console.log('🚀 ~ retry-lazy ~ error:', error);
        console.log('🚀 ~ retry-lazy ~ hasRefreshed:', hasRefreshed);
        if (!hasRefreshed || refreshCount <= 3) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          window.sessionStorage.setItem(
            'retry-lazy-refresh-count',
            String(refreshCount + 1),
          );
          return window.location.reload();
        }
        rej(error);
      });
  });

export default retryLazy;
