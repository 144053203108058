import React from 'react';
import ResetPasswordSuccess from './components/reset-password-success';
import ResetPasswordForm from './components/reset-password-form';
import { IProps, IState } from './types';

class ResetPassword extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { showSuccessComponent: false };
    this.showSuccessComponent = this.showSuccessComponent.bind(this);
  }

  showSuccessComponent() {
    this.setState({ showSuccessComponent: true });
  }

  render() {
    const { showSuccessComponent } = this.state;

    return (
      <div className="forgot-right-inner">
        {showSuccessComponent ? (
          <ResetPasswordSuccess />
        ) : (
          <ResetPasswordForm showSuccessComponent={this.showSuccessComponent} />
        )}
      </div>
    );
  }
}

export default ResetPassword;
