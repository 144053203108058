import {
  ProfileProgress,
  ProfileProgressSteps,
} from '../../types/user-setting';

export type IProps = {
  firstName: string;
  profileProgress: ProfileProgress[];
  showHideWatchVideoModal: (value: boolean) => void;
  handleActivateSequenceTooltip: (value: boolean) => void;
  handleAddEmailAccountModal: (value: boolean) => void;
  createSequence: () => void;
  showGettingStarted: boolean;
};

export type GettingStartedStep = {
  id: number;
  stepName: string;
  isCompleted: boolean;
  state: Record<string, unknown>;
};

export const GettingStartedStepType = {
  LINK: 'link',
  CLICK: 'click',
};

export const ProfileProgressStepsState = {
  [ProfileProgressSteps.WatchVideo]: {
    type: GettingStartedStepType.CLICK,
  },
  [ProfileProgressSteps.ConnectEmail]: {
    type: GettingStartedStepType.CLICK,
    path: '/settings/email-accounts',
  },
  [ProfileProgressSteps.CreateSequence]: {
    type: GettingStartedStepType.CLICK,
  },
  [ProfileProgressSteps.AddProspects]: {
    type: GettingStartedStepType.CLICK,
  },
  [ProfileProgressSteps.ActivateSequence]: {
    type: GettingStartedStepType.CLICK,
  },
};
