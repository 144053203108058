import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { MyProfile } from '../../types/my-profile';
import {
  getMyProfileRequest,
  updateMyProfileRequest,
  updatePasswordRequest,
  verifyCurrentPasswordRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getUserDetailRequest: RequestState;
  updateMyProfileRequest: RequestState;
  updatePasswordRequest: RequestState;
  verifyCurrentPasswordRequest: RequestState;
  myProfile: MyProfile;
  isCurrentPasswordValid: boolean;
  isLoading: boolean;
};

const initialState: State = {
  getUserDetailRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateMyProfileRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updatePasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyCurrentPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  myProfile: {
    firstName: null,
    lastName: null,
    timeZone: null,
    email: null,
  },
  isCurrentPasswordValid: null,
  isLoading: false,
};

const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get My profile
    builder.addCase(getMyProfileRequest.pending, (state) => {
      state.getUserDetailRequest.status = RequestStatus.Pending;
      state.getUserDetailRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getMyProfileRequest.fulfilled, (state, action) => {
      state.getUserDetailRequest.status = RequestStatus.Succeeded;
      state.getUserDetailRequest.message = action.payload.message;
      state.myProfile = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getMyProfileRequest.rejected, (state, action) => {
      state.getUserDetailRequest.status = RequestStatus.Failed;
      state.getUserDetailRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update My profile
    builder.addCase(updateMyProfileRequest.pending, (state) => {
      state.updateMyProfileRequest.status = RequestStatus.Pending;
      state.updateMyProfileRequest.error = null;
    });
    builder.addCase(updateMyProfileRequest.fulfilled, (state, action) => {
      state.updateMyProfileRequest.status = RequestStatus.Succeeded;
      state.updateMyProfileRequest.message = action.payload.message;
    });
    builder.addCase(updateMyProfileRequest.rejected, (state, action) => {
      state.updateMyProfileRequest.status = RequestStatus.Failed;
      state.updateMyProfileRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Password
    builder.addCase(updatePasswordRequest.pending, (state) => {
      state.updatePasswordRequest.status = RequestStatus.Pending;
      state.updatePasswordRequest.error = null;
    });
    builder.addCase(updatePasswordRequest.fulfilled, (state, action) => {
      state.updatePasswordRequest.status = RequestStatus.Succeeded;
      state.updatePasswordRequest.message = action.payload.message;
    });
    builder.addCase(updatePasswordRequest.rejected, (state, action) => {
      state.updatePasswordRequest.status = RequestStatus.Failed;
      state.updatePasswordRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Password
    builder.addCase(verifyCurrentPasswordRequest.pending, (state) => {
      state.verifyCurrentPasswordRequest.status = RequestStatus.Pending;
      state.verifyCurrentPasswordRequest.error = null;
    });
    builder.addCase(verifyCurrentPasswordRequest.fulfilled, (state, action) => {
      state.verifyCurrentPasswordRequest.status = RequestStatus.Succeeded;
      state.verifyCurrentPasswordRequest.message = action.payload.message;
      state.isCurrentPasswordValid = action.payload.payload;
    });
    builder.addCase(verifyCurrentPasswordRequest.rejected, (state, action) => {
      state.verifyCurrentPasswordRequest.status = RequestStatus.Failed;
      state.verifyCurrentPasswordRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export { getMyProfileRequest, updateMyProfileRequest, updatePasswordRequest };
export default myProfileSlice.reducer;
