import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMyProfile,
  updateMyProfile,
  updatePassword,
  verifyCurrentPassword,
} from '../../helpers/my-profile.api';
import {
  UpdateUserProfilePayload,
  UpdatePasswordPayload,
  verifyCurrentPasswordPayload,
} from '../../types/request-payload';

import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

export const getMyProfileRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/getMyProfileRequest', async (args, thunkAPI) => {
  try {
    return await getMyProfile();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateMyProfileRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateUserProfilePayload,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/updateMyProfileRequest', async (payload, thunkAPI) => {
  try {
    return await updateMyProfile(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updatePasswordRequest = createAsyncThunk<
  ResponseSuccess,
  UpdatePasswordPayload,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/updatePasswordRequest', async (payload, thunkAPI) => {
  try {
    return await updatePassword(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const verifyCurrentPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  verifyCurrentPasswordPayload,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/verifyCurrentPassword', async (payload, thunkAPI) => {
  try {
    return await verifyCurrentPassword(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
