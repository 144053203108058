import api from '../../../api';
import {
  EmailAccountId,
  EmailAccountWarmupFilters,
  EmailAccountWarmupSettingsPayload,
  EmailWarmupDeliverabilityPayload,
} from '../types';

export const getEmailAccountsInWarmup = async (
  options: EmailAccountWarmupFilters,
) =>
  api.get(`email-account/email-warmup`, {
    params: { ...options },
  });

export const getEmailAccountWarmupDeliverabilityReport = async ({
  id,
  timeFrame,
}: EmailWarmupDeliverabilityPayload) =>
  api.get(`email-account/email-warmup/deliverability/${id}`, {
    params: {
      timeFrame,
    },
  });

export const getEmailAccountWarmupList = async () =>
  api.get('email-account/email-warmup/list');

export const getEmailAccountWarmupSentReceivedReport = async ({
  id,
}: EmailAccountId) => api.get(`email-account/email-warmup/sent-received/${id}`);

export const getEmailAccountWarmupSettings = async ({ id }: EmailAccountId) =>
  api.get(`email-account/email-warmup/settings/${id}`);

export const saveEmailAccountWarmupSettings = async ({
  id,
  ...rest
}: EmailAccountWarmupSettingsPayload) =>
  api.patch(`email-account/email-warmup/settings/${id}`, rest);

export const turnOnOffEmailAccountWarmup = async ({ id }: EmailAccountId) =>
  api.patch(`email-account/email-warmup/${id}`);

export const importEmailAccountCsv = (file: File) => {
  const data = new FormData();
  data.append('file', file);

  return api.post('/email-account/bulk-connect', data);
};
