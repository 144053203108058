export enum SubscriptionPlans {
  FREE = 'free',
  TRIAL = 'trial',
  PAID_MONTHLY = 'paid-monthly',
  PAID_YEARLY = 'paid-yearly',
  EMAIL_STARTER_MONTHLY = 'email-starter-monthly',
  EMAIL_STARTER_YEARLY = 'email-starter-yearly',
  EMAIL_OUTREACH_MONTHLY = 'email-outreach-monthly',
  EMAIL_OUTREACH_YEARLY = 'email-outreach-yearly',
  EMAIL_OUTREACH_LIFETIME = 'ltd-email-outreach',
  EMAIL_OUTREACH_PRO_LIFETIME = 'ltd-email-outreach-pro',
  OLD_OUTREACH_MONTHLY = 'old-outreach-monthly',
  OLD_OUTREACH_YEARLY = 'old-outreach-yearly',
  OUTREACH_BASIC_MONTHLY = 'email-outreach-basic-monthly',
  OUTREACH_BASIC_YEARLY = 'email-outreach-basic-yearly',
  OUTREACH_PRO_MONTHLY = 'email-outreach-pro-monthly',
  OUTREACH_PRO_YEARLY = 'email-outreach-pro-yearly',
  OUTREACH_SCALE_MONTHLY = 'email-outreach-scale-monthly',
  OUTREACH_SCALE_YEARLY = 'email-outreach-scale-yearly',
}

export enum SubscriptionPlanTitle {
  StarterMonthly = 'Starter (Monthly)',
  StarterYearly = 'Starter (Yearly)',
  OutreachMonthly = 'Outreach (Monthly)',
  OutreachYearly = 'Outreach (Yearly)',
  EmailOutreach = 'Email Outreach',
  EmailOutreachPro = 'Outreach (Lifetime)',
  CancelledProMonthly = 'Cancelled Pro ( Monthly )',
  CancelledProYearly = 'Cancelled Pro ( Yearly )',
  OutreachBasicMonthly = 'Outreach Basic (Monthly)',
  OutreachBasicYearly = 'Outreach Basic (Yearly)',
  OutreachProMonthly = 'Outreach Pro (Monthly)',
  OutreachProYearly = 'Outreach Pro (Yearly)',
  OutreachScaleMonthly = 'Outreach Scale (Monthly)',
  OutreachScaleYearly = 'Outreach Scale (Yearly)',
  Custom = 'Custom',
}

export enum SubscriptionPlanPrice {
  StarterMonthly = 9,
  StarterYearly = 7,
  OutreachMonthly = 33,
  OutreachYearly = 25,
  EmailOutreachLifetime = 99,
  EmailOutreachProMonthly = 59,
  EmailOutreachProYearly = 49,
  EmailOutreachProLifetime = 79,
  OldOureachMonthly = 29,
  OldOureachYearly = 25,
  OutreachBasicMonthly = 34,
  OutreachBasicYearly = 27,
  OutreachProMonthly = 94,
  OutreachProYearly = 79,
  OutreachScaleMonthly = 179,
  OutreachScaleYearly = 159,
}

export enum SubscriptionPlanModes {
  MonthlyMode = 1,
  YearlyMode = 12,
  LTDMode = 0,
}

export enum ModifyPlanAction {
  Update = 'update',
  Downgrade = 'downgrade',
  AddSlots = 'add-slots',
}

export enum PlanPurchaseAction {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
  Subscribe = 'subscribe',
}

export enum PlanType {
  User = 'user',
  EmailAccount = 'email-account',
  Slot = 'slot',
  ValueBased = 'value-based',
  LTD = 'ltd',
  LTD2 = 'ltd-2',
}

export const SubscriptionPlanIdMap = Object.freeze({
  free: 1,
  trial: 2,
  [SubscriptionPlans.PAID_MONTHLY]: 3,
  [SubscriptionPlans.PAID_YEARLY]: 5,
  [SubscriptionPlans.EMAIL_STARTER_MONTHLY]: 17,
  [SubscriptionPlans.EMAIL_STARTER_YEARLY]: 18,
  [SubscriptionPlans.EMAIL_OUTREACH_MONTHLY]: 19,
  [SubscriptionPlans.EMAIL_OUTREACH_YEARLY]: 20,
  [SubscriptionPlans.EMAIL_OUTREACH_LIFETIME]: 10,
  [SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME]: 13,
  [SubscriptionPlans.OUTREACH_BASIC_MONTHLY]: 21,
  [SubscriptionPlans.OUTREACH_PRO_MONTHLY]: 22,
  [SubscriptionPlans.OUTREACH_SCALE_MONTHLY]: 23,
  [SubscriptionPlans.OUTREACH_BASIC_YEARLY]: 24,
  [SubscriptionPlans.OUTREACH_PRO_YEARLY]: 25,
  [SubscriptionPlans.OUTREACH_SCALE_YEARLY]: 26,
});
