import React from 'react';
import { Images } from '../../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../../shared/components/images/image-icon';
import { FieldContentTabs } from '../types';

export const getCustomFieldColumns = (onCopyClickHandler, onKeyHandler) => [
  {
    dataField: 'label',
    text: 'Field Name',
  },
  {
    dataField: 'fieldHashedId',
    text: 'Field Id',
    component: (cell) => (
      <div className="field-hashed-id-container">
        <div className="field-hashed-id-content">{cell}</div>
        <span
          tabIndex={0}
          onKeyPress={onKeyHandler}
          role="button"
          onClick={() => {
            onCopyClickHandler(cell);
          }}
          className="field-hashed-id-icon"
        >
          <ImageIcon src={Images.Copy} />
        </span>
      </div>
    ),
  },
  {
    dataField: 'type',
    text: 'Field Type',
  },
];

export const generateActionsColumn = (tab: FieldContentTabs) =>
  tab === FieldContentTabs.CustomFields
    ? [
        {
          displayName: 'Edit',
          icon: 'pen',
          key: 'edit',
          position: 'out',
        },
        {
          displayName: 'Delete',
          icon: 'trash',
          key: 'delete',
          position: 'out',
        },
      ]
    : [
        {
          displayName: 'Edit',
          icon: 'pen',
          key: 'edit',
          position: 'out',
        },
      ];

export const getDefaultTableWrapper = (
  t: FieldContentTabs,
  customFieldPaginationCount: number,
  defaultFieldPaginationCount: number,
) => {
  switch (t) {
    case FieldContentTabs.CustomFields:
      return customFieldPaginationCount > 25 ? 'paginated-table' : '';
    case FieldContentTabs.SystemFields:
      return defaultFieldPaginationCount > 25 ? 'paginated-table' : '';
    default:
      return '';
  }
};
