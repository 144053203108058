import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import TeamContent from './team-content';
import { User } from '../../../../types/team';
import {
  inviteUserRequest,
  resendInvitationRequest,
  cancelInvitationRequest,
  deactivateUserRequest,
  reactivateUserRequest,
  deleteUserRequest,
} from '../../extra-actions';
import { InviteUserRequestPayload } from '../../../../types/request-payload';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import { getConnectedUsersAndEmailAccountsRequest } from '../../../billing-subscription/extra-actions';

const mapStateToProps = (state: RootState) => ({
  inviteUserRequestStatus: state.team.inviteUserRequest.status,
  inviteUserRequestMessage: state.team.inviteUserRequest.message,
  inviteUserRequestError: state.team.inviteUserRequest.error,

  resendInvitationRequestStatus: state.team.resendInvitationRequest.status,
  resendInvitationRequestMessage: state.team.resendInvitationRequest.message,
  resendInvitationRequestError: state.team.resendInvitationRequest.error,

  cancelInvitationRequestStatus: state.team.cancelInvitationRequest.status,
  cancelInvitationRequestMessage: state.team.cancelInvitationRequest.message,
  cancelInvitationRequestError: state.team.cancelInvitationRequest.error,

  deactivateUserRequestStatus: state.team.deactivateUserRequest.status,
  deactivateUserRequestMessage: state.team.deactivateUserRequest.message,
  deactivateUserRequestError: state.team.deactivateUserRequest.error,

  reactivateUserRequestStatus: state.team.reactivateUserRequest.status,
  reactivateUserRequestMessage: state.team.reactivateUserRequest.message,
  reactivateUserRequestError: state.team.reactivateUserRequest.error,

  deleteUserRequestStatus: state.team.deleteUserRequest.status,
  deleteUserRequestMessage: state.team.deleteUserRequest.message,
  deleteUserRequestError: state.team.deleteUserRequest.error,

  pagination: {
    ...state.team.pagination,
    pagesCount: null,
    options: {
      ...state.team.pagination.options,
      totalElements: state.team.pagination.count,
    },
  },
  isLoading: state.team.isLoading,

  role: state.home.role,
  agencyConfig: state.home.agencyConfig,

  subscriptionPlan: state.home.subscription?.planCode,
  planType: state.home.subscription?.planType,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  adminFirstName: state.home.adminDetails?.firstName,
  slots: state.home.subscription?.slots,

  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  getConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  totalUsers:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse
      ?.totalUsers,
});

const mapDispatchToProps = {
  sendInviteUserRequest: (payload: InviteUserRequestPayload) =>
    inviteUserRequest(payload),
  sendResendInvitationRequest: (userId: number) =>
    resendInvitationRequest(userId),
  sendCancelInvitationRequest: (userId: number) =>
    cancelInvitationRequest(userId),
  sendDeactivateUserRequest: (userId: number) => deactivateUserRequest(userId),
  sendReactivateUserRequest: (userId: number) => reactivateUserRequest(userId),
  sendDeleteUserRequest: (userId: number) => deleteUserRequest(userId),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  sendGetUsersListRequest: (options: PaginationQueryParams) => void;
  users: User[];
  isRequestPending: boolean;
  t: (x: string) => string;
};

export default connector(TeamContent);
