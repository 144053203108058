/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import { Button } from '@saleshandy/design-system';
import type { IProps } from './master-class-chapter-content-container';

const MasterClassChapterContent: React.FC<IProps> = ({
  topic,
  currentLessonId,
  academyLessons,
  onNextTopic,
  onPreviousTopic,
  updateAcademyLessonProgress,
}) => {
  const lastLessonId = useMemo(
    () => academyLessons && academyLessons[0]?.lessons.slice(-1),
    [],
  );
  const lastTopicId = useMemo(
    () => lastLessonId && lastLessonId[0]?.topics[0].id,
    [],
  );
  const firstTopicId = useMemo(
    () => academyLessons && academyLessons[0]?.lessons[0]?.topics[0]?.id,
    [],
  );

  //* Functions
  const onNext = () => {
    updateAcademyLessonProgress({
      courseId: 1,
      lessonId: currentLessonId,
      topicId: topic.id,
    });
    onNextTopic();
  };

  return (
    <div className="master-class-content">
      <div className="master-class-content__header">
        <span className="semibold-3 popover-arrow-color-txt">
          {topic?.title}
        </span>
      </div>
      <div className="master-class-content__section">
        <div
          dangerouslySetInnerHTML={{
            __html: topic?.body,
          }}
        />
      </div>
      <div className="master-class-content__footer d-flex justify-content-between">
        <Button
          variant="secondary"
          onClick={onPreviousTopic}
          disabled={firstTopicId === topic?.id}
        >
          Previous
        </Button>
        <div className="d-flex">
          {lastTopicId !== topic?.id && (
            <Button variant="tertiary" className="mr-1" onClick={onNextTopic}>
              Skip
            </Button>
          )}
          <Button onClick={onNext} disabled={lastTopicId === topic?.id}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MasterClassChapterContent;
