import React from 'react';
import { Link } from 'react-router-dom';
import produce from 'immer';
import Input from '../../../../shared/design-system/components/input';
import Button from '../../../../shared/design-system/components/atoms/button';
import FormSection from '../form-section';
import ButtonContainer from '../button-container';
import { validate } from './validator';
import { IProps, IState } from './types';
import {
  AuthHelper,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
} from '../../../../shared/utils';
import Alert from '../../../../shared/design-system/components/alert';
import { RequestStatus } from '../../../../shared/enums/request-status';
import AlertContainer from '../alert-container';
import TopWelcome from '../top-welcome';

class Login extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: {
        email: '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
      dirty: {
        email: false,
        password: false,
      },
      showPassword: false,
    };

    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const { values } = this.state;

    const { search } = history.location;
    const userEmail = new URLSearchParams(search).get('userEmail');

    if (userEmail !== '') {
      this.setState({ values: { ...values, email: userEmail } });
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      status,
      token,
      trackingId,
      firstName,
      lastName,
      email,
      agencyConfig,
    } = this.props;
    if (status !== prevProps.status) {
      if (status === RequestStatus.Succeeded && token) {
        AuthHelper.login({ token });

        initializeThirdPartyIntegrations({
          trackingId,
          showChatSupport: agencyConfig?.showChatSupport,
          firstName,
          lastName,
          email,
        });
      }
      initializeSentryIntegrations(email);
    }
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError();
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;
    const { sendLoginRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const { email, password } = values;
    sendLoginRequest(email, password);
  }

  togglePasswordVisibility() {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  }

  render() {
    const { values, errors, showPassword } = this.state;
    const { status, error, showError, hideError } = this.props;

    const isLoading = status === RequestStatus.Pending;

    return (
      <div className="login-right-inner">
        <TopWelcome>
          <TopWelcome.Header>Login</TopWelcome.Header>
          <TopWelcome.Body>
            Need an account? <Link to="/signup">Create an account</Link>
          </TopWelcome.Body>
        </TopWelcome>
        {showError && (
          <AlertContainer>
            <Alert
              variant={Alert.Variant.Danger}
              dismissible={true}
              onClose={hideError}
              iconIdentifier="close-o"
              header="Whoops"
            >
              {error.message}
            </Alert>
          </AlertContainer>
        )}
        <FormSection>
          <form onSubmit={this.onFormSubmit}>
            <Input
              name="email"
              label="Email"
              placeholder="Enter your email address"
              value={values.email}
              variant={errors.email && Input.Variant.Error}
              caption={errors.email}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              autoComplete="current-email"
              autoFocus
            />
            <Input
              name="password"
              label="Password"
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              variant={errors.password && Input.Variant.Error}
              caption={errors.password}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              autoComplete="login-password"
              icons={[
                {
                  place: Input.IconPlace.Right,
                  identifier: showPassword ? 'eye-alt' : 'eye',
                  className: 'pointer',
                  onClick: this.togglePasswordVisibility,
                },
              ]}
            />
            <ButtonContainer>
              <Button
                variant={Button.Variant.Primary}
                type={Button.Type.Submit}
                isLoading={isLoading}
                disabled={!(!!values.email && !!values.password) || isLoading}
              >
                Log In
              </Button>
            </ButtonContainer>
            <div className="forgot-link regular-2">
              <Link to="/reset-password" className="pointer">
                Forgot Password?
              </Link>
            </div>
          </form>
        </FormSection>
      </div>
    );
  }
}

export default Login;
