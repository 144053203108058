import React from 'react';
import produce from 'immer';
import { Col, Row } from 'react-bootstrap';

import { isEmpty } from 'lodash';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import FormSection from '../../../form-section';
import {
  IState,
  PrepareIndustryDropDown,
  PrepareJobRoleDropDown,
  PrepareUseCaseDropDown,
  PrepareTeamSizeDropDown,
} from './types';
import type { IProps } from './signup-questions-data-container';
import {
  prepareIndustryDropDown,
  prepareJobRoleDropDown,
  prepareUseCaseDropDown,
  prepareTeamSizeDropDown,
} from './helper';
import Select from '../../../../../../shared/design-system/components/select';
import Input from '../../../../../../shared/design-system/components/input';
import { AuthHelper } from '../../../../../../shared/utils/auth-helper';
import { validate } from './validator';
import {
  getToken,
  initializeThirdPartyIntegrations,
} from '../../../../../../shared/utils';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { showGeneralErrorNotification } from '../../../../../../shared/utils/errors';
import TopWelcome from '../../../top-welcome';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../../../../shared/utils/execute-on-request-status';
import { handleOnboarding } from '../../../../../../shared/utils/handle-onboarding-routing';
import {
  OnboardingSteps,
  UserSettingCode,
} from '../../../../../../shared/types/user-setting';
import { UserRole } from '../../../../../../shared/enums/user-roles';
import { Placement } from '../../../../../../shared/design-system/components/overlay';

class SignUpQuestionsData extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndustry: null,
      selectedJobRole: null,
      selectedUseCase: null,
      selectedTeamSize: null,
      values: {
        firstName: '',
        lastName: '',
        saleshandyUsage: '',
      },
      errors: {
        firstName: '',
        lastName: '',
        saleshandyUsage: '',
      },
      dirty: {
        firstName: false,
        lastName: false,
        saleshandyUsage: false,
      },
      isLoading: false,
      isRedirectedFromLtd: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onSaleshandyUsageChange = this.onSaleshandyUsageChange.bind(this);
    this.areFieldsEmpty = this.areFieldsEmpty.bind(this);
  }

  componentDidMount() {
    const { location, sendGetUserSettingsRequest } = this.props;
    const { search } = location;

    if (search) {
      let team;
      const firstName = new URLSearchParams(search).get('fname');
      const lastName = new URLSearchParams(search).get('lname');
      const teamSize = new URLSearchParams(search).get('team');
      const saleshandyUsage = new URLSearchParams(search).get('susage');

      if (teamSize === '1') {
        team = '1';
      } else if (teamSize === '11') {
        team = '5-20';
      } else if (teamSize === '20') {
        team = '20+';
      }

      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        values: { firstName, lastName, saleshandyUsage },
        selectedTeamSize: team,
        dirty: {
          firstName: true,
          lastName: true,
          saleshandyUsage: true,
        },
      });
    }

    const params = new URLSearchParams(search);
    if (params.get('redirectedFrom') === 'ltd') {
      this.setState({ isRedirectedFromLtd: true });
    }

    sendGetUserSettingsRequest();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      onBoardingStatus,
      onBoardingError,
      getUserSettingsRequestStatus,
      history,
      sendGetUserSettingsRequest,
      location,
      meta,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: onBoardingStatus,
      prevStatus: prevProps.onBoardingStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
      onFailed: () => {
        showGeneralErrorNotification(onBoardingError.message);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getUserSettingsRequestStatus,
      prevStatus: prevProps.getUserSettingsRequestStatus,
      onSuccess: () => {
        const { userRole, firstName, lastName, email, trackingId } = this.props;

        initializeThirdPartyIntegrations({
          trackingId,
          firstName,
          lastName,
          email,
        });

        if (!isEmpty(meta)) {
          const onboardingStep = meta.find(
            (setting) => setting.code === UserSettingCode.Onboarding,
          );

          let currentPath;

          if (onboardingStep) {
            if (
              onboardingStep.value === OnboardingSteps.Step1 &&
              (userRole === UserRole.LtdAdmin ||
                userRole === UserRole.LtdMember)
            ) {
              const search = new URLSearchParams(location.search);
              search.set('redirectedFrom', 'ltd');

              currentPath = location.pathname + search.toString();
              handleOnboarding(meta, currentPath, search.toString());
              return;
            }

            currentPath = location.pathname + location.search;
            handleOnboarding(meta, currentPath, location.search);
            return;
          }

          history.push({
            pathname: '/sequence',
            search: 'signup=success',
          });
        } else {
          this.setState({ isLoading: false });
          const token = getToken();
          AuthHelper.login({ token });
          history.push({
            pathname: '/sequence',
            search: 'signup=success',
          });
        }
      },
    });
  }

  onIndustryDropDownChange(option: PrepareIndustryDropDown) {
    this.setState({
      selectedIndustry: option,
    });
  }

  onJobRoleDropDownChange(option: PrepareJobRoleDropDown) {
    this.setState({
      selectedJobRole: option,
    });
  }

  onUseCaseDropDownChange(option: PrepareUseCaseDropDown) {
    this.setState({
      selectedUseCase: option,
    });
  }

  onTeamSizeDropdownChange(option: PrepareTeamSizeDropDown) {
    this.setState({
      selectedTeamSize: option,
    });
  }

  onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const {
      selectedIndustry,
      selectedTeamSize,
      selectedJobRole,
      selectedUseCase,
      isRedirectedFromLtd,
    } = this.state;
    const { sendOnboardingRequest } = this.props;
    const payload = {
      ...(isRedirectedFromLtd && {
        firstName: values.firstName,
        lastName: values.lastName,
      }),
      ...(selectedTeamSize && { teamSize: selectedTeamSize.value }),
      ...(selectedIndustry && { industry: selectedIndustry.value }),
      designation: selectedJobRole.value,
      useCase: selectedUseCase.value,
    };

    this.setState({ isLoading: true });
    sendOnboardingRequest(payload);
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onSaleshandyUsageChange(values: string) {
    this.setState(
      produce((draft) => {
        draft.values.saleshandyUsage = values;
        draft.dirty.saleshandyUsage = true;
        draft.errors.saleshandyUsage = '';
      }),
    );
  }

  areFieldsEmpty() {
    const {
      values,
      selectedIndustry,
      selectedJobRole,
      selectedUseCase,
      selectedTeamSize,
      isRedirectedFromLtd,
    } = this.state;

    let isButtonDisable =
      (isRedirectedFromLtd &&
        !!values.firstName &&
        !!values.lastName &&
        !!selectedJobRole &&
        !!selectedUseCase) ||
      (!isRedirectedFromLtd && !!selectedJobRole && !!selectedUseCase);

    if (hasPermission('ONBOARDING.COMPANY_SIZE.WRITE')) {
      isButtonDisable =
        isButtonDisable && !!selectedIndustry && !!selectedTeamSize;
    }

    return !isButtonDisable;
  }

  render() {
    const {
      values,
      errors,
      isLoading,
      selectedIndustry,
      selectedJobRole,
      selectedUseCase,
      selectedTeamSize,
      isRedirectedFromLtd,
    } = this.state;

    const { t } = this.props;

    const { getUserSettingsRequestStatus } = this.props;

    if (getUserSettingsRequestStatus === RequestStatus.Pending) {
      return null;
    }

    return (
      <>
        <div className="signup">
          <div className="top-navigation" />
          <div className="signup-questions">
            <TopWelcome prefix={isRedirectedFromLtd ? '' : 'Welcome to'}>
              <TopWelcome.Header>
                <span className="semibold-3">
                  Let’s know each other better...
                </span>
              </TopWelcome.Header>
              <TopWelcome.Body>
                <div className="regular-1">
                  <div>
                    This information helps us to give you a personalised
                  </div>
                  <div>onboarding and support.</div>
                </div>
              </TopWelcome.Body>
            </TopWelcome>
            <FormSection>
              <form
                className="step_3 onboarding-details"
                onSubmit={this.onFormSubmit}
              >
                {isRedirectedFromLtd && (
                  <Row>
                    <Col>
                      <Input
                        name="firstName"
                        label="First name"
                        placeholder="..."
                        value={values.firstName}
                        variant={errors.firstName && Input.Variant.Error}
                        caption={errors.firstName}
                        onChange={this.onInputChange}
                        onBlur={this.onInputBlur}
                        autoComplete="current-firstName"
                        autoFocus
                        className="form-control-input"
                      />
                    </Col>
                    <Col>
                      <Input
                        name="lastName"
                        label="Last name"
                        placeholder="..."
                        value={values.lastName}
                        variant={errors.lastName && Input.Variant.Error}
                        caption={errors.lastName}
                        onChange={this.onInputChange}
                        onBlur={this.onInputBlur}
                        autoComplete="current-lastName"
                        className="form-control-input"
                      />
                    </Col>
                  </Row>
                )}
                {hasPermission('ONBOARDING.INDUSTRY.WRITE') && (
                  <div className="industry-dropdown">
                    <p className="semibold-1 gray-txt-15">
                      {t('messages.user_industry')}
                    </p>
                    <Select<PrepareIndustryDropDown>
                      options={prepareIndustryDropDown}
                      selectedOptionKey={selectedIndustry?.key}
                      optionRenderer={(option) => <span>{option.value}</span>}
                      showOptionsSeparator={true}
                      selectedOptionRenderer={([option]) => (
                        <span>{option.value}</span>
                      )}
                      onChange={([option]) =>
                        this.onIndustryDropDownChange(option)
                      }
                      placeholder="Select your industry"
                    />
                  </div>
                )}
                <div className="industry-dropdown">
                  <p className="semibold-1 gray-txt-15">
                    {t('messages.user_job_role')}
                  </p>
                  <Select<PrepareJobRoleDropDown>
                    options={prepareJobRoleDropDown}
                    selectedOptionKey={selectedJobRole?.key}
                    optionRenderer={(option) => <span>{option.value}</span>}
                    showOptionsSeparator={true}
                    selectedOptionRenderer={([option]) => (
                      <span>{option.value}</span>
                    )}
                    onChange={([option]) =>
                      this.onJobRoleDropDownChange(option)
                    }
                    placeholder="Select your job role"
                  />
                </div>
                <div className="industry-dropdown">
                  <p className="semibold-1 gray-txt-15">
                    {t('messages.use_case')}
                  </p>
                  <Select<PrepareUseCaseDropDown>
                    options={prepareUseCaseDropDown}
                    selectedOptionKey={selectedUseCase?.key}
                    optionRenderer={(option) => <span>{option.value}</span>}
                    showOptionsSeparator={true}
                    selectedOptionRenderer={([option]) => (
                      <span>{option.value}</span>
                    )}
                    onChange={([option]) =>
                      this.onUseCaseDropDownChange(option)
                    }
                    placeholder="Select your use-case"
                    className="form-control-select"
                    placement={Placement.Top}
                  />
                </div>
                {hasPermission('ONBOARDING.COMPANY_SIZE.WRITE') && (
                  <div className="industry-dropdown">
                    <p className="semibold-1 gray-txt-15">
                      {t('messages.team_size')}
                    </p>
                    <Select<PrepareTeamSizeDropDown>
                      options={prepareTeamSizeDropDown}
                      selectedOptionKey={selectedTeamSize?.key}
                      optionRenderer={(option) => <span>{option.value}</span>}
                      showOptionsSeparator={true}
                      selectedOptionRenderer={([option]) => (
                        <span>{option.value}</span>
                      )}
                      onChange={([option]) =>
                        this.onTeamSizeDropdownChange(option)
                      }
                      placeholder="Select your company size"
                      placement={Placement.Top}
                    />
                  </div>
                )}
                <Button
                  type={Button.Type.Submit}
                  variant={Button.Variant.Primary}
                  className="team-selection"
                  disabled={this.areFieldsEmpty() || isLoading}
                  isLoading={isLoading}
                >
                  {isRedirectedFromLtd
                    ? "Let's get started"
                    : t('labels.continue')}
                </Button>
              </form>
            </FormSection>
          </div>
        </div>
      </>
    );
  }
}

export default SignUpQuestionsData;
