import React from 'react';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import ResetPasswordInvalidLink from '../reset-password-invalid-link';
import Auth from '../..';
import store from '../../../../store';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

class ResetPasswordCallback extends React.Component<IProps, null> {
  componentDidMount() {
    this.props.sendVerifyResetPasswordCodeRequest();
    store.dispatch(showLoading());
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { status } = this.props;
    if (status !== prevProps.status) {
      if (status !== RequestStatus.Ideal && status !== RequestStatus.Pending) {
        store.dispatch(hideLoading());
      }
    }
  }

  render() {
    const { status } = this.props;

    if (status === RequestStatus.Ideal || status === RequestStatus.Pending) {
      return null;
    }

    if (status === RequestStatus.Failed) {
      return <ResetPasswordInvalidLink />;
    }

    return <Auth />;
  }
}

export default ResetPasswordCallback;
