import React from 'react';
import { Images } from '../../shared/app-constants';
import { supportUrls } from '../../shared/utils/urls';

const SendColdEmails: React.FC = () => (
  <div className="send-cold-emails--container">
    <div className="send-cold-emails--content">
      <div className="send-cold-emails--image">
        <img src={Images.SendColdEmails} alt="Send Cold Emails" />
      </div>
      <h1 className="send-cold-emails--title">
        Start your Cold Email Outreach journey with our partner Saleshandy.
      </h1>
      <p className="send-cold-emails--desc">
        Connect unlimited email accounts and supercharge your Cold Email
        Outreach.
      </p>
      <div className="send-cold-emails--logos">
        <div className="logo trulyinbox">
          <img src={Images.TrulyInboxLogo} alt="Logo" />
        </div>
        <div className="switch">
          <img src={Images.SwitchHorizontal} alt="Switch" />
        </div>
        <div className="logo saleshandy">
          <img src={Images.SaleshandyLogo} alt="Logo" />
        </div>
      </div>
      <a
        className="send-cold-emails--cta"
        href={supportUrls.sendColdEmail}
        target="_blank"
        rel="noreferrer"
      >
        Start Free Trial
      </a>
    </div>
  </div>
);

export default SendColdEmails;
