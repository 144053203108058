import React from 'react';
import { supportUrls } from '../../../shared/utils/urls';

const PrivacyPolicy = () => (
  <div className="privacy-policy-text">
    <p className="regular-1">
      By signing up, you agree to the TrulyInbox’s{' '}
      <a
        href={supportUrls.termsOfServices}
        target="_blank"
        rel="noreferrer noopener"
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href={supportUrls.privacyPolicy}
        target="_blank"
        rel="noreferrer noopener"
      >
        Privacy Policy.
      </a>
    </p>
  </div>
);

export default PrivacyPolicy;
