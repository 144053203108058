import _refiner from 'refiner-js';

type IdentifierPayload = {
  id: string;
  email: string;
  name: string;
};

export const identifyAndInitializeRefiner = (payload: IdentifierPayload) => {
  _refiner('identifyUser', payload);

  _refiner('setProject', process.env.REACT_APP_REFINER_KEY);
};
