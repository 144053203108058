import React from 'react';
import { Images } from '../../../../../shared/app-constants';
import Alert from '../../../../../shared/design-system/components/alert';

const ZeroLimitErrorNudge = ({ zeroLimitError, t }) =>
  zeroLimitError && (
    <div className="row mt-2">
      <div className="col-md-10">
        <Alert
          contentContainer={<span>{t('messages.zero_limit_error')}</span>}
          variant={Alert.Variant.Danger}
          theme={Alert.Theme.New}
          svgIcon={Images.AlertTriangleRed}
        />
      </div>
    </div>
  );

export default ZeroLimitErrorNudge;
