import React, { ReactNode, Ref } from 'react';
import composeRefs from '@seznam/compose-react-refs';
import Icon from '../atoms/icon/icon';
import classNames from 'classnames';

export type SelectBarProps = {
  targetRef?: Ref<HTMLDivElement>;
  content?: ReactNode;
  placeholder?: ReactNode;
  disabled?: boolean;
};

const SelectBar = React.forwardRef<HTMLDivElement, SelectBarProps>(
  // Extract the 'targetRef' even if you don't use it anywhere, otherwise
  // it will be included in the 'rest' array, resulting in being added to
  // the div element as a property, and the browser/react will complain about
  // adding unknown properties to the native element
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ targetRef, content, placeholder, children, disabled, ...rest }, ref) => {
    const selectBarClass = classNames([
      'bs-select-box',
      'bs-select-box',
      {
        'bs-select-box-disabled': disabled,
      },
    ]);

    const contentClass = classNames([
      'bs-select-box-text',
      {
        placeholder: placeholder && !content,
      },
    ]);

    let tabIndex;

    if (!disabled) {
      tabIndex = 0;
    }

    return (
      <div
        {...rest}
        className={selectBarClass}
        tabIndex={tabIndex}
        ref={composeRefs(ref, targetRef)}
      >
        <div className={contentClass}>{content || placeholder}</div>
        <div className="bs-select-box-icon-wrapper">
          <Icon identifier="chevron-down" />
        </div>
      </div>
    );
  },
);

SelectBar.defaultProps = {
  disabled: false,
};

export default SelectBar;
