import {
  AttachmentMetaDataType,
  AttachmentType,
} from '../../../../../shared/editor/types';
import { Template } from '../template-modal/types';

export type StateValues = {
  title: string;
  subject: string;
  content: string;
  email: string;
  preheader: string;
  attachments?: string;
};

export type StateErrors = { [P in keyof StateValues]: string };
export type StateDirty = { [P in keyof StateErrors]: boolean };

export type SubjectEmailSuggestion = {
  subjectPercentage: number;
  subjectStatus: string;
  subjectLength: number;
};

export type PersonalizationEmailSuggestion = {
  personalizationPercentage: number;
  personalizationStatus: string;
  personalizationLength: number;
};

export type LinkEmailSuggestion = {
  linkPercentage: number;
  linkStatus: string;
  linkLength: number;
};

export type SpammyEmailSuggestion = {
  spammyPercentage: number;
  spammyStatus: string;
  spammyLength: number;
  spammyWords: string[];
};

export type IState = {
  values: StateValues;
  errors: StateErrors;
  dirty: StateDirty;
  subjectEmailSuggestion: SubjectEmailSuggestion;
  personalizationEmailSuggestion: PersonalizationEmailSuggestion;
  linkEmailSuggestion: LinkEmailSuggestion;
  spammyEmailSuggestion: SpammyEmailSuggestion;
  sendAutomatically: boolean;
  sendTestEmailVisible: boolean;
  skipFocusOnEditorOnce: boolean;
  selectedTemplate?: Template;
  showTemplates: boolean;
  showTitle: boolean;
  showSendTestEmailModal: boolean;
  showEmailDisconnectedModal: boolean;
  showEmailNotConnectedModal: boolean;
  showEmailNotAddedModal: boolean;
  showConfirmationModal: boolean;
  showPreheaderInput: boolean;
  showPreheaderRecommendation: boolean;
  shouldInitiateAChat: boolean;
  attachments: AttachmentType[];
  attachmentsMeta: AttachmentMetaDataType[];
  attachmentsSizeInBytes: number;
};

export type TemplateDropDownOption = {
  name: string;
  key: number;
  isOwn: boolean;
};

export enum PreheaderErrorConditions {
  ErrorLength = 150,
  RecommendationLength = 130,
}
export const EmailSuggestionStatus = {
  TOO_SHORT: 'Too short',
  SHORT: 'Short',
  IDEAL: 'Ideal',
  LENGTHY: 'Lengthy',
  TOO_LENGTHY: 'Too lengthy',
  POOR: 'Poor',
  AVERAGE: 'Average',
  GOOD: 'Good',
  EXCELLENT: 'Excellent',
};

export const EmailSuggestionClasses = {
  [EmailSuggestionStatus.TOO_SHORT]: 'too-short',
  [EmailSuggestionStatus.SHORT]: 'short',
  [EmailSuggestionStatus.IDEAL]: 'ideal',
  [EmailSuggestionStatus.LENGTHY]: 'lengthy',
  [EmailSuggestionStatus.TOO_LENGTHY]: 'too-lengthy',
  [EmailSuggestionStatus.POOR]: 'poor',
  [EmailSuggestionStatus.AVERAGE]: 'average',
  [EmailSuggestionStatus.GOOD]: 'good',
  [EmailSuggestionStatus.EXCELLENT]: 'excellent',
};
