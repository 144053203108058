import validator from 'validator';

export type ImportEmailAccountHeader = {
  index?: number;
  label: string;
  required: boolean;
  duplicateAllowed: boolean;
  validator: (payload: any) => boolean | null;
  messages: {
    invalid?: string;
    notFound?: string;
    duplicate?: string;
  };
};

const importEmailAccountHeaders: ImportEmailAccountHeader[] = [
  {
    label: 'Email Address',
    required: true,
    duplicateAllowed: false,
    validator: (email: string): boolean => validator.isEmail(email),
    messages: {
      invalid: 'Invalid email account.',
      notFound: 'Email account not found.',
      duplicate: 'Duplicate email account.',
    },
  },
  {
    label: 'Email Service Provider',
    required: true,
    duplicateAllowed: true,
    validator: null,
    messages: {
      notFound: 'Email service provider not found.',
    },
  },
  {
    label: 'Sender name',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'SMTP Username',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'SMTP Password',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'SMTP Host',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'SMTP Port',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'SMTP Encryption',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'IMAP Username',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'IMAP Password',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'IMAP Host',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'IMAP Port',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'IMAP Encryption',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'Start with email per day',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'Increase email by every day',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'Maximum emails to be sent per day',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
  {
    label: 'Reply rate',
    required: false,
    duplicateAllowed: true,
    validator: null,
    messages: {},
  },
];

// Function to return an array of all import email account headers
export const getAllImportEmailAccountHeaders = () =>
  importEmailAccountHeaders.map((header) => header.label);

// Function to return an array of required import email account headers
export const getRequiredImportEmailAccountHeaders = () =>
  importEmailAccountHeaders.filter((header) => header.required);

// Function to return an array of all import email account headers for failed report
export const getAllImportEmailAccountHeadersForFailedReport = (): string[] => {
  const arr = importEmailAccountHeaders.map((header) => header.label);
  arr.push('Failed Reason');
  return arr;
};
