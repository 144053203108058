/* eslint-disable no-restricted-syntax */
import React from 'react';
import { withTranslation } from 'react-i18next';
import produce from 'immer';
import { validate } from './validator';
import Select from '../../../../../../shared/design-system/components/select';
import Input from '../../../../../../shared/design-system/components/input';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import Icon from '../../../../../../shared/design-system/components/atoms/icon/icon';

import {
  CreateUpdateCustomFieldModalProps,
  CreateUpdateCustomFieldModalState,
  DropdownCreateUpdateOption,
} from './types';
import { FieldType } from '../../../../enums/field';
import { CreateUpdateModalData } from '../../types';
import { capitalize } from '../../../../../../shared/utils';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../shared/design-system/components/overlay';
import { Images } from '../../../../../../shared/app-constants';

const options: DropdownCreateUpdateOption[] = [
  { name: FieldType.Text, key: FieldType.Text },
  { name: FieldType.Number, key: FieldType.Number },
];

class CreateUpdateCustomFieldModal extends React.Component<
  CreateUpdateCustomFieldModalProps,
  CreateUpdateCustomFieldModalState
> {
  constructor(props) {
    super(props);

    const { customField } = this.props;

    this.state = {
      values: {
        fieldName: customField ? customField.label : '',
        fallbackText: customField?.fallbackText ? customField.fallbackText : '',
      },
      errors: {
        fieldName: '',
        fallbackText: '',
      },
      dirty: {
        fieldName: false,
        fallbackText: false,
      },
      fieldType: customField ? customField.fieldType : FieldType.Text,
      isDefault: customField && customField.isDefault,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] =
          name === 'fieldName' ? value.length > 100 : value.length > 255;
      }),
    );
  }

  onSelectChange(option) {
    const { values: stateValues } = this.state;
    this.setState({
      values: { ...stateValues },
      fieldType: option.name,
    });
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onSubmit(e) {
    e.preventDefault();
    const { dirty, errors, values } = this.state;
    const dirtyKeys = Object.keys(dirty);

    const tempDirtyKeys = { ...dirty };
    const tempErrors = { ...errors };

    for (const key of dirtyKeys) {
      tempDirtyKeys[key] = true;
    }

    const keys = Object.keys(errors);
    let isError = false;

    for (const key of keys) {
      const error = validate(key, values[key]);
      tempErrors[key] = error;
      isError = isError || !!error;
    }

    if (isError) {
      this.setState({ errors: tempErrors, dirty: tempDirtyKeys });
      return;
    }

    const { fieldType } = this.state;
    const { onSave } = this.props;

    const payload: CreateUpdateModalData = {
      label: values.fieldName,
      fallbackText: values.fallbackText,
      type: fieldType,
    };

    onSave(payload);
  }

  render() {
    const { values, errors, isDefault, dirty, fieldType } = this.state;
    const {
      show,
      modalHeaderTitle,
      onClose,
      isRequestPending,
      t,
      disableDropdownBox,
    } = this.props;

    return (
      <Modal
        show={show}
        className="add-prospect-field"
        titleContent={
          <div className="modal-header-custom">
            <div className="sidenav__icon">
              <Icon identifier="file-document" style={{ color: '#1D4ED8' }} />
            </div>
            <h4 className="ml-2">{modalHeaderTitle}</h4>
          </div>
        }
        onClose={onClose}
        onSubmit={this.onSubmit}
        showCloseIcon={true}
        cancelButtonText={t(`Cancel`)}
        submitButtonText={t(`Save`)}
        isSubmitLoading={isRequestPending}
        isSubmitDisabled={isRequestPending || dirty.fieldName}
        backdrop="static"
        extraModalProps={{
          centered: true,
        }}
      >
        <div className="prospect-modal-body">
          <Input
            disabled={isDefault}
            name="fieldName"
            label={t(`Field Name`)}
            placeholder="Enter Field Name"
            value={values.fieldName}
            size={Input.Size.Medium}
            variant={errors.fieldName && Input.Variant.Error}
            caption={errors.fieldName ? t(`messages.${errors.fieldName}`) : ''}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
          />
          <div>
            {dirty.fieldName && (
              <span className="error-label">
                <img src={Images.AlertTriangleRed} alt="Alert" />
                {t('labels.prospect_label_error')}
              </span>
            )}
          </div>
          <span className="select-label semibold-11">{t(`Field Type`)}</span>{' '}
          <Select<DropdownCreateUpdateOption>
            options={options}
            selectedOptionKey={fieldType}
            optionFilterProp="name"
            optionRenderer={(option) => <span>{capitalize(option.name)}</span>}
            showOptionsSeparator={true}
            onChange={([option]) => {
              this.onSelectChange(option);
            }}
            selectedOptionRenderer={([option]) => (
              <span>{capitalize(option.name)}</span>
            )}
            disabled={disableDropdownBox}
          />
          <OverlayTooltip
            rootClose={true}
            text={t('messages.fallback_text_info')}
            className="tooltip-md"
            placement={Placement.Bottom}
          >
            <div className="info-icon">
              <Icon identifier="info" />
            </div>
          </OverlayTooltip>
          <Input
            className="fallback-text-input"
            name="fallbackText"
            label={t(`Fallback Text (Optional)`)}
            placeholder="Enter Fallback Text"
            value={values.fallbackText}
            size={Input.Size.Medium}
            variant={errors.fallbackText && Input.Variant.Error}
            caption={
              errors.fallbackText ? t(`messages.${errors.fallbackText}`) : ''
            }
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
          />
          <div>
            {dirty.fallbackText && (
              <span className="error-label">
                <img src={Images.AlertTriangleRed} alt="Alert" />
                {t('labels.prospect_fallback_text_error')}
              </span>
            )}
          </div>
          <hr />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(CreateUpdateCustomFieldModal);
