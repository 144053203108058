import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  AcceptInvitationRequestPayload,
  SetPasswordRequest,
} from '../../types';
import { acceptInvitationRequest } from '../invitation-signup/extra-actions';
import AccountDetails from './account-details';
import { setPasswordRequest } from './extra-action';

const mapStateToProps = (state: RootState) => ({
  accountDetailsRequestStatus: state.accountDetails.setPasswordRequest.status,
  accountDetailsRequestMessage: state.accountDetails.setPasswordRequest.message,
  canResendUserVerificationCode:
    state.accountDetails.canResendUserVerificationCode,
});

const mapDispatchToProps = {
  sendSetPasswordRequest: (payload: SetPasswordRequest) =>
    setPasswordRequest(payload),
  sendAcceptInvitationRequest: (payload: AcceptInvitationRequestPayload) =>
    acceptInvitationRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    inviteUserEmail?: string;
    isInvitationRequest?: boolean;
    token?: string;
  };

export default withRouter(connector(AccountDetails));
