import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
// eslint-disable-next-line import/no-cycle
import BillingSubscription from './billing-subscription';
import {
  getAccountSubscriptionRequest,
  getPlansRequest,
  getInvoiceURLRequest,
  purchaseSubscriptionRequest,
  getConnectedUsersAndEmailAccountsRequest,
  applyCouponCodeRequest,
  getEmailVerificationCreditsRequest,
  getEmailVerificationPlanRequest,
  purchaseEmailVerificationCreditsRequest,
  modifySubscriptionRequest,
  purchaseModifySubscriptionRequest,
  reactivateSubscriptionRequest,
  resumeSubscriptionRequest,
  handleSubscriptionRequest,
} from './extra-actions';
import {
  resetSubscription,
  deleteAppliedCouponCode,
  resetPurchaseEmailVerificationCreditsResponse,
  resetModifySubscriptionResponse,
  resetReactivateSubscriptionResponse,
  resetResumeSubscriptionResponse,
  resetHandleSubscriptionResponse,
  resetInvoice,
} from './billing-subscription-slice';
import { getUserSettingsRequest } from '../../../home/extra-actions';
import {
  ApplyCouponCodeRequest,
  PurchaseEmailVerificationCreditsRequest,
  ModifySubscriptionRequest,
  PurchaseModifySubscriptionRequest,
  ReactivateResumeSubscriptionRequest,
  HandleSubscriptionRequest,
  PurchaseSubscriptionRequest,
} from './types';

const mapStateToProps = (state: RootState) => ({
  sendGetAccountSubscriptionRequestStatus:
    state.billingAndSubscription.getAccountSubscriptionRequest.status,
  sendGetAccountSubscriptionRequestError:
    state.billingAndSubscription.getAccountSubscriptionRequest.error,
  sendGetPlansRequestStatus:
    state.billingAndSubscription.getPlansRequest.status,
  sendGetPlansRequestError: state.billingAndSubscription.getPlansRequest.error,
  sendGetInvoiceURLRequestStatus:
    state.billingAndSubscription.getInvoiceURLRequest.status,
  sendGetInvoiceURLRequestError:
    state.billingAndSubscription.getInvoiceURLRequest.error,
  sendPurchaseSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseSubscriptionRequest.status,
  sendPurchaseSubscriptionRequestError:
    state.billingAndSubscription.purchaseSubscriptionRequest.error,
  sendPurchaseSubscriptionRequestMessage:
    state.billingAndSubscription.purchaseSubscriptionRequest.message,
  sendGetAccountSubscriptionResponse:
    state.billingAndSubscription.getAccountSubscriptionResponse,
  sendGetConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  sendGetConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  sendGetConnectedUsersAndEmailAccountsRequestResponse:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse,
  sendApplyCouponCodeRequestStatus:
    state.billingAndSubscription.applyCouponCodeRequest.status,
  sendApplyCouponCodeRequestMessage:
    state.billingAndSubscription.applyCouponCodeRequest.message,
  sendApplyCouponCodeRequestError:
    state.billingAndSubscription.applyCouponCodeRequest.error,
  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,
  sendGetPlansResponse: state.billingAndSubscription.getPlansResponse,
  getInvoiceResponse: state.billingAndSubscription.getInvoiceResponse,
  customerId: state.home.subscription?.customerId,
  subscriptionId: state.home.subscription?.subscriptionId,
  isPaymentActionRequired: state.home.subscription?.paymentActionRequired,
  sendGetEmailVerificationCreditsRequestStatus:
    state.billingAndSubscription.getEmailVerificationCreditsRequest.status,
  sendGetEmailVerificationCreditsRequestError:
    state.billingAndSubscription.getEmailVerificationCreditsRequest.error,
  sendGetEmailVerificationPlansRequestStatus:
    state.billingAndSubscription.getEmailVerificationPlanRequest.status,
  sendGetEmailVerificationPlansRequestError:
    state.billingAndSubscription.getEmailVerificationPlanRequest.error,
  sendPurchaseEmailVerificationCreditsRequestStatus:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest.status,
  sendPurchaseEmailVerificationCreditsRequestError:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest.error,
  sendPurchaseEmailVerificationCreditsRequestMessage:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest
      .message,
  getPurchaseEmailVerificationCreditsResponse:
    state.billingAndSubscription.purchaseEmailVerificationCreditsResponse,
  getEmailVerificationCreditsResponse:
    state.billingAndSubscription.getEmailVerificationCreditsResponse,
  getEmailVerificationPlanResponse:
    state.billingAndSubscription.getEmailVerificationPlanResponse,
  sendGetModifySubscriptionRequestStatus:
    state.billingAndSubscription.modifySubscriptionRequest.status,
  sendGetModifySubscriptionRequestError:
    state.billingAndSubscription.modifySubscriptionRequest.error,
  sendGetModifySubscriptionResponse:
    state.billingAndSubscription.modifySubscriptionResponse,
  sendGetPurchaseModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseModifySubscriptionRequest.status,
  sendGetReactivateSubscriptionRequestStatus:
    state.billingAndSubscription.reactivateSubscriptionRequest.status,
  sendGetReactivateSubscriptionRequestError:
    state.billingAndSubscription.reactivateSubscriptionRequest.error,
  sendGetReactivateSubscriptionResponse:
    state.billingAndSubscription.reactivateSubscriptionResponse,
  sendGetResumeSubscriptionRequestStatus:
    state.billingAndSubscription.resumeSubscriptionRequest.status,
  sendGetResumeSubscriptionRequestError:
    state.billingAndSubscription.resumeSubscriptionRequest.error,
  sendGetResumeSubscriptionResponse:
    state.billingAndSubscription.resumeSubscriptionResponse,
  sendGetHandleSubscriptionResponse:
    state.billingAndSubscription.handleSubscriptionResponse,
  sendGetHandleSubscriptionRequestError:
    state.billingAndSubscription.handleSubscriptionRequest.error,
  sendGetHandleSubscriptionRequestStatus:
    state.billingAndSubscription.handleSubscriptionRequest.status,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  churnkeyHash: state.home.churnkeyHash,
  subscriptionPlanStatus: state.home.subscription?.status,
  pauseEndsAt: state.home.subscription?.pauseEndsAt,
  pausedAt: state.home.subscription?.pausedAt,
  ltdExpiryDate: state.home.agencyConfig?.ltdExpiryDate,
  planCode: state.home?.subscription?.planCode,
  planType: state.home?.subscription?.planType,
  userId: state.home?.userId,
  userEmail: state.home?.email,
});

const mapDispatchToProps = {
  sendGetAccountSubscriptionRequest: () => getAccountSubscriptionRequest(),
  sendGetPlansRequest: () => getPlansRequest(),
  sendGetInvoiceURLRequest: (customerId: string) =>
    getInvoiceURLRequest({ customerId }),
  sendPurchaseSubscriptionRequest: (payload: PurchaseSubscriptionRequest) =>
    purchaseSubscriptionRequest(payload),
  resetSubscription: () => resetSubscription(),
  resetPurchaseEmailVerificationCreditsResponse: () =>
    resetPurchaseEmailVerificationCreditsResponse(),
  resetReactivateSubscriptionResponse: () =>
    resetReactivateSubscriptionResponse(),
  resetResumeSubscriptionResponse: () => resetResumeSubscriptionResponse(),
  resetHandleSubscriptionResponse: () => resetHandleSubscriptionResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  sendApplyCouponCodeRequest: (payload: ApplyCouponCodeRequest) =>
    applyCouponCodeRequest(payload),
  deleteAppliedCouponCode: () => deleteAppliedCouponCode(),
  sendGetEmailVerificationCreditsRequest: () =>
    getEmailVerificationCreditsRequest(),
  sendGetEmailVerificationPlansRequest: () => getEmailVerificationPlanRequest(),
  sendPurchaseEmailVerificationCreditsRequest: (
    payload: PurchaseEmailVerificationCreditsRequest,
  ) => purchaseEmailVerificationCreditsRequest(payload),
  sendModifySubscriptionRequest: (payload: ModifySubscriptionRequest) =>
    modifySubscriptionRequest(payload),
  sendPurchaseModifySubscriptionRequest: (
    payload: PurchaseModifySubscriptionRequest,
  ) => purchaseModifySubscriptionRequest(payload),
  sendReactivateSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => reactivateSubscriptionRequest(payload),
  sendResumeSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => resumeSubscriptionRequest(payload),
  sendHandleSubscriptionRequest: (payload: HandleSubscriptionRequest) =>
    handleSubscriptionRequest(payload),
  resetModifySubscriptionResponse: () => resetModifySubscriptionResponse(),
  resetInvoice: () => resetInvoice(),
  hideLoading: () => hideLoading(),
  showLoading: () => showLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export type LocationStateType = {
  isModifySubscription: boolean;
};

export default connector(BillingSubscription);
