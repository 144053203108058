import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import produce from 'immer';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import FormSection from '../../../form-section';
import { IProps, IState } from './types';
import Alert from '../../../../../../shared/design-system/components/alert';
import { validate } from './validator';
import ButtonContainer from '../../../button-container';
import {
  initializeThirdPartyIntegrations,
  setToken,
} from '../../../../../../shared/utils';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import ResendVerificationCode from '../resend-verification-code';
import AlertContainer from '../../../alert-container';
import TopWelcome from '../../../top-welcome';

class VerificationCodeForm extends React.PureComponent<IProps, IState> {
  private readonly submitButtonRef: RefObject<HTMLButtonElement>;

  constructor(props) {
    super(props);

    this.submitButtonRef = React.createRef<HTMLButtonElement>();

    this.state = {
      values: {
        number: '',
      },
      errors: {
        number: '',
      },
      dirty: {
        number: false,
      },
    };

    this.onInputChangeHelper = this.onInputChangeHelper.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      token,
      showSignUpQuestionsComponent,
      trackingId,
      firstName,
      lastName,
      email,
      agencyConfig,
    } = this.props;
    if (token !== prevProps.token) {
      if (token) {
        setToken(token);
        showSignUpQuestionsComponent();

        initializeThirdPartyIntegrations({
          trackingId,
          showChatSupport: agencyConfig?.showChatSupport,
          firstName,
          lastName,
          email,
        });
      }
    }
  }

  componentWillUnmount() {
    const { hideVerifyUserVerificationCodeRequestError } = this.props;

    hideVerifyUserVerificationCodeRequestError();
  }

  onInputChangeHelper(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;

    const error = validate(value);

    if (!error || value === '') {
      this.onInputChange(value, e);
    }
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  onInputFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.currentTarget.select();
  }

  onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const keys = Object.keys(errorsRef);
    let isError = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      // eslint-disable-next-line react/destructuring-assignment
      const error = validate(values[key]);
      errorsRef[key] = error;
      isError = isError || !!error;
    }

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const valueKeys = Object.keys(values);
    let codeStr = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const key of valueKeys) {
      codeStr = codeStr.concat(values[key]);
    }

    const code = Number(codeStr);

    const { email, sendVerifyUserVerificationCodeRequest } = this.props;
    sendVerifyUserVerificationCodeRequest(email, code);
  }

  render() {
    const { values, errors } = this.state;
    const { email } = this.props;
    const {
      verifyUserVerificationCodeRequestStatus,
      verifyUserVerificationCodeRequestError,
      verifyUserVerificationCodeRequestShowError,
      hideVerifyUserVerificationCodeRequestError,
      canResendUserVerificationCode,
    } = this.props;

    const isLoading =
      verifyUserVerificationCodeRequestStatus === RequestStatus.Pending;

    const errorClass = 'bs-input input-error';

    return (
      <div className="otp-right-inner">
        <TopWelcome>
          <TopWelcome.Header>A quick verification</TopWelcome.Header>
          <TopWelcome.Body>
            <>
              <div>
                Please enter the OTP sent to your below email id. This is for
              </div>
              <div>
                security purpose and to ensure that your email is not misused.
              </div>
            </>
          </TopWelcome.Body>
        </TopWelcome>
        {verifyUserVerificationCodeRequestShowError && (
          <AlertContainer>
            <Alert
              variant={Alert.Variant.Danger}
              dismissible={true}
              onClose={hideVerifyUserVerificationCodeRequestError}
              iconIdentifier="close-o"
              header="Whoops"
            >
              {verifyUserVerificationCodeRequestError.message}
            </Alert>
          </AlertContainer>
        )}
        <FormSection>
          <form onSubmit={this.onFormSubmit} className="otp-form otp step_2">
            <h3 className="semibold-3 mb-4">{email}</h3>
            <div className="otp-container">
              <input
                name="number"
                placeholder="Enter OTP sent to your email"
                value={values.number}
                className={errors.number && errorClass}
                onChange={(e) => this.onInputChangeHelper(e)}
                onFocus={this.onInputFocus}
                onBlur={this.onInputBlur}
                autoComplete="off"
              />
            </div>
            <ButtonContainer>
              <Button
                type={Button.Type.Submit}
                variant={Button.Variant.Primary}
                ref={this.submitButtonRef}
                isLoading={isLoading}
                disabled={!values.number || isLoading}
              >
                Verify
              </Button>
            </ButtonContainer>
          </form>
        </FormSection>
        {canResendUserVerificationCode && (
          <ResendVerificationCode email={email} />
        )}
        <div className="bottom-navigation">
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default VerificationCodeForm;
