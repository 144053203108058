import { EmailAccountType } from '../enums/email-account';
import { EmailAccountSettings } from './email-account-settings';
import { Status } from '../../../shared/enums/status';
import { CustomDomainType } from './custom-domain';
import { User } from './team';

type CredentialPayload = {
  emailAddress: string;
  password: string;
  host: string;
  port: number;
};

type SmtpImapConnectionPayload = {
  id: number;
  emailAccount: number;
  smtpCredentialPayload: CredentialPayload;
  imapCredentialPayload: CredentialPayload;
  smtpConnectionErrorDetails: string | null;
  imapConnectionErrorDetails: string | null;
};

export type EmailAccount = {
  id?: number;
  user?: User;
  fromName?: string;
  fromEmail?: string;
  type?: EmailAccountType;
  status?: Status;
  isDefault?: number;
  lastConnectedAt?: Date;
  createdAt?: Date | string;
  modifiedAt?: Date | string;
  // emailAccountPayload?: EmailAccountPayload;
  settings?: EmailAccountSettings[];
  emailAccountCustomDomain?: EmailAccountCustomDomain;
  healthScore: number;
  emailServiceProvider?: string;
  smtpImapConnectionPayload?: SmtpImapConnectionPayload;
  hashId: string;
};

export type EmailAccountCustomDomain = {
  id: number;
  userCustomDomain: CustomDomainType;
};

export enum EmailAccountHealthScoreRequestStatus {
  Success = 'success',
  Pending = 'pending',
}

export type EmailHealthScoreResult = {
  status: EmailAccountHealthScoreRequestStatus;
  id: number;
  spf: {
    validSpf: boolean;
    spfResults: string;
  };
  dmarc: {
    validDmarc: boolean;
    dmarcResults: string;
  };
  emailAccountHealthScore: number;
  customTrackingDomain: {
    isCustomTrackingDomainCreated: boolean;
    isCustomTrackingDomainLinkedToEmailAccount: boolean;
  };
  emailAccountAgeScore: number;
  timeStamp: string;
  isEmailAccountDisconnected: boolean;
};
