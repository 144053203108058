import { PaginationQueryParams } from '../../../../../../shared/types/request';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import { CustomField, DefaultField } from '../../../../types/custom-field';

export type IProps = {
  customFields: CustomField[];
  defaultFields: DefaultField[];
  customFieldPagination: {
    options: Required<PaginationQueryParams> & { totalElements: number };
    pagesCount: number;
    count: number;
  };
  defaultFieldPagination: {
    options: Required<PaginationQueryParams> & { totalElements: number };
    pagesCount: number;
    count: number;
  };
  isRequestPending: boolean;
  onCreateHandler: (event: React.MouseEvent<HTMLElement>) => void;
  onUpdateHandler: (customField: CustomField) => void;
  onDeleteHandler: (customField: CustomField) => void;
  sendGetCustomFieldsRequest: (pagination: PaginationQueryParams) => void;
  sendGetDefaultFieldsRequest: (pagination: PaginationQueryParams) => void;
  subscriptionPlan: SubscriptionPlans;
  t: (x: string) => React.ReactNode;
};

export enum FieldContentTabs {
  CustomFields = 'custom-fields',
  SystemFields = 'system-fields',
}
