export enum UserSettingCode {
  Onboarding = 'onboarding',
  ProfileSetupCompletion = 'profile-setup-completion',
  DesktopNotification = 'desktop_notification',
  TrackEmailByMyTeam = 'track_email_by_my_team',
  ShowEmailWarmupEnableConsent = 'show_email_warmup_enable_consent',
}

export enum OnboardingSteps {
  Step0 = 'plan_purchase',
  Step1 = 'onboarding_form',
  Step1_1 = 'set-password',
  Step2 = 'email_connect',
  Step3 = 'intro-video',
}

export type UserSetting = {
  code: UserSettingCode;
  value: string;
};

export const OnboardingRoutes = {
  [OnboardingSteps.Step0]: '/LTD-Checkout',
  [OnboardingSteps.Step1]: '/warmup',
  [OnboardingSteps.Step2]: `/connect-account`,
  [OnboardingSteps.Step3]: `/sequence`,
};

export enum ProfileProgressSteps {
  WatchVideo = 'watch-video',
  ConnectEmail = 'connect-email',
  CreateSequence = 'create-sequence',
  AddProspects = 'add-prospects',
  ActivateSequence = 'activate-sequence',
}

export type ProfileProgressStep = {
  id: number;
  stepName: ProfileProgressSteps;
  order: number;
};

export type ProfileProgress = {
  id: number;
  profileProgressStepId: number;
  isCompleted: boolean;
  profileProgressStep: ProfileProgressStep;
};

export type UpdateProfileProgressPayload = {
  step: ProfileProgressSteps;
  isCompleted: boolean;
};
