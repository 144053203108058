import React from 'react';
import FormSection from '../../../form-section';
import { IProps } from './types';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import Alert from '../../../../../../shared/design-system/components/alert';
import ResetPasswordFormComponent from '../reset-password-form-component';
import { Link } from 'react-router-dom';
import AlertContainer from '../../../alert-container';
import TopWelcome from '../../../top-welcome';

class ResetPasswordForm extends React.Component<IProps, null> {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(email: string) {
    this.props.sendResetPasswordRequest(email);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { status, showSuccessComponent } = this.props;
    if (status !== prevProps.status) {
      if (status === RequestStatus.Succeeded) {
        showSuccessComponent();
      }
    }
  }

  componentWillUnmount() {
    this.props.hideError();
  }

  render() {
    const { status, error, showError, hideError } = this.props;

    const isLoading = status === RequestStatus.Pending;

    return (
      <>
        <TopWelcome>
          <TopWelcome.Header>Reset Password</TopWelcome.Header>
          <TopWelcome.Body>
            We’ll email you instructions to reset your password.
          </TopWelcome.Body>
        </TopWelcome>
        {showError && (
          <AlertContainer>
            <Alert
              variant={Alert.Variant.Danger}
              dismissible={true}
              onClose={hideError}
              iconIdentifier="close-o"
              header="Whoops"
            >
              {error.message}
            </Alert>
          </AlertContainer>
        )}
        <FormSection>
          <ResetPasswordFormComponent
            isLoading={isLoading}
            buttonText="Reset Password"
            onSubmit={this.onFormSubmit}
          />
          <div className="login-link regular-2">
            <Link to="/login" className="pointer">
              Return to Login
            </Link>
          </div>
        </FormSection>
      </>
    );
  }
}

export default ResetPasswordForm;
