import React, { ReactElement, Ref, useState } from 'react';
import { Overlay, OverlayProps, Placement, TriggerType } from '../overlay';
import TransferOverlay, { TransferOverlayProps } from './transfer-overlay';
import { ExtendedOption, TransferProps } from './transfer';
import { generateTransferOverlayId } from './helpers';

export type TransferOverlayTriggerProps<T> = Omit<OverlayProps, 'overlay'> &
  Partial<Pick<TransferOverlayProps<T>, 'id' | 'style'>> &
  TransferProps<T>;

const TransferOverlayTrigger = (React.forwardRef(
  <T extends ExtendedOption>(
    {
      multiSelect,
      header,
      footer,
      options,
      // tabs,
      // showTabs,
      optionRenderer,
      filterOption,
      optionFilterProp,
      selectedOptionKey,
      onSearch,
      onSelect,
      onDeselect,
      onChange,
      showOptionsSeparator,
      showSearch,
      showEmpty,
      emptyText,
      className,
      id: _id,
      style,
      children,
      trigger = TriggerType.Click,
      rootClose = true,
      placement = Placement.Bottom,
      searchPlaceholder,
      isLoading,
      ...rest
    }: TransferOverlayTriggerProps<T>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [id] = useState(_id || generateTransferOverlayId());
    return (
      <Overlay
        {...rest}
        placement={placement}
        trigger={trigger}
        rootClose={rootClose}
        overlay={
          <TransferOverlay<T>
            id={id}
            style={style}
            multiSelect={multiSelect}
            header={header}
            footer={footer}
            options={options}
            // tabs={tabs}
            // showTabs={showTabs}
            optionRenderer={optionRenderer}
            filterOption={filterOption}
            optionFilterProp={optionFilterProp}
            selectedOptionKey={selectedOptionKey}
            onSearch={onSearch}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onChange={onChange}
            showOptionsSeparator={showOptionsSeparator}
            showSearch={showSearch}
            showEmpty={showEmpty}
            emptyText={emptyText}
            className={className}
            customRef={ref}
            searchPlaceholder={searchPlaceholder}
            isLoading={isLoading}
          />
        }
      >
        {children}
      </Overlay>
    );
  },
) as unknown) as (<T>(
  props: TransferOverlayTriggerProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement) & { propTypes?: Record<string, any> };

// Move this to the transfer.tsx file.
// Added here because of the types mismatch.
TransferOverlayTrigger.propTypes = {
  selectedOptionKey: (props, propName) => {
    if (
      !props.multiSelect &&
      Array.isArray(props[propName]) &&
      props[propName].length > 1
    ) {
      throw new Error(
        `\`${propName}\` can't contain multiple values if \`multiSelect\` is set to \`false\`. Either set \`multiSelect\` to \`true\` or pass only one value to \`${propName}\``,
      );
    }
  },
};

export default TransferOverlayTrigger;
