import { DateTime } from 'luxon';
import { DatePickerOption, ReportDatePickerOption } from './types';

const getCurrentTimeInUTC = () =>
  DateTime.fromJSDate(new Date(new Date().setUTCHours(0, 0, 0, 0)));

export const durationOptions: ReportDatePickerOption[] = [
  {
    displayText: 'Today',
    label: 'today',
    startDate: getCurrentTimeInUTC(),
    endDate: getCurrentTimeInUTC(),
    value: 'today',
    key: 'today',
  },
  {
    displayText: 'Last 7 Days',
    label: 'last_7_days',
    startDate: getCurrentTimeInUTC().minus({ days: 7 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_7_days',
    key: 'last_7_days',
  },
  {
    displayText: 'Last 14 Days',
    label: 'last_14_days',
    startDate: getCurrentTimeInUTC().minus({ days: 14 }),
    endDate: getCurrentTimeInUTC(),
    value: 'last_14_days',
    key: 'last_14_days',
  },
  {
    displayText: 'Last Month',
    label: 'last_month',
    startDate: getCurrentTimeInUTC()
      .startOf('month')
      .minus({ months: 1 })
      .startOf('day'),
    endDate: getCurrentTimeInUTC().startOf('month').minus({ days: 1 }),
    value: 'last_month',
    key: 'last_month',
  },
  {
    displayText: 'Last Quarter',
    label: 'last_quarter',
    startDate: getCurrentTimeInUTC()
      .startOf('month')
      .minus({ months: 3 })
      .startOf('day'),
    endDate: getCurrentTimeInUTC().startOf('month').minus({ days: 1 }),
    value: 'last_quarter',
    key: 'last_quarter',
  },
  {
    displayText: 'Custom',
    label: 'custom',
    startDate: getCurrentTimeInUTC().minus({ days: 30 }),
    endDate: getCurrentTimeInUTC(),
    value: 'custom',
    key: 'custom',
  },
];

export const warmupDurationOptions: DatePickerOption[] = [
  {
    displayText: 'Today',
    label: 'today',
    startDate: DateTime.utc().toString(),
    endDate: DateTime.utc().toString(),
    value: 'today',
    key: 'today',
  },
  {
    displayText: 'Last 7 Days',
    label: 'last_7_days',
    startDate: DateTime.utc().minus({ days: 7 }).toString(),
    endDate: getCurrentTimeInUTC().toString(),
    value: 'last_7_days',
    key: 'last_7_days',
  },
];
