import api from '../../../api';
import { EmailAccountMethod } from '../enums/email-account';
import {
  UpdateEmailAccountRampUpSettingsRequestPayload,
  UpdateEmailAccountRequestPayload,
  VerifyEmailAccountRequestPayload,
  EmailTimeIntervalSettingsRequest,
} from '../types/request-payload';
import {
  TestConnectionRequest,
  ConnectSmtpImapAccountRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../types/smtp-imap';

export const connectEmailAccount = async (
  method: string,
  emailAccountId?: number,
) => {
  let requestUrl = `/email-account/connect/${method}`;
  if (emailAccountId) {
    requestUrl = `${requestUrl}/${emailAccountId}`;
  }
  return api.get(requestUrl);
};

export const verifyEmailAccount = async (
  method: EmailAccountMethod,
  payload: VerifyEmailAccountRequestPayload,
) => api.post(`/email-account/verify/${method}`, payload);

export const setDefaultEmailAccount = async (emailAccountId: number) =>
  api.get(`/email-account/${emailAccountId}/make-default`);

export const disconnectEmailAccount = async (emailAccountId: number) =>
  api.get(`/email-account/${emailAccountId}/disconnect`);

export const getEmailAccounts = async () => api.get(`/email-account`);

export const deleteEmailAccount = async (emailAccountId: number) =>
  api.delete(`/email-account/${emailAccountId}`);

export const updateEmailAccount = async (
  emailAccountId: number,
  payload: UpdateEmailAccountRequestPayload,
  // TODO: change endpoint in backend as well as frontend.
) => api.patch(`/email-account/${emailAccountId}`, payload);

export const getEmailAccount = async (hashId: string | number) =>
  api.get(`/email-account/${hashId}`);

export const getEmailAccountSettings = async (hashId: string | number) =>
  api.get(`/email-account/email-account-settings/${hashId}`);

type PayloadProps = Pick<
  UpdateEmailAccountRampUpSettingsRequestPayload,
  'rampUpInitialSendingLimit' | 'rampUpPercent' | 'rampUpStatus'
>;
export const updateEmailAccountRampUpSettings = async (
  emailAccountId: number,
  payload: PayloadProps,
) =>
  api.patch(`/email-account/ramp-up-settings/${emailAccountId}`, {
    rampUpInitialSendingLimit: Number(payload.rampUpInitialSendingLimit),
    rampUpPercent: Number(payload.rampUpPercent),
    rampUpStatus: payload.rampUpStatus,
  });

export const emailTimeIntervalSettings = async (
  emailAccountId: number,
  payload: EmailTimeIntervalSettingsRequest,
) => api.patch(`/email-account/interval-settings/${emailAccountId}`, payload);

export const initiateEmailAccountHealthCheck = async (emailAccountId: number) =>
  api.get(`/email-account/email-health/initiate/${emailAccountId}`);

export const fetchEmailAccountHealthScore = async (emailAccountId: number) =>
  api.get(`/email-account/email-health/account/${emailAccountId}`);

export const uploadImage = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
) => api.post('/attachment-broker/upload', formData, { onUploadProgress });

export const testSmtpImapConnection = async (payload: TestConnectionRequest) =>
  api.post('/email-account/smtp-imap/test-connection', payload);

export const connectSmtpImapAccount = async (
  payload: ConnectSmtpImapAccountRequest,
) => api.post('/email-account/smtp-imap/connect', payload);

export const getSmtpImapAccountDetails = async (emailAccountId: number) =>
  api.get(`/email-account/smtp-imap/${emailAccountId}`);

export const updateSmtpImapAccountDetails = async (
  emailAccountId: number,
  payload: UpdateSMTPIMAPAccountRequest,
) => api.patch(`/email-account/smtp-imap/${emailAccountId}`, payload);
