import { map } from 'lodash';
import {
  Filters,
  ProspectsFilters,
  FilterProperties,
} from '../../../shared/types/prospects-filters';
import {
  ContactStatusType,
  ContactStatusTypeDisplayName,
  ContactVerificationStatus,
  ContactVerificationStatusTypeDisplayName,
} from '../../sequence/enums';

// To separate filters when displayed on UI
export const filterSeparator = ', ';

export const filterToProperties: Record<Filters, FilterProperties> = {
  tags: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  owners: {
    displayNameKey: 'firstName',
    valueKey: 'id',
    separator: filterSeparator,
  },
  status: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  sequences: {
    displayNameKey: 'title',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'verification status': {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'created date': {
    displayNameKey: 'displayDate',
    valueKey: 'date',
    separator: ' - ',
  },
};

/**
 * Prospect Statuses
 */
export const statuses = [
  {
    name: ContactStatusTypeDisplayName.subscribed,
    id: ContactStatusType.Subscribed,
  },
  {
    name: ContactStatusTypeDisplayName.unsubscribed,
    id: ContactStatusType.Unsubscribed,
  },
  {
    name: ContactStatusTypeDisplayName.bounced,
    id: ContactStatusType.Bounced,
  },
  {
    name: ContactStatusTypeDisplayName.paused,
    id: ContactStatusType.Paused,
  },
];

/**
 * Prospect Statuses
 */
export const verificationStatuses = [
  {
    name: ContactVerificationStatusTypeDisplayName.Unverified,
    id: ContactVerificationStatus.Unverified,
  },
  {
    name: ContactVerificationStatusTypeDisplayName.Valid,
    id: ContactVerificationStatus.Valid,
  },
  {
    name: ContactVerificationStatusTypeDisplayName.Risky,
    id: ContactVerificationStatus.Risky,
  },
  {
    name: ContactVerificationStatusTypeDisplayName.Bad,
    id: ContactVerificationStatus.Bad,
  },
];

/**
 * Retrieves all the value of the filter.
 * @summary - Gets all the values of the applied filters using the value Key mentioned in filter properties
 * @param  {Filters} filterName - Name of the filter.
 * @param  {ProspectsFilters} filter - Applied filters.
 * @returns {string[]} Values of the filter.
 */
export const getFilterValues = (
  filterName: Filters,
  filter: ProspectsFilters,
): string[] => map(filter, filterToProperties[filterName].valueKey);

/**
 * Resolves the filters to query string
 * @param  {Record<Filters, any>} appliedFilters - applied filters.
 * @returns {Record<Filters, string>} Returns the Object having the applied filter and its corresponding query string
 */
type ResolveFilters = Record<Filters, string> & { isSequenceNone: boolean };

export const resolveFilters = (
  appliedFilters: Record<Filters, any>,
): ResolveFilters => {
  const payload: ResolveFilters = {
    tags: '',
    owners: '',
    status: '',
    sequences: '',
    isSequenceNone: false,
    'verification status': '',
    'created date': '',
  };

  Object.entries(appliedFilters).forEach(([filter, values]) => {
    payload[filter] = '';
    const filterValues: any = [...values];

    if (filterValues.some((item) => item?.isNoneSequence === true)) {
      payload.isSequenceNone = true;
      filterValues.shift();
    }

    const filterVal = getFilterValues(filter as Filters, filterValues).join(
      ',',
    );
    filterVal && (payload[filter] += filterVal);
  });

  return payload;
};

export const setProspectsFiltersInLocalStore = (filters: ProspectsFilters) => {
  localStorage.setItem('p-f', JSON.stringify(filters));
};

export const getProspectsFiltersFromLocalStore = (): ProspectsFilters =>
  JSON.parse(localStorage.getItem('p-f'));

export const removeProspectsFiltersFromLocalStore = (): void => {
  localStorage.removeItem('p-f');
};

/**
 * Returns the extended params of an API with the applied filters.
 * @param  {Record<string, any<} params - Params of a API
 * @param  {ProspectsFilters} filters - Applied filters.
 */
export const getProspectParams = (
  params: Record<string, any>,
  filters: ProspectsFilters,
) => {
  // Resolving the applied filters to query string
  const {
    tags,
    owners,
    status,
    sequences,
    isSequenceNone,
    'verification status': verificationStatus,
    'created date': createdDate,
  } = resolveFilters(filters);

  // Adding them to params
  status && (params.status = status);
  tags && (params.tags = tags);
  owners && (params.owners = owners);
  sequences && (params.sequences = sequences);
  isSequenceNone && (params.isSequenceNone = isSequenceNone);
  verificationStatus && (params.verification = verificationStatus);
  createdDate && (params.createdDate = createdDate);

  return params;
};

export const areFiltersApplied = (filters: ProspectsFilters): boolean =>
  Object.values(filters).some((value) => value.length);
