import React from 'react';

import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  AuthHelper,
  initializeSentryIntegrations,
  initializeThirdPartyIntegrations,
} from '../../../../shared/utils';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../../shared/utils/execute-on-request-status';
import AccountDetails from '../account-details';
import { IProps } from './types';

class Signup extends React.Component<IProps> {
  componentDidMount() {
    const { sendVerifyInvitationTokenRequest } = this.props;

    sendVerifyInvitationTokenRequest();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      verifyInvitationTokenRequestStatus,
      acceptInvitationRequestStatus,
      accessToken,
      history,
      firstName,
      lastName,
      email,
      trackingId,
      agencyConfig,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: verifyInvitationTokenRequestStatus,
      prevStatus: prevProps.verifyInvitationTokenRequestStatus,
      onSuccess: () => {
        if (accessToken) {
          AuthHelper.login({ token: accessToken });

          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
        }
        initializeSentryIntegrations(email);
      },
      onFailed: () => {
        history.push('/not-found');
      },
    });

    if (
      acceptInvitationRequestStatus !==
        prevProps.acceptInvitationRequestStatus &&
      acceptInvitationRequestStatus === RequestStatus.Succeeded &&
      accessToken !== prevProps.accessToken
    ) {
      AuthHelper.login({ token: accessToken });
    }
  }

  render() {
    const { match, accessToken } = this.props;
    const { token } = match.params;
    const { verifyInvitationTokenRequestStatus, email } = this.props;

    const status =
      verifyInvitationTokenRequestStatus === RequestStatus.Succeeded;
    if (status && !accessToken) {
      return (
        <>
          <AccountDetails
            inviteUserEmail={email}
            isInvitationRequest={true}
            token={token}
          />
        </>
      );
    }

    return null;
  }
}

export default Signup;
