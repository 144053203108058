import React from 'react';

export const PaperPlane = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5631 3.33958C20.2711 3.14058 19.8961 3.11058 19.5741 3.26058L2.57408 11.2606C2.21908 11.4286 1.99508 11.7876 2.00008 12.1796C2.00608 12.5716 2.24008 12.9246 2.60008 13.0816L8.00008 15.4446V20.2235C8.00008 21.0369 8.91939 21.51 9.58127 21.0373L13.8361 17.9986L18.6001 20.0826C18.7281 20.1396 18.8651 20.1666 19.0001 20.1666C19.1811 20.1666 19.3601 20.1176 19.5201 20.0206C19.7981 19.8516 19.9771 19.5576 19.9991 19.2326L20.9991 4.23258C21.0211 3.87858 20.8561 3.53958 20.5631 3.33958ZM18.0971 17.6796L12.8281 15.3736L14.5058 12.0907C14.9552 11.2111 13.993 10.2818 13.1296 10.7615L8.35108 13.4166L5.41908 12.1336L18.8901 5.79358L18.0971 17.6796Z"
      fill="currentColor"
    />
  </svg>
);
