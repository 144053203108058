import React, { useState } from 'react';
import Papa from 'papaparse';
import { DragAndDrop } from '@saleshandy/design-system';
import FileUploader from '../file-uploader';
import { PreSelectProps } from './types';
import { FileConfig } from '../../enum';
import toaster, { Theme } from '../../../../../../../shared/toaster';
import { Images } from '../../../../../../../shared/app-constants';
import ImageIcon from '../../../../../../../shared/components/images/image-icon';
import {
  ImportEmailAccountHeader,
  getAllImportEmailAccountHeaders,
  getRequiredImportEmailAccountHeaders,
} from '../../../utils/import-email-accounts-header';

const allowedExtensions = ['csv'];

const PreSelect = ({
  dragging,
  setDragging,
  onFileSelect,
  setImportedCSVStats,
}: PreSelectProps) => {
  const [fileUploaderKey, setFileUploaderKey] = useState(0);

  const resetFileUploader = () => setFileUploaderKey(1 - fileUploaderKey);

  const showFailedMessage = (message: string) =>
    toaster.error(message, { theme: Theme.New });

  const failureHandler = (message: string) => {
    showFailedMessage(message);
    resetFileUploader();
  };

  const onSelect = (file) => {
    let isHeaderRowValidated = false;
    let failedMessage = '';
    let rowsCount = 0;
    let skippedRowsCount = 0;
    const skippedRows = [];
    const validRows = [];
    const occurrences = new Map();

    // Extract the File Extension
    const extension: string[] = file.name.split('.');

    // Validate the uploaded file extension against the supported extensions
    if (
      !allowedExtensions.includes(
        extension[extension.length - 1].trim().toLowerCase(),
      )
    ) {
      failedMessage = `The file type you are trying to upload is not supported.`;
      return failureHandler(failedMessage);
    }

    // Validate the uploaded file size against the max file size allowed
    if (file.size > FileConfig.MaxFileSizeInMB * 1024 * 1024) {
      failedMessage = `File size must be less than ${FileConfig.MaxFileSizeInMB} MB`;
      return failureHandler(failedMessage);
    }

    const requiredColumnsWithIndices: ImportEmailAccountHeader[] = [];
    const requiredColumns = getRequiredImportEmailAccountHeaders().map(
      (column) => ({ ...column, label: column.label.toLowerCase() }),
    );

    // Parse the CSV file
    Papa.parse<string>(file, {
      step: ({ data }, parser) => {
        /**
         * Validate the CSV Header row if it not validated yet
         */
        if (!isHeaderRowValidated) {
          const csvColumns = data.map((column) => column.toLowerCase());

          requiredColumns.forEach((requiredColumn) => {
            const index = csvColumns.indexOf(requiredColumn.label);
            requiredColumnsWithIndices.push({
              ...requiredColumn,
              index,
            });
          });

          if (requiredColumnsWithIndices.some((col) => col.index === -1)) {
            failedMessage = `Required columns does not exist.`;

            return parser.abort();
          }

          isHeaderRowValidated = true;

          return null;
        }

        let shouldSkipRow = false;

        requiredColumnsWithIndices.forEach((column) => {
          const requiredColumnValue = data[column.index];

          if (!requiredColumnValue) {
            /**
             * If the requiredColumnValue is not found
             * then we should skip the row
             */
            shouldSkipRow = true;
            data.push(column.messages?.notFound);
          } else if (
            column?.validator &&
            !column?.validator(requiredColumnValue)
          ) {
            /**
             * If the requiredColumnValue is not valid
             * then we should skip the row
             */
            shouldSkipRow = true;
            data.push(column.messages?.invalid);
          } else {
            /**
             * Get the number of occurrences for the requiredColumnValue
             */
            const numberOfOccurrences =
              occurrences.get(requiredColumnValue) || 0;

            if (numberOfOccurrences >= 1 && !column.duplicateAllowed) {
              /**
               * If number of occurrences is greater than equal to 1
               * which means that the requiredColumnValue is already exist
               * and duplicate column value is not allowed
               * then we should skip the row
               */
              shouldSkipRow = true;
              data.push(column.messages?.duplicate);
            }

            // Update the number of occurrences for the requiredColumnValue
            occurrences.set(requiredColumnValue, numberOfOccurrences + 1);
          }
        });

        if (shouldSkipRow) {
          skippedRows.push(data);
          skippedRowsCount += 1;
        } else {
          validRows.push(data);
        }

        /**
         * Validate if the number of parsed rows exceeds the max rows allowed threshold or not
         */
        if (rowsCount > FileConfig.MaxRows) {
          failedMessage = `Maximum ${FileConfig.MaxRows} emails are allowed`;
          return parser.abort();
        }

        // Increase the rows count by 1
        rowsCount += 1;

        return null;
      },
      complete: () => {
        if (!failedMessage && rowsCount === 0) {
          failedMessage = 'At least 1 email account is required';
        }

        if (failedMessage) {
          return failureHandler(failedMessage);
        }

        const validRowsCount = rowsCount - skippedRowsCount;

        //* Create CSV for Success Data
        const csvData = Papa.unparse({
          fields: getAllImportEmailAccountHeaders(),
          data: validRows,
        });
        const validFile = new File([csvData], file.name, {
          type: 'text/csv',
        });

        setImportedCSVStats({ validRowsCount, skippedRowsCount, skippedRows });
        onFileSelect(validFile);

        return null;
      },
      skipEmptyLines: 'greedy',
    });

    return null;
  };

  return (
    <DragAndDrop
      dragging={dragging}
      setDragging={setDragging}
      handleDroppedFiles={(files) => onSelect(files[0])}
      backgroundVariant="solid"
    >
      <div className="pre-select">
        <ImageIcon src={Images.UploadCloud} />

        <div className="pre-select-tagline">
          <span>Drag & Drop CSV file or </span>
          <FileUploader
            key={fileUploaderKey}
            className="pre-select-file-uploader"
            onSelect={onSelect}
          />
        </div>

        <p className="pre-select-info">Maximum allowed accounts per CSV: 100</p>
      </div>
    </DragAndDrop>
  );
};

export default PreSelect;
