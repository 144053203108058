import { isEmpty } from 'lodash';
import React from 'react';
import connectEmailAccountLeftSvg from '../../../../assets/images/connect-email-account-left.svg';
import ConnectEmailAccountOptions from '../../../../shared/components/connect-email-account-options';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  OnboardingSteps,
  UserSettingCode,
} from '../../../../shared/types/user-setting';
import { redirectWithRefresh } from '../../../../shared/utils';
import { handleOnboarding } from '../../../../shared/utils/handle-onboarding-routing';
import type { IProps } from './connect-email-account-container';

class ConnectEmailAccount extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.skipConnectEmailAccount = this.skipConnectEmailAccount.bind(this);
  }

  componentDidMount() {
    const { sendGetUserSettingsRequest } = this.props;
    sendGetUserSettingsRequest();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      getUserSettingsRequestStatus,
      meta,
      connectEmailAccountRequestStatus,
      upgradeOnboardingStepRequestStatus,
      authUrl,
      location,
      history,
      sendGetUserSettingsRequest,
    } = this.props;

    if (
      getUserSettingsRequestStatus !== prevProps.getUserSettingsRequestStatus
    ) {
      if (getUserSettingsRequestStatus === RequestStatus.Succeeded) {
        if (!isEmpty(meta)) {
          const currentPath = location.pathname + location.search;
          handleOnboarding(meta, currentPath);
        } else {
          history.push('./sequence');
        }
      }
    }

    if (
      connectEmailAccountRequestStatus !==
        prevProps.connectEmailAccountRequestStatus &&
      connectEmailAccountRequestStatus === RequestStatus.Succeeded
    ) {
      redirectWithRefresh(authUrl);
    }

    if (
      upgradeOnboardingStepRequestStatus !==
        prevProps.upgradeOnboardingStepRequestStatus &&
      upgradeOnboardingStepRequestStatus === RequestStatus.Succeeded
    ) {
      sendGetUserSettingsRequest();
    }
  }

  skipConnectEmailAccount() {
    const { sendUpgradeOnboardingStepRequest, meta } = this.props;

    if (!isEmpty(meta)) {
      const onboardingStep = meta.find(
        (setting) => setting.code === UserSettingCode.Onboarding,
      );
      if (onboardingStep.value === OnboardingSteps.Step2) {
        sendUpgradeOnboardingStepRequest({
          onboardingStep: OnboardingSteps.Step3,
        });
      }
    }
  }

  render() {
    const { sendConnectEmailAccountRequest } = this.props;
    return (
      <div className="connect-email-account-container">
        <div className="connect-email-account-content">
          <img alt="connect_account_graphic" src={connectEmailAccountLeftSvg} />
          <div className="options-container">
            <ConnectEmailAccountOptions
              skipConnectEmailAccount={this.skipConnectEmailAccount}
              sendConnectEmailAccountRequest={sendConnectEmailAccountRequest}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectEmailAccount;
