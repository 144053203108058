import React from 'react';
import SignUpQuestionsData from './components/signup-questions-data';

class OnBoarding extends React.Component {
  render() {
    return <SignUpQuestionsData />;
  }
}

export default OnBoarding;
