import React from 'react';
import Switch, {
  Size,
} from '../../../../../../shared/design-system/components/atoms/switch';
import { ShAccountSettingsCode } from '../../../../enums/admin-settings';

type IProps = {
  checked: boolean;
  onChange: (code: ShAccountSettingsCode) => void;
  label: string;
  code: ShAccountSettingsCode;
};

const AdminSettingToggle: React.FC<IProps> = ({
  checked,
  onChange,
  label,
  code,
}) => (
  <div className="admin-setting-toggle">
    <Switch
      className="admin-setting-toggle--switch"
      checked={checked}
      onChange={() => onChange(code)}
      size={Size.Small}
    />
    <div className="admin-setting-toggle--label">{label}</div>
  </div>
);

export default AdminSettingToggle;
