import React from 'react';
import { IProps, IState } from './types';
import ResetPasswordInvalidLinkSuccess from './components/reset-password-invalid-link-success';
import ResetPasswordInvalidLinkForm from './components/reset-password-invalid-link-form';
import { Container } from 'react-bootstrap';

class ResetPasswordInvalidLink extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { showSuccessComponent: false };
    this.showSuccessComponent = this.showSuccessComponent.bind(this);
  }

  showSuccessComponent() {
    this.setState({ showSuccessComponent: true });
  }

  render() {
    const { showSuccessComponent } = this.state;

    return (
      <Container fluid className="invalid-msg-container">
        {showSuccessComponent ? (
          <ResetPasswordInvalidLinkSuccess />
        ) : (
          <ResetPasswordInvalidLinkForm
            showSuccessComponent={this.showSuccessComponent}
          />
        )}
      </Container>
    );
  }
}

export default ResetPasswordInvalidLink;
