export enum AnalyticsEvents {
  SignedOut = 'Signed Out',
  NotificationClicked = 'Notification Clicked',
  ExtensionIconClicked = 'Extension Icon Clicked',
  FeatureRequestClicked = 'Feature Request Clicked',
  BeaconOpened = 'Beacon Opened',
  TemplateInserted = 'Template Inserted',
  ExtraVariantAdded = 'Extra Variant Added',
  TrialExtended = 'Trial Extended',
}
