export enum FileConfig {
  MaxFileSizeInMB = 5,
  MaxRows = 100,
  MaxColumns = 50,
}

export enum ImportStage {
  PreSelect = 'pre-select',
  Uploading = 'uploading',
  PostSelect = 'post-select',
}
