import React, { useEffect, useState } from 'react';
import { Icon, ProgressBar } from '@saleshandy/design-system';
import find from 'lodash/find';
import type { IProps } from './master-class-progress-container';
import { getPercentageValue } from '../../../home/components/header/utils/helper';

const MasterClassProgress: React.FC<IProps> = ({
  academyLessons,
  academyTopicsProgress,
}) => {
  //* state
  const [
    numberOfCompletedLessons,
    setNumberOfCompletedLessons,
  ] = useState<number>(0);

  const getCompletedLessonIdWithTotalTopics = () => {
    const obj = {};

    academyTopicsProgress.forEach(({ lessonId }) => {
      if (lessonId in obj) {
        obj[lessonId] += 1;
      } else {
        obj[lessonId] = 1;
      }
    });

    Object.entries(obj).forEach(([key, value]) => {
      const filterLessonArray = find(academyLessons[0].lessons, {
        id: Number(key),
      });

      if (filterLessonArray.topics.length === value) {
        setNumberOfCompletedLessons((preState) => preState + 1);
      }
    });
  };

  //* useEffect
  useEffect(() => {
    if (academyLessons && academyTopicsProgress) {
      getCompletedLessonIdWithTotalTopics();
    }

    return () => {
      setNumberOfCompletedLessons(0);
    };
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <span className="semibold-3 popover-arrow-color-txt">
          Cold Email 1O1
        </span>
        <div>
          <div className="d-flex justify-content-between mt-3 mb-1">
            <span className="font-10 semibold-1 gray-txt-15">{`${numberOfCompletedLessons}/6 Lessons Completed`}</span>
            <Icon identifier="trophy" className="green-txt-14 regular-4" />
          </div>
          <ProgressBar
            percentage={getPercentageValue(numberOfCompletedLessons, 6)}
            baseColor="#047857"
            hasBreakPoints={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MasterClassProgress;
