import { RootState } from '../../../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import ChangePasswordSuccess from './change-password-success';
import { ResetPasswordCallbackRouteProps } from '../../../reset-password-callback/reset-password-callback-container';

const mapStateToProps = (state: RootState) => ({
  message: state.changePassword.message,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> &
  ResetPasswordCallbackRouteProps;

export default withRouter(connector(ChangePasswordSuccess));
