import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { produce } from 'immer';
import Input from '../../../../../../shared/design-system/components/input';
import Select from '../../../../../../shared/design-system/components/select';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import ChangePasswordModal from '../change-password-modal';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { IState, IProps, TimezoneOption } from './types';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { UpdatePasswordRequestPayload } from '../change-password-modal/types';
import { validate } from './validator';
import { UpdateUserProfilePayload } from '../../../../types/request-payload';
import { getTimezoneList } from '../../../../../../shared/utils/date-time';
import TimezoneOptionRenderer from '../../../../../../shared/design-system/components/atoms/timezone-option-renderer';
import HeaderBanner from '../../../header-banner';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';

class MyProfileContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { myProfile } = props;
    this.state = {
      values: {
        firstName: myProfile.firstName,
        lastName: myProfile.lastName,
      },
      errors: {
        firstName: '',
        lastName: '',
      },
      dirty: {
        firstName: false,
        lastName: false,
      },
      timeZoneKey: this.getTimeZoneOptions().find(
        (item) => item.name.toLowerCase() === myProfile.timeZone.toLowerCase(),
      ).name,
      showChangePasswordModal: false,
    };

    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getTimeZoneOptions = this.getTimeZoneOptions.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
    this.hideChangePasswordModal = this.hideChangePasswordModal.bind(this);
    this.changePasswordHandler = this.changePasswordHandler.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      updatePasswordRequestStatus,
      updatePasswordRequestMessage,
      updatePasswordRequestError,
      updateMyProfileRequestError,
      updateMyProfileRequestMessage,
      updateMyProfileRequestStatus,
      getMyProfileRequest,
    } = this.props;

    if (updatePasswordRequestStatus !== prevProps.updatePasswordRequestStatus) {
      if (updatePasswordRequestStatus === RequestStatus.Succeeded) {
        toaster.success(updatePasswordRequestMessage, { theme: Theme.New });
        this.hideChangePasswordModal();
      }

      if (
        updatePasswordRequestStatus === RequestStatus.Failed &&
        updatePasswordRequestError
      ) {
        toaster.error(updatePasswordRequestError.message, { theme: Theme.New });
      }
    }

    if (
      updateMyProfileRequestStatus !== prevProps.updateMyProfileRequestStatus
    ) {
      if (updateMyProfileRequestStatus === RequestStatus.Succeeded) {
        toaster.success(updateMyProfileRequestMessage, { theme: Theme.New });
        getMyProfileRequest();
      }

      if (
        updateMyProfileRequestStatus === RequestStatus.Failed &&
        updateMyProfileRequestError
      ) {
        toaster.error(updateMyProfileRequestError.message, {
          theme: Theme.New,
        });
      }
    }
  }

  onInputChange(value: string, event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;
    this.setState(
      produce((draft: IState) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onSelectChange(selectedOption: TimezoneOption) {
    this.setState({ timeZoneKey: selectedOption.key });
  }

  onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { dirty, errors, values, timeZoneKey } = this.state;
    const { sendUpdateMyProfileRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errors);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);
      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (isError) {
      this.setState({ errors, dirty });
      return;
    }

    const timeZone = this.getTimeZoneOptions().find(
      (tz) => tz.key === timeZoneKey,
    ).name;

    const payload: UpdateUserProfilePayload = {
      ...values,
      timeZone,
    };

    sendUpdateMyProfileRequest(payload);
  }

  getTimeZoneOptions = () => {
    const timeZones = getTimezoneList();
    return timeZones.map((item) => ({
      key: item.name,
      ...item,
    }));
  };

  showChangePasswordModal(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();
    this.setState({ showChangePasswordModal: true });
  }

  hideChangePasswordModal() {
    this.setState({ showChangePasswordModal: false });
  }

  changePasswordHandler(payload: UpdatePasswordRequestPayload) {
    const { sendUpdatePasswordRequest } = this.props;
    sendUpdatePasswordRequest(payload);
  }

  render() {
    const { values, errors, timeZoneKey, showChangePasswordModal } = this.state;
    const {
      myProfile,
      subscriptionPlan,
      updateMyProfileRequestStatus,
      updatePasswordRequestStatus,
    } = this.props;

    const isUpdateMyProfileRequestStatusPending =
      updateMyProfileRequestStatus === RequestStatus.Pending;
    const isUpdatePasswordRequestStatusPending =
      updatePasswordRequestStatus === RequestStatus.Pending;

    return (
      <div className="profile-container">
        <div className="profile-header">
          <span className="semibold-3">Profile</span>
        </div>

        {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}

        <div className="form-container">
          <form onSubmit={this.onFormSubmit}>
            <Input
              name="firstName"
              label="First name"
              placeholder="Enter your first name"
              value={values.firstName}
              variant={errors.firstName && Input.Variant.Error}
              caption={errors.firstName}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              autoComplete="current-firstName"
              autoFocus
              disabled={!hasPermission(Permissions.MY_PROFILE_UPDATE)}
            />
            <Input
              name="lastName"
              label="Last name"
              placeholder="Enter your last name"
              value={values.lastName}
              variant={errors.lastName && Input.Variant.Error}
              caption={errors.lastName}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              autoComplete="current-lastName"
              disabled={!hasPermission(Permissions.MY_PROFILE_UPDATE)}
            />
            <Input
              name="email"
              label="Email"
              disabled={true}
              value={myProfile.email}
            />

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="regular-2">Time Zone</label>
            <Select<TimezoneOption>
              showSearch
              filterOption={(value, option) =>
                option.name.toLowerCase().includes(value.toLowerCase())
              }
              selectedOptionRenderer={([option]) => <span>{option.name}</span>}
              optionRenderer={(option) => (
                <TimezoneOptionRenderer timezone={option.value} />
              )}
              options={this.getTimeZoneOptions()}
              placeholder="Select Time Zone"
              selectedOptionKey={timeZoneKey}
              onChange={([selectedOption]) =>
                this.onSelectChange(selectedOption)
              }
            />

            <div className="button-container">
              <Button
                variant={Button.Variant.Primary}
                type={Button.Type.Submit}
                isLoading={isUpdateMyProfileRequestStatusPending}
                disabled={isUpdateMyProfileRequestStatusPending}
              >
                Save
              </Button>
            </div>
          </form>
        </div>

        {hasPermission(Permissions.MY_PROFILE_UPDATE) && (
          <>
            <div className="profile-header">
              <span className="semibold-3">Change Password</span>
            </div>

            <div className="change-password">
              <BootstrapButton
                onClick={this.showChangePasswordModal}
                variant="link"
                href="#"
              >
                Click here to Change Password
              </BootstrapButton>
            </div>
          </>
        )}

        {hasPermission(Permissions.MY_PROFILE_UPDATE) &&
          showChangePasswordModal && (
            <ChangePasswordModal
              show={showChangePasswordModal}
              onClose={this.hideChangePasswordModal}
              onSubmit={this.changePasswordHandler}
              isLoading={isUpdatePasswordRequestStatusPending}
            />
          )}
      </div>
    );
  }
}

export default MyProfileContent;
