import React from 'react';
import CellCard from '../../../../../../../shared/design-system/components/atoms/cell-card';
import CustomDomainValidIcon from './custom-domain-valid-icon';

const customDomainColumnData = [
  {
    dataField: 'customDomain',
    text: 'Domain Name',
    headerAlign: 'left',
    cellClasses: 'name-field',
    component: (cell) => {
      return <CustomDomainValidIcon customDomain={cell} />;
    },
  },
  {
    dataField: 'emailAccounts',
    text: 'Email',
    headerAlign: 'left',
    component: (cell) => {
      if (cell && cell.length > 0) {
        const mappedCell = cell.map((emailAccount) => {
          return {
            value: emailAccount.email,
          };
        });
        return <CellCard list={mappedCell} bulkText="Email Accounts" />;
      }
    },
  },
];

export default customDomainColumnData;
