import React, { useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import type { IProps } from './growth-hub-container';
import MasterclassProgress from './components/masterclass-progress';
import MasterClassLessons from './components/master-class-lessons';
import MasterClassChapterContent from './components/master-class-chapter-content';
import type { Topic } from './types';
import { executeOnRequestStatus } from '../../shared/utils/execute-on-request-status';
import { getIsRequestPending } from '../templates/components/helpers';
import Spinner from '../../shared/design-system/components/atoms/custom-spinner';

const GrowthHub: React.FC<IProps> = ({
  academyLessons,
  getAcademyTopicsRequestStatus,
  getAcademyTopics,
  getAcademyTopicsProgress,
}) => {
  const isLoading = getIsRequestPending(getAcademyTopicsRequestStatus);

  //* state
  const [currentLessonId, setCurrentLessonId] = useState<number>(1);
  const [selectedTopic, setSelectedTopic] = useState<Topic>(null);

  //* functions
  const onTopicChange = (topic: Topic, lessonId: number) => {
    setSelectedTopic(topic);
    setCurrentLessonId(lessonId);
  };

  const onNextTopic = () => {
    const currentLessonIdex = findIndex(academyLessons[0]?.lessons, {
      id: currentLessonId,
    });
    const currentTopicIndex = findIndex(
      academyLessons[0]?.lessons[currentLessonIdex].topics,
      {
        id: selectedTopic.id,
      },
    );
    const updatedLessonId = academyLessons[0]?.lessons[currentLessonIdex]?.id;

    const isLessonHasNextTopic =
      academyLessons[0]?.lessons[currentLessonIdex]?.topics[
        currentTopicIndex + 1
      ];
    const nextLessonFirstTopic =
      academyLessons[0]?.lessons[currentLessonIdex + 1]?.topics[0];

    if (isLessonHasNextTopic) {
      onTopicChange(isLessonHasNextTopic, updatedLessonId);
      return;
    }

    if (nextLessonFirstTopic) {
      onTopicChange(nextLessonFirstTopic, updatedLessonId + 1);
    }
  };

  const onPreviousTopic = () => {
    const currentLessonIdex = findIndex(academyLessons[0]?.lessons, {
      id: currentLessonId,
    });
    const currentTopicIndex = findIndex(
      academyLessons[0]?.lessons[currentLessonIdex].topics,
      {
        id: selectedTopic.id,
      },
    );
    const updatedLessonId = academyLessons[0]?.lessons[currentLessonIdex]?.id;

    const isLessonHasNextTopic =
      academyLessons[0]?.lessons[currentLessonIdex]?.topics[
        currentTopicIndex - 1
      ];
    const preciousLessonLastTopicIndex =
      academyLessons[0]?.lessons[currentLessonIdex - 1]?.topics?.length;
    const previousLessonLastTopic =
      academyLessons[0]?.lessons[currentLessonIdex - 1]?.topics[
        preciousLessonLastTopicIndex - 1
      ];

    if (isLessonHasNextTopic) {
      onTopicChange(isLessonHasNextTopic, updatedLessonId);
      return;
    }

    if (previousLessonLastTopic) {
      onTopicChange(previousLessonLastTopic, updatedLessonId - 1);
    }
  };

  //* useEffect
  useEffect(() => {
    getAcademyTopics();
    getAcademyTopicsProgress();
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAcademyTopicsRequestStatus,
      onSuccess: () => {
        setSelectedTopic(academyLessons[0].lessons[0].topics[0]);
      },
    });
  }, [getAcademyTopicsRequestStatus]);

  //* Loader
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="growth-hub pt-4 pl-4 pr-4 gray-19">
      <div className="growth-hub__left-container">
        <MasterclassProgress />
        <div className="bs-mt-20 lesson-container">
          <MasterClassLessons
            onTopicSelect={(topic, lessonId) => onTopicChange(topic, lessonId)}
            currentLessonId={currentLessonId}
            currentTopicId={selectedTopic?.id}
          />
        </div>
      </div>
      <div className="growth-hub__right-container">
        <MasterClassChapterContent
          topic={selectedTopic}
          currentLessonId={currentLessonId}
          onNextTopic={onNextTopic}
          onPreviousTopic={onPreviousTopic}
        />
      </div>
    </div>
  );
};

export default GrowthHub;
