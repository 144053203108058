import React from 'react';
import { Images } from '../../../shared/app-constants';

const TopLogo = ({ prefix, children = <></> }) => (
  <div className="top-logo d-flex justify-content-center align-items-center">
    <span className="semibold-4 blue-txt-6 mr-2 pr-1">{prefix}</span>
    <img src={Images.TrulyInbox} alt="logo" />
    {children}
  </div>
);

export default TopLogo;
