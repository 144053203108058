import React from 'react';
import { IProps } from './types';

const CustomFieldHeader: React.FC<IProps> = ({ headerName }) => (
  <div className="custom-fields-grid-header">
    <div>
      <span className="semibold-3">{headerName}</span>
    </div>
  </div>
);

export default CustomFieldHeader;
