import React from 'react';
import Input from '../../../../../../shared/design-system/components/input';
import ButtonContainer from '../../../button-container';
import Button from '../../../../../../shared/design-system/components/atoms/button/button-container';
import produce from 'immer';
import { validate } from './validator';
import { IProps, IState } from './types';

class ResetPasswordFormComponent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: '',
      },
      errors: {
        email: '',
      },
      dirty: {
        email: false,
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onInputChange(value, e) {
    const name = e.target.name;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const name = e.target.name;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const dirty = { ...this.state.dirty };
    const dirtyKeys = Object.keys(dirty);

    for (const key of dirtyKeys) {
      dirty[key] = true;
    }

    const errors = { ...this.state.errors };
    const keys = Object.keys(errors);
    let isError = false;

    for (const key of keys) {
      const error = validate(key, this.state.values[key]);
      errors[key] = error;
      isError = isError || !!error;
    }

    this.setState({ errors, dirty });

    if (isError) {
      return;
    }

    const { email } = this.state.values;
    this.props.onSubmit(email);
  }

  render() {
    const { values, errors } = this.state;
    const { isLoading, buttonText } = this.props;

    return (
      <form onSubmit={this.onFormSubmit}>
        <Input
          name="email"
          label="Email"
          placeholder="Enter your email address"
          value={values.email}
          variant={errors.email && Input.Variant.Error}
          caption={errors.email}
          onChange={this.onInputChange}
          onBlur={this.onInputBlur}
          autoComplete="current-email"
          autoFocus
        />
        <ButtonContainer>
          <Button
            variant={Button.Variant.Primary}
            type={Button.Type.Submit}
            isLoading={isLoading}
            disabled={!this.state.values.email || isLoading}
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </form>
    );
  }
}

export default ResetPasswordFormComponent;
