import validator from 'validator';

export const validate = (name, value) => {
  switch (name) {
    case 'fieldName':
      if (validator.isEmpty(value)) {
        return 'provide_field_name';
      }
      break;
  }

  return '';
};
