export enum SaleshandyUsageRadioButtonOptions {
  SalesTeam = 'Sales Team',
  MarketingTeam = 'Marketing Team',
  Agency_Consultant = 'Agency/Consultant',
  Recruiters = 'Recruiters',
  Other = 'Other',
}

export enum JobRoleOptions {
  BusinessDevelopmentRepresentative = 'Business Development Representative',
  BusinessDevelopmentManager = 'Business Development Manager',
  SalesDevelopmentRepresentative = 'Sales Development Representative',
  SalesDevelopmentManager = 'Sales Development Manager',
  AccountExecutive = 'Account Executive',
  SalesAccountManager = 'Sales account manager',
  MarketingExecutive = 'Marketing Executive',
  GrowthManager = 'Growth Manager',
  MarketingManager = 'Marketing Manager',
  Recruiter = 'Recruiter',
  Consultant = 'Consultant',
  Founder = 'Founder',
  Other = 'Other',
}

export enum UseCaseOptions {
  LeadGeneration = 'Lead Generation',
  LeadQualification = 'Lead Qualification',
  BookMoreMeetings_BuildSalesPipeline = 'Book more meetings / Build sales pipeline',
  OutreachToCandidates_Recruitment = 'Outreach to candidates / Recruitment',
  PromoteProduct_Services = 'Promote your product & services',
  OneTimeColdEmailOutreach = 'One time cold email outreach to my list',
  LinkBuilding_PR = 'Link Building / PR',
  Other = 'Other',
}

export enum TeamSizeRadioButtonOptions {
  TEAM_1_10 = '1-10',
  TEAM_11_50 = '11-50',
  TEAM_51_200 = '51-200',
  TEAM_201_500 = '201-500',
  TEAM_501_1000 = '501-1000',
  TEAM_1000 = '1000+',
}

export enum IndustryRadioButtonOptions {
  Saas = 'SaaS',
  ItServices = 'Information Technology & Services',
  ComputerSoftware = 'Computer Software',
  SalesMarketingAgency = 'Sales / Marketing agency',
  Internet = 'Internet',
  StaffingRecruitment = 'Staffing & Recruitment',
  MarketingAndAdvertising = 'Marketing & Advertising',
  ELearningEdTech = 'E-learning / EdTech',
  ManagementConsulting = 'Management Consulting',
  RealEstate = 'Real Estate',
  Finance = 'Finance',
  Other = 'Other',
}
