import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { getAcademyTopicsProgressRequest } from '../../extra-actions';
import MasterClassProgress from './master-class-progress';

const mapStateToProps = (state: RootState) => ({
  academyLessons: state.growthHub.getAcademyTopicsResponse,
  academyTopicsProgress: state.growthHub.getAcademyTopicsProgressResponse,
  updateAcademyTopicProgressRequestStatus:
    state.growthHub.updateAcademyTopicProgressRequest.status,
});

const mapDispatchToProps = {
  getAcademyTopicsProgress: () => getAcademyTopicsProgressRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(MasterClassProgress));
