import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getCustomFieldRequest,
  getCustomFieldsWithPaginationRequest,
  getDefaultFieldsWithPaginationRequest,
  createCustomFieldRequest,
  updateCustomFieldRequest,
  deleteCustomFieldRequest,
} from './extra-actions';
import { CustomField, DefaultField } from '../../types/custom-field';
import { PaginationQueryParams } from '../../../../shared/types/request';
import { constants } from '../../../../shared/enums/constants';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  getCustomFieldsWithPaginationRequest: RequestState;
  getDefaultFieldsWithPaginationRequest: RequestState;
  getCustomFieldRequest: RequestState;
  createCustomFieldRequest: RequestState;
  updateCustomFieldRequest: RequestState;
  deleteCustomFieldRequest: RequestState;
  customField: CustomField[];
  defaultField: DefaultField[];
  isLoading: boolean;
  totalFields: number;
  customFieldPagination: {
    options: Required<PaginationQueryParams>;
    pagesCount: number;
    count: number;
  };
  defaultFieldPagination: {
    options: Required<PaginationQueryParams>;
    pagesCount: number;
    count: number;
  };
}

const initialState: State = {
  getCustomFieldRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getCustomFieldsWithPaginationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getDefaultFieldsWithPaginationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createCustomFieldRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateCustomFieldRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteCustomFieldRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  customField: [],
  defaultField: [],
  isLoading: false,
  totalFields: 0,
  customFieldPagination: {
    options: {
      page: 1,
      limit: constants.DEFAULT_PAGE_SIZE,
    },
    count: 0,
    pagesCount: 0,
  },
  defaultFieldPagination: {
    options: {
      page: 1,
      limit: constants.DEFAULT_PAGE_SIZE,
    },
    count: 0,
    pagesCount: 0,
  },
};

const customFieldSlice = createSlice({
  name: 'customField',
  initialState,
  reducers: {
    resetCreateCustomFieldRequest: (state) => {
      state.createCustomFieldRequest.status = RequestStatus.Ideal;
      state.createCustomFieldRequest.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // Get custom fields
    builder.addCase(getCustomFieldRequest.pending, (state) => {
      state.getCustomFieldRequest.status = RequestStatus.Pending;
      state.getCustomFieldRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getCustomFieldRequest.fulfilled, (state, action) => {
      state.getCustomFieldRequest.status = RequestStatus.Succeeded;
      state.getCustomFieldRequest.message = action.payload.message;
      state.customField = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomFieldRequest.rejected, (state, action) => {
      state.getCustomFieldRequest.status = RequestStatus.Failed;
      state.getCustomFieldRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get custom fields with pagination
    builder.addCase(
      getCustomFieldsWithPaginationRequest.pending,
      (state, action) => {
        state.getCustomFieldsWithPaginationRequest.status =
          RequestStatus.Pending;
        state.getCustomFieldsWithPaginationRequest.error = null;
        Object.keys(state.customFieldPagination.options).forEach((key) => {
          state.customFieldPagination.options[key] = action.meta.arg[key];
        });

        state.isLoading = true;
      },
    );
    builder.addCase(
      getCustomFieldsWithPaginationRequest.fulfilled,
      (state, action) => {
        state.getCustomFieldsWithPaginationRequest.status =
          RequestStatus.Succeeded;
        state.getCustomFieldsWithPaginationRequest.message =
          action.payload.message;
        state.customField = action.payload.payload.fields;
        state.customFieldPagination.options.page =
          action.payload.payload.meta.currentPage;
        state.totalFields = action.payload.payload.meta.itemCount;
        state.customFieldPagination.count =
          action.payload.payload.meta.totalItems;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getCustomFieldsWithPaginationRequest.rejected,
      (state, action) => {
        state.getCustomFieldsWithPaginationRequest.status =
          RequestStatus.Failed;
        state.getCustomFieldsWithPaginationRequest.error = action.payload;
        state.isLoading = false;
      },
    );

    // Get default fields with pagination
    builder.addCase(
      getDefaultFieldsWithPaginationRequest.pending,
      (state, action) => {
        state.getDefaultFieldsWithPaginationRequest.status =
          RequestStatus.Pending;
        state.getDefaultFieldsWithPaginationRequest.error = null;
        Object.keys(state.defaultFieldPagination.options).forEach((key) => {
          state.defaultFieldPagination.options[key] = action.meta.arg[key];
        });

        state.isLoading = true;
      },
    );
    builder.addCase(
      getDefaultFieldsWithPaginationRequest.fulfilled,
      (state, action) => {
        state.getDefaultFieldsWithPaginationRequest.status =
          RequestStatus.Succeeded;
        state.getDefaultFieldsWithPaginationRequest.message =
          action.payload.message;
        state.defaultField = action.payload.payload.fields;
        state.defaultFieldPagination.options.page =
          action.payload.payload.meta.currentPage;
        state.totalFields = action.payload.payload.meta.itemCount;
        state.defaultFieldPagination.count =
          action.payload.payload.meta.totalItems;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getDefaultFieldsWithPaginationRequest.rejected,
      (state, action) => {
        state.getDefaultFieldsWithPaginationRequest.status =
          RequestStatus.Failed;
        state.getDefaultFieldsWithPaginationRequest.error = action.payload;
        state.isLoading = false;
      },
    );

    // Create custom field for prospect
    builder.addCase(createCustomFieldRequest.pending, (state) => {
      state.createCustomFieldRequest.status = RequestStatus.Pending;
      state.createCustomFieldRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(createCustomFieldRequest.fulfilled, (state, action) => {
      state.createCustomFieldRequest.status = RequestStatus.Succeeded;
      state.createCustomFieldRequest.message = action.payload.message;
      state.customField.unshift(action.payload.payload);
      state.isLoading = false;
    });
    builder.addCase(createCustomFieldRequest.rejected, (state, action) => {
      state.createCustomFieldRequest.status = RequestStatus.Failed;
      state.createCustomFieldRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update custom field for prospect
    builder.addCase(updateCustomFieldRequest.pending, (state) => {
      state.updateCustomFieldRequest.status = RequestStatus.Pending;
      state.updateCustomFieldRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(updateCustomFieldRequest.fulfilled, (state, action) => {
      state.updateCustomFieldRequest.status = RequestStatus.Succeeded;
      state.updateCustomFieldRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(updateCustomFieldRequest.rejected, (state, action) => {
      state.updateCustomFieldRequest.status = RequestStatus.Failed;
      state.updateCustomFieldRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Delete custom field for prospect
    builder.addCase(deleteCustomFieldRequest.pending, (state) => {
      state.deleteCustomFieldRequest.status = RequestStatus.Pending;
      state.deleteCustomFieldRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(deleteCustomFieldRequest.fulfilled, (state, action) => {
      state.deleteCustomFieldRequest.status = RequestStatus.Succeeded;
      state.deleteCustomFieldRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteCustomFieldRequest.rejected, (state, action) => {
      state.deleteCustomFieldRequest.status = RequestStatus.Failed;
      state.deleteCustomFieldRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });
  },
});

export {
  getCustomFieldRequest,
  getCustomFieldsWithPaginationRequest,
  getDefaultFieldsWithPaginationRequest,
  deleteCustomFieldRequest,
  createCustomFieldRequest,
  updateCustomFieldRequest,
};

export const { resetCreateCustomFieldRequest } = customFieldSlice.actions;
export default customFieldSlice.reducer;
