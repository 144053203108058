import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import { ReportSummaryFilter } from './components/reports-content/components/reports-filters/types';
import { ReportsSequenceStatsFilter } from './components/reports-content/components/sequence-wise-report/types';
import {
  getSequenceList,
  reportAllSequenceSummarisedStats,
  reportAllSequenceStats,
  exportAllSequenceStats,
  getUserTimeZone,
  getSelectedSequences,
} from './helpers/reports-api';
import { SelectedSequenceRequest } from './type';

export const getSequenceListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSequenceList', async (args, thunkAPI) => {
  try {
    return await getSequenceList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const reportAllSequenceSummarisedStatsRequest = createAsyncThunk<
  ResponseSuccess,
  ReportSummaryFilter,
  { rejectValue: ResponseErrorWithHandled }
>('reports/reportAllSequenceSummarisedStats', async (args, thunkAPI) => {
  try {
    return await reportAllSequenceSummarisedStats(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const reportAllSequenceStatsRequest = createAsyncThunk<
  ResponseSuccess,
  ReportsSequenceStatsFilter,
  { rejectValue: ResponseErrorWithHandled }
>('reports/reportAllSequenceStats', async (args, thunkAPI) => {
  try {
    return await reportAllSequenceStats(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const exportAllSequenceStatsRequest = createAsyncThunk<
  ResponseSuccess,
  ReportSummaryFilter,
  { rejectValue: ResponseErrorWithHandled }
>('reports/exportAllSequenceStats', async (args, thunkAPI) => {
  try {
    return await exportAllSequenceStats(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getUserTimeZoneRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getUserTimeZone', async (args, thunkAPI) => {
  try {
    return await getUserTimeZone();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSelectedSequencesRequest = createAsyncThunk<
  ResponseSuccess,
  SelectedSequenceRequest,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSelectedSequences', async (args, thunkAPI) => {
  try {
    return await getSelectedSequences(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
