import React from 'react';
import Input from '../input';

type SearchProps = {
  value: string;
  searchPlaceholder?: string;
  onChange: (value: string) => void;
};

const Search: React.FC<SearchProps> = ({
  value,
  onChange,
  searchPlaceholder = 'Search here',
}) => (
  <div className="transfer-search">
    <Input
      placeholder={searchPlaceholder}
      icons={[
        {
          place: Input.IconPlace.Left,
          identifier: 'search',
        },
      ]}
      value={value}
      onChange={onChange}
      className="transfer-search-input"
    />
  </div>
);

export default React.memo(Search);
