export const onInputChangeHelper = (
  setValues,
  setIsPasswordDirty,
  value: string,
  e: React.ChangeEvent<HTMLInputElement>,
  data?: any,
) => {
  const fieldName = e.target?.name;

  if (data?.name) {
    setValues((preState) => ({
      ...preState,
      country: data?.name,
    }));
  }
  setValues((preState) => ({
    ...preState,
    [fieldName || 'contactNumber']: value,
  }));

  if (fieldName === 'password') {
    setIsPasswordDirty(true);
  }
};
