import classNames from 'classnames';
import { ProspectType } from '../../../../settings/enums/prospect-type';
import {
  SubjectEmailSuggestion,
  EmailSuggestionStatus,
  PersonalizationEmailSuggestion,
  LinkEmailSuggestion,
  SpammyEmailSuggestion,
} from './types';
import { Sequence } from '../../../types';
import SpammyWords from '../../../../../shared/utils/spammy-word-list';
// eslint-disable-next-line import/no-cycle
import { EmailContentSizeAllowed } from '../../../../../shared/utils';
import {
  AttachmentType,
  AttachmentUploadStatus,
} from '../../../../../shared/editor/types';

export const getTestEmailAddressFromLocalStorage = () =>
  localStorage.getItem('test_email_address');

export const saveTestEmailAddressInLocalStorage = (value: string) =>
  localStorage.setItem('test_email_address', value);

export const clearTestEmailAddressFromLocalStorage = () =>
  localStorage.removeItem('test_email_address');

export const getTestEmaiContent = () => [
  {
    id: 1,
    text: 'test_email_content',
  },
];

export const getEmailAccountDisconnectedContent = () => [
  {
    id: 1,
    text: 'email_account_disconnected_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getEmailAccountNotConnectedContent = () => [
  {
    id: 1,
    text: 'email_account_not_connected_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getEmailNotAddedModalContent = () => [
  {
    id: 1,
    text: 'email_not_added_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getConfirmationModalContent = () => [
  {
    id: 1,
    text: 'confirmation_modal_content',
  },
];

export const prepareMergeTagOptions = (orderedFields) =>
  orderedFields.map((item) => {
    let tag = '';
    let fallbackText = '';
    let label = `{{${item.label}}}`;

    if (item.fallbackText !== null) {
      fallbackText =
        item.fallbackText.length > 0 ? `|'${item.fallbackText}'` : '';
    }

    if (item.prospectType === ProspectType.Account) {
      label = `{{account.${item.label}}}`;
      tag = `{{account.${item.label}${fallbackText}}}`;
    } else {
      tag = `{{${item.label}${fallbackText}}}`;
    }

    return {
      key: `${item.prospectType}-${item.id}`,
      type: item.prospectType,
      tag,
      label,
    };
  });

export const isButtonDisabled = (errors, attachments: AttachmentType[]) =>
  errors.content !== '' ||
  attachments.some(
    (item) =>
      item.status === AttachmentUploadStatus.Uploading ||
      item.status === AttachmentUploadStatus.UploadFailed ||
      item.status === AttachmentUploadStatus.Deleting,
  );

export const isSubmitDisabled = (
  isRequestPending,
  isSaveDisabled,
  errors,
  attachments: AttachmentType[],
) =>
  isRequestPending || isSaveDisabled || isButtonDisabled(errors, attachments);

export const footerContent = (footerPart) => footerPart && footerPart();
export const getPrevStepSubject = (sequence: Sequence, currentStepNumber) => {
  let prevStepSubject = '';

  sequence.steps.forEach((step) => {
    if (step.number < currentStepNumber) {
      if (step.variants[0].subject) {
        prevStepSubject = step.variants[0].subject;
      } else if (step.variants[1]?.subject) {
        prevStepSubject = step.variants[1].subject;
      }
    }
  });

  return prevStepSubject;
};

export const checkSubjectEmailSuggestions = (
  subject: string,
): SubjectEmailSuggestion => {
  // Remove Emoji from subject string
  const subjectWithoutEmojis = subject.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  );

  const { length } = subjectWithoutEmojis;

  if (length >= 1 && length <= 10) {
    return {
      subjectPercentage: 25,
      subjectStatus: EmailSuggestionStatus.TOO_SHORT,
      subjectLength: length,
    };
  }
  if (length >= 11 && length <= 30) {
    return {
      subjectPercentage: 50,
      subjectStatus: EmailSuggestionStatus.SHORT,
      subjectLength: length,
    };
  }
  if (length >= 31 && length <= 60) {
    return {
      subjectPercentage: 100,
      subjectStatus: EmailSuggestionStatus.IDEAL,
      subjectLength: length,
    };
  }
  if (length >= 61 && length <= 70) {
    return {
      subjectPercentage: 50,
      subjectStatus: EmailSuggestionStatus.LENGTHY,
      subjectLength: length,
    };
  }
  if (length >= 71) {
    return {
      subjectPercentage: 25,
      subjectStatus: EmailSuggestionStatus.TOO_LENGTHY,
      subjectLength: length,
    };
  }
  return {
    subjectPercentage: 0,
    subjectStatus: '',
    subjectLength: length,
  };
};

export const checkPersonalizationEmailSuggestions = (
  content: string,
  mergeTagOptions,
): PersonalizationEmailSuggestion => {
  // This will return all matching merge tags which has opened with {{ and closed with }}
  const ptrn = /{{([^}}]+)}}/g;
  const mergeTags = content.match(ptrn);

  if (mergeTags === null || mergeTags.length === 0) {
    return {
      personalizationPercentage: 25,
      personalizationStatus: EmailSuggestionStatus.POOR,
      personalizationLength: 0,
    };
  }

  const tags = [];
  mergeTagOptions.forEach((field) => {
    tags.push(field.tag);
  });

  const filteredMergeTags = [];
  mergeTags.forEach((tag) => {
    if (tags.includes(tag)) {
      filteredMergeTags.push(tag);
    }
  });

  const { length } = filteredMergeTags;

  if (length === 1) {
    return {
      personalizationPercentage: 50,
      personalizationStatus: EmailSuggestionStatus.AVERAGE,
      personalizationLength: length,
    };
  }
  if (length === 2) {
    return {
      personalizationPercentage: 100,
      personalizationStatus: EmailSuggestionStatus.GOOD,
      personalizationLength: length,
    };
  }
  if (length >= 3) {
    return {
      personalizationPercentage: 100,
      personalizationStatus: EmailSuggestionStatus.EXCELLENT,
      personalizationLength: length,
    };
  }

  return {
    personalizationPercentage: 0,
    personalizationStatus: '',
    personalizationLength: 0,
  };
};

export const checkLinksEmailSuggestions = (
  content: string,
): LinkEmailSuggestion => {
  const container = document.createElement('p');
  container.innerHTML = content;

  const anchors = container.getElementsByTagName('a');
  const links = [];
  const keys = Object.keys(anchors);
  keys.forEach((key) => {
    links.push(anchors[key].href);
  });
  container.remove();

  const { length } = links;

  if (length === 0) {
    return {
      linkPercentage: 100,
      linkStatus: EmailSuggestionStatus.EXCELLENT,
      linkLength: 0,
    };
  }

  if (length === 1) {
    return {
      linkPercentage: 100,
      linkStatus: EmailSuggestionStatus.GOOD,
      linkLength: 1,
    };
  }
  if (length >= 2 && length <= 3) {
    return {
      linkPercentage: 50,
      linkStatus: EmailSuggestionStatus.AVERAGE,
      linkLength: length,
    };
  }
  if (length >= 4) {
    return {
      linkPercentage: 25,
      linkStatus: EmailSuggestionStatus.POOR,
      linkLength: length,
    };
  }

  return {
    linkPercentage: 0,
    linkStatus: '',
    linkLength: 0,
  };
};

export const checkSpammyEmailSuggestions = (
  content: string,
): SpammyEmailSuggestion => {
  const spammyWordsInContent = [];

  SpammyWords.forEach((word) => {
    const regx = new RegExp(`\\b${word}\\b`, 'gi');
    if (regx.test(content)) {
      spammyWordsInContent.push(word);
    }
  });

  spammyWordsInContent.sort();

  const filteredSpammies = [...spammyWordsInContent];

  spammyWordsInContent.forEach((word, indx) => {
    const regx = new RegExp(`\\b${word}\\b`, 'gi');

    const slicedSpammies = [...spammyWordsInContent].slice(
      indx + 1,
      spammyWordsInContent.length,
    );

    slicedSpammies.some((spamy) => {
      if (regx.test(spamy)) {
        filteredSpammies.splice(filteredSpammies.indexOf(word), 1);
        return true;
      }
      return false;
    });
  });

  const { length } = filteredSpammies;

  if (length === 0) {
    return {
      spammyPercentage: 100,
      spammyStatus: EmailSuggestionStatus.EXCELLENT,
      spammyLength: 0,
      spammyWords: [],
    };
  }

  if (length === 1) {
    return {
      spammyPercentage: 100,
      spammyStatus: EmailSuggestionStatus.GOOD,
      spammyLength: 1,
      spammyWords: filteredSpammies,
    };
  }

  if (length >= 2 && length <= 5) {
    return {
      spammyPercentage: 50,
      spammyStatus: EmailSuggestionStatus.AVERAGE,
      spammyLength: length,
      spammyWords: filteredSpammies,
    };
  }

  if (length >= 6) {
    return {
      spammyPercentage: 25,
      spammyStatus: EmailSuggestionStatus.POOR,
      spammyLength: length,
      spammyWords: filteredSpammies,
    };
  }

  return {
    spammyPercentage: 0,
    spammyStatus: '',
    spammyLength: 0,
    spammyWords: [],
  };
};

export const checkContentEmailSuggestions = ({
  content,
  doNotCheckLink = false,
  mergeTagOptions = [],
}: {
  content: string;
  doNotCheckLink?: boolean;
  mergeTagOptions: any;
}): {
  personalization: PersonalizationEmailSuggestion;
  link: LinkEmailSuggestion;
  spammy: SpammyEmailSuggestion;
} => {
  // Personalization Email Suggestion
  const {
    personalizationPercentage,
    personalizationStatus,
    personalizationLength,
  } = checkPersonalizationEmailSuggestions(content, mergeTagOptions);

  // Links Email Suggestion
  let linkObj = {
    linkPercentage: 0,
    linkStatus: '',
    linkLength: 0,
  };

  if (!doNotCheckLink) {
    const {
      linkPercentage,
      linkStatus,
      linkLength,
    } = checkLinksEmailSuggestions(content);
    linkObj = {
      linkPercentage,
      linkStatus,
      linkLength,
    };
  }

  const {
    spammyPercentage,
    spammyStatus,
    spammyLength,
    spammyWords,
  } = checkSpammyEmailSuggestions(content);

  return {
    personalization: {
      personalizationPercentage,
      personalizationStatus,
      personalizationLength,
    },
    link: linkObj,
    spammy: {
      spammyPercentage,
      spammyStatus,
      spammyLength,
      spammyWords,
    },
  };
};

export const getEditorClassNames = ({
  showTitle,
  showPreheaderInput,
  values,
  errors,
}): string =>
  classNames([
    'email-modal--editor',
    {
      'with-two-inputs': showTitle || showPreheaderInput,
      'with-one-inputs-one-error':
        !showTitle &&
        !showPreheaderInput &&
        (errors.title ||
          errors.preheader ||
          values.preheader.length > 0 ||
          errors.subject),
      'with-two-inputs-one-error':
        (errors.title ||
          errors.preheader ||
          values.preheader.length > 0 ||
          errors.subject) &&
        (showTitle || showPreheaderInput),
      'with-two-errors':
        (errors.title || errors.preheader || values.preheader.length > 0) &&
        errors.subject,
    },
  ]);

export const getStringSize = (content: string) =>
  Buffer.byteLength(content, 'utf-8');

export const checkTotalEmailContentSizeValidator = ({
  content,
  attachmentsSizeInBytes,
}): boolean => {
  const contentSizeInBytes = getStringSize(content);

  const totalEmailSize = contentSizeInBytes + attachmentsSizeInBytes;

  return totalEmailSize > EmailContentSizeAllowed;
};

export const getAttachmentsSize = (attachments) => {
  let attachmentsSizeInBytes = 0;

  if (attachments && attachments.length) {
    attachments.forEach((item) => {
      attachmentsSizeInBytes += parseInt(item.file.size, 10);
    });
  }

  return attachmentsSizeInBytes;
};

export const attachmentStatusChangeHandler = (
  attachment: AttachmentType,
  attachments: AttachmentType[],
) => {
  let oldAttachment = null;

  if (attachment.attachmentIdentifier) {
    oldAttachment = attachments.find(
      (item) => item.attachmentIdentifier === attachment.attachmentIdentifier,
    );
  } else if (attachment.attachmentId) {
    oldAttachment = attachments.find(
      (item) => item.attachmentId === attachment.attachmentId,
    );
  }

  if (oldAttachment) {
    if (attachment.attachmentIdentifier) {
      return attachments.map((item) =>
        item.attachmentIdentifier === attachment.attachmentIdentifier
          ? {
              ...oldAttachment,
              ...attachment,
            }
          : item,
      );
    }

    if (attachment.attachmentId) {
      return attachments.map((item) =>
        item.attachmentId === attachment.attachmentId
          ? {
              ...oldAttachment,
              ...attachment,
            }
          : item,
      );
    }
  }
  return attachments;
};
