import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ApiTokens from './api-tokens';
import { RootState } from '../../../../store/root-reducer';
import { resetCreateApiTokenRequest } from './api-tokens-slice';
import {
  createApiTokenRequest,
  getApiTokenListRequest,
  revokeApiTokenRequest,
} from './extra-actions';

const mapStateToProps = (state: RootState) => ({
  getApiTokenListRequestStatus: state.apiToken.getApiTokenListRequest.status,
  getApiTokenListRequestMessage: state.apiToken.getApiTokenListRequest.message,
  getApiTokenListRequestError: state.apiToken.getApiTokenListRequest.error,

  getCreateApiTokenRequestStatus: state.apiToken.createApiTokenRequest.status,
  getCreateApiTokenRequestMessage: state.apiToken.createApiTokenRequest.message,
  getCreateApiTokenRequestError: state.apiToken.createApiTokenRequest.error,

  getRevokeApiTokenRequestStatus: state.apiToken.revokeApiTokenRequest.status,
  getRevokeApiTokenRequestMessage: state.apiToken.revokeApiTokenRequest.message,
  getRevokeApiTokenRequestError: state.apiToken.revokeApiTokenRequest.error,

  generatedApiToken: state.apiToken.generatedApiToken,
  apiTokenList: state.apiToken.apiTokenList,
  isLoading: state.apiToken.isLoading,
  role: state.home.role,
  subscriptionPlan: state.home.subscription.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
});

const mapDispatchToProps = {
  sendGetApiTokenListRequest: () => getApiTokenListRequest(),
  sendCreateApiTokenRequest: (args) => createApiTokenRequest(args),
  sendRevokeApiTokenRequest: (args) => revokeApiTokenRequest(args),
  resetCreateApiTokenRequest: () => resetCreateApiTokenRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(ApiTokens);
