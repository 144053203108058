import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import type { IProps } from './billing-subscription-container';
import Suspense from '../../../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../../../shared/components/error-boundary-wrapper';

const SubscriptionInfo = React.lazy(
  () => import('./components/subscription-info'),
);
const ModifySubscription = React.lazy(
  () => import('./components/modify-subscription'),
);

const BillingSubscription: React.FC<IProps> = (props) => {
  useEffect(
    () => () => {
      const { resetSubscription } = props;
      resetSubscription();
    },
    [],
  );

  return (
    <div className="billing-subscription">
      <ErrorBoundaryWrapper>
        <Suspense>
          <Switch>
            <Route path="/settings/billing/subscriptions">
              <SubscriptionInfo containerProps={props} />
            </Route>
            <Route path="/settings/billing/upgrade-plan">
              <ModifySubscription />
            </Route>
            <Redirect to="/settings/billing/subscriptions" />
          </Switch>
        </Suspense>
      </ErrorBoundaryWrapper>
    </div>
  );
};

export default BillingSubscription;
