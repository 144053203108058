import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  getSequenceListRequest,
  reportAllSequenceSummarisedStatsRequest,
  reportAllSequenceStatsRequest,
  getUserTimeZoneRequest,
  getSelectedSequencesRequest,
} from './extra-actions';
import {
  Sequence,
  SelectedSequencesSummary,
  SequenceStatsReport,
  SequenceGraphPayload,
} from './type';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getSequenceListRequest: RequestState;
  reportAllSequenceSummarisedStatsRequest: RequestState;
  reportAllSequenceStatsRequest: RequestState;
  getUserTimeZoneRequest: RequestState;
  getSelectedSequencesRequest: RequestState;
  getSelectedSequencesResponse: any;
  sequences: Sequence[];
  sequenceGraph: SequenceGraphPayload;
  selectedSequencesSummary: SelectedSequencesSummary;
  sequenceStatsReports: SequenceStatsReport[];
  sequenceStatsReportsTotalSize: number;
  userTimeZone: string;
};

const initialState: State = {
  getSequenceListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reportAllSequenceSummarisedStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reportAllSequenceStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getUserTimeZoneRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSelectedSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sequences: [],
  sequenceGraph: null,
  selectedSequencesSummary: null,
  sequenceStatsReports: null,
  sequenceStatsReportsTotalSize: 0,
  userTimeZone: null,
  getSelectedSequencesResponse: null,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Sequence List
    builder.addCase(getSequenceListRequest.pending, (state) => {
      state.getSequenceListRequest.status = RequestStatus.Pending;
      state.getSequenceListRequest.error = null;
    });
    builder.addCase(getSequenceListRequest.fulfilled, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Succeeded;
      state.getSequenceListRequest.message = action.payload.message;
      state.sequences = action.payload.payload;
    });
    builder.addCase(getSequenceListRequest.rejected, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Failed;
      state.getSequenceListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Sequences Summary
    builder.addCase(
      reportAllSequenceSummarisedStatsRequest.pending,
      (state) => {
        state.reportAllSequenceSummarisedStatsRequest.status =
          RequestStatus.Pending;
        state.reportAllSequenceSummarisedStatsRequest.error = null;
      },
    );
    builder.addCase(
      reportAllSequenceSummarisedStatsRequest.fulfilled,
      (state, action) => {
        state.reportAllSequenceSummarisedStatsRequest.status =
          RequestStatus.Succeeded;
        state.reportAllSequenceSummarisedStatsRequest.message =
          action.payload.message;
        // eslint-disable-next-line prefer-destructuring
        state.selectedSequencesSummary = action.payload.payload[0];
      },
    );
    builder.addCase(
      reportAllSequenceSummarisedStatsRequest.rejected,
      (state, action) => {
        state.reportAllSequenceSummarisedStatsRequest.status =
          RequestStatus.Failed;
        state.reportAllSequenceSummarisedStatsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Sequences Stats
    builder.addCase(reportAllSequenceStatsRequest.pending, (state) => {
      state.reportAllSequenceStatsRequest.status = RequestStatus.Pending;
      state.reportAllSequenceStatsRequest.error = null;
    });
    builder.addCase(
      reportAllSequenceStatsRequest.fulfilled,
      (state, action) => {
        state.reportAllSequenceStatsRequest.status = RequestStatus.Succeeded;
        state.reportAllSequenceStatsRequest.message = action.payload.message;
        state.sequenceStatsReports = action.payload.payload;
      },
    );
    builder.addCase(reportAllSequenceStatsRequest.rejected, (state, action) => {
      state.reportAllSequenceStatsRequest.status = RequestStatus.Failed;
      state.reportAllSequenceStatsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get user Time zone
    builder.addCase(getUserTimeZoneRequest.pending, (state) => {
      state.getUserTimeZoneRequest.status = RequestStatus.Pending;
      state.getUserTimeZoneRequest.error = null;
    });
    builder.addCase(getUserTimeZoneRequest.fulfilled, (state, action) => {
      state.getUserTimeZoneRequest.status = RequestStatus.Succeeded;
      state.userTimeZone = action.payload.payload.timeZone;
    });
    builder.addCase(getUserTimeZoneRequest.rejected, (state) => {
      state.getUserTimeZoneRequest.status = RequestStatus.Failed;
    });
    // Get Selected Sequences
    builder.addCase(getSelectedSequencesRequest.pending, (state) => {
      state.getSelectedSequencesRequest.status = RequestStatus.Pending;
      state.getSelectedSequencesRequest.error = null;
    });
    builder.addCase(getSelectedSequencesRequest.fulfilled, (state, action) => {
      state.getSelectedSequencesRequest.status = RequestStatus.Succeeded;
      state.getSelectedSequencesResponse = action.payload.payload;
      state.userTimeZone = action.payload.payload.timeZone;
    });
    builder.addCase(getSelectedSequencesRequest.rejected, (state) => {
      state.getSelectedSequencesRequest.status = RequestStatus.Failed;
    });
  },
});

export default reportsSlice.reducer;
