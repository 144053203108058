import {
  EmailAccountMethod,
  EmailAccountType,
} from '../../components/settings/enums/email-account';
import { EmailAccount } from '../../components/settings/types/email-account';

const getEmailAccountMethod = (emailAccount: EmailAccount) => {
  if (emailAccount) {
    const { type } = emailAccount;

    if (type === EmailAccountType.Gmail) {
      return EmailAccountMethod.Gmail;
    }
    if (type === EmailAccountType.Microsoft) {
      return EmailAccountMethod.Microsoft;
    }
    if (type === EmailAccountType.SmtpImap) {
      return EmailAccountMethod.SmtpImap;
    }
  }
  return null;
};

export default getEmailAccountMethod;
