import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import type { IProps } from './user-onboarding-container';
import VerificationCodeForm from '../signup/components/verification-code-form';
import { redirectWithoutRefresh } from '../../../../shared/utils/redirect';

const UserOnboarding: React.FC<IProps> = () => {
  const location = useLocation();

  // States
  const [email, setEmail] = useState<string>('');

  // Functions
  const showSignUpQuestionsComponent = () => {
    redirectWithoutRefresh('/warmup?signup=completed');
  };

  // useEffect
  useEffect(() => {
    const { email: emailValue } = queryString.parse(location.search);

    setEmail(emailValue);
  }, []);

  return (
    <VerificationCodeForm
      showSignUpQuestionsComponent={showSignUpQuestionsComponent}
      email={email}
    />
  );
};

export default UserOnboarding;
