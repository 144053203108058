import React from 'react';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import TeamContent from './components/team-content';

class Team extends React.Component<IProps> {
  componentDidMount() {
    this.props.sendGetUsersListRequest(this.props.pagination.options);
  }

  render() {
    const {
      getUsersListRequestStatus,
      isEmpty,
      users,
      sendGetUsersListRequest,
    } = this.props;

    if (getUsersListRequestStatus !== RequestStatus.Succeeded && isEmpty) {
      return null;
    }

    return (
      <TeamContent
        users={users}
        sendGetUsersListRequest={sendGetUsersListRequest}
        isRequestPending={getUsersListRequestStatus === RequestStatus.Pending}
      />
    );
  }
}

export default Team;
