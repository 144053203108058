import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { difference, flatMap } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from '../../../../shared/enums/request-status';
import EmptyList from '../../../../shared/design-system/components/molecules/empty-list/empty-list';
import CustomDomainModal from './components/custom-domain-modal';
import CustomDomainHeader from './components/custom-domain-header';
import CustomDomainList from './components/custom-domain-list';
import type { IProps } from './custom-domain-container';
import ConfirmationModal from '../../../../shared/design-system/components/atoms/confirmation-modal';
import toaster from '../../../../shared/toaster';
import { Images } from '../../../../shared/app-constants';
import { UpdateCustomDomainPayload } from './types';
import { CustomDomainType } from '../../types/custom-domain';
import Button from '../../../../shared/design-system/components/atoms/button';
import HeaderBanner from '../header-banner';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import {
  executeSendGetEmailAccountsListRequest,
  executeVerifyAndSave,
  sendInitiateEmailhealthScoreForEmailAccunts,
  ConditionTypeEnum,
  deleteCustomDomainContent,
  deleteCustomDomainTitle,
  showWithCondition,
} from './helper';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';

export enum DomainModal {
  AddDomain = 'Add New Domain',
  EditDomain = 'Edit Domain',
}

export type MultiSelectData = {
  label: string;
  value: string;
};

const CustomDomain: React.FC<IProps> = ({
  sendGetEmailAccountsListRequest,
  sendGetCustomDomainsRequest,
  sendCreateCustomDomainRequest,

  getCustomDomainsRequestStatus,

  createCustomDomainRequestStatus,
  createCustomDomainRequestMessage,
  createCustomDomainRequestError,

  sendDeleteCustomDomainRequest,
  deleteCustomDomainRequestStatus,
  deleteCustomDomainRequestError,
  deleteCustomDomainRequestMessage,

  sendUpdateCustomDomainRequest,
  updateCustomDomainRequestStatus,
  updateCustomDomainRequestMessage,
  updateCustomDomainRequestError,

  customDomains,
  emailAccounts,

  subscriptionPlan,
  sendInitiateEmailAccountCheckRequest,
  agencyConfig,
  sendSetDefaultCustomDomainRequest,
  setDefaultCustomDomainRequestError,
  setDefaultCustomDomainRequestMessage,
  setDefaultCustomDomainRequestStatus,
  resetCustomDomainState,

  resetCustomTrackingDomainState,
  resetCreateCustomTrackingDomain,
  resetUpdateCustomTrackingDomain,
  resetDeleteCustomTrackingDomain,
}) => {
  // Translation
  const { t } = useTranslation();

  // states.
  const [showCustomDomainModal, setShowCustomDomainModal] = useState(false);
  const [showDeleteDomainModal, setShowDeleteDomainModal] = useState(false);
  const [modal, setModal] = useState<DomainModal>(DomainModal.AddDomain);
  const [modalEmailAccounts, setModalEmailAccounts] = useState<
    MultiSelectData[]
  >();
  const [modalDomain, setModalDomain] = useState<CustomDomainType>({
    id: null,
    domain: '',
    isDefault: false,
  });
  const [modalSelectedEmailAccounts, setModalSelectedEmailAccounts] = useState<
    MultiSelectData[]
  >();
  const [currentRowData, setCurrentRowData] = useState<any>();
  const [verificationStatus, setVerificationStatus] = useState<RequestStatus>(
    RequestStatus.Ideal,
  );
  const [emailAccountToBeInserted, setEmailAccountToBeInserted] = useState<
    number[]
  >([]);
  const [emailAccountToBeRemoved, setEmailAccountToBeRemoved] = useState<
    number[]
  >([]);

  const resetModalProps = () => {
    setModalSelectedEmailAccounts(null);
    setModalDomain({ id: null, domain: '', isDefault: false });
  };

  const showModal = () => {
    setShowCustomDomainModal(true);
  };

  const hideModal = () => {
    resetModalProps();
    sendGetCustomDomainsRequest();
    setShowCustomDomainModal(false);
  };

  const hideDeleteDomainModal = () => {
    setShowDeleteDomainModal(false);
  };

  // table action handlers.
  const onRowEditClickHandler = (row) => {
    setCurrentRowData(row);
    setModal(DomainModal.EditDomain);
    setModalDomain(row.customDomain);

    if (row.emailAccounts && row.emailAccounts.length > 0) {
      const temp = row.emailAccounts.map((emailAccount) => ({
        label: emailAccount.email,
        value: emailAccount.id.toString(),
      }));
      setModalSelectedEmailAccounts(temp);
    }
    hasResource(ResourceIdentifiers.CUSTOM_DOMAIN_UPDATE) && showModal();
  };

  // functions.
  const verifyAndSave = (newDomain: string, emailAccountIds: number[]) => {
    if (modal === DomainModal.AddDomain) {
      sendCreateCustomDomainRequest({
        domain: newDomain,
        emailAccountIds,
      });
      const emailAccountsToBeInserted = flatMap(emailAccounts, 'id');
      setEmailAccountToBeInserted(emailAccountsToBeInserted);
    } else {
      const emailAccountsToBeRemoved = difference(
        flatMap(currentRowData.emailAccounts, 'id'),
        emailAccountIds,
      );
      const emailAccountsToBeInserted = difference(
        emailAccountIds,
        flatMap(currentRowData.emailAccounts, 'id'),
      );
      setEmailAccountToBeInserted(emailAccountsToBeInserted);
      setEmailAccountToBeRemoved(emailAccountsToBeRemoved);
      executeVerifyAndSave({
        emailAccountsToBeRemoved,
        emailAccountsToBeInserted,
        modalDomain,
        newDomain,
        onSendUpdateCustomDomainRequest: () => {
          const payload: UpdateCustomDomainPayload = {
            emailAccountsToBeInserted,
            emailAccountsToBeRemoved,
            userCustomDomainId: modalDomain.id,
          };
          if (modalDomain.domain !== newDomain) {
            payload.newDomain = newDomain;
          }
          sendUpdateCustomDomainRequest(payload);
        },
        onHideModal: () => {
          hideModal();
        },
      });
    }
  };

  // modal handlers.
  const onAddDomainClickHandler = () => {
    if (hasResource(ResourceIdentifiers.CUSTOM_DOMAIN_CREATE)) {
      setModal(DomainModal.AddDomain);
      showModal();
    }
  };

  const onConfirmDeleteClickHandler = () => {
    sendDeleteCustomDomainRequest({
      userCustomDomainId: currentRowData.customDomain.id,
    });
  };

  const onRowDeleteClickHandler = (row) => {
    resetModalProps();
    setModal(DomainModal.AddDomain);
    setCurrentRowData(row);
    setShowDeleteDomainModal(true);
    const emailAccountsToBeRemoved = flatMap(row.emailAccounts, 'id');
    setEmailAccountToBeRemoved(emailAccountsToBeRemoved);
  };

  const onRowDefaultClickHandler = (row) => {
    setCurrentRowData(row);
    sendSetDefaultCustomDomainRequest(row.id);
  };

  // component lifecycle functions.
  useEffect(() => {
    sendGetCustomDomainsRequest();
    setVerificationStatus(createCustomDomainRequestStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      resetCustomDomainState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const emailAccountsData: MultiSelectData[] = emailAccounts.map(
      (emailAccount) => ({
        label: emailAccount.fromEmail,
        value: emailAccount.id.toString(),
      }),
    );
    setModalEmailAccounts(emailAccountsData);
  }, [emailAccounts]);

  useEffect(() => {
    executeSendGetEmailAccountsListRequest({
      setShowCustomDomainModal,
      onRequest: () => {
        sendGetEmailAccountsListRequest();
      },
    });
  }, [setShowCustomDomainModal]);

  // network request use effects
  useEffect(() => {
    executeOnRequestStatus({
      status: deleteCustomDomainRequestStatus,
      onSuccess: () => {
        hideDeleteDomainModal();
        sendGetCustomDomainsRequest();
        toaster.success(deleteCustomDomainRequestMessage);
        sendInitiateEmailhealthScoreForEmailAccunts({
          emailAccountToBeInserted: [],
          emailAccountToBeRemoved,
          sendInitiateEmailAccountCheckRequest,
        });
        resetDeleteCustomTrackingDomain();
      },
      onFailed: () => {
        toaster.error(deleteCustomDomainRequestError.message);
        resetDeleteCustomTrackingDomain();
      },
    });
  }, [deleteCustomDomainRequestStatus]);

  // Set default custom tracking domain request
  useEffect(() => {
    executeOnRequestStatus({
      status: setDefaultCustomDomainRequestStatus,
      onSuccess: () => {
        sendGetCustomDomainsRequest();
        toaster.success(setDefaultCustomDomainRequestMessage);
      },
      onFailed: () => {
        toaster.error(setDefaultCustomDomainRequestError.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDefaultCustomDomainRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateCustomDomainRequestStatus,
      onIdeal: () => {
        setVerificationStatus(updateCustomDomainRequestStatus);
      },
      onSuccess: () => {
        hideModal();
        toaster.success(updateCustomDomainRequestMessage);
        sendInitiateEmailhealthScoreForEmailAccunts({
          emailAccountToBeInserted,
          emailAccountToBeRemoved,
          sendInitiateEmailAccountCheckRequest,
        });
        resetUpdateCustomTrackingDomain();
      },
      onFailed: () => {
        toaster.error(updateCustomDomainRequestError.message);
        resetUpdateCustomTrackingDomain();
      },
    });
  }, [updateCustomDomainRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: createCustomDomainRequestStatus,
      onIdeal: () => {
        setVerificationStatus(createCustomDomainRequestStatus);
      },
      onSuccess: () => {
        hideModal();
        toaster.success(createCustomDomainRequestMessage);
        sendInitiateEmailhealthScoreForEmailAccunts({
          emailAccountToBeInserted,
          emailAccountToBeRemoved: [],
          sendInitiateEmailAccountCheckRequest,
        });
        resetCreateCustomTrackingDomain();
      },
      onFailed: () => {
        toaster.error(createCustomDomainRequestError.message);
        resetCreateCustomTrackingDomain();
      },
    });
  }, [createCustomDomainRequestStatus]);

  useEffect(
    () => () => {
      resetCustomTrackingDomainState();
    },
    [],
  );

  // render.
  return (
    <div className="custom-domain-container">
      <ConfirmationModal
        show={showDeleteDomainModal}
        onClose={() => hideDeleteDomainModal()}
        onSubmit={onConfirmDeleteClickHandler}
        isRequestPending={
          deleteCustomDomainRequestStatus === RequestStatus.Pending
        }
        contents={deleteCustomDomainContent(currentRowData, t)}
        title={t(deleteCustomDomainTitle())}
        showSuccessButton={true}
        showCancelButton={true}
        cancelButtonText="Cancel"
      />

      {showWithCondition(
        showCustomDomainModal,
        <CustomDomainModal
          show={showCustomDomainModal}
          hide={() => hideModal()}
          modalTitle={modal}
          emailAccounts={modalEmailAccounts}
          selectedValues={modalSelectedEmailAccounts}
          customDomain={modalDomain}
          verifyAndSave={(domain, emailAccountIds) =>
            verifyAndSave(domain, emailAccountIds)
          }
          verificationStatus={verificationStatus}
          agencyConfig={agencyConfig}
        />,
        null,
        ConditionTypeEnum.If,
      )}

      <Row>
        <Col>
          <CustomDomainHeader
            onClick={onAddDomainClickHandler}
            show={customDomains.length > 0}
            subscriptionPlan={subscriptionPlan}
          />
        </Col>
      </Row>

      {showWithCondition(
        subscriptionPlan === SubscriptionPlans.FREE,
        <HeaderBanner />,
        null,
        ConditionTypeEnum.If,
      )}

      {showWithCondition(
        customDomains.length > 0 ||
          getCustomDomainsRequestStatus === RequestStatus.Pending,
        <CustomDomainList
          isRequestPending={
            getCustomDomainsRequestStatus === RequestStatus.Pending
          }
          customDomains={customDomains}
          editClickHandler={onRowEditClickHandler}
          deleteClickHandler={onRowDeleteClickHandler}
          defaultClickHandler={onRowDefaultClickHandler}
        />,
        <div className="custom-domain-empty-list">
          <EmptyList
            description="Please add Custom domains"
            title="Custom Domains are empty"
            imgSrc={Images.EmptyData1}
          >
            <span>
              {/* TODO: refactor subscription plan check condition */}
              <Button
                variant={Button.Variant.Primary}
                onClick={onAddDomainClickHandler}
                disabled={subscriptionPlan === SubscriptionPlans.FREE}
                className="header-btn"
              >
                Add Custom Domain
              </Button>
            </span>
          </EmptyList>
        </div>,
        ConditionTypeEnum.IfElse,
      )}
    </div>
  );
};

export default CustomDomain;
