export enum Permissions {
  // Sequence
  ACCOUNT_SEQUENCE_READ = 'ACCOUNT.SEQUENCE.READ',
  ACCOUNT_SEQUENCE_WRITE = 'ACCOUNT.SEQUENCE.WRITE',
  ACCOUNT_SEQUENCE_UPDATE = 'ACCOUNT.SEQUENCE.UPDATE',
  ACCOUNT_SEQUENCE_DELETE = 'ACCOUNT.SEQUENCE.DELETE',
  SEQUENCE_READ = 'SEQUENCE.READ',
  SEQUENCE_PROSPECT_READ = 'SEQUENCE.PROSPECT.READ',
  SEQUENCE_EMAIL_READ = 'SEQUENCE.EMAIL.READ',
  SEQUENCE_SETTING_READ = 'SEQUENCE.SETTING.READ',
  SEQUENCE_WRITE = 'SEQUENCE.WRITE',
  SEQUENCE_DELETE = 'SEQUENCE.DELETE',
  SEQUENCE_UPDATE = 'SEQUENCE.UPDATE',

  // Sequence Step
  ACCOUNT_SEQUENCE_STEP_READ = 'ACCOUNT.SEQUENCE_STEP.READ',
  ACCOUNT_SEQUENCE_STEP_WRITE = 'ACCOUNT.SEQUENCE_STEP.WRITE',
  ACCOUNT_SEQUENCE_STEP_UPDATE = 'ACCOUNT.SEQUENCE_STEP.UPDATE',
  ACCOUNT_SEQUENCE_STEP_DELETE = 'ACCOUNT.SEQUENCE_STEP.DELETE',
  SEQUENCE_STEP_READ = 'SEQUENCE_STEP.READ',
  SEQUENCE_STEP_WRITE = 'SEQUENCE_STEP.WRITE',
  SEQUENCE_STEP_UPDATE = 'SEQUENCE_STEP.UPDATE',
  SEQUENCE_STEP_DELETE = 'SEQUENCE_STEP.DELETE',

  // Sequence Step Variant
  ACCOUNT_SEQUENCE_STEP_VARIANT_READ = 'ACCOUNT.SEQUENCE_STEP_VARIANT.READ',
  ACCOUNT_SEQUENCE_STEP_VARIANT_WRITE = 'ACCOUNT.SEQUENCE_STEP_VARIANT.WRITE',
  ACCOUNT_SEQUENCE_STEP_VARIANT_UPDATE = 'ACCOUNT.SEQUENCE_STEP_VARIANT.UPDATE',
  ACCOUNT_SEQUENCE_STEP_VARIANT_DELETE = 'ACCOUNT.SEQUENCE_STEP_VARIANT.DELETE',
  SEQUENCE_STEP_VARIANT_READ = 'SEQUENCE_STEP_VARIANT.READ',
  SEQUENCE_STEP_VARIANT_WRITE = 'SEQUENCE_STEP_VARIANT.WRITE',
  SEQUENCE_STEP_VARIANT_UPDATE = 'SEQUENCE_STEP_VARIANT.UPDATE',
  SEQUENCE_STEP_VARIANT_DELETE = 'SEQUENCE_STEP_VARIANT.DELETE',

  // Custom Domain
  ACCOUNT_CUSTOM_DOMAIN_READ = 'ACCOUNT.CUSTOM_DOMAIN.READ',
  ACCOUNT_CUSTOM_DOMAIN_WRITE = 'ACCOUNT.CUSTOM_DOMAIN.WRITE',
  ACCOUNT_CUSTOM_DOMAIN_UPDATE = 'ACCOUNT.CUSTOM_DOMAIN.UPDATE',
  ACCOUNT_CUSTOM_DOMAIN_DELETE = 'ACCOUNT.CUSTOM_DOMAIN.DELETE',
  CUSTOM_DOMAIN_READ = 'CUSTOM_DOMAIN.READ',
  CUSTOM_DOMAIN_WRITE = 'CUSTOM_DOMAIN.WRITE',
  CUSTOM_DOMAIN_UPDATE = 'CUSTOM_DOMAIN.UPDATE',
  CUSTOM_DOMAIN_DELETE = 'CUSTOM_DOMAIN.DELETE',
  ACCOUNT_CUSTOM_DOMAIN_EMAIL_ACCOUNT_READ = 'ACCOUNT.CUSTOM_DOMAIN_EMAIL_ACCOUNT.READ',
  ACCOUNT_CUSTOM_DOMAIN_EMAIL_ACCOUNT_UPDATE = 'ACCOUNT.CUSTOM_DOMAIN_EMAIL_ACCOUNT.READ',

  // Schedule
  ACCOUNT_SCHEDULE_READ = 'ACCOUNT.SCHEDULE.READ',
  ACCOUNT_SCHEDULE_WRITE = 'ACCOUNT.SCHEDULE.WRITE',
  ACCOUNT_SCHEDULE_DELETE = 'ACCOUNT.SCHEDULE.DELETE',
  ACCOUNT_SCHEDULE_UPDATE = 'ACCOUNT.SCHEDULE.UPDATE',
  SCHEDULE_READ = 'SCHEDULE.READ',
  SCHEDULE_WRITE = 'SCHEDULE.WRITE',
  SCHEDULE_DELETE = 'SCHEDULE.DELETE',
  SCHEDULE_UPDATE = 'SCHEDULE.UPDATE',

  // User
  ACCOUNT_USER_READ = 'ACCOUNT.USER.READ',
  ACCOUNT_USER_INVITE = 'ACCOUNT.USER.INVITE',
  ACCOUNT_USER_DELETE = 'ACCOUNT.USER.DELETE',
  ACCOUNT_USER_UPDATE = 'ACCOUNT.USER.UPDATE',
  USER_READ = 'USER.READ',

  // Prospect
  ACCOUNT_PROSPECT_READ = 'ACCOUNT.PROSPECT.READ',
  ACCOUNT_PROSPECT_WRITE = 'ACCOUNT.PROSPECT.WRITE',
  ACCOUNT_PROSPECT_UPDATE = 'ACCOUNT.PROSPECT.UPDATE',
  ACCOUNT_PROSPECT_DELETE = 'ACCOUNT.PROSPECT.DELETE',
  PROSPECT_READ = 'PROSPECT.READ',
  PROSPECT_WRITE = 'PROSPECT.WRITE',
  PROSPECT_UPDATE = 'PROSPECT.UPDATE',
  PROSPECT_DELETE = 'PROSPECT.DELETE',

  // Fields
  ACCOUNT_FIELD_READ = 'ACCOUNT.FIELD.READ',
  ACCOUNT_FIELD_WRITE = 'ACCOUNT.FIELD.WRITE',
  ACCOUNT_FIELD_UPDATE = 'ACCOUNT.FIELD.UPDATE',
  ACCOUNT_FIELD_DELETE = 'ACCOUNT.FIELD.DELETE',
  FIELD_READ = 'FIELD.READ',
  FIELD_WRITE = 'FIELD.WRITE',
  FIELD_UPDATE = 'FIELD.UPDATE',
  FIELD_DELETE = 'FIELD.DELETE',

  // TEMPLATE
  ACCOUNT_TEMPLATE_READ = 'ACCOUNT.TEMPLATE.READ',
  ACCOUNT_TEMPLATE_WRITE = 'ACCOUNT.TEMPLATE.WRITE',
  ACCOUNT_TEMPLATE_UPDATE = 'ACCOUNT.TEMPLATE.UPDATE',
  ACCOUNT_TEMPLATE_DELETE = 'ACCOUNT.TEMPLATE.DELETE',
  TEMPLATE_READ = 'TEMPLATE.READ',
  TEMPLATE_WRITE = 'TEMPLATE.WRITE',
  TEMPLATE_UPDATE = 'TEMPLATE.UPDATE',
  TEMPLATE_DELETE = 'TEMPLATE.DELETE',

  // Report
  REPORT_READ = 'REPORT.READ',

  // EMAIL
  ACCOUNT_EMAIL_READ = 'ACCOUNT.EMAIL.READ',
  ACCOUNT_EMAIL_WRITE = 'ACCOUNT.EMAIL.WRITE',
  ACCOUNT_EMAIL_UPDATE = 'ACCOUNT.EMAIL.UPDATE',
  ACCOUNT_EMAIL_DELETE = 'ACCOUNT.EMAIL.DELETE',
  EMAIL_READ = 'EMAIL.READ',
  EMAIL_WRITE = 'EMAIL.WRITE',
  EMAIL_UPDATE = 'EMAIL.UPDATE',
  EMAIL_DELETE = 'EMAIL.DELETE',
  MAILBOX_EMAIL_READ = 'MAILBOX.EMAIL.READ',
  EMAIL_WARMUP_READ = 'EMAIL.WARMUP.READ',
  EMAIL_WARMUP_SETTINGS_UPDATE = 'EMAIL.WARMUP_SETTINGS.UPDATE',

  // Subscription
  ACCOUNT_SUBSCRIPTION_CREATE = 'ACCOUNT.SUBSCRIPTION.CREATE',
  ACCOUNT_SUBSCRIPTION_UPDATE = 'ACCOUNT.SUBSCRIPTION.UPDATE',
  ACCOUNT_SUBSCRIPTION_READ = 'ACCOUNT.SUBSCRIPTION.READ',

  // Blacklist Domains
  ACCOUNT_BLACKLISTED_DOMAIN_WRITE = 'ACCOUNT.BLACKLISTED_DOMAIN.WRITE',
  ACCOUNT_BLACKLISTED_DOMAIN_READ = 'ACCOUNT.BLACKLISTED_DOMAIN.READ',
  ACCOUNT_BLACKLISTED_DOMAIN_DELETE = 'ACCOUNT.BLACKLISTED_DOMAIN.DELETE',

  // Api Token
  ACCOUNT_API_TOKEN_WRITE = 'ACCOUNT.API_TOKEN.WRITE',
  ACCOUNT_API_TOKEN_READ = 'ACCOUNT.API_TOKEN.READ',
  ACCOUNT_API_TOKEN_DELETE = 'ACCOUNT.API_TOKEN.DELETE',
  API_TOKEN_WRITE = 'API_TOKEN.WRITE',
  API_TOKEN_READ = 'API_TOKEN.READ',
  API_TOKEN_DELETE = 'API_TOKEN.DELETE',

  // Notifications
  NOTIFICATION_READ = 'NOTIFICATION.READ',
  NOTIFICATION_UPDATE = 'NOTIFICATION.UPDATE',

  // EV-Credits
  ACCOUNT_EV_CREDIT_WRITE = 'ACCOUNT.EV_CREDIT.WRITE',
  EV_CREDIT_READ = 'EV_CREDIT.READ',

  // My-Profile
  MY_PROFILE_READ = 'MY_PROFILE.READ',
  MY_PROFILE_UPDATE = 'MY_PROFILE.UPDATE',

  // Admin-Settings
  ADMIN_SETTING_READ = 'ADMIN_SETTING.READ',

  // Setting
  SETTING_READ = 'SETTING.READ',

  // Chrome-Extentation
  CHROME_EXTENSION_READ = 'CHROME.EXTENSION.READ',

  // Agency
  AGENCY_USER_READ = 'AGENCY.USER.READ',
  AGENCY_CLIENT_ASSIGN = 'AGENCY.CLIENT.ASSIGN',
  AGENCY_USER_UPDATE = 'AGENCY.USER.UPDATE',
  AGENCY_CLIENT_UPDATE = 'AGENCY.CLIENT.UPDATE',
  AGENCY_CLIENT_READ = 'AGENCY.CLIENT.READ',
  AGENCY_CLIENT_CREATE = 'AGENCY.CLIENT.CREATE',
  ACCOUNT_ADMIN_CREATE = 'ACCOUNT.ADMIN.CREATE',
  AGENCY_USER_INVITE = 'AGENCY.USER.INVITE',
  AGENCY_CLIENT_DELETE = 'AGENCY.CLIENT.DELETE',
  AGENCY_USER_DELETE = 'AGENCY.USER.DELETE',
  ACCOUNT_CLIENT_READ = 'ACCOUNT.CLIENT.READ',
  AGENCY_ADMIN_UPDATE = 'AGENCY.ADMIN.UPDATE',
}
