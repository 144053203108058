import { RootState } from '../../../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import ResetPasswordSuccess from './reset-password-success';

const mapStateToProps = (state: RootState) => ({
  message: state.resetPassword.message,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(ResetPasswordSuccess));
