/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Spinner } from 'reactstrap';
import { ExtendedOption, TransferProps } from './transfer';
import Checkbox from '../atoms/checkbox';
import TransferEmpty from './transfer-empty';

type OptionListProps<T> = Pick<
  TransferProps<T>,
  | 'multiSelect'
  | 'options'
  | 'optionRenderer'
  | 'filterOption'
  | 'optionFilterProp'
  | 'selectedOptionKey'
  | 'onSelect'
  | 'onDeselect'
  | 'onChange'
  | 'showOptionsSeparator'
  | 'showEmpty'
  | 'emptyText'
> & {
  searchText: string;
  isLoading: boolean;
};

const OptionList = <T extends ExtendedOption>({
  multiSelect,
  options: propOptions,
  optionRenderer,
  filterOption: propFilterOption,
  optionFilterProp,
  selectedOptionKey,
  onSelect,
  onDeselect,
  onChange,
  showOptionsSeparator,
  searchText,
  showEmpty,
  emptyText,
  isLoading,
}: OptionListProps<T>) => {
  const defaultFilterOption = (value, option, options, searchProp) =>
    !searchProp ||
    option[searchProp].toLowerCase().includes(value.toLowerCase().trim());

  const options = useMemo(() => {
    const filterOptions = propFilterOption || defaultFilterOption;
    const searchProp = optionFilterProp;
    return propOptions.filter((option) =>
      filterOptions(searchText, option, propOptions, searchProp),
    );
  }, [propFilterOption, optionFilterProp, propOptions, searchText]);

  // eslint-disable-next-line no-nested-ternary
  const selectedOptionKeyArr = selectedOptionKey
    ? Array.isArray(selectedOptionKey)
      ? selectedOptionKey
      : [selectedOptionKey]
    : [];

  const isOptionSelected = (key: T['key']) =>
    selectedOptionKeyArr.includes(key);

  const optionClickHandler = (option: T) => {
    const isSelected = isOptionSelected(option.key);
    let selectedOptionKeyToEmit: T['key'][];
    if (multiSelect) {
      if (isSelected) {
        onDeselect?.(option);
        selectedOptionKeyToEmit = selectedOptionKeyArr.filter(
          (key) => key !== option.key,
        );
      } else {
        onSelect?.(option);
        selectedOptionKeyToEmit = selectedOptionKeyArr.concat([option.key]);
      }
    } else {
      const prevSelectedOption = propOptions.find(
        (o) => o.key === selectedOptionKeyArr[0],
      );
      onSelect?.(option);
      onDeselect?.(prevSelectedOption);
      // below two lined can be simplified into one. i.e. just create a
      // new array containing the selected option's key.
      selectedOptionKeyToEmit = selectedOptionKeyArr.filter(
        (key) => key !== prevSelectedOption.key,
      );
      selectedOptionKeyToEmit = selectedOptionKeyToEmit.concat([option.key]);
    }
    const selectedOptionToEmit = selectedOptionKeyToEmit.map((key) =>
      propOptions.find((o) => o.key === key),
    );
    onChange?.(selectedOptionToEmit);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className="spinner--blue" animation="border" />
      </div>
    );
  }

  if (options.length === 0 && showEmpty) {
    return <TransferEmpty text={emptyText} />;
  }

  const optionList = options.map((option) => {
    const isSelected = isOptionSelected(option.key);
    const optionClass = classNames([
      {
        active: isSelected && !multiSelect,
        separator: showOptionsSeparator,
      },
    ]);
    let optionJSX = optionRenderer(option);
    if (multiSelect) {
      optionJSX = (
        <>
          <div className="checkbox-wrapper">
            <Checkbox checked={isSelected} />
          </div>
          <div className="option-content">{optionJSX}</div>
        </>
      );
    }
    return (
      <li
        key={option.key}
        className={optionClass}
        onClick={() => optionClickHandler(option)}
      >
        {optionJSX}
      </li>
    );
  });

  return <ul className="transfer-list">{optionList}</ul>;
};

export default OptionList;
