import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  createBlacklistedDomainRequest,
  getBlacklistedDomainsRequest,
  deleteBlacklistDomainRequest,
} from './extra-actions';
import { BlacklistDomain } from './types';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  createBlacklistedDomainRequest: RequestState;
  getBlacklistedDomainsRequest: RequestState;
  deleteBlacklistDomainRequest: RequestState;
  blacklistedDomainList: BlacklistDomain[];
  isLoading: boolean;
}

const initialState: State = {
  createBlacklistedDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getBlacklistedDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteBlacklistDomainRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  blacklistedDomainList: [],
  isLoading: false,
};

const customDomainSlice = createSlice({
  name: 'blacklistDomain',
  initialState,
  reducers: {
    resetBlacklistDomainsState: (state) => {
      state.createBlacklistedDomainRequest =
        initialState.createBlacklistedDomainRequest;
      state.getBlacklistedDomainsRequest =
        initialState.getBlacklistedDomainsRequest;
      state.deleteBlacklistDomainRequest =
        initialState.deleteBlacklistDomainRequest;
      state.blacklistedDomainList = initialState.blacklistedDomainList;
      state.isLoading = initialState.isLoading;
    },
  },
  extraReducers: (builder) => {
    // Create blacklisted domain
    builder.addCase(createBlacklistedDomainRequest.pending, (state) => {
      state.createBlacklistedDomainRequest.status = RequestStatus.Pending;
      state.createBlacklistedDomainRequest.error = null;
      state.isLoading = false;
    });
    builder.addCase(
      createBlacklistedDomainRequest.fulfilled,
      (state, action) => {
        state.createBlacklistedDomainRequest.status = RequestStatus.Succeeded;
        state.createBlacklistedDomainRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      createBlacklistedDomainRequest.rejected,
      (state, action) => {
        state.createBlacklistedDomainRequest.status = RequestStatus.Failed;
        state.createBlacklistedDomainRequest.error = action.payload;
        state.createBlacklistedDomainRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );

    // Get blacklisted domains list.
    builder.addCase(getBlacklistedDomainsRequest.pending, (state) => {
      state.getBlacklistedDomainsRequest.status = RequestStatus.Pending;
      state.getBlacklistedDomainsRequest.error = null;
      state.isLoading = false;
    });
    builder.addCase(getBlacklistedDomainsRequest.fulfilled, (state, action) => {
      state.getBlacklistedDomainsRequest.status = RequestStatus.Succeeded;
      state.getBlacklistedDomainsRequest.message = action.payload.message;
      state.blacklistedDomainList = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getBlacklistedDomainsRequest.rejected, (state, action) => {
      state.getBlacklistedDomainsRequest.status = RequestStatus.Failed;
      state.getBlacklistedDomainsRequest.error = action.payload;
      state.isLoading = false;
    });

    // Delete blacklisted domain
    builder.addCase(deleteBlacklistDomainRequest.pending, (state) => {
      state.deleteBlacklistDomainRequest.status = RequestStatus.Pending;
      state.deleteBlacklistDomainRequest.error = null;
      state.isLoading = false;
    });
    builder.addCase(deleteBlacklistDomainRequest.fulfilled, (state, action) => {
      state.deleteBlacklistDomainRequest.status = RequestStatus.Succeeded;
      state.deleteBlacklistDomainRequest.error = null;
      state.deleteBlacklistDomainRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteBlacklistDomainRequest.rejected, (state, action) => {
      state.deleteBlacklistDomainRequest.status = RequestStatus.Failed;
      state.deleteBlacklistDomainRequest.error = action.payload;
      state.deleteBlacklistDomainRequest.message = action.payload.message;
      state.isLoading = false;
    });
  },
});

export const { resetBlacklistDomainsState } = customDomainSlice.actions;
export default customDomainSlice.reducer;
