import { ResponseSuccess } from '../../../../../../../shared/types';
import api from '../../../../../../../api';
import { SendTestEmailPayload } from '../types/request-payload';

export const sendTestEmail = async ({
  to,
  content,
  sequenceId,
  subject,
  stepNumber,
  preheader,
  attachmentIds,
}: SendTestEmailPayload) => {
  let url = `/templates/send-test-mail`;

  const payload: any = {
    to,
    content,
    subject,
    preheader,
    attachmentIds,
  };

  if (sequenceId) {
    payload.stepNumber = stepNumber;
    url = `/sequences/${sequenceId}/test-email`;
  }

  return api.post<ResponseSuccess>(url, payload);
};
