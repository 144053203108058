import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../store/root-reducer';
import { getAgencyConfigRequest } from '../home/extra-actions';
import Auth from './auth';

const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home?.agencyConfig,
});

const mapDispatchToProps = {
  sendGetAgencyConfigRequest: (agencyConfigPayload: {
    baseUrl: string;
    email?: string;
  }) => getAgencyConfigRequest(agencyConfigPayload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any>;

export default withRouter(connector(Auth));
