import moment from 'moment-timezone';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { Status } from '../../../../../../shared/enums/status';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { showGeneralErrorNotification } from '../../../../../../shared/utils/errors';
import { planError } from '../../../../../../shared/utils/errors/plan-permission-error/plan-error';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import store from '../../../../../../store';
import DefaultRampUpSettings from '../../../../enums/default-rampup-settings';
import {
  EmailAccountMethod,
  EmailAccountType,
} from '../../../../enums/email-account';
import { EmailAccountSettingCode } from '../../../../enums/email-account-settings';
import {
  EmailAccountHealthScoreRequestStatus,
  EmailHealthScoreResult,
} from '../../../../types/email-account';
import { EmailAccountInfo, ScoreFactorStatus, RampUpSettings } from './types';

export const getEmailAccountId = (id: string): number => {
  const state = store.getState();
  const { emailAccounts } = state.emailAccount;

  return emailAccounts.find(({ hashId }) => hashId === id)?.id;
};

export const getEmailAccountMethod = (emailAccount) =>
  emailAccount?.type === EmailAccountType.Gmail
    ? EmailAccountMethod.Gmail
    : EmailAccountMethod.Microsoft;

export const getEmailAccountInfo = (emailAccount): EmailAccountInfo => {
  let emailAccountInfo = {
    name: '',
    email: '',
    accountType: '',
    isConnected: 0,
    method: EmailAccountMethod.Gmail,
  };

  if (emailAccount) {
    const { fromName, fromEmail, type, status } = emailAccount;

    emailAccountInfo = {
      name: fromName || '',
      email: fromEmail || '',
      accountType: type === EmailAccountType.Gmail ? 'google' : 'windows',
      isConnected: status,
      method:
        type === EmailAccountType.Gmail
          ? EmailAccountMethod.Gmail
          : EmailAccountMethod.Microsoft,
    };
  }

  return emailAccountInfo;
};

export const getSettingsForEmailAccount = (
  emailAccountSettings,
): RampUpSettings => {
  let rampUpSettings = {
    rampUpStatus: 0,
    rampUpPercent: DefaultRampUpSettings.RampUpPercent,
    initialSendingLimit: DefaultRampUpSettings.InitialSendingLimit,
    rampUpLimit: '0',
    maxSendingLimit: '0',
    rampUpLimitReached: false,
    minInterval: 0,
    maxInterval: 0,
  };

  const rampUpStatus = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.RampUpStatus,
  )[0]?.value;
  const rampUpPercent = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.RampUpPercent,
  )[0]?.value;
  const initialSendingLimit = emailAccountSettings?.filter(
    (settings) =>
      settings.code === EmailAccountSettingCode.RampUpInitialSendingLimit,
  )[0]?.value;
  const rampUpLimit = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.RampUpLimit,
  )[0]?.value;
  const maxSendingLimit = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.MaxSendingLimit,
  )[0]?.value;
  const minInterval = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.MinInterval,
  )[0]?.value;
  const maxInterval = emailAccountSettings?.filter(
    (settings) => settings.code === EmailAccountSettingCode.MaxInterval,
  )[0]?.value;

  rampUpSettings = {
    rampUpStatus: Number(rampUpStatus) || 0,
    rampUpPercent: Number(rampUpPercent) || DefaultRampUpSettings.RampUpPercent,
    initialSendingLimit:
      Number(initialSendingLimit) || DefaultRampUpSettings.InitialSendingLimit,
    rampUpLimit: rampUpLimit || '0',
    maxSendingLimit: maxSendingLimit || '0',
    rampUpLimitReached:
      Boolean(rampUpStatus) && rampUpLimit === maxSendingLimit,
    minInterval: Number(minInterval) || 0,
    maxInterval: Number(maxInterval) || 0,
  };

  return rampUpSettings;
};

export const showGeneralErrorMessage = (requestError) => {
  if (requestError) {
    showGeneralErrorNotification(requestError.message);
  }
};

export const getIsRequestPending = (status) => status === RequestStatus.Pending;

export const getErrorMessage = (error) => (error ? error.message : '');

export const getEmailHealthScorePercent = (
  emailHealthScoreResult: EmailHealthScoreResult,
) => {
  if (emailHealthScoreResult !== null) {
    if (
      emailHealthScoreResult.status ===
      EmailAccountHealthScoreRequestStatus.Success
    ) {
      const { emailAccountHealthScore } = emailHealthScoreResult;

      if (emailAccountHealthScore <= 4) {
        return {
          percentCompleted: emailAccountHealthScore * 10,
          scoreStatus: 'poor',
          score: emailAccountHealthScore,
          colorCode: '#DC2626',
        };
      }
      if (emailAccountHealthScore > 4 && emailAccountHealthScore <= 8) {
        return {
          percentCompleted: emailAccountHealthScore * 10,
          scoreStatus: 'average',
          score: emailAccountHealthScore,
          colorCode: '#FBBF24',
        };
      }
      if (emailAccountHealthScore > 8 && emailAccountHealthScore <= 10) {
        return {
          percentCompleted: emailAccountHealthScore * 10,
          scoreStatus: 'excellent',
          score: emailAccountHealthScore,
          colorCode: '#059669',
        };
      }
    }
  }
  return {
    percentCompleted: 0,
    scoreStatus: 'default',
    score: 0,
    colorCode: '#ddd',
  };
};

export const getLastCheckedDate = (
  emailHealthScoreResult: EmailHealthScoreResult,
  userTimezone: string,
): string => {
  if (emailHealthScoreResult) {
    const momentObj = moment(emailHealthScoreResult.timeStamp);
    const date = momentObj.format('MMM DD');
    const year = momentObj.format('YYYY');
    const time = momentObj.format('hh:mm a').toUpperCase();
    const timezone = `GMT${momentObj.tz(userTimezone).format('Z')}`;

    return `${date}, ${year}. at ${time} ${timezone}`;
  }
  return '';
};

export const getShouldShowCTAToScheduleCall = (
  emailHealthScoreResult: EmailHealthScoreResult,
): boolean =>
  emailHealthScoreResult
    ? emailHealthScoreResult.emailAccountHealthScore < 8
    : false;

export const getScoreFactorStatus = (value: boolean): ScoreFactorStatus =>
  value ? ScoreFactorStatus.Valid : ScoreFactorStatus.NotValid;

export const getEmailAgeValid = (emailAge: number): boolean =>
  emailAge === 1 || emailAge === 2;

export const getEmailAgeValidStatus = (emailAge: number): ScoreFactorStatus => {
  if (emailAge === 1) {
    return ScoreFactorStatus.PartiallyValid;
  }
  if (emailAge === 2) {
    return ScoreFactorStatus.Valid;
  }
  return ScoreFactorStatus.NotValid;
};

export const getYoutubeVideo = (actionId: number): string => {
  if (actionId === 1) {
    return process.env.REACT_APP_SPF_RECORD_YT_URL;
  }
  if (actionId === 2) {
    return process.env.REACT_APP_CUSTOM_TRACKING_DOMAIN_YT_URL;
  }
  if (actionId === 3) {
    return process.env.REACT_APP_DMARC_YT_URL;
  }
  if (actionId === 4) {
    return process.env.REACT_APP_EMAIL_AGE_YT_URL;
  }
  return '';
};

export const getBlogPostForScoreFactor = (actionId: number): string => {
  if (actionId === 1) {
    return process.env.REACT_APP_SPF_RECORD_BLOG_URL;
  }
  if (actionId === 2) {
    return process.env.REACT_APP_CUSTOM_TRACKING_DOMAIN_BLOG_URL;
  }
  if (actionId === 3) {
    return process.env.REACT_APP_DMARC_BLOG_URL;
  }
  if (actionId === 4) {
    return '';
  }
  return '';
};

export const autoFetchFunction = ({
  apiCounterStatus,
  onFetchEmailHealthScoreHandler,
  setShowReCheckButton,
  autoFetchInterval,
}) => {
  if (apiCounterStatus < 4) {
    onFetchEmailHealthScoreHandler();
  } else {
    setShowReCheckButton(true);
    clearInterval(autoFetchInterval);
  }
};

export const showPlanErrorForSubscription = () => {
  if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
    planError(2009);
  } else {
    planError(3009);
  }
};

export const getShouldSendingSettingsTabDisabled = (emailAccount) =>
  emailAccount?.type === EmailAccountType.SmtpImap &&
  emailAccount?.status === Status.Inactive;

export const handleLoading = (isLoading) => {
  if (isLoading) {
    store.dispatch(showLoading());
  } else {
    store.dispatch(hideLoading());
  }
};

export const checkSubscriptionPlan = ({
  subscriptionPlan,
  setDoNotHaveSubscription,
}) => {
  if (subscriptionPlan === SubscriptionPlans.FREE) {
    setDoNotHaveSubscription(true);
  } else {
    setDoNotHaveSubscription(false);
  }
};

export const onTabChange = (
  tabKey: string,
  UpdateEmailTabKeys,
  setShowSmtpModalOnDisconnect,
  history,
  hashId,
) => {
  if (tabKey === UpdateEmailTabKeys.EMAIL_HEALTH_SCORE) {
    setShowSmtpModalOnDisconnect(true);
  } else {
    setShowSmtpModalOnDisconnect(false);
  }

  history.push(`/settings/email-accounts/${hashId}/${tabKey}`);
};

export const connectEmailAccountHandler = (
  subscriptionPlan,
  emailAccount,
  emailAccountId,
  sendGetSmtpImapAccountDetailsRequest,
  sendConnectEmailAccountRequest,
) => {
  if (subscriptionPlan === SubscriptionPlans.FREE) {
    showPlanErrorForSubscription();
  } else if (emailAccount?.type === 3) {
    sendGetSmtpImapAccountDetailsRequest(emailAccountId);
  } else {
    sendConnectEmailAccountRequest(
      getEmailAccountMethod(emailAccount),
      emailAccountId,
    );
  }
};
