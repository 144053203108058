import {
  SubscriptionPlanIdMap,
  SubscriptionPlans,
} from '../../../shared/utils/subscription-plans';

const checkIsPlanDowngraded = (from, includeTrailPlan = false): boolean => {
  const plansToCheck = [
    includeTrailPlan && SubscriptionPlanIdMap[SubscriptionPlans.TRIAL],
    SubscriptionPlanIdMap[SubscriptionPlans.PAID_MONTHLY],
    SubscriptionPlanIdMap[SubscriptionPlans.PAID_YEARLY],
    SubscriptionPlanIdMap[SubscriptionPlans.EMAIL_STARTER_MONTHLY],
    SubscriptionPlanIdMap[SubscriptionPlans.EMAIL_STARTER_YEARLY],
    SubscriptionPlanIdMap[SubscriptionPlans.EMAIL_OUTREACH_MONTHLY],
    SubscriptionPlanIdMap[SubscriptionPlans.EMAIL_OUTREACH_YEARLY],
    SubscriptionPlanIdMap[SubscriptionPlans.EMAIL_OUTREACH_LIFETIME],
    SubscriptionPlanIdMap[SubscriptionPlans.OUTREACH_BASIC_MONTHLY],
    SubscriptionPlanIdMap[SubscriptionPlans.OUTREACH_BASIC_YEARLY],
    SubscriptionPlanIdMap[SubscriptionPlans.OUTREACH_PRO_MONTHLY],
    SubscriptionPlanIdMap[SubscriptionPlans.OUTREACH_PRO_YEARLY],
    SubscriptionPlanIdMap[SubscriptionPlans.OUTREACH_SCALE_MONTHLY],
    SubscriptionPlanIdMap[SubscriptionPlans.OUTREACH_SCALE_YEARLY],
  ];

  return plansToCheck.includes(from);
};

// eslint-disable-next-line import/prefer-default-export
export const isPlanDowngraded = (planTransitionDetails: {
  current: number;
  from: number;
}) =>
  planTransitionDetails !== null &&
  planTransitionDetails?.current === SubscriptionPlanIdMap.free &&
  checkIsPlanDowngraded(planTransitionDetails?.from, true);

export const isPlanDowngradedFromTrial = (planTransitionDetails: {
  current: number;
  from: number;
}) =>
  planTransitionDetails !== null &&
  planTransitionDetails?.current === SubscriptionPlanIdMap.free &&
  planTransitionDetails?.from === SubscriptionPlanIdMap.trial;

export const isPlanDowngradedFromPaid = (planTransitionDetails: {
  current: number;
  from: number;
}) =>
  planTransitionDetails !== null &&
  planTransitionDetails?.current === SubscriptionPlanIdMap.free &&
  checkIsPlanDowngraded(planTransitionDetails?.from);
