export const NumberOfAttachmentsAllowed = 4;

// Note - Referring below value in Bytes - 1mb = 1 * 1024 * 1000
export const EmailContentSizeAllowed = 20 * 1024 * 1000;

// Docs, Images, Videos, Sheets, Presentations & Zip

export const docExtensions = [
  '.doc',
  '.docx',
  '.odt',
  '.msg',
  '.rtf',
  '.tex',
  '.txt',
  '.wks',
  '.wps',
  '.wpd',
];

export const pdfExtensions = ['.pdf'];

export const imageFileExtensions = [
  '.ai',
  '.bmp',
  '.gif',
  '.ico',
  '.jpeg',
  '.jpg',
  '.max',
  '.obj',
  '.png',
  '.ps',
  '.psd',
  '.svg',
  '.tif',
  '.tiff',
  '.3ds',
  '.3dm',
];

export const videoFileExtensions = [
  '.avi',
  '.flv',
  '.h264',
  '.m4v',
  '.mkv',
  '.mov',
  '.mp4',
  '.mpg',
  '.mpeg',
  '.rm',
  '.swf',
  '.vob',
  '.wmv',
  '.3g2',
  '.3gp',
];

export const sheetFileExtensions = ['.ods', '.xlr', '.xls', '.xlsx', '.csv'];

export const presentationFileExtensions = [
  '.key',
  '.odp',
  '.pps',
  '.ppt',
  '.pptx',
];

export const zipFileExtensions = ['.zip'];

export const validAttachmentExtensions = [
  ...docExtensions,
  ...imageFileExtensions,
  ...videoFileExtensions,
  ...sheetFileExtensions,
  ...presentationFileExtensions,
  ...pdfExtensions,
  ...zipFileExtensions,
];
