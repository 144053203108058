import validator from 'validator';

export const validate = (name, value) => {
  switch (name) {
    case 'email':
      if (!validator.isEmail(value)) {
        return 'Enter a valid email address';
      }
      break;
  }
  return '';
};
