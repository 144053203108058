import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../home';
import FixedHome from '../../shared/components/fixed-home';
import Auth from '../auth';
import TempNavigation from '../temp/temp-navigation';
import ResetPasswordCallback from '../auth/components/reset-password-callback';
import TempAuthIndicator from '../temp/temp-auth-indicator';
import ProtectedRoute from '../../shared/components/protected-route';
import ConnectEmailAccountCallback from '../settings/components/connect-email-account-callback';
import ConfigRoute from '../../shared/components/config-route';
import Error404 from '../../shared/components/error-404';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import hasPermission from '../../shared/utils/access-control/has-permission';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import MicrosoftIdentityAssociation from '../../shared/components/microsoft-identity-association';

const App: React.FC = () => (
  <>
    <FixedHome />
    <ErrorBoundaryWrapper>
      <Switch>
        <ConfigRoute
          title="Login"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/login"
          component={Auth}
        />
        <ConfigRoute
          title="Sign up"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/signup"
          component={Auth}
        />
        <ConfigRoute
          title="User OnBoarding"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/welcome"
          component={Auth}
        />
        <ConfigRoute
          title="User OnBoarding"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/verify-account"
          component={Auth}
        />

        <ConfigRoute
          title="Reset Password"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/reset-password/:code"
          component={ResetPasswordCallback}
        />
        <ConfigRoute
          title="Accept Invitation"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/accept-invite/:token"
          component={Auth}
        />
        <ConfigRoute
          title="Reset Password"
          type={ConfigRoute.RouteType.Auth}
          exact
          path="/reset-password"
          component={Auth}
        />
        <ProtectedRoute
          exact
          path="/warmup/verify/:method"
          component={ConnectEmailAccountCallback}
        />
        <ProtectedRoute path="/warmup" component={Home} />
        <ProtectedRoute path="/settings" component={Home} />
        <ProtectedRoute path="/billing/subscription" component={Home} />
        <ProtectedRoute path="/cold-emails" component={Home} />
        <Redirect from="/warmup" to="/warmup" />
        <Redirect exact from="/" to="/warmup" />
        <ConfigRoute
          exact
          path="/.well-known/microsoft-identity-association.json"
          title="TrulyInbox"
          type={ConfigRoute.RouteType.Default}
          component={MicrosoftIdentityAssociation}
        />
        <ConfigRoute title="Not Found" component={Error404} />
        <ConfigRoute
          exact
          path="/not-found"
          title="Not Found"
          type={ConfigRoute.RouteType.Default}
          component={Error404}
        />

        {hasPermission(Permissions.SEQUENCE_READ) && (
          <>
            <ConfigRoute
              title="Sign up"
              type={ConfigRoute.RouteType.Auth}
              exact
              path="/LTD-Signup"
              component={Auth}
            />
            <Route exact path="/auth-indicator" component={TempAuthIndicator} />
            <ProtectedRoute exact path="/LTD-Checkout" component={Auth} />
            <ProtectedRoute exact path="/onboarding-form" component={Auth} />
            <ProtectedRoute exact path="/set-password" component={Auth} />
            <ProtectedRoute exact path="/connect-account" component={Auth} />
            <ProtectedRoute exact path="/email-insights" component={Home} />
            <ProtectedRoute exact path="/prospects" component={Home} />
            <ProtectedRoute exact path="/reports" component={Home} />
            <ProtectedRoute exact path="/growth-hub" component={Home} />
            <ProtectedRoute exact path="/templates" component={Home} />
            <ProtectedRoute path="/block" component={Home} />
            <ProtectedRoute exact path="/sequence" component={Home} />
            <ProtectedRoute
              exact
              path="/sequence/:sequenceId/:activeTab"
              component={Home}
            />
            <Route exact path="/navigation" component={TempNavigation} />
          </>
        )}
      </Switch>
    </ErrorBoundaryWrapper>
  </>
);

export default App;
