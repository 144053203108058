import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import PreSelect from './components/pre-select';
import PostSelect from './components/post-select';
import { ImportStage } from './enum';
import type { SelectFileProps } from './types';

const SelectFile: React.FC<SelectFileProps> = ({
  selectedFile,
  setSelectedFile,
}) => {
  const [importStage, setImportStage] = useState<ImportStage>(
    ImportStage.PreSelect,
  );
  const [dragging, setDragging] = useState(false);

  const [importedCSVStats, setImportedCSVStats] = useState({
    validRowsCount: 0,
    skippedRowsCount: 0,
    skippedRows: [],
  });

  const onRemoveHandler = () => {
    setSelectedFile(null);
    setImportStage(ImportStage.PreSelect);
    setImportedCSVStats({
      validRowsCount: 0,
      skippedRowsCount: 0,
      skippedRows: [],
    });
  };

  const onFileSelect = useCallback(
    (file) => {
      setSelectedFile(file);
      setImportStage(ImportStage.PostSelect);
    },
    [setSelectedFile],
  );

  const clsNames = classNames([
    'select-file',
    {
      dragging,
      [ImportStage.PreSelect]: importStage === ImportStage.PreSelect,
      [ImportStage.PostSelect]: importStage === ImportStage.PostSelect,
    },
  ]);

  return (
    <div className="select-file-wrapper">
      <div className={clsNames}>
        {importStage === ImportStage.PreSelect && (
          <div className="select-file-content">
            <PreSelect
              dragging={dragging}
              setDragging={setDragging}
              onFileSelect={onFileSelect}
              setImportedCSVStats={setImportedCSVStats}
            />
          </div>
        )}

        {importStage === ImportStage.PostSelect && (
          <div className="select-file-content">
            <PostSelect
              fileName={selectedFile.name}
              onRemove={onRemoveHandler}
              stats={importedCSVStats}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFile;
