import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { loginReducer } from '../components/auth/components/login';
import { signupReducer } from '../components/auth/components/signup';
import { resetPasswordReducer } from '../components/auth/components/reset-password';
import { resetPasswordCallbackReducer } from '../components/auth/components/reset-password-callback';
import {
  changePasswordReducer,
  setUserPasswordReducer,
} from '../components/auth/components/change-password';
import { sequenceReducer } from '../components/sequence';
import { emailAccountReducer } from '../components/settings/components/email-account';
import { scheduleReducer } from '../components/settings/components/schedule';
import { safetySettingsReducer } from '../components/settings/components/safety-settings';
import { customFieldReducer } from '../components/settings/components/custom-fields';
import { myProfileReducer } from '../components/settings/components/my-profile';
import { teamReducer } from '../components/settings/components/team';
import { invitationSignupReducer } from '../components/auth/components/invitation-signup';
import { reset } from './actions';
import { prospectReducer } from '../components/prospect';
import { customDomainReducer } from '../components/settings/components/custom-domain';
import { adminSettingsReducer } from '../components/settings/components/admin-settings';
import { homeReducer } from '../components/home';
import { reportsReducer } from '../components/reports';
import { templateReducer } from '../components/templates';
import { blacklistDomainReducer } from '../components/settings/components/domain-blacklisting';
import { emailModalReducer } from '../components/sequence/shared/modals/email-modal/test-email';
import { pageHeaderBannerAlertReducer } from '../shared/design-system/components/atoms/page-header';
import { billingReducer } from '../components/settings/components/billing-subscription';
import { updatePreferredAppReducer } from '../shared/components/connect-email-account-options';
import { apiTokensReducer } from '../components/settings/components/api-tokens';
import { emailDailySendingLimitReducer } from '../components/settings/components/email-daily-sending-limit';
import { mailboxEmailsReducer } from '../components/mailbox-emails';
import { emailWarmupReducer } from '../components/email-warmup';
import { accountDetailsReducer } from '../components/auth/components/account-details';
import { growthHubReducer } from '../components/growth-hub';

const combinedReducer = combineReducers({
  loadingBar: loadingBarReducer,
  login: loginReducer,
  signup: signupReducer,
  accountDetails: accountDetailsReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordCallback: resetPasswordCallbackReducer,
  changePassword: changePasswordReducer,
  setUserPassword: setUserPasswordReducer,
  sequence: sequenceReducer,
  emailAccount: emailAccountReducer,
  schedule: scheduleReducer,
  prospect: prospectReducer,
  safetySettings: safetySettingsReducer,
  customField: customFieldReducer,
  customDomain: customDomainReducer,
  myProfile: myProfileReducer,
  adminSettings: adminSettingsReducer,
  team: teamReducer,
  invitationSignup: invitationSignupReducer,
  emailModal: emailModalReducer,
  home: homeReducer,
  reports: reportsReducer,
  templates: templateReducer,
  pageHeader: pageHeaderBannerAlertReducer,
  blacklistDomains: blacklistDomainReducer,
  billingAndSubscription: billingReducer,
  updatePreferredApp: updatePreferredAppReducer,
  apiToken: apiTokensReducer,
  emailDailySendingLimit: emailDailySendingLimitReducer,
  mailboxEmails: mailboxEmailsReducer,
  emailWarmup: emailWarmupReducer,
  growthHub: growthHubReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === reset.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
