/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Link } from 'react-router-dom';
import produce from 'immer';
import Input from '../../../../../../shared/design-system/components/input';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import FormSection from '../../../form-section';
import ButtonContainer from '../../../button-container';
import { IProps, IState } from './types';
import PasswordValidationChecklist from '../../../../../../shared/components/password-validation-checklist';
import { validate } from './validator';
import Alert from '../../../../../../shared/design-system/components/alert';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import AlertContainer from '../../../alert-container';
import TopWelcome from '../../../top-welcome';
import { redirectWithoutRefresh } from '../../../../../../shared/utils/redirect';

class ChangePasswordForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    const { location } = this.props;

    this.state = {
      values: {
        password: '',
        confirmPassword: '',
      },
      errors: {
        password: '',
        confirmPassword: '',
      },
      dirty: {
        password: false,
        confirmPassword: false,
      },
      showPassword: {
        password: false,
        confirmPassword: false,
      },
      currentRoute: location.pathname,
      setPasswordRoute: '/set-password',
    };

    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { status, showSuccessComponent, setPasswordStatus } = this.props;
    if (status !== prevProps.status) {
      if (status === RequestStatus.Succeeded) {
        showSuccessComponent();
      }
    }
    if (setPasswordStatus !== prevProps.setPasswordStatus) {
      if (setPasswordStatus === RequestStatus.Succeeded) {
        redirectWithoutRefresh('/connect-account');
      }
    }
  }

  componentWillUnmount() {
    const { hideError } = this.props;

    hideError();
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name], draft.values);
          if (name !== 'confirmPassword' && draft.dirty.confirmPassword) {
            draft.errors.confirmPassword = validate(
              'confirmPassword',
              draft.values.confirmPassword,
              draft.values,
            );
          }
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { userSetPasswordRequest, sendChangePasswordRequest } = this.props;
    const {
      dirty: dirtyValue,
      errors: errorsValue,
      values,
      setPasswordRoute,
    } = this.state;

    const dirty = { ...dirtyValue };
    const dirtyKeys = Object.keys(dirty);

    for (const key of dirtyKeys) {
      dirty[key] = true;
    }

    const errors = { ...errorsValue };
    const keys = Object.keys(errors);
    let isError = false;

    for (const key of keys) {
      const error = validate(key, values[key], values);
      errors[key] = error;
      isError = isError || !!error;
    }

    this.setState({ errors, dirty });

    if (isError) {
      return;
    }

    const {
      values: { password },
      currentRoute,
    } = this.state;

    if (currentRoute === setPasswordRoute) {
      userSetPasswordRequest(password);
    } else {
      sendChangePasswordRequest(password);
    }
  }

  togglePasswordVisibility(name) {
    this.setState(
      produce((draft) => {
        draft.showPassword[name] = !draft.showPassword[name];
      }),
    );
  }

  render() {
    const {
      values,
      errors,
      showPassword,
      dirty,
      currentRoute,
      setPasswordRoute,
    } = this.state;
    const { status, error, showError, hideError } = this.props;

    const isLoading = status === RequestStatus.Pending;

    return (
      <>
        <TopWelcome>
          <TopWelcome.Header>Set Password</TopWelcome.Header>
          <TopWelcome.Body>
            A strong password helps prevent unauthorized
            <br />
            access to your account.
          </TopWelcome.Body>
        </TopWelcome>
        {showError && (
          <AlertContainer>
            <Alert
              variant={Alert.Variant.Danger}
              dismissible={true}
              onClose={hideError}
              iconIdentifier="close-o"
              header="Whoops"
            >
              {error.message}
            </Alert>
          </AlertContainer>
        )}
        <FormSection>
          <form onSubmit={this.onFormSubmit}>
            <Input
              name="password"
              label="New Password"
              placeholder="Enter your new password"
              type={showPassword.password ? 'text' : 'password'}
              value={values.password}
              variant={errors.password && Input.Variant.Error}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              autoComplete="current-password"
              autoFocus
              icons={[
                {
                  place: Input.IconPlace.Right,
                  identifier: showPassword.password ? 'eye-alt' : 'eye',
                  className: 'pointer',
                  onClick: () => this.togglePasswordVisibility('password'),
                },
              ]}
            />
            <Input
              name="confirmPassword"
              label="Confirm new password"
              placeholder="Confirm your new password"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              variant={errors.confirmPassword && Input.Variant.Error}
              caption={errors.confirmPassword}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              autoComplete="current-confirm-password"
              icons={[
                {
                  place: Input.IconPlace.Right,
                  identifier: showPassword.confirmPassword ? 'eye-alt' : 'eye',
                  className: 'pointer',
                  onClick: () =>
                    this.togglePasswordVisibility('confirmPassword'),
                },
              ]}
            />
            <PasswordValidationChecklist
              password={values.password}
              isDirty={dirty.password}
            />
            <ButtonContainer>
              <Button
                variant={Button.Variant.Primary}
                type={Button.Type.Submit}
                isLoading={isLoading}
                disabled={
                  !(!!values.confirmPassword && !!values.password) || isLoading
                }
              >
                {currentRoute !== setPasswordRoute ? 'Submit' : 'Next'}
              </Button>
            </ButtonContainer>
            {currentRoute !== setPasswordRoute && (
              <div className="login-link regular-2">
                <Link to="/login" className="pointer">
                  Return to Login
                </Link>
              </div>
            )}
          </form>
        </FormSection>
      </>
    );
  }
}

export default ChangePasswordForm;
