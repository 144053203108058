import {
  IndustryRadioButtonOptions,
  JobRoleOptions,
  SaleshandyUsageRadioButtonOptions,
  TeamSizeRadioButtonOptions,
  UseCaseOptions,
} from './enum';
import {
  PrepareIndustryDropDown,
  PrepareJobRoleDropDown,
  PrepareUseCaseDropDown,
  PrepareTeamSizeDropDown,
  saleshanyUsage,
} from './types';
import i18n from '../../../../../../i18n';

export const prepareIndustryDropDown: PrepareIndustryDropDown[] = [
  {
    key: IndustryRadioButtonOptions.Saas,
    value: IndustryRadioButtonOptions.Saas,
  },
  {
    key: IndustryRadioButtonOptions.ItServices,
    value: IndustryRadioButtonOptions.ItServices,
  },
  {
    key: IndustryRadioButtonOptions.ComputerSoftware,
    value: IndustryRadioButtonOptions.ComputerSoftware,
  },
  {
    key: IndustryRadioButtonOptions.SalesMarketingAgency,
    value: IndustryRadioButtonOptions.SalesMarketingAgency,
  },
  {
    key: IndustryRadioButtonOptions.Internet,
    value: IndustryRadioButtonOptions.Internet,
  },
  {
    key: IndustryRadioButtonOptions.StaffingRecruitment,
    value: IndustryRadioButtonOptions.StaffingRecruitment,
  },
  {
    key: IndustryRadioButtonOptions.MarketingAndAdvertising,
    value: IndustryRadioButtonOptions.MarketingAndAdvertising,
  },
  {
    key: IndustryRadioButtonOptions.ELearningEdTech,
    value: IndustryRadioButtonOptions.ELearningEdTech,
  },
  {
    key: IndustryRadioButtonOptions.ManagementConsulting,
    value: IndustryRadioButtonOptions.ManagementConsulting,
  },
  {
    key: IndustryRadioButtonOptions.RealEstate,
    value: IndustryRadioButtonOptions.RealEstate,
  },
  {
    key: IndustryRadioButtonOptions.Finance,
    value: IndustryRadioButtonOptions.Finance,
  },
  { key: i18n.t('labels.other'), value: IndustryRadioButtonOptions.Other },
];

export const prepareJobRoleDropDown: PrepareJobRoleDropDown[] = [
  {
    key: JobRoleOptions.BusinessDevelopmentRepresentative,
    value: JobRoleOptions.BusinessDevelopmentRepresentative,
  },
  {
    key: JobRoleOptions.BusinessDevelopmentManager,
    value: JobRoleOptions.BusinessDevelopmentManager,
  },
  {
    key: JobRoleOptions.SalesDevelopmentRepresentative,
    value: JobRoleOptions.SalesDevelopmentRepresentative,
  },
  {
    key: JobRoleOptions.SalesDevelopmentManager,
    value: JobRoleOptions.SalesDevelopmentManager,
  },
  {
    key: JobRoleOptions.AccountExecutive,
    value: JobRoleOptions.AccountExecutive,
  },
  {
    key: JobRoleOptions.SalesAccountManager,
    value: JobRoleOptions.SalesAccountManager,
  },
  {
    key: JobRoleOptions.MarketingExecutive,
    value: JobRoleOptions.MarketingExecutive,
  },
  {
    key: JobRoleOptions.GrowthManager,
    value: JobRoleOptions.GrowthManager,
  },
  {
    key: JobRoleOptions.MarketingManager,
    value: JobRoleOptions.MarketingManager,
  },
  {
    key: JobRoleOptions.Recruiter,
    value: JobRoleOptions.Recruiter,
  },
  {
    key: JobRoleOptions.Consultant,
    value: JobRoleOptions.Consultant,
  },
  {
    key: JobRoleOptions.Founder,
    value: JobRoleOptions.Founder,
  },
  {
    key: JobRoleOptions.Other,
    value: JobRoleOptions.Other,
  },
];

export const prepareUseCaseDropDown: PrepareUseCaseDropDown[] = [
  {
    key: UseCaseOptions.LeadGeneration,
    value: UseCaseOptions.LeadGeneration,
  },
  {
    key: UseCaseOptions.LeadQualification,
    value: UseCaseOptions.LeadQualification,
  },
  {
    key: UseCaseOptions.BookMoreMeetings_BuildSalesPipeline,
    value: UseCaseOptions.BookMoreMeetings_BuildSalesPipeline,
  },
  {
    key: UseCaseOptions.OutreachToCandidates_Recruitment,
    value: UseCaseOptions.OutreachToCandidates_Recruitment,
  },
  {
    key: UseCaseOptions.PromoteProduct_Services,
    value: UseCaseOptions.PromoteProduct_Services,
  },
  {
    key: UseCaseOptions.OneTimeColdEmailOutreach,
    value: UseCaseOptions.OneTimeColdEmailOutreach,
  },
  {
    key: UseCaseOptions.LinkBuilding_PR,
    value: UseCaseOptions.LinkBuilding_PR,
  },
  {
    key: UseCaseOptions.Other,
    value: UseCaseOptions.Other,
  },
];

export const prepareTeamSizeDropDown: PrepareTeamSizeDropDown[] = [
  {
    key: i18n.t('labels.team_1_10'),
    value: TeamSizeRadioButtonOptions.TEAM_1_10,
  },
  {
    key: i18n.t('labels.team_11_50'),
    value: TeamSizeRadioButtonOptions.TEAM_11_50,
  },
  {
    key: i18n.t('labels.team_51_200'),
    value: TeamSizeRadioButtonOptions.TEAM_51_200,
  },
  {
    key: i18n.t('labels.team_201_500'),
    value: TeamSizeRadioButtonOptions.TEAM_201_500,
  },
  {
    key: i18n.t('labels.team_501_1000'),
    value: TeamSizeRadioButtonOptions.TEAM_501_1000,
  },
  {
    key: i18n.t('labels.team_1000_plus'),
    value: TeamSizeRadioButtonOptions.TEAM_1000,
  },
];

export const saleshandyUsageRadioButtonOptions: saleshanyUsage[] = [
  {
    label: i18n.t('labels.sales_team'),
    value: SaleshandyUsageRadioButtonOptions.SalesTeam,
  },
  {
    label: i18n.t('labels.marketing_team'),
    value: SaleshandyUsageRadioButtonOptions.MarketingTeam,
  },
  {
    label: i18n.t('labels.agency_consultant'),
    value: SaleshandyUsageRadioButtonOptions.Agency_Consultant,
  },
  {
    label: i18n.t('labels.recruiters'),
    value: SaleshandyUsageRadioButtonOptions.Recruiters,
  },
  {
    label: i18n.t('labels.other'),
    value: SaleshandyUsageRadioButtonOptions.Other,
  },
];
