import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  PlanPurchaseAction,
  PlanType,
} from '../../../../../../../shared/utils/subscription-plans';

export enum UpgradePlanQueryParamsKey {
  Action = 'action',
  Plan = 'plan',
  Period = 'period',
}

export const isValueBasedPlan = (planType: PlanType) =>
  planType === PlanType.ValueBased;

export const getPlanIndex = (plans, planId) =>
  plans
    .filter(({ planCode }) => isValueBasedPlan(planCode))
    .findIndex(({ id }) => id === planId);

export const getPlanPeriod = (planCycle: BillingOption) =>
  planCycle === BillingOption.MONTHLY ? '1' : '12';

export const getSubscribeAction = (isUserModifyingSubscription: boolean) =>
  isUserModifyingSubscription
    ? PlanPurchaseAction.Upgrade
    : PlanPurchaseAction.Subscribe;
