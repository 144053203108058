import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { loginRequest } from './extra-actions';

type State = {
  status: RequestStatus;
  message: string;
  error: any;
  token: string;
  showError: boolean;
  trackingId: string;
  firstName: string;
  lastName: string;
  email: string;
};

const initialState: State = {
  status: RequestStatus.Ideal,
  message: '',
  error: '',
  token: '',
  showError: false,
  trackingId: null,
  firstName: null,
  lastName: null,
  email: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    hideError: (state) => {
      state.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state) => {
      state.status = RequestStatus.Pending;
      state.showError = false;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.status = RequestStatus.Succeeded;
      state.message = action.payload.message;
      state.token = action.payload.payload.accessToken;
      state.trackingId = action.payload.payload.trackingId;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.status = RequestStatus.Failed;
      state.error = action.payload;
      state.showError = !action.payload.isHandled;
    });
  },
});

export const { hideError } = loginSlice.actions;
export { loginRequest };
export default loginSlice.reducer;
