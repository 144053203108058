import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocationDescriptor, Location } from 'history';
import { getToken, removeToken, setToken } from './token';
import { resetState } from './reset-state';
import { redirectWithoutRefresh } from './redirect';
import history from '../history';
import loader from '../loader';
// eslint-disable-next-line import/no-cycle
import api from '../../api';
import toaster from '../toaster';
import { AnalyticsEvents } from '../enums/analytics';
import { clearTestEmailAddressFromLocalStorage } from '../../components/sequence/shared/modals/email-modal/helpers';
import { removeProspectsFiltersFromLocalStore } from '../../components/prospect/helpers/prospect-filters-resolver';

export type LoginOptions = {
  redirect?: boolean;
  token: string;
};

export type LogoutOptions = {
  redirect?: boolean;
  fallbackTo?: LocationDescriptor;
  query?: string;
};

type FallbackUrlState = {
  from: LocationDescriptor;
};

const deleteTokenAndRedirect = ({
  redirect = true,
  fallbackTo,
  query,
}: LogoutOptions = {}) => {
  removeToken();
  resetState();
  // cleanup
  loader.hide();

  if (redirect) {
    redirectWithoutRefresh<FallbackUrlState>({
      pathname: '/login',
      state: { from: fallbackTo },
      search: query,
    });
  }
};

const deleteTestEmailFromLocalStorage = () =>
  clearTestEmailAddressFromLocalStorage();

export class AuthHelper {
  static get isAuthenticated(): boolean {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      setToken(token);
      return true;
    }
    const isTokenPresent = !!getToken();
    if (isTokenPresent) {
      return isTokenPresent;
    }

    return false;
  }

  static login({ redirect = true, token }: LoginOptions) {
    setToken(token);

    if (redirect) {
      const location = (history.location as Location<FallbackUrlState>).state
        ?.from || { pathname: '/' };
      redirectWithoutRefresh(location);
    }
  }

  static async logout({
    redirect = true,
    fallbackTo,
    query = '',
  }: LogoutOptions = {}) {
    if (getToken()) {
      api
        .get('/auth/logout', {
          headers: { authorization: `Bearer ${getToken()}` },
        })
        .then(() => {
          toaster.success('Successfully logged out!');
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Session expired');
        });
    }

    // Analytics Track this event
    window.analytics.track(AnalyticsEvents.SignedOut);

    // Sentry, set user to null on logout
    Sentry.setUser(null);

    deleteTokenAndRedirect({ redirect, fallbackTo, query });

    // Delete test_email_address
    deleteTestEmailFromLocalStorage();

    // Removing filters from local store
    removeProspectsFiltersFromLocalStore();
  }
}
