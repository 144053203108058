import { CancelTokenSource } from 'axios';
import { IAllProps as TinyMCEEditorProps } from '@tinymce/tinymce-react';
import { ClassNameProp, ResponseErrorWithHandled } from '../types';
import { RequestStatus } from '../enums/request-status';
import { MergeTagOption } from '../components/overlay-merge-tags';
import {
  AttachmentDeletePayload,
  AttachmentUploadPayload,
  AttachmentUploadResponse,
} from '../../components/sequence/types';

export type EditorProps = Partial<Pick<TinyMCEEditorProps, 'disabled'>> &
  Pick<TinyMCEEditorProps, 'value' | 'onEditorChange'> &
  ClassNameProp & {
    error?: boolean;
    onEditorBlur?: () => void;
    mergeTags?: MergeTagOption[];
    imageUploader?: ({ formData, onUploadProgress }) => any;
    onEditorInit?: () => void;
    getEditorInstance?: (editor: any) => void;
    shouldRenderTemplate?: boolean;
    showTemplateModal?: () => void;
    onKeyUp?: (e: any) => void;
    addMergeTagInContent?: (option: any) => void;
    showAddAttachments: boolean;
    attachments?: AttachmentType[];
    onAttachmentStatusChange?: (attachment: AttachmentType) => void;
    onAttachmentsChange?: (
      attachments: AttachmentType[],
      isAttachmentDeleted?: boolean,
    ) => void;
    sendAttachmentUploadRequest?: ({
      formData,
      onUploadProgress,
      cancelToken,
    }: AttachmentUploadPayload) => void;
    uploadedAttachment?: AttachmentUploadResponse;
    uploadAttachmentRequestStatus?: RequestStatus;
    uploadAttachmentRequestError?: ResponseErrorWithHandled;
    uploadAttachmentRequestMessage?: string;
    resetUploadAttachmentRequest?: VoidFunction;
    errorMessage?: string;
    sendDeleteAttachmentRequest?: (payload: AttachmentDeletePayload) => void;
    deleteAttachmentRequestStatus?: RequestStatus;
    deleteAttachmentRequestError?: ResponseErrorWithHandled;
    deletedAttachmentId?: number;
    resetDeleteAttachmentRequest?: VoidFunction;
    onAttachmentsMetaChange?: (
      attachmentsMeta: AttachmentMetaDataType[],
    ) => void;
    isTemplateEditorModal?: boolean;
    sequenceStepVariantId?: number;
  };

export enum AttachmentUploadStatus {
  Upload = 'upload',
  Uploading = 'uploading',
  UploadFailed = 'upload_failed',
  UploadSuccess = 'upload_success',
  Existing = 'existing',
  Deleting = 'deleting',
}

export type AttachmentType = {
  attachmentId: number;
  attachmentIdentifier?: string;
  status: AttachmentUploadStatus;
  file: any;
  attachmentSize?: number;
};

export type AttachmentMetaDataType = {
  attachmentIdentifier: string;
  percentage: number;
  source: CancelTokenSource;
};
