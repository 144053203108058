import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../shared/design-system/components/atoms/icon';
import { IProps } from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const DeleteCustomFieldModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      className="delete-modal"
      titleContent={
        <div className="d-flex align-items-center">
          <Icon identifier="info" />
          <div className="pl-2">Do you want to delete this custom field?</div>
        </div>
      }
      showCloseIcon={true}
      onClose={onClose}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={t('labels.delete')}
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isRequestPending}
      onSubmit={onSubmit}
      cancelButtonVarient={Button.Variant.Outlined}
      onHide={onClose}
    >
      <span className="regular-2 popover-arrow-color-txt ml-4">
        If you delete the custom field, it can hinder the personalization of
        emails.
      </span>
    </Modal>
  );
};

export default DeleteCustomFieldModal;
