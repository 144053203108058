import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import AcceptInvitationSignup from './invitation-signup';
import { verifyInvitationTokenRequest } from './invitation-signup-slice';

export type AcceptInvitationCallbackRouteProps = RouteComponentProps<{
  token: string;
}>;
const mapStateToProps = (state: RootState) => ({
  acceptInvitationRequestStatus:
    state.invitationSignup.acceptInvitationRequest.status,
  verifyInvitationTokenRequestStatus:
    state.invitationSignup.verifyInvitationTokenRequest.status,
  email: state.invitationSignup.email,
  accessToken: state.invitationSignup.accessToken,
  trackingId: state.invitationSignup.trackingId,
  firstName: state.invitationSignup.firstName,
  lastName: state.invitationSignup.lastName,
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = (
  dispatch,
  ownProps: AcceptInvitationCallbackRouteProps,
) => ({
  sendVerifyInvitationTokenRequest: () =>
    dispatch(verifyInvitationTokenRequest(ownProps.match.params.token)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  AcceptInvitationCallbackRouteProps;

export default connector(AcceptInvitationSignup);
