/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '../initials-avatar';
import Icon from '../icon';
import ImageIcon from '../../../../components/images/image-icon';
import { Images } from '../../../../app-constants';
import { useOutClickHook } from '../../../../utils/hooks';
import { AuthHelper, truncate } from '../../../../utils';
import history from '../../../../history';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import { AgencyConfig } from '../../../../../components/home/types';
import { supportUrls } from '../../../../utils/urls';
import { AnalyticsEvents } from '../../../../enums/analytics';
import { AvatarEmail } from './helper/AvatarEmail';
import { ProfileSettings } from './helper/ProfileSettings';
import { openChatSupport } from '../../../../utils/open-chat-support';
import hasPermission from '../../../../utils/access-control/has-permission';
import { Permissions } from '../../../../utils/access-control/enums/permissions';

type UserProfile = {
  firstName: string;
  lastName: string;
  email: string;
};

type IProps = {
  userProfile: UserProfile;
  agencyConfig: AgencyConfig;
  trackingId: string;
};

enum DropdownKeys {
  ProfileSettingsActive = 'profileSettingsActive',
  LogOutActive = 'logOutActive',
  HelpActive = 'helpActive',
  BecomeOurAffiliate = 'becomeOurAffiliate',
}

const ProfileDropdown: React.FC<IProps> = ({
  userProfile,
  agencyConfig,
  trackingId,
}) => {
  const onLogoutHandler = () => {
    AuthHelper.logout();
    if (agencyConfig?.closeOnLogout) {
      window.close();
    }
  };
  const navigateToSettings = () => history.push('/settings/profile');
  const navigateToFeatureRequest = () => {
    window.analytics.track({
      userId: trackingId,
      event: AnalyticsEvents.FeatureRequestClicked,
      properties: {
        current_tab_url: document.location.href,
      },
    });
    window.open(process.env.REACT_APP_SH_HELP, '_blank');
  };
  const navigateToBecomeOurAffiliate = () =>
    window.open(supportUrls.becomeOurAffiliate, '_blank');
  const openChat = () => {
    openChatSupport();
  };
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownState, setDropDownState] = useState({
    profileSettingsActive: false,
    logOutActive: false,
    helpActive: false,
    becomeOurAffiliate: false,
  });
  const closeDropdown = () => setShowDropdown(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const changeActivationState = (key) =>
    setDropDownState({ ...dropdownState, [key]: !dropdownState[key] });
  const dropdownRef = useRef(null);
  useOutClickHook(dropdownRef, closeDropdown);
  const propsProfileSettings = {
    changeActivationState,
    DropdownKeys,
    accessibleOnClick,
    navigateToSettings,
    dropdownState,
    Images,
    t,
  };
  return (
    <>
      <div
        ref={dropdownRef}
        className="d-flex justify-content-end align-items-center pointer"
        {...accessibleOnClick(toggleDropdown)}
      >
        <Avatar
          className="header-avatar"
          firstName={userProfile.firstName}
          lastName={userProfile.lastName}
        />
        <span className="ml-2 regular-3 popover-arrow-color-txt">
          {truncate(`${userProfile.firstName} ${userProfile.lastName}`, 15)}
        </span>
        <Icon
          identifier="chevron-down"
          className="ml-2 profile-dropdown-icon"
        />
        {showDropdown && (
          <div className="profile-dropdown-view">
            <ul className="list-unstyled profile-dropdown-hovered-list">
              {/* Avatar & email */}
              <AvatarEmail userProfile={userProfile} />
              {/* Profile settings */}
              <ProfileSettings propsProfileSettings={propsProfileSettings} />

              {/* feature request */}
              {agencyConfig?.showFeatureRequest &&
                hasPermission(Permissions.SEQUENCE_READ) &&
                false && (
                  <li className="my-1 menu-item">
                    <div
                      className="d-flex align-items-center px-3 py-2 profile-cursor-pointer"
                      {...accessibleOnClick(navigateToFeatureRequest)}
                    >
                      <div className="text-left menu-icon feature-icon-color">
                        <Icon identifier="extension-add" />
                      </div>
                      <div className="text-left ml-2">
                        <p className="profile-list-font menu-text">
                          {t('labels.feature_request')}
                        </p>
                      </div>
                    </div>
                  </li>
                )}
              {/* help */}

              <li
                className="my-1 menu-item"
                onMouseOver={() =>
                  changeActivationState(DropdownKeys.HelpActive)
                }
                onMouseOut={() =>
                  changeActivationState(DropdownKeys.HelpActive)
                }
              >
                <div
                  className="d-flex align-items-center px-3 py-2 profile-cursor-pointer"
                  {...accessibleOnClick(() =>
                    window.open('https://support.trulyinbox.com/en/', '_blank'),
                  )}
                >
                  <div className="text-left menu-icon">
                    <ImageIcon
                      src={
                        dropdownState.helpActive
                          ? Images.HelpActive
                          : Images.Help
                      }
                    />
                  </div>
                  <div className="text-left ml-2">
                    <p className="profile-list-font menu-text">
                      {t('labels.help')}
                    </p>
                  </div>
                </div>
              </li>

              {/* Become Our Affiliate */}
              {/* Add Permission to hide the menu item for view-only agency client users */}
              {hasPermission(Permissions.SEQUENCE_READ) && false && (
                <>
                  <li
                    className="my-1 menu-item"
                    onMouseOver={() =>
                      changeActivationState(DropdownKeys.BecomeOurAffiliate)
                    }
                    onMouseOut={() =>
                      changeActivationState(DropdownKeys.BecomeOurAffiliate)
                    }
                  >
                    <div
                      className="d-flex align-items-center px-3 py-2 profile-cursor-pointer"
                      {...accessibleOnClick(navigateToBecomeOurAffiliate)}
                    >
                      <div className="text-left menu-icon feature-icon-color">
                        <ImageIcon
                          src={
                            dropdownState.becomeOurAffiliate
                              ? Images.FriendsBlue
                              : Images.Friends
                          }
                        />
                      </div>
                      <div className="text-left ml-2">
                        <p className="profile-list-font menu-text">
                          {t('labels.become_our_affiliate')}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* help */}
                  {agencyConfig?.showHelp && (
                    <li
                      className="my-1 menu-item"
                      onMouseOver={() =>
                        changeActivationState(DropdownKeys.HelpActive)
                      }
                      onMouseOut={() =>
                        changeActivationState(DropdownKeys.HelpActive)
                      }
                    >
                      <div
                        className="d-flex align-items-center px-3 py-2 profile-cursor-pointer"
                        {...accessibleOnClick(openChat)}
                      >
                        <div className="text-left menu-icon">
                          <ImageIcon
                            src={
                              dropdownState.helpActive
                                ? Images.HelpActive
                                : Images.Help
                            }
                          />
                        </div>
                        <div className="text-left ml-2">
                          <p className="profile-list-font menu-text">
                            {t('labels.help')}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                </>
              )}
              {/* logout */}
              <li
                className="mt-1 menu-item"
                onMouseOver={() =>
                  changeActivationState(DropdownKeys.LogOutActive)
                }
                onMouseOut={() =>
                  changeActivationState(DropdownKeys.LogOutActive)
                }
              >
                <div
                  className="d-flex align-items-center px-3 profile-border-top align-items-center py-2 profile-cursor-pointer"
                  {...accessibleOnClick(onLogoutHandler)}
                >
                  <div className="text-left menu-icon">
                    <ImageIcon
                      src={
                        dropdownState.logOutActive
                          ? Images.LogoutActive
                          : Images.Logout
                      }
                    />
                  </div>
                  <div className="text-left ml-2">
                    <p className="profile-list-font menu-text">
                      {t('labels.logout')}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileDropdown;
