import map from 'lodash/map';
import { SMTPIMAP } from '../../../../../enums/smtp-or-imap';
import { supportUrls } from '../../../../../../../shared/utils/urls';

// eslint-disable-next-line import/prefer-default-export
export const testSmtpImapAccountConnectionFn = ({
  isSmtpEmailValid,
  isImapEmailValid,
  requestFrom,
  username,
  emailAddress,
  password,
  host,
  port,
  encryption,
  onSendTestSMTPConnectionRequest,
  onSendTestIMAPConnectionRequest,
}) => {
  const requestPayload = {
    type: requestFrom,
    payload: {
      ...(username && { userName: username }),
      emailAddress,
      password,
      host,
      port,
      ...(encryption && { encryption }),
    },
  };

  if (isSmtpEmailValid && requestFrom === SMTPIMAP.SMTP) {
    onSendTestSMTPConnectionRequest(requestPayload);
  } else if (isImapEmailValid && requestFrom === SMTPIMAP.IMAP) {
    onSendTestIMAPConnectionRequest(requestPayload);
  }
};

export const handleSwitchFn = ({
  change,
  username,
  onChangeToTrue,
  onChangeToFalse,
  onSetUsername,
}) => {
  if (change && username) {
    onSetUsername();
  } else if (change && !username) {
    onChangeToTrue();
  } else {
    onChangeToFalse();
  }
};

export const onConnectAndSaveFn = ({
  isSmtpEmailValid,
  isImapEmailValid,
  fromName,
  smtp,
  imap,
  emailAccountId,
  emailServiceProvider,
  onSendUpdateSmtpImapAccountDetailsRequest,
  onSendConnectSmtpImapAccountRequest,
}) => {
  const payload = {
    emailServiceProvider,
    fromName,
    payload: {
      smtp: {
        ...(smtp.userName && { userName: smtp.userName }),
        emailAddress: smtp.emailAddress,
        password: smtp.password,
        host: smtp.host,
        port: smtp.port,
        ...(smtp.encryption && { encryption: smtp.encryption }),
      },
      imap: {
        emailAddress: imap.emailAddress,
        password: imap.password,
        host: imap.host,
        port: imap.port,
        ...(imap.encryption && { encryption: imap.encryption }),
      },
    },
  };

  if (isSmtpEmailValid && isImapEmailValid && emailAccountId) {
    onSendUpdateSmtpImapAccountDetailsRequest(emailAccountId, payload);
  } else if (isSmtpEmailValid && isImapEmailValid) {
    onSendConnectSmtpImapAccountRequest(payload);
  }
};

export const setFormFieldValues = ({
  sendGetSmtpImapAccountDetailsResponse = null,
  setValues,
  setSMTPEncryptionValue,
  setIMAPEncryptionValue,
  setSmtpUsernameValue,
  resetValue,
}) => {
  if (sendGetSmtpImapAccountDetailsResponse) {
    const { imap, smtp } = sendGetSmtpImapAccountDetailsResponse;
    setValues?.();
    if (smtp.encryption) {
      setSMTPEncryptionValue?.();
    }
    if (smtp.userName) {
      setSmtpUsernameValue?.();
    }
    if (imap.encryption) {
      setIMAPEncryptionValue?.();
    }
  } else {
    resetValue?.();
  }
};

export const validateSenderName = (senderName, setIsSenderNameValid) => {
  if (senderName.length > 50) {
    setIsSenderNameValid(true);
  } else {
    setIsSenderNameValid(false);
  }
};

const smtpErrorTitleAndTroubleshoot = [
  {
    error: [],
    title: 'Other',
    troubleshoot: [
      'Make sure SMTP is enabled for your email account.',
      'Make sure You are using correct info to connect your email account.',
      'Contact your system admin or email service provider to make sure that TrulyInbox is whitelisted.',
    ],
    faqLink: supportUrls.smtpImapOtherError,
  },
  {
    error: ['EAUTH', 'Invalid Login'],
    title: 'Could not authenticate',
    troubleshoot: [
      'Use correct email-password/app password.',
      'Confirm your SMTP server address, TLS Port number, or SSL Port number with your email service provider',
    ],
    faqLink: supportUrls.smtpImapCouldNotAuth,
  },
  {
    error: ['ENOTFOUND'],
    title: 'Email not found',
    troubleshoot: [
      'Use correct email-password/app password.',
      'Use correct domain name(host name)',
      'Use correct port number.',
      'Check whether system firewalls and DNS if it might be restricting TrulyInbox activity.',
    ],
    faqLink: supportUrls.smtpImapEmailNotFound,
  },
  {
    error: ['ETIMEDOUT', 'Greeting'],
    title: 'Connection time out',
    troubleshoot: [
      'Make sure you are using correct info to connect your email account.',
      'It is possible that your SMTP sever is currently down, try again in sometimes.',
    ],
    faqLink: supportUrls.smtpImapConnectionTimeOut,
  },
];

const imapErrorTitleAndTroubleshoot = [
  {
    error: [''],
    title: 'Other',
    troubleshoot: [
      'Make sure IMAP is enabled for your email account.',
      'Make sure You are using correct info to connect your email account.',
      'Contact your system admin or email service provider to make sure that TrulyInbox is whitelisted.',
    ],
    faqLink: supportUrls.smtpImapOtherError,
  },
  {
    error: ['AUTHENTICATIONFAILED', 'Invalid credentials', 'LOGIN failed'],
    title: 'Could not authenticate',
    troubleshoot: [
      'Use correct email-password/app password.',
      'Confirm your SMTP server address, TLS Port number, or SSL Port number with your email service provider',
    ],
    faqLink: supportUrls.smtpImapCouldNotAuth,
  },
  {
    error: ['ECONNREFUSED', 'ENOTFOUND'],
    title: 'Email not found',
    troubleshoot: [
      'Use correct email-password/app password.',
      'Use correct domain name(host name)',
      'Use correct port number.',
      'It is possible that your IMAP sever is currently down, try again in sometimes.',
    ],
    faqLink: supportUrls.smtpImapEmailNotFound,
  },
  {
    error: ['SERVERBUG', 'UNAVAILABLE', 'TIMEDOUT', 'Greeting'],
    title: 'Connection time out',
    troubleshoot: [
      'Make sure you are using correct info to connect your email account.',
      'It is possible that your SMTP sever is currently down, try again in sometimes.',
    ],
    faqLink: supportUrls.smtpImapConnectionTimeOut,
  },
];

const findError = (obj, errorMsg) =>
  obj.some(
    (err) => errorMsg?.toLowerCase().indexOf(err.toString().toLowerCase()) > -1,
  );

export const getSMTPErrorTitleAndTroubleshoot = (errorMsg) => {
  const newArr = map(
    smtpErrorTitleAndTroubleshoot,
    (obj) => findError(obj.error, errorMsg) && obj,
  );
  const getError = newArr.find((obj) => obj);

  return getError || smtpErrorTitleAndTroubleshoot[0];
};

export const getIMAPErrorTitleAndTroubleshoot = (errorMsg) => {
  const newArr = map(
    imapErrorTitleAndTroubleshoot,
    (obj) => findError(obj.error, errorMsg) && obj,
  );
  const getError = newArr.find((obj) => obj);

  return getError || imapErrorTitleAndTroubleshoot[0];
};
