import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  getEmailAccountsInWarmupRequest,
  getEmailAccountWarmupDeliverabilityReportRequest,
  getEmailAccountWarmupSentReceivedReportRequest,
  getEmailAccountWarmupSettingsRequest,
  saveEmailAccountWarmupSettingsRequest,
  turnOnOffEmailAccountWarmupRequest,
  getEmailAccountWarmupListRequest,
  importEmailAccountCsvRequest,
} from './extra-actions';
import {
  EmailAccountWarmupDeliverabilityReport,
  EmailAccountWarmupList,
  EmailAccountWarmupSentReceivedReport,
  EmailAccountWarmupSettings,
  EmailWarmupAccount,
} from './types';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getEmailAccountsInWarmupRequest: RequestState;
  getEmailAccountWarmupDeliverabilityReportRequest: RequestState;
  getEmailAccountWarmupSentReceivedReportRequest: RequestState;
  getEmailAccountWarmupSettingsRequest: RequestState;
  saveEmailAccountWarmupSettingsRequest: RequestState;
  turnOnOffEmailAccountWarmupRequest: RequestState;
  getEmailAccountWarmupListRequest: RequestState;
  importEmailAccountCsvRequest: RequestState;
  emailWarmupAccounts: EmailWarmupAccount[];
  emailAccountWarmupSentReceivedReport: EmailAccountWarmupSentReceivedReport;
  emailAccountWarmupDeliverabilityReport: EmailAccountWarmupDeliverabilityReport;
  emailAccountWarmupSettings: EmailAccountWarmupSettings;
  emailAccountWarmupList: EmailAccountWarmupList[];
  totalEmailWarmupAccounts: number;
};

const initialState: State = {
  getEmailAccountsInWarmupRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupDeliverabilityReportRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupSentReceivedReportRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveEmailAccountWarmupSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  turnOnOffEmailAccountWarmupRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  importEmailAccountCsvRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailWarmupAccounts: [],
  totalEmailWarmupAccounts: 0,
  emailAccountWarmupSentReceivedReport: null,
  emailAccountWarmupDeliverabilityReport: null,
  emailAccountWarmupSettings: null,
  emailAccountWarmupList: null,
};

const emailWarmupSlice = createSlice({
  name: 'emailWarmup',
  initialState,
  reducers: {
    resetEmailWarmupState: (state) => {
      state.getEmailAccountsInWarmupRequest =
        initialState.getEmailAccountsInWarmupRequest;
      state.getEmailAccountWarmupDeliverabilityReportRequest =
        initialState.getEmailAccountWarmupDeliverabilityReportRequest;
      state.getEmailAccountWarmupSentReceivedReportRequest =
        initialState.getEmailAccountWarmupSentReceivedReportRequest;
      state.getEmailAccountWarmupSettingsRequest =
        initialState.getEmailAccountWarmupSettingsRequest;
      state.saveEmailAccountWarmupSettingsRequest =
        initialState.saveEmailAccountWarmupSettingsRequest;
      state.turnOnOffEmailAccountWarmupRequest =
        initialState.turnOnOffEmailAccountWarmupRequest;
      state.getEmailAccountWarmupListRequest =
        initialState.getEmailAccountWarmupListRequest;
      state.emailWarmupAccounts = initialState.emailWarmupAccounts;
      state.emailAccountWarmupSentReceivedReport =
        initialState.emailAccountWarmupSentReceivedReport;
      state.emailAccountWarmupDeliverabilityReport =
        initialState.emailAccountWarmupDeliverabilityReport;
      state.emailAccountWarmupSettings =
        initialState.emailAccountWarmupSettings;
      state.emailAccountWarmupList = initialState.emailAccountWarmupList;
      state.totalEmailWarmupAccounts = initialState.totalEmailWarmupAccounts;
    },
    resetTurnOnOffEmailAccountWarmupState: (state) => {
      state.turnOnOffEmailAccountWarmupRequest =
        initialState.turnOnOffEmailAccountWarmupRequest;
    },
  },
  extraReducers: (builder) => {
    // Get Email Account List In Warmup
    builder.addCase(getEmailAccountsInWarmupRequest.pending, (state) => {
      state.getEmailAccountsInWarmupRequest.status = RequestStatus.Pending;
      state.getEmailAccountsInWarmupRequest.error = null;
      state.getEmailAccountsInWarmupRequest.message = null;
    });
    builder.addCase(
      getEmailAccountsInWarmupRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountsInWarmupRequest.status = RequestStatus.Succeeded;
        state.emailWarmupAccounts = action.payload.payload.emailAccounts;
        state.totalEmailWarmupAccounts =
          action.payload.payload.emailAccountCount;
      },
    );
    builder.addCase(
      getEmailAccountsInWarmupRequest.rejected,
      (state, action) => {
        state.getEmailAccountsInWarmupRequest.status = RequestStatus.Failed;
        state.getEmailAccountsInWarmupRequest.message = action.payload.message;
        state.getEmailAccountsInWarmupRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Account Warmup Deliverability Report
    builder.addCase(
      getEmailAccountWarmupDeliverabilityReportRequest.pending,
      (state) => {
        state.getEmailAccountWarmupDeliverabilityReportRequest.status =
          RequestStatus.Pending;
        state.getEmailAccountWarmupDeliverabilityReportRequest.error = null;
        state.getEmailAccountWarmupDeliverabilityReportRequest.message = null;
      },
    );
    builder.addCase(
      getEmailAccountWarmupDeliverabilityReportRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupDeliverabilityReportRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountWarmupDeliverabilityReport = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupDeliverabilityReportRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupDeliverabilityReportRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountWarmupDeliverabilityReportRequest.message =
          action.payload.message;
        state.getEmailAccountWarmupDeliverabilityReportRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Account Warmup Sent Received Report
    builder.addCase(
      getEmailAccountWarmupSentReceivedReportRequest.pending,
      (state) => {
        state.getEmailAccountWarmupSentReceivedReportRequest.status =
          RequestStatus.Pending;
        state.getEmailAccountWarmupSentReceivedReportRequest.error = null;
        state.getEmailAccountWarmupSentReceivedReportRequest.message = null;
      },
    );
    builder.addCase(
      getEmailAccountWarmupSentReceivedReportRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupSentReceivedReportRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountWarmupSentReceivedReport = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupSentReceivedReportRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupSentReceivedReportRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountWarmupSentReceivedReportRequest.message =
          action.payload.message;
        state.getEmailAccountWarmupSentReceivedReportRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Account Warmup Settings
    builder.addCase(getEmailAccountWarmupSettingsRequest.pending, (state) => {
      state.getEmailAccountWarmupSettingsRequest.status = RequestStatus.Pending;
      state.getEmailAccountWarmupSettingsRequest.error = null;
      state.getEmailAccountWarmupSettingsRequest.message = null;
    });
    builder.addCase(
      getEmailAccountWarmupSettingsRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountWarmupSettings = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupSettingsRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
        state.getEmailAccountWarmupSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Save Email Account Warmup Settings
    builder.addCase(saveEmailAccountWarmupSettingsRequest.pending, (state) => {
      state.saveEmailAccountWarmupSettingsRequest.status =
        RequestStatus.Pending;
      state.saveEmailAccountWarmupSettingsRequest.error = null;
      state.saveEmailAccountWarmupSettingsRequest.message = null;
    });
    builder.addCase(
      saveEmailAccountWarmupSettingsRequest.fulfilled,
      (state, action) => {
        state.saveEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Succeeded;
        state.saveEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      saveEmailAccountWarmupSettingsRequest.rejected,
      (state, action) => {
        state.saveEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Failed;
        state.saveEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
        state.saveEmailAccountWarmupSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Turn On/Off Email Account Warmup Settings
    builder.addCase(turnOnOffEmailAccountWarmupRequest.pending, (state) => {
      state.turnOnOffEmailAccountWarmupRequest.status = RequestStatus.Pending;
      state.turnOnOffEmailAccountWarmupRequest.error = null;
      state.turnOnOffEmailAccountWarmupRequest.message = null;
    });
    builder.addCase(
      turnOnOffEmailAccountWarmupRequest.fulfilled,
      (state, action) => {
        state.turnOnOffEmailAccountWarmupRequest.status =
          RequestStatus.Succeeded;
        state.turnOnOffEmailAccountWarmupRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      turnOnOffEmailAccountWarmupRequest.rejected,
      (state, action) => {
        state.turnOnOffEmailAccountWarmupRequest.status = RequestStatus.Failed;
        state.turnOnOffEmailAccountWarmupRequest.message =
          action.payload.message;
        state.turnOnOffEmailAccountWarmupRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Email Account Warmup List
    builder.addCase(getEmailAccountWarmupListRequest.pending, (state) => {
      state.getEmailAccountWarmupListRequest.status = RequestStatus.Pending;
      state.getEmailAccountWarmupListRequest.error = null;
      state.getEmailAccountWarmupListRequest.message = null;
    });
    builder.addCase(
      getEmailAccountWarmupListRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupListRequest.status = RequestStatus.Succeeded;
        state.emailAccountWarmupList = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupListRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupListRequest.status = RequestStatus.Failed;
        state.getEmailAccountWarmupListRequest.message = action.payload.message;
        state.getEmailAccountWarmupListRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Import Email Accounts Via CSV
    builder.addCase(importEmailAccountCsvRequest.pending, (state) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Pending;
      state.importEmailAccountCsvRequest.message = null;
      state.importEmailAccountCsvRequest.error = null;
    });
    builder.addCase(importEmailAccountCsvRequest.fulfilled, (state, action) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Succeeded;
      state.importEmailAccountCsvRequest.message = action.payload.message;
      state.importEmailAccountCsvRequest.error = null;
    });
    builder.addCase(importEmailAccountCsvRequest.rejected, (state, action) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Failed;
      state.importEmailAccountCsvRequest.message = action.payload.message;
      state.importEmailAccountCsvRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetEmailWarmupState,
  resetTurnOnOffEmailAccountWarmupState,
} = emailWarmupSlice.actions;

export default emailWarmupSlice.reducer;
