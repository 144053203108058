import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ApiTokenHeader from './components/api-token-header';
import ApiTokenList from './components/api-token-list';
import CreateOrShowApiTokenModal from './components/create-or-show-api-token-modal';
import DeleteApiTokenModal from './components/delete-api-token-modal';
import toaster from '../../../../shared/toaster';
import EmptyList from '../../../../shared/design-system/components/molecules/empty-list/empty-list';
import { Images } from '../../../../shared/app-constants';
import { RequestStatus } from '../../../../shared/enums/request-status';
import type { IProps } from './api-tokens-container';
import HeaderBanner from '../header-banner';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import UpgradePlanErrorModal from '../../../../shared/components/upgrade-plan-error-modal';
import RestrictionErrorModal from '../../../prospect/components/prospect-list/components/modals/restriction-error-modal';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { basicTrailPlanRestriction } from '../../../../shared/utils/mail-body-contents';
import { starterPlanRestriction } from '../../../../shared/utils/plan-restriction';

const ApiTokens: React.FC<IProps> = ({
  apiTokenList,
  generatedApiToken,
  sendGetApiTokenListRequest,
  getApiTokenListRequestStatus,
  sendCreateApiTokenRequest,
  getCreateApiTokenRequestStatus,
  getCreateApiTokenRequestError,
  sendRevokeApiTokenRequest,
  getRevokeApiTokenRequestStatus,
  resetCreateApiTokenRequest,
  role,
  subscriptionPlan,
}) => {
  const { t } = useTranslation();

  // states
  const [showCreateApiTokenModal, setShowCreateApiTokenModal] = useState(false);
  const [showApiTokenModal, setShowApiTokenModal] = useState(false);
  const [showDeleteApiTokenModal, setShowDeleteApiTokenModal] = useState(false);
  const [selectedApiToken, setSelectedApiToken] = useState({
    id: 0,
    label: '',
  });
  const [inputError, setInputError] = useState('');
  const [showUpgradePlanErrorModal, setShowUpgradePlanErrorModal] = useState(
    false,
  );
  const [isRestrictionModalShow, setIsRestrictionModalShow] = useState<boolean>(
    false,
  );

  // Status
  const isCreateApiTokenRequestPending =
    getCreateApiTokenRequestStatus === RequestStatus.Pending;
  const isCreateApiTokenRequestSucceed =
    getCreateApiTokenRequestStatus === RequestStatus.Succeeded;

  const isRevokeApiTokenRequestPending =
    getRevokeApiTokenRequestStatus === RequestStatus.Pending;

  const isRevokeApiTokenRequestSucceed =
    getRevokeApiTokenRequestStatus === RequestStatus.Succeeded;

  // modal handlers
  const showRestrictionModal = () => {
    setIsRestrictionModalShow(true);
  };

  const hideRestrictionModal = () => {
    setIsRestrictionModalShow(false);
  };

  const onCreateApiTokenClickHandler = () => {
    if (!hasPermission(Permissions.API_TOKEN_WRITE)) {
      showRestrictionModal();
      return;
    }

    if (starterPlanRestriction()) {
      setShowUpgradePlanErrorModal(true);
      return;
    }

    if (hasResource(ResourceIdentifiers.API_TOKEN_CREATE)) {
      setInputError('');
      setShowCreateApiTokenModal(true);
    }
  };
  const hideModal = () => {
    setShowCreateApiTokenModal(false);
    setShowApiTokenModal(false);
    resetCreateApiTokenRequest();
  };

  const revokeApiToken = (row) => {
    setSelectedApiToken({ id: row.key, label: row.label });
    setShowDeleteApiTokenModal(true);
  };

  // Use Effect
  useEffect(() => {
    sendGetApiTokenListRequest();
  }, []);

  useEffect(() => {
    if (isRevokeApiTokenRequestSucceed) {
      sendGetApiTokenListRequest();
      toaster.success(t('messages.your_api_key_deleted'));
    }
  }, [isRevokeApiTokenRequestSucceed]);

  useEffect(() => {
    setShowCreateApiTokenModal(isCreateApiTokenRequestPending);
    if (isCreateApiTokenRequestSucceed) {
      sendGetApiTokenListRequest();
    }
    setShowApiTokenModal(isCreateApiTokenRequestSucceed);
  }, [isCreateApiTokenRequestSucceed]);

  useEffect(() => {
    setShowDeleteApiTokenModal(isRevokeApiTokenRequestPending);
  }, [isRevokeApiTokenRequestPending]);

  useEffect(() => {
    if (getCreateApiTokenRequestError) {
      setInputError(getCreateApiTokenRequestError.message);
    }
  }, [getCreateApiTokenRequestError]);

  // Functions
  const createApiToken = (label: string) => {
    sendCreateApiTokenRequest({ label });
  };

  const onSubmitDeleteApiToken = () => {
    sendRevokeApiTokenRequest({ tokenId: selectedApiToken.id });
  };

  const onAction = (key, row) => {
    if (key === 'revoke') {
      revokeApiToken(row);
    }
  };

  const hideUpgradePlanErrorModal = () => {
    setShowUpgradePlanErrorModal(false);
  };

  return (
    <div className="api-token-container">
      {(showCreateApiTokenModal || showApiTokenModal) && (
        <CreateOrShowApiTokenModal
          hide={() => hideModal()}
          onSubmit={(label) => createApiToken(label)}
          showCreateApiTokenModal={showCreateApiTokenModal}
          showApiTokenModal={showApiTokenModal}
          token={generatedApiToken}
          isRequestPending={isCreateApiTokenRequestPending}
          error={inputError}
        />
      )}

      {showDeleteApiTokenModal && (
        <DeleteApiTokenModal
          hide={() => setShowDeleteApiTokenModal(false)}
          label={selectedApiToken.label}
          onSubmitDeleteApiToken={() => onSubmitDeleteApiToken()}
          isRequestPending={isRevokeApiTokenRequestPending}
        />
      )}

      <Row>
        <Col>
          <ApiTokenHeader
            onClick={onCreateApiTokenClickHandler}
            subscriptionPlan={subscriptionPlan}
          />
        </Col>
      </Row>
      {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
      {getApiTokenListRequestStatus === RequestStatus.Succeeded &&
        apiTokenList.length > 0 && (
          <ApiTokenList
            apiTokens={apiTokenList}
            onAction={(key, row) => onAction(key, row)}
            role={role}
          />
        )}
      {getApiTokenListRequestStatus === RequestStatus.Succeeded &&
        apiTokenList.length === 0 && (
          <EmptyList
            title={t('messages.api_keys_empty')}
            description={t('messages.please_add_api_keys')}
            imgSrc={Images.EmptyData1}
          />
        )}

      <UpgradePlanErrorModal
        show={showUpgradePlanErrorModal}
        onClose={hideUpgradePlanErrorModal}
        planName="starter"
        restrictionOn="api key"
      />

      <RestrictionErrorModal
        show={isRestrictionModalShow}
        modalTitle="Upgrade your plan to access Saleshandy API"
        bodyContent={[t('messages.api_key_msg_1'), t('messages.api_key_msg_2')]}
        emailBody={basicTrailPlanRestriction()}
        onClose={hideRestrictionModal}
      />
    </div>
  );
};

export default ApiTokens;
