import api from '../../../api';
import {
  UpdateUserProfilePayload,
  UpdatePasswordPayload,
  verifyCurrentPasswordPayload,
} from '../types/request-payload';
import { ResponseSuccess } from '../../../shared/types/response';

export const updatePassword = async (payload: UpdatePasswordPayload) =>
  api.patch<ResponseSuccess>(`/user/password`, payload);

export const updateMyProfile = async (payload: UpdateUserProfilePayload) =>
  api.patch<ResponseSuccess>(`/user/`, payload);

export const getMyProfile = async () => api.get<ResponseSuccess>(`/user/`);

export const verifyCurrentPassword = async (
  payload: verifyCurrentPasswordPayload,
) => api.post<ResponseSuccess>('/user/verify-current-password', payload);
