import { constants } from '../../../../enums/constants';
import { PaginationShowHide } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getFinalColumns = ({
  actions,
  dataColumns,
  generateActionsColumn,
  headerVisibleForGenerateColumn,
  borderOverActions,
}) =>
  actions
    ? [
        ...dataColumns,
        generateActionsColumn(
          actions,
          headerVisibleForGenerateColumn,
          borderOverActions,
        ),
      ]
    : dataColumns;

export const getTotalSize = ({ paginationOptions }) => {
  let totalSize;

  if (paginationOptions.pagesCount) {
    totalSize = paginationOptions.count;
  } else {
    totalSize = paginationOptions.options.totalElements;
  }
  if (!totalSize) {
    totalSize = constants.DEFAULT_PAGE_SIZE;
  }

  return totalSize;
};

export const getShouldShowPagination = ({
  pagination,
  totalSize,
  isNewPagination,
}) => {
  // for new pagination the initial per page limit is 50
  if (isNewPagination && totalSize <= constants.PROSPECT_PAGE_SIZE) {
    return false;
  }

  // we will not show pagination in case  totalSize is less then 25 because we don't require pagination there(only 1 page is sufficient)
  if (totalSize <= constants.DEFAULT_PAGE_SIZE) {
    return false;
  }

  if (pagination === PaginationShowHide.SHOW) {
    return true;
  }

  if (pagination === PaginationShowHide.HIDE) {
    return false;
  }

  return null;
};

export const getKeyAndId = (id) => id || null;

export const getNoDataClassName = (data) =>
  data?.length === 0 ? 'no-data' : '';

export const showingLengthPerPageOptions = [
  {
    key: '50',
    value: 50,
  },
  {
    key: '100',
    value: 100,
  },
  {
    key: '250',
    value: 250,
  },
  {
    key: '500',
    value: 500,
  },
  {
    key: '1000',
    value: 1000,
  },
];
