import React, { ReactElement, ReactNode } from 'react';
import { SortOrder } from 'react-bootstrap-table-next';
import { Placement } from '../../overlay';

export type Column = {
  dataField: string;
  text: string;
  component?: string | ReactElement | ((cell, row) => void);
  headerAlign?: string;
  isDummyField?: boolean;
  cellClasses?: string;
  componentClasses?: string;
  align?: string;
  onClick?: any;
  style?: any;
  headerStyle?: any;
  sort?: boolean;
  headerTooltipPlacement?: Placement;
  onSort?: (field, order) => void;
  sortCaret?: (order, column) => ReactNode;
  headerFormatter?: (
    column,
    colIndex,
    { sortElement, filterElement },
  ) => ReactNode;
  switchOnTooltip?: string;
  switchOffTooltip?: string;
  tooltipPlacement?: Placement;
  overlayTooltipClass?: string;
  headerLoadingSkeleton?: ReactElement;
  cellLoadingSkeleton?: ReactElement;
  isCellClickable?: boolean;
};

export enum PaginationShowHide {
  HIDE = 'HIDE',
  SHOW = 'SHOW',
}

export type IState = {
  selectedRows: any;
  goToPage: number;
  showRowsPerPage: TablePageLengthDropDown;
  showBounce: boolean;
  isError: boolean;
};

export type Action = {
  key: string;
  displayName: string;
  icon?: string;
  position?: string;
  conditionKey?: string;
  condition?: boolean;
  imageIcon?: any;
  customIcon?: boolean;
};

export type SortOptions = {
  dataField: string;
  order: SortOrder;
};

export type IProps = {
  columns: Column[];
  data?: any;
  actions?: Action[] | ((cell, row) => Action[]);
  onRowClickHandler?: (id: any) => void;
  tableWrapperClasses?: string;
  bodyWrapperClasses?: string;
  headerWrapperClasses?: string;
  onClick?: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    row: any,
  ) => void;
  onChange?: (
    value: any,
    event: React.ChangeEvent<HTMLInputElement>,
    cell: any,
    row: any,
    index: number,
  ) => void;
  paginationOptions: any;
  onPaginationOptionsChange: any;
  onAction?: (key: string, row: any) => void;
  onRowSelect?: (row: any, checked: boolean) => void;
  onRowSelectAll?: (rows: any, checked: boolean) => void;
  resetSelected?: boolean;
  headerVisibleForGenerateColumn?: boolean;
  borderOverActions?: boolean;
  pagination: PaginationShowHide;
  sort?: SortOptions;
  id?: any;
  hidePageOptions?: boolean;
  isNewPagination?: boolean;
  tableRef?: any;
  selectedRowsDetails?: any;
  isLoading?: boolean;
};

export enum Sorting {
  Ascending = 'asc',
  Descending = 'desc',
}

export type TablePageLengthDropDown = {
  key: string;
  value: number;
};

export enum PaginationLocalStorageKey {
  ProspectRowsPerPage = 'prospect-rows-per-page',
}
