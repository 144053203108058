import validator from 'validator';

export const validate = (name, value) => {
  switch (name) {
    case 'team':
      if (validator.isEmpty(value)) {
        return 'Team size must be selected';
      }
      break;
    case 'selectedRole':
      if (!value && !value.key) {
        return 'Role must be selected';
      }
      break;
    default:
      return '';
  }

  return '';
};
