import React from 'react';
import Papa from 'papaparse';
import { Download } from '@saleshandy/icons';

import type { PostSelectProps } from './types';

import Icon from '../../../../../../../shared/design-system/components/atoms/icon';
import ImageIcon from '../../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../../shared/app-constants';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { getAllImportEmailAccountHeadersForFailedReport } from '../../../utils/import-email-accounts-header';

const PostSelect = ({
  fileName,
  onRemove,
  stats: { validRowsCount, skippedRowsCount, skippedRows },
}: PostSelectProps) => {
  const onSkippedCSVDownload = () => {
    const csvData = Papa.unparse({
      fields: getAllImportEmailAccountHeadersForFailedReport(),
      data: skippedRows,
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link element to download the CSV file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Bulk Email Import Failure Report.csv');
    link.click();
  };

  return (
    <div className="post-select">
      <div className="post-select-icon-wrapper">
        <ImageIcon src={Images.Sheets} />
      </div>

      <div className="post-select-filename">{fileName}</div>

      <div className="post-select-message">
        <p className="successfully-imported">
          <ImageIcon src={Images.ChecksGreen} />
          <span>
            Successfully imported:{' '}
            <span className="count">{validRowsCount}</span>
          </span>
        </p>

        <p className="skipped">
          <ImageIcon src={Images.AlertTriangleRed} />
          <span>
            Skipped: <span className="count">{skippedRowsCount}</span>
          </span>
          {skippedRowsCount > 0 && (
            <Download
              className="gray-txt-15"
              {...accessibleOnClick(onSkippedCSVDownload)}
            />
          )}
        </p>
      </div>

      <div className="post-select-delete" {...accessibleOnClick(onRemove)}>
        <Icon identifier="trash" />
        <span>Remove file</span>
      </div>
    </div>
  );
};

export default PostSelect;
