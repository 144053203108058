import React from 'react';
import { withTranslation } from 'react-i18next';
import { IState, IProps } from './types';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import {
  Column,
  PaginationShowHide,
} from '../../../../../../shared/design-system/components/organisms/table/types';
import { displayTag, Status } from '../../../../../../shared/enums/status';
import InviteMembersModal from '../invite-members-modal';
import { InviteUserRequestPayload } from '../../../../types/request-payload';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { User } from '../../../../types/team';
import TableSkeleton from '../../../../../../shared/design-system/components/atoms/skeletons';
import {
  SubscriptionPlans,
  SubscriptionPlanTitle,
} from '../../../../../../shared/utils/subscription-plans';
import { planError } from '../../../../../../shared/utils/errors/plan-permission-error/plan-error';
import { UserRole } from '../../../../../../shared/enums/user-roles';
import HeaderBanner from '../../../header-banner';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';
import RestrictionErrorModal from '../../../../../../shared/components/restriction-error-modal';
import { getIsRequestPending } from '../../../email-account/components/update-email-account/helpers';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatusWithPrevStatusCheck,
} from '../../../../../../shared/utils/execute-on-request-status';
import { resetConnectedUsersAndEmailAccountsRequest } from '../../../billing-subscription/billing-subscription-slice';
import NoSeatAvailableForTeamModal from '../no-seat-available-for-team-modal';
import PlanRestrictionModal from '../../../../../prospect/components/prospect-list/components/modals/restriction-error-modal';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { basicTrailPlanRestriction } from '../../../../../../shared/utils/mail-body-contents';
import { isValueBasedPlan } from '../../../billing-subscription/components/outreach-plans/utils/helper';

class TeamContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showInviteModal: false,
      restrictionErrorModalMeta: {
        show: false,
        planName: '',
      },
      showNoSeatAvailableForTeamModal: false,
      isPlanRestrictionModalShow: false,
    };

    this.renderStatusTag = this.renderStatusTag.bind(this);
    this.showInviteUserModal = this.showInviteUserModal.bind(this);
    this.hideInviteUserModal = this.hideInviteUserModal.bind(this);
    this.inviteUserHandler = this.inviteUserHandler.bind(this);
    this.showInviteUserModalHandler = this.showInviteUserModalHandler.bind(
      this,
    );
    this.hideRestrictionErrorModal = this.hideRestrictionErrorModal.bind(this);
    this.onPaginationOptionsChangeHandler = this.onPaginationOptionsChangeHandler.bind(
      this,
    );
    this.hideNoSeatAvailableForTeamModal = this.hideNoSeatAvailableForTeamModal.bind(
      this,
    );
    this.hidePlanRestrictionModal = this.hidePlanRestrictionModal.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      inviteUserRequestStatus,
      inviteUserRequestMessage,
      inviteUserRequestError,
      sendGetUsersListRequest,

      resendInvitationRequestStatus,
      resendInvitationRequestMessage,
      resendInvitationRequestError,

      cancelInvitationRequestStatus,
      cancelInvitationRequestMessage,
      cancelInvitationRequestError,

      deactivateUserRequestStatus,
      deactivateUserRequestMessage,
      deactivateUserRequestError,

      reactivateUserRequestStatus,
      reactivateUserRequestMessage,
      reactivateUserRequestError,

      deleteUserRequestStatus,
      deleteUserRequestMessage,
      deleteUserRequestError,

      getConnectedUsersAndEmailAccountsRequestStatus,
      getConnectedUsersAndEmailAccountsRequestError,
    } = this.props;

    if (inviteUserRequestStatus !== prevProps.inviteUserRequestStatus) {
      if (inviteUserRequestStatus === RequestStatus.Succeeded) {
        toaster.success(inviteUserRequestMessage);
        this.hideInviteUserModal();
        const {
          pagination: {
            options: { limit, page },
          },
        } = this.props;
        sendGetUsersListRequest({ limit, page });
      }

      if (
        inviteUserRequestStatus === RequestStatus.Failed &&
        inviteUserRequestError
      ) {
        this.hideInviteUserModal();
        toaster.error(inviteUserRequestError.message);
      }
    }

    if (
      resendInvitationRequestStatus !== prevProps.resendInvitationRequestStatus
    ) {
      if (resendInvitationRequestStatus === RequestStatus.Succeeded) {
        toaster.success(resendInvitationRequestMessage);
        this.hideInviteUserModal();
        const {
          pagination: {
            options: { limit, page },
          },
        } = this.props;
        sendGetUsersListRequest({ limit, page });
      }

      if (
        resendInvitationRequestStatus === RequestStatus.Failed &&
        resendInvitationRequestError
      ) {
        toaster.error(resendInvitationRequestError.message);
      }
    }

    if (
      cancelInvitationRequestStatus !== prevProps.cancelInvitationRequestStatus
    ) {
      if (cancelInvitationRequestStatus === RequestStatus.Succeeded) {
        toaster.success(cancelInvitationRequestMessage);
        this.hideInviteUserModal();
        const {
          pagination: {
            options: { limit, page },
          },
        } = this.props;
        sendGetUsersListRequest({ limit, page });
      }

      if (
        cancelInvitationRequestStatus === RequestStatus.Failed &&
        cancelInvitationRequestError
      ) {
        toaster.error(cancelInvitationRequestError.message);
      }
    }

    if (deactivateUserRequestStatus !== prevProps.deactivateUserRequestStatus) {
      if (deactivateUserRequestStatus === RequestStatus.Succeeded) {
        toaster.success(deactivateUserRequestMessage);
        this.hideInviteUserModal();
        const {
          pagination: {
            options: { limit, page },
          },
        } = this.props;
        sendGetUsersListRequest({ limit, page });
      }

      if (
        deactivateUserRequestStatus === RequestStatus.Failed &&
        deactivateUserRequestError
      ) {
        toaster.error(deactivateUserRequestError.message);
      }
    }

    if (reactivateUserRequestStatus !== prevProps.reactivateUserRequestStatus) {
      if (reactivateUserRequestStatus === RequestStatus.Succeeded) {
        toaster.success(reactivateUserRequestMessage);
        this.hideInviteUserModal();
        const {
          pagination: {
            options: { limit, page },
          },
        } = this.props;
        sendGetUsersListRequest({ limit, page });
      }

      if (
        reactivateUserRequestStatus === RequestStatus.Failed &&
        reactivateUserRequestError
      ) {
        toaster.error(reactivateUserRequestError.message);
      }
    }

    if (deleteUserRequestStatus !== prevProps.deleteUserRequestStatus) {
      if (deleteUserRequestStatus === RequestStatus.Succeeded) {
        toaster.success(deleteUserRequestMessage);
        this.hideInviteUserModal();
        const {
          pagination: {
            options: { limit, page },
          },
        } = this.props;
        sendGetUsersListRequest({ limit, page });
      }

      if (
        deleteUserRequestStatus === RequestStatus.Failed &&
        deleteUserRequestError
      ) {
        toaster.error(deleteUserRequestError.message);
      }
    }

    executeOnRequestStatusWithPrevStatusCheck({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      prevStatus: prevProps.getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        this.showInviteUserModalHandler();
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getConnectedUsersAndEmailAccountsRequestError,
          onError: () => {
            toaster.error(
              getConnectedUsersAndEmailAccountsRequestError.message,
              { theme: Theme.New, showCloseIcon: true, delay: 10000 },
            );
          },
        });
      },
    });
  }

  onPaginationOptionsChangeHandler(
    newPartialOptions: Partial<PaginationQueryParams>,
  ) {
    const {
      pagination: { options },
      sendGetUsersListRequest,
    } = this.props;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetUsersListRequest(payload);
  }

  generateActionsColumn = (cell, row: User) => {
    switch (row.status) {
      case Status.Invited:
        if (
          new Date().getTime() - row.invitationCreatedAt >
          24 * 60 * 60 * 1000
        ) {
          return [
            {
              displayName: 'Resend Invite',
              icon: 'redo',
              key: 'resend',
            },
            {
              displayName: 'Delete',
              icon: 'remove',
              key: 'delete',
            },
          ];
        }
        return [
          {
            displayName: 'Cancel Invite',
            icon: 'redo',
            key: 'cancel',
          },
          {
            displayName: 'Delete',
            icon: 'remove',
            key: 'delete',
          },
        ];

      case Status.Active:
        return [
          {
            displayName: 'Deactivate',
            icon: 'lock',
            key: 'deactivate',
          },
          {
            displayName: 'Delete',
            icon: 'remove',
            key: 'delete',
          },
        ];
      case Status.Inactive:
        return [
          {
            displayName: 'Reactivate',
            icon: 'lock-unlock',
            key: 'reactivate',
          },
          {
            displayName: 'Delete',
            icon: 'remove',
            key: 'delete',
          },
        ];
      case Status.InviteCancelled:
        return [
          {
            displayName: 'Resend Invite',
            icon: 'redo',
            key: 'resend',
          },
          {
            displayName: 'Delete',
            icon: 'remove',
            key: 'delete',
          },
        ];
      case Status.Deleted:
        return [];
      default:
        return [];
    }
  };

  onAction = (key, row) => {
    const {
      sendResendInvitationRequest,
      sendCancelInvitationRequest,
      sendDeactivateUserRequest,
      sendReactivateUserRequest,
      sendDeleteUserRequest,
    } = this.props;

    switch (key) {
      case 'resend':
        sendResendInvitationRequest(row.id);
        break;
      case 'cancel':
        sendCancelInvitationRequest(row.id);
        break;
      case 'deactivate':
        sendDeactivateUserRequest(row.id);
        break;
      case 'reactivate':
        sendReactivateUserRequest(row.id);
        break;
      case 'delete':
        sendDeleteUserRequest(row.id);
        break;
      default:
        break;
    }
  };

  hidePlanRestrictionModal = () => {
    this.setState({ isPlanRestrictionModalShow: false });
  };

  showPlanRestrictionModal() {
    this.setState({ isPlanRestrictionModalShow: true });
  }

  hideInviteUserModal() {
    this.setState({ showInviteModal: false });
  }

  inviteUserHandler(payload: InviteUserRequestPayload) {
    const { sendInviteUserRequest } = this.props;
    sendInviteUserRequest(payload);
  }

  showInviteUserModal(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();

    if (!hasPermission(Permissions.ACCOUNT_USER_INVITE)) {
      this.showPlanRestrictionModal();
      return;
    }

    if (hasResource(ResourceIdentifiers.TEAM_USER_INVITE)) {
      const {
        subscriptionPlan,
        sendGetConnectedUsersAndEmailAccountsRequest,
      } = this.props;
      if (subscriptionPlan === SubscriptionPlans.TRIAL) {
        this.setState({ showInviteModal: true });
        return;
      }

      sendGetConnectedUsersAndEmailAccountsRequest();
    }
  }

  showInviteUserModalHandler() {
    const { subscriptionPlan, role, totalUsers, slots, planType } = this.props;

    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (role === UserRole.ADMIN) {
        planError(2007);
      } else {
        planError(3007);
      }
    } else if (
      subscriptionPlan !== SubscriptionPlans.TRIAL &&
      Number(totalUsers) >= slots &&
      !isValueBasedPlan(planType)
    ) {
      if (subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
        this.setState({
          restrictionErrorModalMeta: {
            show: true,
            planName: SubscriptionPlanTitle.EmailOutreach,
          },
        });
        return;
      }
      if (subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME) {
        this.setState({
          restrictionErrorModalMeta: {
            show: true,
            planName: SubscriptionPlanTitle.EmailOutreachPro,
          },
        });
        return;
      }

      this.setState({ showNoSeatAvailableForTeamModal: true });
      return;
    }

    hasResource(ResourceIdentifiers.TEAM_USER_INVITE) &&
      this.setState({ showInviteModal: true });
  }

  hideRestrictionErrorModal() {
    this.setState({
      restrictionErrorModalMeta: {
        show: false,
        planName: '',
      },
    });
  }

  hideNoSeatAvailableForTeamModal() {
    this.setState({
      showNoSeatAvailableForTeamModal: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderStatusTag(status: number) {
    switch (status) {
      case Status.Active:
        return (
          <span className="bs-tag bs-tag-green">
            {displayTag[Status.Active]}
          </span>
        );
      case Status.Inactive:
        return <span className="bs-tag">{displayTag[Status.Inactive]}</span>;
      case Status.Invited:
        return (
          <span className="bs-tag bs-tag-cyan">
            {displayTag[Status.Invited]}
          </span>
        );
      case Status.InviteCancelled:
        return (
          <span className="bs-tag bs-tag-orange">
            {displayTag[Status.InviteCancelled]}
          </span>
        );
      case Status.Deleted:
        return (
          <span className="bs-tag bs-tag-red">
            {displayTag[Status.Deleted]}
          </span>
        );
      case Status.Failed:
        return (
          <span className="bs-tag bs-tag-red">{displayTag[Status.Failed]}</span>
        );
      default:
        break;
    }
    return null;
  }

  render() {
    const {
      users,
      pagination,
      isLoading,
      isRequestPending,
      subscriptionPlan,
      agencyConfig,
      adminFirstName,
      getConnectedUsersAndEmailAccountsRequestStatus,
      t,
    } = this.props;

    const {
      showInviteModal,
      restrictionErrorModalMeta,
      showNoSeatAvailableForTeamModal,
      isPlanRestrictionModalShow,
    } = this.state;

    const columns: Column[] = [
      {
        dataField: 'name',
        text: 'Name',
        align: 'left',
        component: 'team-member-name-field',
      },
      {
        dataField: 'email',
        text: 'Email',
        align: 'left',
      },
      {
        dataField: 'status',
        text: 'Status',
        align: 'left',
        component: (cell) => this.renderStatusTag(cell),
      },
    ];

    const data = users.map(
      ({ id, firstName, lastName, email, status, invitationCreatedAt }) => ({
        id,
        name: `${firstName} ${lastName}`,
        email,
        invitationCreatedAt,
        status,
      }),
    );

    return (
      <div className="team-container">
        <div className="team-header">
          <div>
            <span className="semibold-3">Team</span>
          </div>
          <div>
            {/* TODO: refactor subscription plan check condition */}
            <Button
              theme={Button.Theme.Solid}
              variant={Button.Variant.Primary}
              onClick={this.showInviteUserModal}
              disabled={
                subscriptionPlan === SubscriptionPlans.FREE ||
                getIsRequestPending(
                  getConnectedUsersAndEmailAccountsRequestStatus,
                )
              }
              className="header-btn"
              isLoading={getIsRequestPending(
                getConnectedUsersAndEmailAccountsRequestStatus,
              )}
            >
              Add Members
            </Button>
          </div>
        </div>
        {subscriptionPlan === SubscriptionPlans.FREE && (
          <HeaderBanner isTeamPage={true} />
        )}
        {!isRequestPending ? (
          <div className="list-container">
            <Table
              actions={this.generateActionsColumn}
              paginationOptions={pagination}
              onPaginationOptionsChange={this.onPaginationOptionsChangeHandler}
              columns={columns}
              data={data}
              onAction={this.onAction}
              headerVisibleForGenerateColumn={true}
              borderOverActions={false}
              pagination={PaginationShowHide.SHOW}
            />
          </div>
        ) : (
          <TableSkeleton colCount={4} rowWidth={16} />
        )}

        {showInviteModal && (
          <InviteMembersModal
            show={showInviteModal}
            onClose={this.hideInviteUserModal}
            onSubmit={this.inviteUserHandler}
            isLoading={isLoading}
            agencyConfig={agencyConfig}
          />
        )}

        <RestrictionErrorModal
          show={restrictionErrorModalMeta.show}
          onClose={this.hideRestrictionErrorModal}
          planName={restrictionErrorModalMeta.planName}
          restrictionOn="team members"
          feature="team members"
          firstName={adminFirstName}
        />

        <NoSeatAvailableForTeamModal
          show={showNoSeatAvailableForTeamModal}
          onClose={this.hideNoSeatAvailableForTeamModal}
          firstName={adminFirstName}
        />

        <PlanRestrictionModal
          show={isPlanRestrictionModalShow}
          modalTitle="Upgrade your plan to invite new members"
          bodyContent={[
            t('messages.team_invite_msg_1'),
            t('messages.team_invite_msg_2'),
          ]}
          emailBody={basicTrailPlanRestriction()}
          onClose={this.hidePlanRestrictionModal}
        />
      </div>
    );
  }
}

export default withTranslation()(TeamContent);
