import React from 'react';
import InputNumber from '../../../../../shared/design-system/components/input-number';

const DailySendingLimitInput = ({
  t,
  sendingLimit,
  changeSendingLimit,
  zeroLimitError,
}) => (
  <div className="row">
    <div className="col-md-8">
      <p className="total-number-title mb-1">
        {t('labels.email_sending_limit_header')}
      </p>
      <p className="total-number-caption m-0">
        {t('labels.email_sending_limit_caption')}
      </p>
    </div>
    <div className="col-md-1" />
    <div className="sending-limit-input col-md-1 pt-2 text-center">
      <InputNumber
        size={InputNumber.Size.Medium}
        min={0}
        max={5000}
        value={sendingLimit}
        onChange={changeSendingLimit}
        variant={zeroLimitError && InputNumber.Variant.Error}
      />
    </div>
  </div>
);

export default DailySendingLimitInput;
