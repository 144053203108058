export enum SequenceSettingCode {
  EMAIL_LIMIT = 'email-limit', // Email limit of prospect in a single day
  UNSUBSCRIBE_LINK = 'unsubscribe-link', // Unsubscribe link in email
  MARK_AS_FINISHED = 'mark-as-finished', // Consider the prospect as finished if a reply is received
  SCHEDULE = 'schedule',
  TRACK_LINK_CLICKS = 'track-link-clicks', // To toggle activation of click tracking in emails.
  TRACK_EMAIL_OPENS = 'track-email-opens', // To toggle activation of click tracking on open email
  IS_SAMPLE_SEQUENCE = 'is-sample-sequence',
}

export enum UnsubscribeText {
  NO_MAIL_FROM_ME = `If you'd prefer not to receive email from me, <a href="{{link}}" target="_blank">Unsubscribe here</a>.`,
  NO_MAIL_FUTURE = `If you don't want to receive such emails in future, <a href="{{link}}" target="_blank">Unsubscribe here</a>.`,
  NOT_UP_TO_MAILS = `Not up for all these emails? No Sweat! you can, <a href="{{link}}" target="_blank">Unsubscribe here</a>.`,
  CUSTOM = 'custom',
}
