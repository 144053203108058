import React from 'react';
import Pagination from '../../pagination';

const PaginationWrapper: React.FC<any> = (props) => {
  const {
    dataSize,
    page,
    showRowsPerPage,
    isNewPagination,
    onPageChange,
  } = props;

  const perPageLimit = isNewPagination ? showRowsPerPage : 25;
  const totalPage = Math.ceil(dataSize / perPageLimit);

  return (
    <Pagination
      currentPage={page}
      totalPages={totalPage}
      onChange={onPageChange}
    />
  );
};

export default PaginationWrapper;
