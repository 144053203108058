/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import validator from 'validator';
import 'react-phone-input-2/lib/style.css';
import type { IProps } from './account-details-container';
import { AccountDetailsFormValues } from './types';
import TopWelcome from '../top-welcome';
import FormSection from '../form-section';
import Input from '../../../../shared/design-system/components/input';
import Button from '../../../../shared/design-system/components/atoms/button';
import PasswordValidationChecklist from '../../../../shared/components/password-validation-checklist';
import { supportUrls } from '../../../../shared/utils/urls';
import { validate } from '../signup-form/validator';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import { redirectWithoutRefresh } from '../../../../shared/utils';
import toaster, { Theme } from '../../../../shared/toaster/index';
import { getCurrentTimeZone } from '../../../../shared/utils/date-time';
import { onInputChangeHelper } from './utils/account-details-helper';

const AccountDetails: React.FC<IProps> = ({
  accountDetailsRequestStatus,
  accountDetailsRequestMessage,
  sendSetPasswordRequest,
  sendAcceptInvitationRequest,
  canResendUserVerificationCode,
  inviteUserEmail,
  isInvitationRequest,
  token,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [values, setValues] = useState<AccountDetailsFormValues>({
    firstName: '',
    lastName: '',
    contactNumber: '',
    password: '',
    country: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isPasswordDirty, setIsPasswordDirty] = useState<boolean>(false);

  const onInputChange = (
    value: string,
    e: React.ChangeEvent<HTMLInputElement>,
    data?: any,
  ) => {
    onInputChangeHelper(setValues, setIsPasswordDirty, value, e, data);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    let timeZone = getCurrentTimeZone();
    if (timeZone === 'Asia/Calcutta') {
      timeZone = 'Asia/Kolkata';
    }

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      // phone: values.contactNumber,
      password: values.password,
      country: values.country,
    };

    if (!isInvitationRequest) {
      sendSetPasswordRequest({
        ...payload,
        email,
      });
    } else {
      sendAcceptInvitationRequest({
        ...payload,
        token,
        timeZone,
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((preState) => !preState);
  };

  const isLoading = accountDetailsRequestStatus === RequestStatus.Pending;

  const areFieldsEmpty = () =>
    validator.isEmpty(values.firstName) ||
    validator.isEmpty(values.lastName) ||
    !!validate('password', values.password) ||
    isLoading;

  const redirectToNextScreen = () => {
    if (canResendUserVerificationCode) {
      const queryParams = new URLSearchParams('');
      queryParams.set('email', email);

      redirectWithoutRefresh(`/verify-account?${queryParams.toString()}`);
    }
  };

  // useEffect
  useEffect(() => {
    const { email: emailValue } = queryString.parse(location.search);

    setEmail(emailValue || inviteUserEmail);
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: accountDetailsRequestStatus,
      onSuccess: () => {
        toaster.success(accountDetailsRequestMessage, { theme: Theme.New });
        redirectToNextScreen();
      },
    });
  }, [accountDetailsRequestStatus]);

  return (
    <div className="account-details">
      <TopWelcome>
        <TopWelcome.Header>Try TrulyInbox for free</TopWelcome.Header>
      </TopWelcome>

      <FormSection>
        <form className="step_2 account-details" onSubmit={onFormSubmit}>
          <Row className="justify-content-between">
            <Col>
              <Input
                name="firstName"
                label="First name*"
                placeholder="..."
                value={values.firstName}
                onChange={onInputChange}
                autoComplete="current-firstName"
                autoFocus
                tabIndex={1}
              />
            </Col>
            <Col>
              <Input
                name="lastName"
                label="Last name*"
                placeholder="..."
                value={values.lastName}
                onChange={onInputChange}
                autoComplete="current-lastName"
                tabIndex={2}
              />
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <div>
                <span className="semibold-1 gray-txt-15">Contact Number*</span>
              </div>
              <PhoneInput
                country="us"
                value={values.contactNumber}
                onChange={(value, data, e) => onInputChange(value, e, data)} // passed country data
                containerClass="region-contact-number"
                inputProps={{
                  tabIndex: 3,
                  name: 'contactNumber',
                }}
              />
            </Col>
          </Row> */}

          <Row>
            <Col>
              <Input
                name="password"
                label="Create Password"
                placeholder="..."
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={onInputChange}
                autoComplete="current-password"
                icons={[
                  {
                    place: Input.IconPlace.Right,
                    identifier: showPassword ? 'eye-alt' : 'eye',
                    className: 'pointer',
                    onClick: togglePasswordVisibility,
                  },
                ]}
                tabIndex={4}
              />
              <PasswordValidationChecklist
                password={values.password}
                isDirty={isPasswordDirty}
              />
            </Col>
          </Row>

          <Button
            type={Button.Type.Submit}
            variant={Button.Variant.Primary}
            className="team-selection"
            isLoading={isLoading}
            disabled={areFieldsEmpty()}
            tabIndex={5}
          >
            {t('labels.next')}
          </Button>
        </form>
      </FormSection>
      <div className="text-center mt-1">
        <span className="regular-1 font-medium gray-txt-15">
          By signing up, you agree to the TrulyInbox's{' '}
          <Link
            to={{ pathname: supportUrls.termsOfServices }}
            target="_blank"
            className="gray-txt-15 link font-normal"
          >
            Terms of Service.
          </Link>
        </span>
      </div>
    </div>
  );
};

export default AccountDetails;
