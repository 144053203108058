import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IProps } from './type';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';

const SequenceList = React.lazy(() => import('./components/sequence-list'));
const SequenceSingle = React.lazy(() => import('./components/sequence-single'));

const Sequence: React.FC<IProps> = () => (
  <Container fluid className="sequence-container p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/sequence" component={SequenceList} />
          <Route
            path="/sequence/:sequenceId/:activeTab"
            component={SequenceSingle}
          />
          <Redirect to="/sequence" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default Sequence;
