import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  acceptInvitationRequest,
  verifyInvitationTokenRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  acceptInvitationRequest: RequestState;
  verifyInvitationTokenRequest: RequestState;
  accessToken: string;
  trackingId: string;
  firstName: string;
  lastName: string;
  email: string;
};

const initialState: State = {
  acceptInvitationRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
  },
  verifyInvitationTokenRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
  },
  email: '',
  accessToken: null,
  trackingId: null,
  firstName: null,
  lastName: null,
};

const invitationSignup = createSlice({
  name: 'invitationSignup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Accept invitation signup
    builder.addCase(acceptInvitationRequest.pending, (state) => {
      state.acceptInvitationRequest.status = RequestStatus.Pending;
    });
    builder.addCase(acceptInvitationRequest.fulfilled, (state, action) => {
      state.acceptInvitationRequest.status = RequestStatus.Succeeded;
      state.acceptInvitationRequest.message = action.payload.message;
      state.accessToken = action.payload.payload.accessToken;
      state.trackingId = action.payload.payload.trackingId;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
    });
    builder.addCase(acceptInvitationRequest.rejected, (state, action) => {
      state.acceptInvitationRequest.status = RequestStatus.Failed;
      state.acceptInvitationRequest.error = action.payload;
    });

    // Verify invitation token
    builder.addCase(verifyInvitationTokenRequest.pending, (state: State) => {
      state.verifyInvitationTokenRequest.status = RequestStatus.Pending;
    });
    builder.addCase(
      verifyInvitationTokenRequest.fulfilled,
      (state: State, action) => {
        state.verifyInvitationTokenRequest.status = RequestStatus.Succeeded;
        state.verifyInvitationTokenRequest.message = action.payload.message;
        state.accessToken = action.payload.payload.accessToken;
        state.trackingId = action.payload.payload.trackingId;
        state.firstName = action.payload.payload.firstName;
        state.lastName = action.payload.payload.lastName;
        state.email = action.payload.payload.email;
      },
    );
    builder.addCase(
      verifyInvitationTokenRequest.rejected,
      (state: State, action) => {
        state.verifyInvitationTokenRequest.status = RequestStatus.Failed;
        state.verifyInvitationTokenRequest.error = action.payload;
      },
    );
  },
});

export { acceptInvitationRequest, verifyInvitationTokenRequest };
export default invitationSignup.reducer;
