import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import { IProps } from './types';

const ApiTokenHeader: React.FC<IProps> = ({
  onClick: onCreateApiTokenClickHandler,
  subscriptionPlan,
}) => {
  const { t } = useTranslation();

  return (
    <div className="api-token-header">
      <div>
        <div className="api-token-title">{t('labels.api_key')}</div>
        <div className="regular-2 block-display">
          {t('messages.api_key_home_part_1')}
          <a
            href={process.env.REACT_APP_OPEN_API_URL}
            target="_blank"
            rel="noreferrer"
          >
            {t('messages.api_key_home_part_2')}
          </a>
          {t('messages.api_key_home_part_3')}
        </div>
      </div>

      <div className="api-token-create-button">
        {/* TODO: refactor subscription plan check condition */}
        <Button
          variant={Button.Variant.Primary}
          onClick={onCreateApiTokenClickHandler}
          disabled={subscriptionPlan === SubscriptionPlans.FREE}
          className="header-btn"
        >
          {t('labels.create_api_key')}
        </Button>
      </div>
    </div>
  );
};

export default ApiTokenHeader;
