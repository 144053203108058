import validator from 'validator';

export const validate = (name, value: string, skipNameValidation = false) => {
  switch (name) {
    case 'email':
      if (value.includes(',')) {
        const emails = value.split(',');
        const invalid = emails.findIndex((email) => !validator.isEmail(email));
        if (invalid !== -1) {
          return 'Enter valid email addresses';
        }
      } else if (!validator.isEmail(value)) {
        return 'Enter a valid email address';
      }
      break;

    case 'firstName':
      if (!skipNameValidation) {
        if (!validator.isLength(value.trim(), { min: 1 })) {
          return 'Enter at least one character';
        }
        if (!validator.isLength(value, { max: 50 })) {
          return 'First name should be at most 50 characters long';
        }
      }
      break;

    case 'lastName':
      if (!skipNameValidation) {
        if (!validator.isLength(value.trim(), { min: 1 })) {
          return 'Enter at least one character';
        }
        if (!validator.isLength(value, { max: 50 })) {
          return 'Last name should be at most 50 characters long';
        }
      }
      break;

    default:
      break;
  }

  return '';
};
