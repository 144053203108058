import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../shared/design-system/components/atoms/icon';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { SMTPIMAP } from '../../../../../enums/smtp-or-imap';
import { openUrl } from '../../../../../../../shared/utils/open-url';
import { supportUrls } from '../../../../../../../shared/utils/urls';

const Tips = ({
  accountType,
  isConnectionError,
  errorDetails,
  onShowErrorLog,
  selectGmailAsDefault = false,
}) => {
  const { t } = useTranslation();

  // Tips Data
  const tips = {
    [SMTPIMAP.SMTP]: ['smtp_tips_one', 'smtp_tips_two', 'smtp_tips_three'],
    [SMTPIMAP.IMAP]: selectGmailAsDefault
      ? ['imap_gmail_tips_one', 'imap_gmail_tips_two']
      : ['imap_tips_one', 'imap_tips_two'],
  };

  const getBulletView = (bullets) => (
    <div className="tips-sections">
      <div className="tip-section-points">
        <ol className="regular-1 gray-txt-15">
          {bullets?.map((tip) => (
            <li key={tip}>{isConnectionError ? tip : t(`messages.${tip}`)}</li>
          ))}
        </ol>
      </div>
    </div>
  );

  const getBulletViewForGmail = () => (
    <div className="tips-sections">
      <div className="tip-section-points">
        <ol className="regular-1 gray-txt-15">
          <li>
            {t('messages.smtp_gmail_tips_one_a')}{' '}
            <a
              href={supportUrls.howToConnectGmailAccountWithSmtpImapStep3}
              target="_blank"
              rel="noreferrer"
            >
              {t('messages.smtp_gmail_tips_one_b')}
            </a>{' '}
            {t('messages.smtp_gmail_tips_one_c')}
          </li>
          <li>{t('messages.smtp_gmail_tips_two')}</li>
          <li>
            <a
              href={supportUrls.howToConnectGmailAccountWithSmtpImap}
              target="_blank"
              rel="noreferrer"
            >
              {t('messages.smtp_gmail_tips_three_a')}
            </a>{' '}
            {t('messages.smtp_gmail_tips_three_b')}
          </li>
        </ol>
      </div>
    </div>
  );

  const renderTipsBulletView = () =>
    selectGmailAsDefault && accountType === SMTPIMAP.SMTP
      ? getBulletViewForGmail()
      : getBulletView(tips[accountType]);

  return (
    <div
      className={`tips-container p-3 ${
        isConnectionError ? 'red-17' : 'blue-17'
      }`}
    >
      {isConnectionError ? (
        <>
          <div className="tips-title">
            <p className="semibold-1 red-txt-12">{`${accountType} Error: ${errorDetails?.title}`}</p>
          </div>

          {/* Bullets View */}
          {getBulletView(errorDetails?.troubleshoot)}
          <div className="d-flex justify-content-between bs-mt-20 tips-footer">
            <span
              role="button"
              className="link regular-1 font-medium"
              {...accessibleOnClick(() => openUrl(errorDetails?.faqLink))}
            >
              Learn More
            </span>
            <span
              role="button"
              className="link-2 regular-1 font-medium"
              {...accessibleOnClick(onShowErrorLog)}
            >
              Show error log
              <Icon identifier="external" className="ml-1" />
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="tips-title">
            <p className="title-txt semibold-1">{`Steps to connect ${accountType}`}</p>
          </div>

          {/* Bullets View */}
          {renderTipsBulletView()}
        </>
      )}
    </div>
  );
};

export default Tips;
