export enum EmailAccountWarmupSortKey {
  Deliverability = 'deliverability',
  SentToday = 'sent-today',
  ReceivedToday = 'received-today',
}

export enum EmailAccountWarmupOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum EmailWarmupStatus {
  Stopped = 0,
  Running = 1,
  Suspended = 2,
  Paused = 3,
}

export enum ErrorMessage {
  DefaultObjObjError = '"[object Object]"',
  DisplayErrorMessage = 'Please contact our support team at support@trulyinbox.com',
}
