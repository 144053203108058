import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../shared/design-system/components/atoms/modal';
import Button from '../../../shared/design-system/components/atoms/button';
import { accessibleOnClick } from '../../../shared/utils/accessible-on-click';

type IProps = {
  show: boolean;
  firstName: string;
  onViewSampleSequence: VoidFunction;
  onCreateNewSequence: VoidFunction;
  onClose: VoidFunction;
  updateWatchVideoProfileProgressStep: VoidFunction;
  isGettingStartedVideModalShowThroughIcon: boolean;
  isCreateSequenceRequestPending: boolean;
  isViewSampleSequenceRequestPending: boolean;
};

const WatchVideoModal: React.FC<IProps> = ({
  show,
  firstName,
  onViewSampleSequence,
  onCreateNewSequence,
  onClose,
  updateWatchVideoProfileProgressStep,
  isGettingStartedVideModalShowThroughIcon,
  isViewSampleSequenceRequestPending,
  isCreateSequenceRequestPending,
}) => {
  const { t } = useTranslation();
  const viewSampleSequenceBtnRef = useRef<HTMLButtonElement>();

  const generateWelcomeText = () => {
    if (isGettingStartedVideModalShowThroughIcon) {
      if (firstName && firstName.length > 15) {
        return `Hi ${firstName.substring(0, 15)}...!👋, Welcome to Saleshandy`;
      }
      return `Hi ${firstName}!👋, Welcome to Saleshandy`;
    }

    return t('messages.learn_create_sequence');
  };

  useEffect(() => {
    if (show && viewSampleSequenceBtnRef.current) {
      viewSampleSequenceBtnRef.current.focus();
    }
  }, [viewSampleSequenceBtnRef, show]);

  useEffect(() => {
    const firstScript = document.createElement('script');
    firstScript.setAttribute(
      'src',
      'https://fast.wistia.com/embed/medias/juk2lpf0k1.jsonp',
    );
    firstScript.setAttribute('async', '');

    const secondScript = document.createElement('script');
    secondScript.setAttribute(
      'src',
      'https://fast.wistia.com/assets/external/E-v1.js',
    );
    secondScript.setAttribute('async', '');

    document.body.appendChild(firstScript);
    document.body.appendChild(secondScript);
  }, []);

  return (
    <Modal
      show={show}
      submitButtonRef={viewSampleSequenceBtnRef}
      titleContent={generateWelcomeText()}
      submitButtonText={t('labels.view_sample_sequence')}
      submitButtonClassName="watch-video-modal-btn"
      onSubmit={onViewSampleSequence}
      showCloseIcon
      cancelButtonText={t('labels.create_new_sequence')}
      onCancel={onCreateNewSequence}
      onClose={onClose}
      onHide={onClose}
      className="watch-video-modal"
      backdrop="static"
      cancelButtonVarient={Button.Variant.Primary}
      hideFooter={!isGettingStartedVideModalShowThroughIcon}
      isSubmitLoading={isViewSampleSequenceRequestPending}
      isSubmitDisabled={
        isViewSampleSequenceRequestPending || isCreateSequenceRequestPending
      }
      isCancelLoading={isCreateSequenceRequestPending}
      isCancelDisabled={
        isViewSampleSequenceRequestPending || isCreateSequenceRequestPending
      }
    >
      <div className="watch-video-modal-body">
        <p className="watch-video-modal-text regular-2">
          {t('messages.watch_video_description_1')}{' '}
          {t('messages.watch_video_description_2')}
        </p>

        <div
          className="wistia_responsive_padding"
          style={{ padding: '43.96% 0 0 0', position: 'relative' }}
          {...accessibleOnClick(updateWatchVideoProfileProgressStep)}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <div
              className="wistia_embed wistia_async_juk2lpf0k1"
              style={{ height: '100%', position: 'relative', width: '100%' }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: '100%',
                  left: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  transition: 'opacity 200ms',
                  width: '100%',
                }}
              >
                <img
                  src="https://fast.wistia.com/embed/medias/2jl6r9uwam/swatch"
                  style={{
                    filter: 'blur(5px)',
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WatchVideoModal;
