import React, { useState } from 'react';
import { OverlayTarget, OverlayTargetProps } from '../overlay-target';
import { Placement } from '../enums';
import { generatePopoverConfirmId } from '../helpers';
import { PopoverConfirmProps, PopoverConfirm } from '../../overlay';

type OverlayTargetPopoverConfirmProps = Omit<
  OverlayTargetProps,
  'overlay' | 'children'
> &
  Partial<Pick<PopoverConfirmProps, 'id'>> &
  Pick<
    PopoverConfirmProps,
    'content' | 'footer' | 'rePositionOnChange' | 'className'
  >;

export const OverlayTargetPopoverConfirm: React.FC<OverlayTargetPopoverConfirmProps> = ({
  id: _id,
  placement,

  content,
  footer,
  rePositionOnChange,
  className,

  ...rest
}) => {
  const [id] = useState(_id || generatePopoverConfirmId());
  const popoverConfirm = (
    <PopoverConfirm
      id={id}
      content={content}
      footer={footer}
      rePositionOnChange={rePositionOnChange}
      className={className}
    />
  );
  return (
    <OverlayTarget {...rest} placement={placement}>
      {popoverConfirm}
    </OverlayTarget>
  );
};

OverlayTargetPopoverConfirm.defaultProps = {
  placement: Placement.Right,
};
