import React from 'react';
import './validation-error.scss';

type ValidationErrorsProps = {
  messages: string[];
};

const ValidationError: React.FC<ValidationErrorsProps> = ({ messages }) => (
  <ul className="notification-validation-errors">
    {messages.map((message, index) => (
      <li key={index}>{message}</li>
    ))}
  </ul>
);

export default ValidationError;
