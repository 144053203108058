import React from 'react';

export const NewFeature = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.46168 9.1506C9.80078 8.72019 10.5093 8.28888 10.9844 3.16162C10.9849 3.02223 11.1005 2.90918 11.2418 2.90918C11.3837 2.90918 11.4987 3.02278 11.4998 3.16162C11.9022 8.25118 12.6135 8.66452 16.0225 9.1506C16.194 9.1517 16.333 9.28835 16.333 9.45463C16.333 9.62147 16.194 9.75812 16.0225 9.75922C12.5328 10.3478 11.839 10.7607 11.4998 15.7476C11.4993 15.887 11.3837 16.0001 11.2424 16.0001C11.1011 16.0001 10.9855 15.887 10.9844 15.7482C10.5672 10.6526 9.87142 10.2082 6.46168 9.75922C6.2908 9.75812 6.15119 9.62147 6.15119 9.45463C6.15119 9.28835 6.2908 9.1517 6.46168 9.1506Z"
      fill="#FD853A"
    />
    <path
      d="M1.9208 12.9556C3.35184 12.7643 3.65551 12.5726 3.8591 10.2938C3.85934 10.2319 3.90888 10.1816 3.96943 10.1816C4.03022 10.1816 4.07952 10.2321 4.08 10.2938C4.25244 12.5559 4.5573 12.7396 6.0183 12.9556C6.09177 12.9561 6.15137 13.0168 6.15137 13.0907C6.15137 13.1649 6.09177 13.2256 6.0183 13.2261C4.52269 13.4877 4.22535 13.6712 4.08 15.8876C4.07976 15.9496 4.03022 15.9998 3.96967 15.9998C3.90912 15.9998 3.85958 15.9496 3.8591 15.8879C3.68033 13.6232 3.38211 13.4256 1.9208 13.2261C1.84756 13.2256 1.78773 13.1649 1.78773 13.0907C1.78773 13.0168 1.84756 12.9561 1.9208 12.9556Z"
      fill="#FD853A"
    />
    <path
      d="M0.555006 4.16095C2.94008 3.87401 3.44619 3.58646 3.78551 0.168297C3.78591 0.0753676 3.86848 0 3.96939 0C4.07071 0 4.15288 0.0757335 4.15368 0.168297C4.44108 3.56133 4.94917 3.83689 7.38418 4.16095C7.50664 4.16168 7.60596 4.25278 7.60596 4.36364C7.60596 4.47486 7.50664 4.56596 7.38418 4.56669C4.89149 4.95906 4.39593 5.23431 4.15368 8.55898C4.15328 8.65191 4.07071 8.72727 3.96979 8.72727C3.86888 8.72727 3.78631 8.65191 3.78551 8.55934C3.48756 5.1623 2.99053 4.86601 0.555006 4.56669C0.432949 4.56596 0.33323 4.47486 0.33323 4.36364C0.33323 4.25278 0.432949 4.16168 0.555006 4.16095Z"
      fill="#FD853A"
    />
  </svg>
);
