import { RouteComponentProps, withRouter } from 'react-router';
import { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import SignUpQuestionsData from './signup-questions-data';
import { onboardingDetailsRequest } from '../../../signup/extra-actions';
import { OnBoardingRequest } from '../../../../types';
import { getUserSettingsRequest } from '../../../../../home/extra-actions';

const mapStateToProps = (state: RootState) => ({
  onBoardingStatus: state.signup.onBoardingDetailsRequest.status,
  onBoardingError: state.signup.onBoardingDetailsRequest.error,
  onBoardingMessage: state.signup.onBoardingDetailsRequest.message,
  token: state.signup.token,
  meta: state.home.meta,
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
  userRole: state.home.role,
  trackingId: state.signup.trackingId,
  firstName: state.home.firstName,
  lastName: state.home.lastName,
  email: state.home.email,
});

const mapDispatchToProps = {
  sendOnboardingRequest: (payload: OnBoardingRequest) =>
    onboardingDetailsRequest(payload),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ redirectedFrom?: string }> & {
    t: (x: string) => ReactNode;
  };

export default withTranslation()(withRouter(connector(SignUpQuestionsData)));
