import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import { IProps } from './types';
import ErrorBoundaryUI from '../../error-boundary/error-boundary';

const ErrorBoundaryWrapper: React.FC<IProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ErrorBoundary fallback={<ErrorBoundaryUI t={t} />} showDialog>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
