import React from 'react';
import ReactDOM from 'react-dom';
// sentry dependencies
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import store from './store';
import history from './shared/history';
import Root from './root';
import './assets/css/design-system.scss';
import './i18n';
import ErrorBoundaryWrapper from './shared/components/error-boundary-wrapper';

declare global {
  interface Window {
    analytics: any;
    smartlook: any;
    fwcrm: any;
    Froged: any;
    Beacon: any;
    Headway: any;
    churnkey: any;
    Rewardful: any;
    rewardful: any;
    intercomSettings: any;
    Intercom: any;
    profitwell: any;
  }
}

// Enable sentry reporting for webui
if (process.env.REACT_APP_ENABLE_SENTRY) {
  Sentry.init({
    dsn:
      'https://3ffad9e97e3a3720acafd09442407960@o557841.ingest.sentry.io/4505622886219776',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACE_RATE),
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: Number(
      process.env.REACT_APP_SENTRY_REPLAY_SESSION_RATE,
    ),
    /* 
      If the entire session is not sampled, use the below sample rate to sample
      sessions when an error occurs. 
    */
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <ErrorBoundaryWrapper>
    <Root store={store} history={history} />
  </ErrorBoundaryWrapper>,
  document.getElementById('root'),
);
