import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Images } from '../../../../shared/app-constants';
import Banner from '../../../../shared/design-system/components/atoms/banner';
import ProfileDropdown from '../../../../shared/design-system/components/atoms/profile-dropdown';
import type { IProps } from './header-container';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import HelpscoutBeaconEvents from '../../../../shared/enums/helpscout-beacon-events';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import {
  Navigations,
  getKeyByValue,
  getTrailPlanWarningString,
  navigationRouter,
} from './utils/helper';
import { getUpgradePlanCTA } from '../../../../shared/utils/get-upgrade-cta';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { redirectWithoutRefresh } from '../../../../shared/utils';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { IntercomEvents } from '../../../../shared/enums/intercom-events';
import history from '../../../../shared/history';
import { OverlayTooltip } from '../../../../shared/design-system/components/overlay';

const Header: React.FC<IProps> = ({
  pageHeaderBannerMessage,
  ctaText,
  ctaURL,
  bannerTextTwo,
  paymentActionType,
  target,

  markNotificationAsRead,
  userProfile,
  sendGetProfileRequest,
  getUserNotificationsRequestStatus,
  agencyConfig,

  trackingId,
  planName,
  getUserSettingsRequestStatus,
  meta,
  subscriptionDetails,

  activeEmailAccountsFeatureLimit,
  shAccountSendingFeatureLimit,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const route = history.location.pathname;

  const [notificationId] = useState<string>();
  const [subscriptionEndDays, setSubscriptionEndDays] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [activeNav, setActiveNav] = useState<string>(Navigations.EmailWarmUp);

  const setAnalyticsTrackData = () => {
    // Send data to analytics
    window.analytics.track({
      userId: trackingId,
      event: AnalyticsEvents.BeaconOpened,
      properties: {
        current_tab_url: document.location.href,
        current_plan: planName,
      },
    });
  };

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('on', HelpscoutBeaconEvents.OPEN, () => {
        setAnalyticsTrackData();
      });
    }
    if (window.Intercom) {
      window.Intercom(IntercomEvents.ON_SHOW, () => {
        setAnalyticsTrackData();
      });
    }
  }, [window.Beacon, window.Intercom]);

  useEffect(() => {
    // Initializing headwat change-log
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);

    const config = {
      selector: '#headway-badge',
      account: '7eoAWy',
      position: {
        y: 40,
        x: 0,
      },
    };
    script.onload = () => {
      window.Headway.init(config);
    };

    // Getting user profile
    sendGetProfileRequest();
  }, []);

  useEffect(() => {
    setActiveNav(getKeyByValue(navigationRouter, location.pathname));
  }, [route]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserNotificationsRequestStatus,
      onSuccess: () => {
        markNotificationAsRead(notificationId);
      },
    });
  }, [getUserNotificationsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onSuccess: () => {
        setSubscriptionPlan(subscriptionDetails?.planCode);
        setSubscriptionEndDays(
          Math.ceil(
            DateTime.fromJSDate(new Date(subscriptionDetails?.nextBillingAt), {
              zone: 'Etc/UTC',
            })
              .diff(DateTime.now().toUTC(), ['days'])
              .toObject().days,
          ),
        );
      },
    });
  }, [meta, subscriptionDetails]);

  const onRouteChange = (r: Navigations) => {
    setActiveNav(r);
    redirectWithoutRefresh(navigationRouter[r]);
  };

  return (
    <>
      <Row className="page-header d-flex justify-content-between">
        <div className="p-0">
          <div className="header-with-notification">
            <div
              className="logo-container"
              {...accessibleOnClick(() => redirectWithoutRefresh('/warmup'))}
            >
              <img src={Images.TrulyInbox} alt="logo" />
            </div>

            {Object.values(Navigations).map((value) => (
              <div
                key={value}
                className={`navigation ${
                  activeNav === value && 'navigation--active'
                }`}
                {...accessibleOnClick(() => onRouteChange(value))}
              >
                <span className="regular-2 font-medium popover-arrow-color-txt">
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="profile d-flex justify-content-end">
          {/* Trail Plan Banner */}
          {(subscriptionPlan === SubscriptionPlans.TRIAL ||
            subscriptionPlan === SubscriptionPlans.FREE) && (
            <OverlayTooltip
              text={
                <span>
                  {`${activeEmailAccountsFeatureLimit} Active Email Account${
                    activeEmailAccountsFeatureLimit === 1 ? '' : 's'
                  }`}
                  <br />
                  {`${shAccountSendingFeatureLimit} ${
                    subscriptionPlan === SubscriptionPlans.FREE
                      ? 'Emails per day'
                      : 'Emails per day from all emails'
                  }`}
                </span>
              }
              className="tooltip-lg"
            >
              <div className="bs-banner mr-2 bs-banner-warning">
                <img
                  src={Images.AlertTriangle}
                  alt="warning"
                  className="mb--2"
                />

                <span className="regular-1 font-medium popover-arrow-color-txt ml-1 pointer">
                  {getTrailPlanWarningString({
                    t,
                    subscriptionEndDays,
                    subscriptionPlan,
                  })}{' '}
                </span>
                <span
                  role="button"
                  className="bs-banner__cta regular-1 font-medium popover-arrow-color-txt"
                  {...accessibleOnClick(() =>
                    redirectWithoutRefresh('/settings/billing/subscription'),
                  )}
                >
                  {t(`labels.${getUpgradePlanCTA()}`)}
                </span>
              </div>
            </OverlayTooltip>
          )}

          <span>
            <ProfileDropdown
              userProfile={userProfile}
              agencyConfig={agencyConfig}
              trackingId={trackingId}
            />
          </span>
        </div>
      </Row>
      {pageHeaderBannerMessage && (
        <div>
          <Banner
            pageHeaderBannerMessage={pageHeaderBannerMessage}
            style={{
              position: 'sticky',
              top: 0,
              marginBottom: '0%',
              borderRadius: '0%',
            }}
            ctaText={ctaText}
            ctaURL={ctaURL}
            target={target}
            bannerTextTwo={bannerTextTwo}
            paymentActionType={paymentActionType}
          />
        </div>
      )}
    </>
  );
};

export default Header;
