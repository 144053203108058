export enum ConflictAction {
  Overwrite = 'overwrite',
  NoUpdate = 'noUpdate',
  AddMissingFields = 'addMissingFields',
}

export enum ContactStatusFilterEnum {
  Total = 'total',
  Active = 'active',
  Open = 'open',
  Replied = 'replied',
  Bounced = 'bounced',
  Unsubscribed = 'unsubscribed',
  Finished = 'finished',
  Clicked = 'clicked',
}

export enum ContactStatusType {
  Active = 'active',
  Finished = 'finished',
  Replied = 'replied',
  Bounced = 'bounced',
  HardBounced = 'hardBounced',
  SoftBounced = 'softBounced',
  Unsubscribed = 'unsubscribed',
  Blacklisted = 'blacklisted',
  Open = 'open',
  Clicked = 'clicked',
  Subscribed = 'subscribed',
  Paused = 'paused',
  Resume = 'resume',
}

export enum ContactStatusTypeDisplayName {
  Total = 'Total',
  active = 'Active',
  open = 'Opened',
  replied = 'Replied',
  bounced = 'Bounced',
  unsubscribed = 'Unsubscribed',
  finished = 'Finished',
  clicked = 'Clicked',
  blacklisted = 'Blacklisted',
  subscribed = 'Subscribed',
  paused = 'Paused',
  resume = 'Resume',
}

export enum ContactVerificationStatus {
  Unverified = 'skip',
  Valid = 'valid',
  Risky = 'risky',
  Bad = 'bad',
}

export enum ContactVerificationStatusTypeDisplayName {
  Unverified = 'Unverified',
  Valid = 'Valid',
  Risky = 'Risky',
  Bad = 'Bad',
}

export enum StatusColor {
  COLOR_TOTAL = '#0C1D33',
  COLOR_ACTIVE = '#0004C9',
  COLOR_FINISH = '#389E0D',
  COLOR_OPEN = '#13C2C2',
  COLOR_CLICKED = '#9254DE',
  COLOR_REPLIED = '#FA8C16',
  COLOR_BOUNCED = '#FF0000',
  COLOR_UNSUBSCRIBED = '#0C1D33',
}
export enum ContactTotal {
  TOTAL = 'total',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ProspectActivityTabs {
  All = 'all',
  Sent = 'email-sent',
  Opened = 'email-opened',
  Clicked = 'link-clicked',
  Replied = 'email-replied',
}
