import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { getAcademyTopicsProgressRequest } from '../../extra-actions';
import type { Topic } from '../../types';
import MasterClassLessons from './master-class-lessons';

const mapStateToProps = (state: RootState) => ({
  academyLessons: state.growthHub.getAcademyTopicsResponse,
  academyTopicsProgress: state.growthHub.getAcademyTopicsProgressResponse,
  updateAcademyTopicProgressRequestStatus:
    state.growthHub.updateAcademyTopicProgressRequest.status,
});

const mapDispatchToProps = {
  getAcademyTopicsProgress: () => getAcademyTopicsProgressRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    onTopicSelect: (topic: Topic, lessonId: number) => void;
    currentLessonId: number;
    currentTopicId: number;
  };

export default withRouter(connector(MasterClassLessons));
