import { KeyCodes, KeyValues } from '../types/keyboard-keys';

export const onKeyDown = (handler) => (e) => {
  if (
    e.keyCode === KeyCodes.ENTER &&
    e.key.trim().toLowerCase() === KeyValues.ENTER
  ) {
    handler(e);
  }
};

export const accessibleOnClick = (handler, tabIndex?: number) => ({
  role: 'button',
  tabIndex: tabIndex || 0,
  onKeyDown: onKeyDown(handler),
  onClick: handler,
});
