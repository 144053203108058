import { AxiosResponse } from 'axios';
import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import { ReportSummaryFilter, SelectedSequenceRequest } from '../type';
import { ReportsSequenceStatsFilter } from '../components/reports-content/components/sequence-wise-report/types';

export const getSequenceList = async () =>
  api.get<ResponseSuccess>('sequences/list-owners', {
    params: { numberOfSteps: 'gt:0' },
  });

export const reportAllSequenceSummarisedStats = async ({
  sequenceIds,
  startDate,
  endDate,
}: ReportSummaryFilter) =>
  api.post<ResponseSuccess>('reports/sequence-summary', {
    sequenceIds,
    startDate,
    endDate,
  });

export const reportAllSequenceStats = async ({
  sequenceIds,
  startDate,
  endDate,
  page,
  limit,
  orderBy,
  sortOrder,
}: ReportsSequenceStatsFilter) =>
  api.post<ResponseSuccess>('reports/sequence-stats', {
    sequenceIds,
    startDate,
    endDate,
    page,
    limit,
    orderBy,
    sortOrder,
  });

export const exportAllSequenceStats = async ({
  sequenceIds,
  startDate,
  endDate,
}: ReportSummaryFilter): Promise<AxiosResponse<ResponseSuccess>> =>
  api.post<ResponseSuccess>('reports/sequence-stats/export', {
    sequenceIds,
    startDate,
    endDate,
  });

export const getUserTimeZone = async () =>
  api.get<ResponseSuccess>('/user/time-zone');

export const getSelectedSequences = async (payload: SelectedSequenceRequest) =>
  api.post<ResponseSuccess>('/reports/selected-sequence', payload);
