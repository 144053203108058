import React from 'react';
import { IProps } from './types';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';

const ScheduleHeader: React.FC<IProps> = ({
  headerName,
  buttonText,
  clickHandler,
  subscriptionPlan,
}) => (
  <div className="schedule-header">
    <div>
      <span className="semibold-3">{headerName}</span>
    </div>
    <div>
      {/* TODO: refactor subscription plan check condition */}
      {hasPermission(Permissions.SCHEDULE_WRITE) && (
        <Button
          variant={Button.Variant.Primary}
          onClick={clickHandler}
          disabled={subscriptionPlan === SubscriptionPlans.FREE}
          className="header-btn"
        >
          {buttonText}
        </Button>
      )}
    </div>
  </div>
);

export default ScheduleHeader;
