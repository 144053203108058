import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  getEmailAccountsInWarmup,
  getEmailAccountWarmupDeliverabilityReport,
  getEmailAccountWarmupList,
  getEmailAccountWarmupSentReceivedReport,
  getEmailAccountWarmupSettings,
  importEmailAccountCsv,
  saveEmailAccountWarmupSettings,
  turnOnOffEmailAccountWarmup,
} from './helpers/email-warmup.api';
import {
  EmailAccountId,
  EmailAccountWarmupFilters,
  EmailAccountWarmupSettingsPayload,
  EmailWarmupDeliverabilityPayload,
} from './types';

export const getEmailAccountsInWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountWarmupFilters,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountsInWarmup', async (args, thunkAPI) => {
  try {
    return await getEmailAccountsInWarmup(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountWarmupDeliverabilityReportRequest = createAsyncThunk<
  ResponseSuccess,
  EmailWarmupDeliverabilityPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/getEmailAccountWarmupDeliverabilityReport',
  async (args, thunkAPI) => {
    try {
      return await getEmailAccountWarmupDeliverabilityReport(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountWarmupListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountWarmupList', async (_args, thunkAPI) => {
  try {
    return await getEmailAccountWarmupList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountWarmupSentReceivedReportRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountId,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/getEmailAccountWarmupSentReceivedReport',
  async (args, thunkAPI) => {
    try {
      return await getEmailAccountWarmupSentReceivedReport(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountWarmupSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountId,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountWarmupSettings', async (args, thunkAPI) => {
  try {
    return await getEmailAccountWarmupSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const saveEmailAccountWarmupSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountWarmupSettingsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/saveEmailAccountWarmupSettings', async (args, thunkAPI) => {
  try {
    return await saveEmailAccountWarmupSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const turnOnOffEmailAccountWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountId,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/turnOnOffEmailAccountWarmup', async (args, thunkAPI) => {
  try {
    return await turnOnOffEmailAccountWarmup(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const importEmailAccountCsvRequest = createAsyncThunk<
  ResponseSuccess,
  { file: File },
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/import', async ({ file }, thunkAPI) => {
  try {
    return await importEmailAccountCsv(file);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
