import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import {
  createApiToken,
  getApiTokenList,
  revokeApiToken,
} from '../../helpers/api-token.api';
import { CreateApiTokenPayload, RevokeApiTokenPayload } from './types';

export const getApiTokenListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/getApiTokenList', async (args, thunkAPI) => {
  try {
    return await getApiTokenList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createApiTokenRequest = createAsyncThunk<
  ResponseSuccess,
  CreateApiTokenPayload,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/createApiTokenRequest', async ({ label }, thunkAPI) => {
  try {
    return await createApiToken(label);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const revokeApiTokenRequest = createAsyncThunk<
  ResponseSuccess,
  RevokeApiTokenPayload,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/revokeApiToken', async ({ tokenId }, thunkAPI) => {
  try {
    return await revokeApiToken(tokenId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
