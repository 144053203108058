import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import Login from './login';
import { loginRequest, hideError } from './login-slice';

const mapStateToProps = (state: RootState) => ({
  status: state.login.status,
  message: state.login.message,
  error: state.login.error,
  token: state.login.token,
  trackingId: state.login.trackingId,
  firstName: state.login.firstName,
  lastName: state.login.lastName,
  email: state.login.email,
  showError: state.login.showError,
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {
  hideError: () => hideError(),
  sendLoginRequest: (email: string, password: string) =>
    loginRequest({ email, password }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(Login);
