import React from 'react';
import TruncatedTextWithTooltip from '../../../../components/truncated-text-with-tooltip';
import { Permissions } from '../../../../utils/access-control/enums/permissions';
import hasPermission from '../../../../utils/access-control/has-permission';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import Icon from '../../atoms/icon';
import { Overlay, Placement, Tooltip } from '../../overlay';

const getContactName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  return lastName;
};

const iconStyle = { marginLeft: '.25rem', marginTop: '.15rem' };

const iconConfigs = {
  skip: {
    identifier: 'circle-check',
    toolTipText: 'Unverified',
    className: 'gray-txt-23',
  },
  valid: {
    identifier: 'circle-check',
    toolTipText: 'Valid',
    className: 'green-txt-14',
  },
  risky: {
    identifier: 'danger',
    toolTipText: 'Risky',
    className: 'orange-txt-6',
  },
  bad: {
    identifier: 'warning',
    toolTipText: 'Bad',
    className: 'red-txt-6',
  },
  unknown: {
    identifier: 'danger',
    toolTipText: 'Risky',
    className: 'red-txt-6',
  },
  inProgress: {
    identifier: 'rotate-clockwise',
    toolTipText: 'In Progress',
    className: 'gray-txt-23',
  },
};

const ContactNameField = ({ cell, onClick }) => {
  const field = {
    Email: '',
    'First Name': '',
    'Last Name': '',
  };
  cell.forEach((contactField) => {
    if (
      contactField.label === 'Email' ||
      contactField.label === 'First Name' ||
      contactField.label === 'Last Name'
    ) {
      field[contactField.label] = contactField.value;
    }
  });

  const iconConfig = iconConfigs[cell.verificationStatus];

  const getVerificationIcon = () => (
    <Icon
      identifier={iconConfig?.identifier}
      className={iconConfig?.className}
      style={iconStyle}
    />
  );

  const verificationToolTip = (
    <Overlay
      placement={Placement.Right}
      overlay={<Tooltip id={cell.id} text={iconConfig?.toolTipText} />}
    >
      {getVerificationIcon()}
    </Overlay>
  );

  if (field['First Name'] || field['Last Name']) {
    const contactName = getContactName(field['First Name'], field['Last Name']);
    return (
      <div
        className="contact-field-name-container"
        // * Using cell[0].id as the cell contains dynamic fields value
        // * for gettings row details, can't loop through as it doesn't
        // * have any fixed id or key
        {...accessibleOnClick(() => !!onClick && onClick(cell[0].id))}
      >
        <div className="name-field w-100">
          <div className="d-flex justify-content-between">
            <span className="mr-2">
              <TruncatedTextWithTooltip
                string={contactName}
                length={30}
                tooltipClassName="contact-field-name-tooltip"
              />
            </span>
          </div>
        </div>
        <div className="email-field">
          {`${field.Email}`}{' '}
          {hasPermission(Permissions.PROSPECT_UPDATE) &&
            iconConfig &&
            verificationToolTip}
        </div>
      </div>
    );
  }
  return (
    <div
      className="contact-field-name-container"
      // * Using cell[0].id as the cell contains dynamic fields value
      // * for gettings row details, can't loop through as it doesn't
      // * have any fixed id or key
      {...accessibleOnClick(() => !!onClick && onClick(cell[0].id))}
    >
      <div className="email-only-field">
        {`${field.Email}`}
        {hasPermission(Permissions.PROSPECT_UPDATE) &&
          iconConfig &&
          verificationToolTip}
      </div>
    </div>
  );
};

export default ContactNameField;
