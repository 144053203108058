import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';

export const getApiTokenList = async () =>
  api.get<ResponseSuccess>(`/api-token/`);

export const createApiToken = async (label: string) =>
  api.post<ResponseSuccess>(`/api-token`, { label });

export const revokeApiToken = async (tokenId: number) =>
  api.delete<ResponseSuccess>(`/api-token/${tokenId}`);
