import api from '../../../api';
import { PaginationQueryParams } from '../../../shared/types/request';
import { ResponseSuccess } from '../../../shared/types/response';
import { InviteUserRequestPayload } from '../types/request-payload';

export const getUsersList = async (queryParams: PaginationQueryParams) =>
  api.get<ResponseSuccess>(`/team/members`, { params: queryParams });

export const inviteUser = async (payload: InviteUserRequestPayload) =>
  api.post<ResponseSuccess>(`/team/invite-user`, payload);

export const resendInvitation = async (userId: number) =>
  api.patch<ResponseSuccess>(`/team/${userId}/resend-invitation`);

export const cancelInvitation = async (userId: number) =>
  api.patch<ResponseSuccess>(`/team/${userId}/cancel-invitation`);

export const deactivateUser = async (userId: number) =>
  api.patch<ResponseSuccess>(`/team/${userId}/deactivate`);

export const reactivateUser = async (userId: number) =>
  api.patch<ResponseSuccess>(`/team/${userId}/reactivate`);

export const deleteUser = async (userId: number) =>
  api.delete<ResponseSuccess>(`/team/${userId}/user`);

export const getAllUsersList = async () =>
  api.get<ResponseSuccess>(`/team/members-list`);
