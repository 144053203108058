import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  getUserNotificationsRequest,
  getUserUnreadNotificationsRequest,
  markNotificationsAsReadRequest,
} from '../../extra-actions';
import Header from './header';
import {
  markNotificationAsRead,
  showGettingStartedVideoModal,
} from '../../home-slice';
import { SequenceIdRouteComponentProps } from '../../../sequence/types';
import { getMyProfileRequest } from '../../../settings/components/my-profile/extra-actions';

const mapStateToProps = (state: RootState) => ({
  notifications: state.home.notifications,
  pageHeaderBannerMessage: state.pageHeader.message,
  subscriptionDetails: state.home.subscription,
  ctaURL: state.pageHeader.CTAUrl,
  ctaText: state.pageHeader.CTAText,
  bannerTextTwo: state.pageHeader.bannerMessageTwo,
  paymentActionType: state.pageHeader.paymentActionType,
  target: state.pageHeader.target,
  notificationCount: state.home.notificationCount,
  userProfile: state.myProfile.myProfile,
  getUserNotificationsRequestStatus:
    state.home.markNotificationsAsReadRequest.status,
  agencyConfig: state.home.agencyConfig,
  userHasUnreadNotification: state.home.userHasUnreadNotification,
  trackingId: state.home.trackingId,
  planName: state.home.subscription?.planName,
  planType: state.home?.subscription?.planType,
  planCode: state.home?.subscription?.planCode,
  remainingProspectLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'PROSPECT.ADD'
    ],
    10,
  ),
  remainingEmailSentLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'EMAIL.ADD'
    ],
    10,
  ),
  activeEmailAccountsFeatureLimit: parseInt(
    state.home.getPostLoadMetaResponse?.featureLimits?.['WARMUP.ENABLE'],
    10,
  ),
  shAccountSendingFeatureLimit: parseInt(
    state.home.getPostLoadMetaResponse?.featureLimits?.[
      'WHOLE.SH.MAX.SENDING.LIMIT'
    ],
    10,
  ),

  meta: state.home.meta,
  firstName: state.home.adminDetails?.firstName,
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
});

const mapDispatchToProps = {
  sendGetUserNotificationRequest: (page: number) =>
    getUserNotificationsRequest(page),
  sendMarkNotificationAsReadRequest: (notificationId?: string) =>
    markNotificationsAsReadRequest(notificationId),
  markNotificationAsRead: (notificationId?: string) =>
    markNotificationAsRead({ notificationId }),
  sendGetProfileRequest: () => getMyProfileRequest(),
  sendGetUserUnreadNotification: () => getUserUnreadNotificationsRequest(),
  showGettingStartedVideoModal: (show: boolean) =>
    showGettingStartedVideoModal({ show }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  SequenceIdRouteComponentProps;

export default withRouter(connector(Header));
