import React from 'react';
import { produce } from 'immer';
import { withTranslation } from 'react-i18next';
import { IProps, IState } from './types';
import Input from '../../../../../../shared/design-system/components/input';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import { validate } from './validator';
import Alert from '../../../../../../shared/design-system/components/alert';
import { Images } from '../../../../../../shared/app-constants';

class InviteMembersModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: {
        email: '',
        firstName: '',
        lastName: '',
      },
      errors: {
        email: '',
        firstName: '',
        lastName: '',
      },
      dirty: {
        email: false,
        firstName: false,
        lastName: false,
      },
    };

    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onInputChange(value: string, event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;
    this.setState(
      produce((draft: IState) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onSubmitHandler() {
    const { dirty, errors, values } = this.state;
    const { onSubmit, agencyConfig } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errors);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(
        key,
        values[key],
        !agencyConfig?.showNameFieldsInInviteModal,
      );
      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (isError) {
      this.setState({ errors: errorsRef, dirty: dirtyRef });
      return;
    }

    const payload = {
      emails: values.email.split(','),
      firstName: values.firstName,
      lastName: values.lastName,
    };

    onSubmit(payload);
  }

  render() {
    const { values, errors } = this.state;
    const { show, onClose, isLoading, agencyConfig, t } = this.props;
    const emailInputLabel = `Email address ${
      agencyConfig?.showNameFieldsInInviteModal
        ? ''
        : '(for multiple, separate with comma):'
    }`;

    return (
      <Modal
        show={show}
        className="settings-general-modal invite-member-modal"
        titleContent="Invite Members"
        onClose={onClose}
        onSubmit={this.onSubmitHandler}
        isSubmitDisabled={isLoading}
        isSubmitLoading={isLoading}
        backdrop="static"
        showCloseIcon
        extraModalProps={{
          'aria-labelledby': 'contained-modal-title-vcenter',
          centered: true,
        }}
      >
        <div className="modal-form-content">
          {agencyConfig?.showNameFieldsInInviteModal && (
            <>
              <Alert
                contentContainer={
                  <div className="ml-2 d-flex align-items-center h-100">
                    <span>{t('messages.view_only_access_to_user')}</span>
                  </div>
                }
                variant={Alert.Variant.Warning}
                theme={Alert.Theme.New}
                svgIcon={Images.InfoCircleYellow}
              />
              <div className="row">
                <div className="col-md-6">
                  <Input
                    name="firstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    value={values.firstName}
                    variant={errors.firstName && Input.Variant.Error}
                    caption={errors.firstName}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    autoFocus
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={values.lastName}
                    variant={errors.lastName && Input.Variant.Error}
                    caption={errors.lastName}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    autoFocus
                  />
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col">
              <Input
                name="email"
                label={emailInputLabel}
                placeholder="Enter Email Address"
                value={values.email}
                variant={errors.email && Input.Variant.Error}
                caption={errors.email}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="email"
                autoFocus
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(InviteMembersModal);
