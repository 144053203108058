import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  connectEmailAccountRequest,
  verifyEmailAccountRequest,
  setDefaultEmailAccountRequest,
  disconnectEmailAccountRequest,
  getEmailAccountsRequest,
  deleteEmailAccountRequest,
  updateEmailAccountRequest,
  getEmailAccountRequest,
  getEmailAccountSettingsRequest,
  updateEmailAccountRampUpSettingsRequest,
  fetchEmailAccountHealthScoreRequest,
  testSMTPConnectionRequest,
  testIMAPConnectionRequest,
  connectSmtpImapAccountRequest,
  getSmtpImapAccountDetailsRequest,
  updateSmtpImapAccountDetailsRequest,
  emailTimeIntervalSettingsRequest,
} from './extra-actions';
import {
  EmailAccount,
  EmailHealthScoreResult,
} from '../../types/email-account';
import { EmailAccountSettings } from '../../types/email-account-settings';
import {
  ConnectSmtpImapAccountResponse,
  GetSMTPIMAPEmailDetailsResponse,
  TestConnectionResponse,
} from '../../types/smtp-imap';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  connectEmailAccountRequest: RequestState;
  verifyEmailAccountRequest: RequestState;
  setDefaultEmailAccountRequest: RequestState;
  disconnectEmailAccountRequest: RequestState;
  getEmailAccountsRequest: RequestState;
  deleteEmailAccountRequest: RequestState;
  updateEmailAccountRequest: RequestState;
  getEmailAccountRequest: RequestState;
  getEmailAccountSettingsRequest: RequestState;
  updateEmailAccountRampUpSettingsRequest: RequestState;
  deleteMultipleEmailAccountRequest: RequestState;
  fetchEmailAccountHealthScoreRequest: RequestState;
  testSMTPConnectionRequest: RequestState;
  testIMAPConnectionRequest: RequestState;
  connectSmtpImapAccountRequest: RequestState;
  getSmtpImapAccountDetailsRequest: RequestState;
  updateSmtpImapAccountDetailsRequest: RequestState;
  emailTimeIntervalSettingsRequest: RequestState;
  authUrl: string;
  emailAccounts: EmailAccount[];
  emailAccount: EmailAccount;
  emailAccountSettings: EmailAccountSettings[];
  isLoading: boolean;
  checked: Record<number, boolean>;
  emailHealthScoreResult: EmailHealthScoreResult;
  testSMTPConnectionResponse: TestConnectionResponse;
  testIMAPConnectionResponse: TestConnectionResponse;
  connectSmtpImapAccountResponse: ConnectSmtpImapAccountResponse;
  sendGetSmtpImapAccountDetailsResponse: GetSMTPIMAPEmailDetailsResponse;
  sendUpdateSmtpImapAccountDetailsResponse: any;
}

const initialState: State = {
  connectEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  verifyEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  setDefaultEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  disconnectEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateEmailAccountRampUpSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteMultipleEmailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  fetchEmailAccountHealthScoreRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  testSMTPConnectionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  testIMAPConnectionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  connectSmtpImapAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSmtpImapAccountDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSmtpImapAccountDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailTimeIntervalSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  authUrl: '',
  emailAccounts: [],
  emailAccount: null,
  emailAccountSettings: [],
  isLoading: false,
  checked: {},
  emailHealthScoreResult: null,
  testSMTPConnectionResponse: null,
  testIMAPConnectionResponse: null,
  connectSmtpImapAccountResponse: null,
  sendGetSmtpImapAccountDetailsResponse: null,
  sendUpdateSmtpImapAccountDetailsResponse: null,
};

const emailAccountSlice = createSlice({
  name: 'emailAccount',
  initialState,
  reducers: {
    changeEmailAccountStatus: (state, action) => {
      state.emailAccount.status = action.payload;
    },
    checkedChange: (state, action) => {
      state.checked[action.payload.emailAccountId] = action.payload.checked;
    },
    checkChangeAll: (state, action) => {
      const keys = Object.keys(state.checked);
      keys.forEach((key) => {
        state.checked[key] = action.payload.checked;
      });
    },
    resetEmailHeathScoreState: (state) => {
      state.fetchEmailAccountHealthScoreRequest =
        initialState.fetchEmailAccountHealthScoreRequest;
      state.emailHealthScoreResult = initialState.emailHealthScoreResult;
    },
    resetEmailAccountRampUpSettingsRequestState: (state) => {
      state.updateEmailAccountRampUpSettingsRequest =
        initialState.updateEmailAccountRampUpSettingsRequest;
    },
    resetEmailAccountAndSettings: (state) => {
      state.emailAccount = initialState.emailAccount;
      state.emailAccountSettings = initialState.emailAccountSettings;
      state.emailTimeIntervalSettingsRequest =
        initialState.emailTimeIntervalSettingsRequest;
    },
    resetUpdateSmtpImapAccountDetails: (state) => {
      state.getSmtpImapAccountDetailsRequest =
        initialState.getSmtpImapAccountDetailsRequest;
      state.updateSmtpImapAccountDetailsRequest =
        initialState.updateSmtpImapAccountDetailsRequest;
      state.sendGetSmtpImapAccountDetailsResponse =
        initialState.sendGetSmtpImapAccountDetailsResponse;
      state.sendUpdateSmtpImapAccountDetailsResponse =
        initialState.sendUpdateSmtpImapAccountDetailsResponse;
    },
    resetSmtpImapTestConnection: (state) => {
      state.testSMTPConnectionRequest = initialState.testSMTPConnectionRequest;
      state.testIMAPConnectionRequest = initialState.testIMAPConnectionRequest;
      state.testSMTPConnectionResponse =
        initialState.testSMTPConnectionResponse;
      state.testIMAPConnectionResponse =
        initialState.testIMAPConnectionResponse;
    },
    resetSmtpIMapConnectResponse: (state) => {
      state.connectSmtpImapAccountRequest =
        initialState.connectSmtpImapAccountRequest;
      state.connectSmtpImapAccountResponse =
        initialState.connectSmtpImapAccountResponse;
      state.sendGetSmtpImapAccountDetailsResponse =
        initialState.sendGetSmtpImapAccountDetailsResponse;
    },
  },
  extraReducers: (builder) => {
    // Get Authorization url
    builder.addCase(connectEmailAccountRequest.pending, (state) => {
      state.connectEmailAccountRequest.status = RequestStatus.Pending;
      state.connectEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(connectEmailAccountRequest.fulfilled, (state, action) => {
      state.connectEmailAccountRequest.status = RequestStatus.Succeeded;
      state.connectEmailAccountRequest.message = action.payload.message;
      state.authUrl = action.payload.payload.authUrl;
      state.isLoading = false;
    });
    builder.addCase(connectEmailAccountRequest.rejected, (state, action) => {
      state.connectEmailAccountRequest.status = RequestStatus.Failed;
      state.connectEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Add and reconnect email account
    builder.addCase(verifyEmailAccountRequest.pending, (state) => {
      state.verifyEmailAccountRequest.status = RequestStatus.Pending;
      state.verifyEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(verifyEmailAccountRequest.fulfilled, (state, action) => {
      state.verifyEmailAccountRequest.status = RequestStatus.Succeeded;
      state.verifyEmailAccountRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(verifyEmailAccountRequest.rejected, (state, action) => {
      state.verifyEmailAccountRequest.status = RequestStatus.Failed;
      state.verifyEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Make a default email account
    builder.addCase(setDefaultEmailAccountRequest.pending, (state) => {
      state.setDefaultEmailAccountRequest.status = RequestStatus.Pending;
      state.setDefaultEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      setDefaultEmailAccountRequest.fulfilled,
      (state, action) => {
        state.setDefaultEmailAccountRequest.status = RequestStatus.Succeeded;
        state.setDefaultEmailAccountRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(setDefaultEmailAccountRequest.rejected, (state, action) => {
      state.setDefaultEmailAccountRequest.status = RequestStatus.Failed;
      state.setDefaultEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Revoke email account
    builder.addCase(disconnectEmailAccountRequest.pending, (state) => {
      state.disconnectEmailAccountRequest.status = RequestStatus.Pending;
      state.disconnectEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      disconnectEmailAccountRequest.fulfilled,
      (state, action) => {
        state.disconnectEmailAccountRequest.status = RequestStatus.Succeeded;
        state.disconnectEmailAccountRequest.message = action.payload.message;
        state.isLoading = false;
        if (state.emailAccount) {
          state.emailAccount = {
            ...state.emailAccount,
            status: action.payload.payload.status,
          };
        }
      },
    );
    builder.addCase(disconnectEmailAccountRequest.rejected, (state, action) => {
      state.disconnectEmailAccountRequest.status = RequestStatus.Failed;
      state.disconnectEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Get Email Accounts
    builder.addCase(getEmailAccountsRequest.pending, (state) => {
      state.getEmailAccountsRequest.status = RequestStatus.Pending;
      state.getEmailAccountsRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getEmailAccountsRequest.fulfilled, (state, action) => {
      state.getEmailAccountsRequest.status = RequestStatus.Succeeded;
      state.getEmailAccountsRequest.message = action.payload.message;
      state.emailAccounts = action.payload.payload;
      state.isLoading = false;

      const checked = {};
      action.payload.payload?.forEach((emailAccount) => {
        checked[emailAccount.id] = false;
      });

      state.checked = checked;
    });
    builder.addCase(getEmailAccountsRequest.rejected, (state, action) => {
      state.getEmailAccountsRequest.status = RequestStatus.Failed;
      state.getEmailAccountsRequest.error = action.payload;
      state.isLoading = false;
    });

    // Delete email account
    builder.addCase(deleteEmailAccountRequest.pending, (state) => {
      state.deleteEmailAccountRequest.status = RequestStatus.Pending;
      state.deleteEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(deleteEmailAccountRequest.fulfilled, (state, action) => {
      state.deleteEmailAccountRequest.status = RequestStatus.Succeeded;
      state.deleteEmailAccountRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteEmailAccountRequest.rejected, (state, action) => {
      state.deleteEmailAccountRequest.status = RequestStatus.Failed;
      state.deleteEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update email account
    builder.addCase(updateEmailAccountRequest.pending, (state) => {
      state.updateEmailAccountRequest.status = RequestStatus.Pending;
      state.updateEmailAccountRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(updateEmailAccountRequest.fulfilled, (state, action) => {
      state.updateEmailAccountRequest.status = RequestStatus.Succeeded;
      state.updateEmailAccountRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(updateEmailAccountRequest.rejected, (state, action) => {
      state.updateEmailAccountRequest.status = RequestStatus.Failed;
      state.updateEmailAccountRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update email account
    builder.addCase(
      updateEmailAccountRampUpSettingsRequest.pending,
      (state) => {
        state.updateEmailAccountRampUpSettingsRequest.status =
          RequestStatus.Pending;
        state.updateEmailAccountRampUpSettingsRequest.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      updateEmailAccountRampUpSettingsRequest.fulfilled,
      (state, action) => {
        state.updateEmailAccountRampUpSettingsRequest.status =
          RequestStatus.Succeeded;
        state.updateEmailAccountRampUpSettingsRequest.message =
          action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      updateEmailAccountRampUpSettingsRequest.rejected,
      (state, action) => {
        state.updateEmailAccountRampUpSettingsRequest.status =
          RequestStatus.Failed;
        state.updateEmailAccountRampUpSettingsRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Email Time Interval
    builder.addCase(emailTimeIntervalSettingsRequest.pending, (state) => {
      state.emailTimeIntervalSettingsRequest.status = RequestStatus.Pending;
      state.emailTimeIntervalSettingsRequest.error = null;
    });
    builder.addCase(
      emailTimeIntervalSettingsRequest.fulfilled,
      (state, action) => {
        state.emailTimeIntervalSettingsRequest.status = RequestStatus.Succeeded;
        state.emailTimeIntervalSettingsRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      emailTimeIntervalSettingsRequest.rejected,
      (state, action) => {
        state.emailTimeIntervalSettingsRequest.status = RequestStatus.Failed;
        state.emailTimeIntervalSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    //   Get Email Account
    builder.addCase(getEmailAccountRequest.pending, (state) => {
      state.getEmailAccountRequest.status = RequestStatus.Pending;
      state.getEmailAccountRequest.error = null;
      state.isLoading = true;
    });

    builder.addCase(getEmailAccountRequest.fulfilled, (state, action) => {
      state.getEmailAccountRequest.status = RequestStatus.Succeeded;
      state.getEmailAccountRequest.error = null;
      state.emailAccount = action.payload.payload;
      state.isLoading = false;
    });

    builder.addCase(getEmailAccountRequest.rejected, (state, action) => {
      state.getEmailAccountRequest.status = RequestStatus.Failed;
      state.getEmailAccountRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get email account settings
    builder.addCase(getEmailAccountSettingsRequest.pending, (state) => {
      state.getEmailAccountSettingsRequest.status = RequestStatus.Pending;
      state.getEmailAccountSettingsRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      getEmailAccountSettingsRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountSettingsRequest.status = RequestStatus.Succeeded;
        state.emailAccountSettings = action.payload.payload;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getEmailAccountSettingsRequest.rejected,
      (state, action) => {
        state.getEmailAccountSettingsRequest.status = RequestStatus.Failed;
        state.getEmailAccountSettingsRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Fetch Email Account Health Score
    builder.addCase(fetchEmailAccountHealthScoreRequest.pending, (state) => {
      state.fetchEmailAccountHealthScoreRequest.status = RequestStatus.Pending;
      state.fetchEmailAccountHealthScoreRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      fetchEmailAccountHealthScoreRequest.fulfilled,
      (state, action) => {
        state.fetchEmailAccountHealthScoreRequest.status =
          RequestStatus.Succeeded;
        state.emailHealthScoreResult = action.payload.payload;
        state.isLoading = false;
      },
    );
    builder.addCase(
      fetchEmailAccountHealthScoreRequest.rejected,
      (state, action) => {
        state.fetchEmailAccountHealthScoreRequest.status = RequestStatus.Failed;
        state.fetchEmailAccountHealthScoreRequest.error =
          !action.payload.isHandled && action.payload;
        state.isLoading = false;
      },
    );

    // Test SMTP  Connection
    builder.addCase(testSMTPConnectionRequest.pending, (state) => {
      state.testSMTPConnectionRequest.status = RequestStatus.Pending;
      state.testSMTPConnectionRequest.message = null;
      state.testSMTPConnectionRequest.error = null;
    });
    builder.addCase(testSMTPConnectionRequest.fulfilled, (state, action) => {
      state.testSMTPConnectionRequest.status = RequestStatus.Succeeded;
      state.testSMTPConnectionResponse = action.payload.payload;
      state.testSMTPConnectionRequest.message = action.payload.message;
      state.testSMTPConnectionRequest.error = null;
    });
    builder.addCase(testSMTPConnectionRequest.rejected, (state, action) => {
      state.testSMTPConnectionRequest.status = RequestStatus.Failed;
      state.testSMTPConnectionRequest.message = action.payload.message;
      state.testSMTPConnectionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Test IMAP  Connection
    builder.addCase(testIMAPConnectionRequest.pending, (state) => {
      state.testIMAPConnectionRequest.status = RequestStatus.Pending;
      state.testIMAPConnectionRequest.message = null;
      state.testIMAPConnectionRequest.error = null;
    });
    builder.addCase(testIMAPConnectionRequest.fulfilled, (state, action) => {
      state.testIMAPConnectionRequest.status = RequestStatus.Succeeded;
      state.testIMAPConnectionResponse = action.payload.payload;
      state.testIMAPConnectionRequest.message = action.payload.message;
      state.testIMAPConnectionRequest.error = null;
    });
    builder.addCase(testIMAPConnectionRequest.rejected, (state, action) => {
      state.testIMAPConnectionRequest.status = RequestStatus.Failed;
      state.testIMAPConnectionRequest.message = action.payload.message;
      state.testIMAPConnectionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Connect SMTP IMAP Email Account
    builder.addCase(connectSmtpImapAccountRequest.pending, (state) => {
      state.connectSmtpImapAccountRequest.status = RequestStatus.Pending;
      state.connectSmtpImapAccountRequest.message = null;
      state.connectSmtpImapAccountRequest.error = null;
    });
    builder.addCase(
      connectSmtpImapAccountRequest.fulfilled,
      (state, action) => {
        state.connectSmtpImapAccountRequest.status = RequestStatus.Succeeded;
        state.connectSmtpImapAccountResponse = action.payload.payload;
        state.connectSmtpImapAccountRequest.message =
          action.payload.payload?.message;
        state.connectSmtpImapAccountRequest.error = null;
      },
    );
    builder.addCase(connectSmtpImapAccountRequest.rejected, (state, action) => {
      state.connectSmtpImapAccountRequest.status = RequestStatus.Failed;
      state.connectSmtpImapAccountRequest.message = action.payload.message;
      state.connectSmtpImapAccountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get SMTP IMAP  Account Details
    builder.addCase(getSmtpImapAccountDetailsRequest.pending, (state) => {
      state.getSmtpImapAccountDetailsRequest.status = RequestStatus.Pending;
      state.getSmtpImapAccountDetailsRequest.message = null;
      state.getSmtpImapAccountDetailsRequest.error = null;
    });
    builder.addCase(
      getSmtpImapAccountDetailsRequest.fulfilled,
      (state, action) => {
        state.getSmtpImapAccountDetailsRequest.status = RequestStatus.Succeeded;
        state.sendGetSmtpImapAccountDetailsResponse = action.payload.payload;
        state.getSmtpImapAccountDetailsRequest.message = action.payload.message;
        state.getSmtpImapAccountDetailsRequest.error = null;
      },
    );
    builder.addCase(
      getSmtpImapAccountDetailsRequest.rejected,
      (state, action) => {
        state.getSmtpImapAccountDetailsRequest.status = RequestStatus.Failed;
        state.getSmtpImapAccountDetailsRequest.message = action.payload.message;
        state.getSmtpImapAccountDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update SMTP IMAP  Account Details
    builder.addCase(updateSmtpImapAccountDetailsRequest.pending, (state) => {
      state.updateSmtpImapAccountDetailsRequest.status = RequestStatus.Pending;
      state.updateSmtpImapAccountDetailsRequest.message = null;
      state.updateSmtpImapAccountDetailsRequest.error = null;
    });
    builder.addCase(
      updateSmtpImapAccountDetailsRequest.fulfilled,
      (state, action) => {
        state.updateSmtpImapAccountDetailsRequest.status =
          RequestStatus.Succeeded;
        state.sendUpdateSmtpImapAccountDetailsResponse = action.payload.payload;
        state.updateSmtpImapAccountDetailsRequest.message =
          action.payload.payload.message;
        state.updateSmtpImapAccountDetailsRequest.error = null;
      },
    );
    builder.addCase(
      updateSmtpImapAccountDetailsRequest.rejected,
      (state, action) => {
        state.updateSmtpImapAccountDetailsRequest.status = RequestStatus.Failed;
        state.updateSmtpImapAccountDetailsRequest.message =
          action.payload.message;
        state.updateSmtpImapAccountDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const {
  changeEmailAccountStatus,
  checkedChange,
  checkChangeAll,
  resetEmailHeathScoreState,
  resetEmailAccountRampUpSettingsRequestState,
  resetEmailAccountAndSettings,
  resetSmtpImapTestConnection,
  resetSmtpIMapConnectResponse,
  resetUpdateSmtpImapAccountDetails,
} = emailAccountSlice.actions;
export {
  connectEmailAccountRequest,
  verifyEmailAccountRequest,
  setDefaultEmailAccountRequest,
  disconnectEmailAccountRequest,
  getEmailAccountsRequest,
  deleteEmailAccountRequest,
  updateEmailAccountRequest,
  emailTimeIntervalSettingsRequest,
};
export default emailAccountSlice.reducer;
