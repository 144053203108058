/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import { getCookie } from './cookies-handlers';

type InitializeThirdPartyIntegrations = {
  trackingId: string;
  showChatSupport?: boolean;
  firstName: string;
  lastName: string;
  email: string;
};

export const initializeThirdPartyIntegrations = ({
  trackingId,
  showChatSupport = false,
  firstName = '',
  lastName = '',
  email = '',
}: InitializeThirdPartyIntegrations): void => {
  const name = `${firstName} ${lastName}`;

  if (trackingId) {
    // Freshworks CRM Initialization
    window.fwcrm &&
      window.fwcrm.identify(trackingId, {
        'First Name': firstName,
        'Last Name': lastName,
        Email: email,
      });

    // Google Analytics Initialization
    window.analytics &&
      window.analytics.identify(trackingId, {
        'First Name': firstName,
        'Last Name': lastName,
        email,
        'Referral page': getCookie('shreferer'),
        'Landing page': getCookie('sh_ulp'),
      });

    // Profitwell Initialization
    window.profitwell && window.profitwell('start', { user_email: email });
  }
};

// Sentry Initialization
export const initializeSentryIntegrations = (email: string): void =>
  email && Sentry.setUser({ email });
