import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from '../../../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../../../shared/types';
import {
  getAccountSubscriptionRequest,
  getConnectedUsersAndEmailAccountsRequest,
  getInvoiceURLRequest,
  getPlansRequest,
  purchaseSubscriptionRequest,
  applyCouponCodeRequest,
  getEmailVerificationCreditsRequest,
  getEmailVerificationPlanRequest,
  purchaseEmailVerificationCreditsRequest,
  modifySubscriptionRequest,
  purchaseModifySubscriptionRequest,
  reactivateSubscriptionRequest,
  resumeSubscriptionRequest,
  handleSubscriptionRequest,
  calculatePayRequest,
  upgradePlanRequest,
  downgradePlanRequest,
  purchaseLtdPlanRequest,
  upgradeLtdPlanRequest,
  modifyLtdPlanRequest,
} from './extra-actions';
import {
  ApplyCouponCodeResponse,
  GetAccountSubscriptionResponse,
  GetConnectedUsersAndEmailAccountsResponse,
  GetEmailVerificationCreditsResponse,
  GetEmailVerificationPlanResponse,
  GetInvoiceURLResponse,
  GetPlansResponse,
  PurchaseModifySubscriptionResponse,
  PurchaseEmailVerificationCreditsResponse,
  ModifySubscriptionResponse,
  ReactivateResumeSubscriptionResponse,
  HandleSubscriptionResponse,
  SelectedPlanDetails,
  CalculatePayResponse,
} from './types';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getAccountSubscriptionRequest: RequestState;
  getPlansRequest: RequestState;
  getInvoiceURLRequest: RequestState;
  getConnectedUsersAndEmailAccountsRequest: RequestState;
  applyCouponCodeRequest: RequestState;
  purchaseSubscriptionRequest: RequestState;
  getEmailVerificationCreditsRequest: RequestState;
  getEmailVerificationPlanRequest: RequestState;
  purchaseEmailVerificationCreditsRequest: RequestState;
  modifySubscriptionRequest: RequestState;
  purchaseModifySubscriptionRequest: RequestState;
  reactivateSubscriptionRequest: RequestState;
  resumeSubscriptionRequest: RequestState;
  getAccountSubscriptionResponse: GetAccountSubscriptionResponse;
  getPlansResponse: GetPlansResponse;
  getInvoiceResponse: GetInvoiceURLResponse;
  getConnectedUsersAndEmailAccountsResponse: GetConnectedUsersAndEmailAccountsResponse;
  applyCouponCodeResponse: ApplyCouponCodeResponse;
  getEmailVerificationCreditsResponse: GetEmailVerificationCreditsResponse;
  getEmailVerificationPlanResponse: GetEmailVerificationPlanResponse[];
  cancelEmailVerificationPlanRequest: RequestState;
  purchaseEmailVerificationCreditsResponse: PurchaseEmailVerificationCreditsResponse;
  modifySubscriptionResponse: ModifySubscriptionResponse;
  purchaseModifySubscriptionResponse: PurchaseModifySubscriptionResponse;
  reactivateSubscriptionResponse: ReactivateResumeSubscriptionResponse;
  resumeSubscriptionResponse: ReactivateResumeSubscriptionResponse;
  handleSubscriptionRequest: RequestState;
  handleSubscriptionResponse: HandleSubscriptionResponse;
  selectedPlanDetails: SelectedPlanDetails;
  calculatePayRequest: RequestState;
  upgradePlanRequest: RequestState;
  downgradePlanRequest: RequestState;
  purchaseLtdPlanRequest: RequestState;
  upgradeLtdPlanRequest: RequestState;
  modifyLtdPlanRequest: RequestState;
  getCalculatePayResponse: CalculatePayResponse;
  purchaseLtdPlanResponse: ReactivateResumeSubscriptionResponse;
  upgradeLtdPlanResponse: ReactivateResumeSubscriptionResponse;
  modifyLtdPlanResponse: ReactivateResumeSubscriptionResponse;
};

const initialState: State = {
  getAccountSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getInvoiceURLRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getPlansRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getConnectedUsersAndEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  applyCouponCodeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailVerificationCreditsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailVerificationPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseEmailVerificationCreditsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cancelEmailVerificationPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  modifySubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseModifySubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reactivateSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resumeSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  handleSubscriptionRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  calculatePayRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  upgradePlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  downgradePlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  purchaseLtdPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  upgradeLtdPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  modifyLtdPlanRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  selectedPlanDetails: null,
  getAccountSubscriptionResponse: null,
  getInvoiceResponse: null,
  getPlansResponse: null,
  getConnectedUsersAndEmailAccountsResponse: null,
  applyCouponCodeResponse: null,
  getEmailVerificationCreditsResponse: null,
  getEmailVerificationPlanResponse: null,
  purchaseEmailVerificationCreditsResponse: null,
  modifySubscriptionResponse: null,
  getCalculatePayResponse: null,
  purchaseModifySubscriptionResponse: null,
  reactivateSubscriptionResponse: null,
  resumeSubscriptionResponse: null,
  handleSubscriptionResponse: null,
  purchaseLtdPlanResponse: null,
  upgradeLtdPlanResponse: null,
  modifyLtdPlanResponse: null,
};

const billingSubscriptionSlice = createSlice({
  name: 'billingSubscription',
  initialState,
  reducers: {
    resetSubscription: (state) => {
      state.getAccountSubscriptionRequest =
        initialState.getAccountSubscriptionRequest;
      state.getAccountSubscriptionResponse =
        initialState.getAccountSubscriptionResponse;
      state.getInvoiceResponse = initialState.getInvoiceResponse;
      state.getInvoiceURLRequest = initialState.getInvoiceURLRequest;
      state.getPlansRequest = initialState.getPlansRequest;
      state.getPlansResponse = initialState.getPlansResponse;
      state.purchaseSubscriptionRequest =
        initialState.purchaseSubscriptionRequest;
      state.applyCouponCodeRequest = initialState.applyCouponCodeRequest;
      state.applyCouponCodeResponse = initialState.applyCouponCodeResponse;
      state.cancelEmailVerificationPlanRequest =
        initialState.cancelEmailVerificationPlanRequest;
      state.getEmailVerificationCreditsRequest =
        initialState.getEmailVerificationCreditsRequest;
      state.getEmailVerificationCreditsResponse =
        initialState.getEmailVerificationCreditsResponse;
      state.getEmailVerificationPlanRequest =
        initialState.getEmailVerificationPlanRequest;
      state.getEmailVerificationPlanResponse =
        initialState.getEmailVerificationPlanResponse;
      state.purchaseEmailVerificationCreditsRequest =
        initialState.purchaseEmailVerificationCreditsRequest;
      state.purchaseEmailVerificationCreditsResponse =
        initialState.purchaseEmailVerificationCreditsResponse;
      state.modifySubscriptionRequest = initialState.modifySubscriptionRequest;
      state.modifySubscriptionResponse =
        initialState.modifySubscriptionResponse;
      state.purchaseModifySubscriptionRequest =
        initialState.purchaseModifySubscriptionRequest;
      state.purchaseModifySubscriptionResponse =
        initialState.purchaseModifySubscriptionResponse;
      state.reactivateSubscriptionRequest =
        initialState.reactivateSubscriptionRequest;
      state.reactivateSubscriptionResponse =
        initialState.reactivateSubscriptionResponse;
      state.resumeSubscriptionRequest =
        initialState.reactivateSubscriptionRequest;
      state.resumeSubscriptionResponse =
        initialState.resumeSubscriptionResponse;
      state.handleSubscriptionRequest = initialState.handleSubscriptionRequest;
      state.handleSubscriptionResponse =
        initialState.handleSubscriptionResponse;

      state.purchaseLtdPlanRequest = initialState.purchaseLtdPlanRequest;
      state.upgradeLtdPlanRequest = initialState.upgradeLtdPlanRequest;
      state.modifyLtdPlanRequest = initialState.modifyLtdPlanRequest;
      state.purchaseLtdPlanResponse = initialState.purchaseLtdPlanResponse;
      state.upgradeLtdPlanResponse = initialState.upgradeLtdPlanResponse;
      state.modifyLtdPlanResponse = initialState.modifyLtdPlanResponse;

      state.calculatePayRequest = initialState.calculatePayRequest;
      state.getCalculatePayResponse = initialState.getCalculatePayResponse;
    },

    /** we're using purchase email verification from different places.
     *  so we need to reset from every places.
     */
    resetPurchaseEmailVerificationCreditsResponse: (state) => {
      state.purchaseEmailVerificationCreditsRequest =
        initialState.purchaseEmailVerificationCreditsRequest;
      state.purchaseEmailVerificationCreditsResponse =
        initialState.purchaseEmailVerificationCreditsResponse;
    },

    resetPurchaseModifySubscriptionResponse: (state) => {
      state.purchaseModifySubscriptionRequest =
        initialState.purchaseModifySubscriptionRequest;
      state.purchaseModifySubscriptionResponse =
        initialState.purchaseModifySubscriptionResponse;
    },

    resetReactivateSubscriptionResponse: (state) => {
      state.reactivateSubscriptionRequest =
        initialState.reactivateSubscriptionRequest;
      state.reactivateSubscriptionResponse =
        initialState.reactivateSubscriptionResponse;
    },

    resetResumeSubscriptionResponse: (state) => {
      state.resumeSubscriptionRequest = initialState.resumeSubscriptionRequest;
      state.resumeSubscriptionResponse =
        initialState.resumeSubscriptionResponse;
    },

    resetHandleSubscriptionResponse: (state) => {
      state.handleSubscriptionRequest = initialState.handleSubscriptionRequest;
      state.handleSubscriptionResponse =
        initialState.handleSubscriptionResponse;
    },

    resetModifySubscriptionResponse: (state) => {
      state.modifySubscriptionRequest = initialState.modifySubscriptionRequest;
      state.modifySubscriptionResponse =
        initialState.modifySubscriptionResponse;
    },

    deleteAppliedCouponCode: (state) => {
      state.applyCouponCodeResponse = initialState.applyCouponCodeResponse;
      state.applyCouponCodeRequest = initialState.applyCouponCodeRequest;
    },
    resetConnectedUsersAndEmailAccountsRequest: (state) => {
      state.getConnectedUsersAndEmailAccountsRequest =
        initialState.getConnectedUsersAndEmailAccountsRequest;
    },

    // Reset Calculate Pay State
    resetCalculatePayState: (state) => {
      state.calculatePayRequest = initialState.calculatePayRequest;
      state.getCalculatePayResponse = initialState.getCalculatePayResponse;
    },

    // Reset Purchase LTD Plan State
    resetPurchaseLtdPlan: (state) => {
      state.purchaseLtdPlanRequest = initialState.purchaseLtdPlanRequest;
      state.purchaseLtdPlanResponse = initialState.purchaseLtdPlanResponse;
    },

    // Reset Upgrade LTD Plan State
    resetUpgradeLtdPlan: (state) => {
      state.upgradeLtdPlanRequest = initialState.upgradeLtdPlanRequest;
      state.upgradeLtdPlanResponse = initialState.upgradeLtdPlanResponse;
    },

    // Reset Modify LTD Plan State
    resetModifyLtdPlan: (state) => {
      state.modifyLtdPlanRequest = initialState.modifyLtdPlanRequest;
      state.modifyLtdPlanResponse = initialState.modifyLtdPlanResponse;
    },

    // Store the select plan details
    selectPlan: (state, action: PayloadAction<SelectedPlanDetails>) => {
      state.selectedPlanDetails = action.payload;
    },

    // Reset Invoice State
    resetInvoice: (state) => {
      state.getInvoiceURLRequest = initialState.getInvoiceURLRequest;
      state.getInvoiceResponse = initialState.getInvoiceResponse;
    },
  },
  extraReducers: (builder) => {
    // Get account subscription detail
    builder.addCase(getAccountSubscriptionRequest.pending, (state) => {
      state.getAccountSubscriptionRequest.status = RequestStatus.Pending;
      state.getAccountSubscriptionRequest.message = null;
      state.getAccountSubscriptionRequest.error = null;
    });
    builder.addCase(
      getAccountSubscriptionRequest.fulfilled,
      (state, action) => {
        state.getAccountSubscriptionRequest.status = RequestStatus.Succeeded;
        state.getAccountSubscriptionResponse = action.payload.payload;
        state.getAccountSubscriptionResponse.nextBillingAt =
          state.getAccountSubscriptionResponse.nextBillingAt === null
            ? null
            : state.getAccountSubscriptionResponse.nextBillingAt?.replace(
                /-/g,
                '/',
              );
        state.getAccountSubscriptionResponse.startAt = state.getAccountSubscriptionResponse.startAt?.replace(
          /-/g,
          '/',
        );
        state.getAccountSubscriptionRequest.message = null;
        state.getAccountSubscriptionRequest.error = null;
      },
    );
    builder.addCase(getAccountSubscriptionRequest.rejected, (state, action) => {
      state.getAccountSubscriptionRequest.status = RequestStatus.Failed;
      state.getAccountSubscriptionRequest.message = action.payload.message;
      state.getAccountSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get invoice-url request
    builder.addCase(getInvoiceURLRequest.pending, (state) => {
      state.getInvoiceURLRequest.status = RequestStatus.Pending;
      state.getInvoiceURLRequest.message = null;
      state.getInvoiceURLRequest.error = null;
    });
    builder.addCase(getInvoiceURLRequest.fulfilled, (state, action) => {
      state.getInvoiceURLRequest.status = RequestStatus.Succeeded;
      state.getInvoiceResponse = action.payload.payload;
      state.getInvoiceURLRequest.message = null;
      state.getInvoiceURLRequest.error = null;
    });
    builder.addCase(getInvoiceURLRequest.rejected, (state, action) => {
      state.getInvoiceURLRequest.status = RequestStatus.Failed;
      state.getInvoiceURLRequest.message = action.payload.message;
      state.getInvoiceURLRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Purchase Subscription request
    builder.addCase(purchaseSubscriptionRequest.pending, (state) => {
      state.purchaseSubscriptionRequest.status = RequestStatus.Pending;
      state.purchaseSubscriptionRequest.message = null;
      state.purchaseSubscriptionRequest.error = null;
    });
    builder.addCase(purchaseSubscriptionRequest.fulfilled, (state, action) => {
      state.purchaseSubscriptionRequest.status = RequestStatus.Succeeded;
      state.purchaseSubscriptionRequest.message =
        action.payload.payload.message;
      state.purchaseSubscriptionRequest.error = null;
    });
    builder.addCase(purchaseSubscriptionRequest.rejected, (state, action) => {
      state.purchaseSubscriptionRequest.status = RequestStatus.Failed;
      state.purchaseSubscriptionRequest.message = action.payload.message;
      state.purchaseSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Apply coupon code request
    builder.addCase(applyCouponCodeRequest.pending, (state) => {
      state.applyCouponCodeRequest.status = RequestStatus.Pending;
      state.applyCouponCodeRequest.message = null;
      state.applyCouponCodeRequest.error = null;
    });
    builder.addCase(applyCouponCodeRequest.fulfilled, (state, action) => {
      state.applyCouponCodeRequest.status = RequestStatus.Succeeded;
      state.applyCouponCodeRequest.message = action.payload.payload.message;
      state.applyCouponCodeRequest.error = null;
      state.applyCouponCodeResponse = action.payload.payload;
    });
    builder.addCase(applyCouponCodeRequest.rejected, (state, action) => {
      state.applyCouponCodeRequest.status = RequestStatus.Failed;
      state.applyCouponCodeRequest.message = action.payload.message;
      state.applyCouponCodeRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get plans request
    builder.addCase(getPlansRequest.pending, (state) => {
      state.getPlansRequest.status = RequestStatus.Pending;
      state.getPlansRequest.message = null;
      state.getPlansRequest.error = null;
    });
    builder.addCase(getPlansRequest.fulfilled, (state, action) => {
      state.getPlansRequest.status = RequestStatus.Succeeded;
      state.getPlansResponse = action.payload.payload.rows;
      state.getPlansRequest.message = null;
      state.getPlansRequest.error = null;
    });
    builder.addCase(getPlansRequest.rejected, (state, action) => {
      state.getPlansRequest.status = RequestStatus.Failed;
      state.getPlansRequest.message = action.payload.message;
      state.getPlansRequest.error = !action.payload.isHandled && action.payload;
    });

    // Get connected users and email accounts
    builder.addCase(
      getConnectedUsersAndEmailAccountsRequest.pending,
      (state) => {
        state.getConnectedUsersAndEmailAccountsRequest.status =
          RequestStatus.Pending;
        state.getConnectedUsersAndEmailAccountsRequest.message = null;
        state.getConnectedUsersAndEmailAccountsRequest.error = null;
      },
    );
    builder.addCase(
      getConnectedUsersAndEmailAccountsRequest.fulfilled,
      (state, action) => {
        state.getConnectedUsersAndEmailAccountsRequest.status =
          RequestStatus.Succeeded;
        // eslint-disable-next-line prefer-destructuring
        state.getConnectedUsersAndEmailAccountsResponse =
          action.payload.payload[0];
        state.getConnectedUsersAndEmailAccountsRequest.message = null;
        state.getConnectedUsersAndEmailAccountsRequest.error = null;
      },
    );
    builder.addCase(
      getConnectedUsersAndEmailAccountsRequest.rejected,
      (state, action) => {
        state.getConnectedUsersAndEmailAccountsRequest.status =
          RequestStatus.Failed;
        state.getConnectedUsersAndEmailAccountsRequest.message =
          action.payload.message;
        state.getConnectedUsersAndEmailAccountsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get email verification credits request
    builder.addCase(getEmailVerificationCreditsRequest.pending, (state) => {
      state.getEmailVerificationCreditsRequest.status = RequestStatus.Pending;
      state.getEmailVerificationCreditsRequest.message = null;
      state.getEmailVerificationCreditsRequest.error = null;
    });
    builder.addCase(
      getEmailVerificationCreditsRequest.fulfilled,
      (state, action) => {
        state.getEmailVerificationCreditsRequest.status =
          RequestStatus.Succeeded;
        state.getEmailVerificationCreditsResponse = action.payload.payload;
        state.getEmailVerificationCreditsRequest.message = null;
        state.getEmailVerificationCreditsRequest.error = null;
      },
    );
    builder.addCase(
      getEmailVerificationCreditsRequest.rejected,
      (state, action) => {
        state.getEmailVerificationCreditsRequest.status = RequestStatus.Failed;
        state.getEmailVerificationCreditsRequest.message =
          action.payload.message;
        state.getEmailVerificationCreditsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get email verification plans request
    builder.addCase(getEmailVerificationPlanRequest.pending, (state) => {
      state.getEmailVerificationPlanRequest.status = RequestStatus.Pending;
      state.getEmailVerificationPlanRequest.message = null;
      state.getEmailVerificationPlanRequest.error = null;
    });
    builder.addCase(
      getEmailVerificationPlanRequest.fulfilled,
      (state, action) => {
        state.getEmailVerificationPlanRequest.status = RequestStatus.Succeeded;
        state.getEmailVerificationPlanResponse = action.payload.payload.rows;
        state.getEmailVerificationPlanRequest.message = null;
        state.getEmailVerificationPlanRequest.error = null;
      },
    );
    builder.addCase(
      getEmailVerificationPlanRequest.rejected,
      (state, action) => {
        state.getEmailVerificationPlanRequest.status = RequestStatus.Failed;
        state.getEmailVerificationPlanRequest.message = action.payload.message;
        state.getEmailVerificationPlanRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Purchase email verification credits request
    builder.addCase(
      purchaseEmailVerificationCreditsRequest.pending,
      (state) => {
        state.purchaseEmailVerificationCreditsRequest.status =
          RequestStatus.Pending;
        state.purchaseEmailVerificationCreditsRequest.message = null;
        state.purchaseEmailVerificationCreditsRequest.error = null;
      },
    );
    builder.addCase(
      purchaseEmailVerificationCreditsRequest.fulfilled,
      (state, action) => {
        state.purchaseEmailVerificationCreditsRequest.status =
          RequestStatus.Succeeded;
        state.purchaseEmailVerificationCreditsResponse = action.payload.payload;
        state.purchaseEmailVerificationCreditsRequest.message =
          action.payload.payload.message;
        state.purchaseEmailVerificationCreditsRequest.error = null;
      },
    );
    builder.addCase(
      purchaseEmailVerificationCreditsRequest.rejected,
      (state, action) => {
        state.purchaseEmailVerificationCreditsRequest.status =
          RequestStatus.Failed;
        state.purchaseEmailVerificationCreditsRequest.message =
          action.payload.message;
        state.purchaseEmailVerificationCreditsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
    // Modify subscription request
    builder.addCase(modifySubscriptionRequest.pending, (state) => {
      state.modifySubscriptionRequest.status = RequestStatus.Pending;
      state.modifySubscriptionRequest.message = null;
      state.modifySubscriptionRequest.error = null;
    });
    builder.addCase(modifySubscriptionRequest.fulfilled, (state, action) => {
      state.modifySubscriptionRequest.status = RequestStatus.Succeeded;
      state.modifySubscriptionRequest.message = action.payload.payload.message;
      state.modifySubscriptionRequest.error = null;
      state.modifySubscriptionResponse = action.payload.payload;
    });
    builder.addCase(modifySubscriptionRequest.rejected, (state, action) => {
      state.modifySubscriptionRequest.status = RequestStatus.Failed;
      state.modifySubscriptionRequest.message = action.payload.message;
      state.modifySubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Purchase modify subscription request
    builder.addCase(purchaseModifySubscriptionRequest.pending, (state) => {
      state.purchaseModifySubscriptionRequest.status = RequestStatus.Pending;
      state.purchaseModifySubscriptionRequest.message = null;
      state.purchaseModifySubscriptionRequest.error = null;
    });
    builder.addCase(
      purchaseModifySubscriptionRequest.fulfilled,
      (state, action) => {
        state.purchaseModifySubscriptionRequest.status =
          RequestStatus.Succeeded;
        state.purchaseModifySubscriptionResponse = action.payload?.payload;
        state.purchaseModifySubscriptionRequest.message = null;
        state.purchaseModifySubscriptionRequest.error = null;
      },
    );
    builder.addCase(
      purchaseModifySubscriptionRequest.rejected,
      (state, action) => {
        state.purchaseModifySubscriptionRequest.status = RequestStatus.Failed;
        state.purchaseModifySubscriptionRequest.message =
          action.payload.message;
        state.purchaseModifySubscriptionRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Reactivate subscription request
    builder.addCase(reactivateSubscriptionRequest.pending, (state) => {
      state.reactivateSubscriptionRequest.status = RequestStatus.Pending;
      state.reactivateSubscriptionRequest.message = null;
      state.reactivateSubscriptionRequest.error = null;
    });
    builder.addCase(
      reactivateSubscriptionRequest.fulfilled,
      (state, action) => {
        state.reactivateSubscriptionRequest.status = RequestStatus.Succeeded;
        state.reactivateSubscriptionResponse = action.payload.payload;
        state.reactivateSubscriptionRequest.message = null;
        state.reactivateSubscriptionRequest.error = null;
      },
    );
    builder.addCase(reactivateSubscriptionRequest.rejected, (state, action) => {
      state.reactivateSubscriptionRequest.status = RequestStatus.Failed;
      state.reactivateSubscriptionRequest.message = action.payload.message;
      state.reactivateSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Resume subscription request
    builder.addCase(resumeSubscriptionRequest.pending, (state) => {
      state.resumeSubscriptionRequest.status = RequestStatus.Pending;
      state.resumeSubscriptionRequest.message = null;
      state.resumeSubscriptionRequest.error = null;
    });
    builder.addCase(resumeSubscriptionRequest.fulfilled, (state, action) => {
      state.resumeSubscriptionRequest.status = RequestStatus.Succeeded;
      state.resumeSubscriptionResponse = action.payload?.payload;
      state.resumeSubscriptionRequest.message = null;
      state.resumeSubscriptionRequest.error = null;
    });
    builder.addCase(resumeSubscriptionRequest.rejected, (state, action) => {
      state.resumeSubscriptionRequest.status = RequestStatus.Failed;
      state.resumeSubscriptionRequest.message = action.payload.message;
      state.resumeSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Handle Subscription Request
    builder.addCase(handleSubscriptionRequest.pending, (state) => {
      state.handleSubscriptionRequest.status = RequestStatus.Pending;
      state.handleSubscriptionRequest.message = null;
      state.handleSubscriptionRequest.error = null;
    });
    builder.addCase(handleSubscriptionRequest.fulfilled, (state, action) => {
      state.handleSubscriptionRequest.status = RequestStatus.Succeeded;
      state.handleSubscriptionResponse = action.payload.payload;
      state.handleSubscriptionRequest.message = null;
      state.handleSubscriptionRequest.error = null;
    });
    builder.addCase(handleSubscriptionRequest.rejected, (state, action) => {
      state.handleSubscriptionRequest.status = RequestStatus.Failed;
      state.handleSubscriptionRequest.message = action.payload.message;
      state.handleSubscriptionRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Calculate Pay Request
    builder.addCase(calculatePayRequest.pending, (state) => {
      state.calculatePayRequest.status = RequestStatus.Pending;
      state.calculatePayRequest.message = null;
      state.calculatePayRequest.error = null;
    });
    builder.addCase(calculatePayRequest.fulfilled, (state, action) => {
      state.calculatePayRequest.status = RequestStatus.Succeeded;
      state.getCalculatePayResponse = action.payload.payload;
      state.calculatePayRequest.message = action.payload.message;
      state.calculatePayRequest.error = null;
    });
    builder.addCase(calculatePayRequest.rejected, (state, action) => {
      state.calculatePayRequest.status = RequestStatus.Failed;
      state.calculatePayRequest.message = action.payload.message;
      state.calculatePayRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Upgrade Plan Request
    builder.addCase(upgradePlanRequest.pending, (state) => {
      state.upgradePlanRequest.status = RequestStatus.Pending;
      state.upgradePlanRequest.message = null;
      state.upgradePlanRequest.error = null;
    });
    builder.addCase(upgradePlanRequest.fulfilled, (state) => {
      state.upgradePlanRequest.status = RequestStatus.Succeeded;
      state.upgradePlanRequest.message = null;
      state.upgradePlanRequest.error = null;
    });
    builder.addCase(upgradePlanRequest.rejected, (state, action) => {
      state.upgradePlanRequest.status = RequestStatus.Failed;
      state.upgradePlanRequest.message = action.payload.message;
      state.upgradePlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Downgrade Plan Request
    builder.addCase(downgradePlanRequest.pending, (state) => {
      state.downgradePlanRequest.status = RequestStatus.Pending;
      state.downgradePlanRequest.message = null;
      state.downgradePlanRequest.error = null;
    });
    builder.addCase(downgradePlanRequest.fulfilled, (state) => {
      state.downgradePlanRequest.status = RequestStatus.Succeeded;
      state.downgradePlanRequest.message = null;
      state.downgradePlanRequest.error = null;
    });
    builder.addCase(downgradePlanRequest.rejected, (state, action) => {
      state.downgradePlanRequest.status = RequestStatus.Failed;
      state.downgradePlanRequest.message = action.payload.message;
      state.downgradePlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // LTD Purchase Plan Request
    builder.addCase(purchaseLtdPlanRequest.pending, (state) => {
      state.purchaseLtdPlanRequest.status = RequestStatus.Pending;
      state.purchaseLtdPlanRequest.message = null;
      state.purchaseLtdPlanRequest.error = null;
    });
    builder.addCase(purchaseLtdPlanRequest.fulfilled, (state, action) => {
      state.purchaseLtdPlanRequest.status = RequestStatus.Succeeded;
      state.purchaseLtdPlanRequest.message = action.payload.message;
      state.purchaseLtdPlanRequest.error = null;
      state.purchaseLtdPlanResponse = action.payload.payload;
    });
    builder.addCase(purchaseLtdPlanRequest.rejected, (state, action) => {
      state.purchaseLtdPlanRequest.status = RequestStatus.Failed;
      state.purchaseLtdPlanRequest.message = action.payload.message;
      state.purchaseLtdPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // LTD Upgrade Plan Request
    builder.addCase(upgradeLtdPlanRequest.pending, (state) => {
      state.upgradeLtdPlanRequest.status = RequestStatus.Pending;
      state.upgradeLtdPlanRequest.message = null;
      state.upgradeLtdPlanRequest.error = null;
    });
    builder.addCase(upgradeLtdPlanRequest.fulfilled, (state, action) => {
      state.upgradeLtdPlanRequest.status = RequestStatus.Succeeded;
      state.upgradeLtdPlanRequest.message = action.payload.message;
      state.upgradeLtdPlanRequest.error = null;
      state.upgradeLtdPlanResponse = action.payload.payload;
    });
    builder.addCase(upgradeLtdPlanRequest.rejected, (state, action) => {
      state.upgradeLtdPlanRequest.status = RequestStatus.Failed;
      state.upgradeLtdPlanRequest.message = action.payload.message;
      state.upgradeLtdPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // LTD Modify Plan Request
    builder.addCase(modifyLtdPlanRequest.pending, (state) => {
      state.modifyLtdPlanRequest.status = RequestStatus.Pending;
      state.modifyLtdPlanRequest.message = null;
      state.modifyLtdPlanRequest.error = null;
    });
    builder.addCase(modifyLtdPlanRequest.fulfilled, (state, action) => {
      state.modifyLtdPlanRequest.status = RequestStatus.Succeeded;
      state.modifyLtdPlanRequest.message = action.payload.message;
      state.modifyLtdPlanRequest.error = null;
      state.modifyLtdPlanResponse = action.payload.payload;
    });
    builder.addCase(modifyLtdPlanRequest.rejected, (state, action) => {
      state.modifyLtdPlanRequest.status = RequestStatus.Failed;
      state.modifyLtdPlanRequest.message = action.payload.message;
      state.modifyLtdPlanRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetSubscription,
  deleteAppliedCouponCode,
  resetPurchaseEmailVerificationCreditsResponse,
  resetPurchaseModifySubscriptionResponse,
  resetModifySubscriptionResponse,
  resetCalculatePayState,
  resetReactivateSubscriptionResponse,
  resetResumeSubscriptionResponse,
  resetConnectedUsersAndEmailAccountsRequest,
  resetHandleSubscriptionResponse,
  resetPurchaseLtdPlan,
  resetUpgradeLtdPlan,
  resetModifyLtdPlan,
  selectPlan,
  resetInvoice,
} = billingSubscriptionSlice.actions;

export default billingSubscriptionSlice.reducer;
