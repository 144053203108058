import React from 'react';
import classNames from 'classnames';
import {
  ContactStatusType,
  ContactStatusTypeDisplayName,
} from '../../../../../components/sequence/enums';

const ContactStatusTag = ({ status, isSequenceProspectTag = false }) => {
  let capitalizedStatus = ContactStatusTypeDisplayName[status]
    ? ContactStatusTypeDisplayName[status]
    : '';

  if (capitalizedStatus) {
    capitalizedStatus =
      capitalizedStatus?.charAt(0)?.toUpperCase() + capitalizedStatus?.slice(1);
  }

  const activeTagClass = classNames([
    'bs-tag',
    { 'bs-tag-blue-2': isSequenceProspectTag },
    { 'bs-tag-green': !isSequenceProspectTag },
  ]);
  const finishedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-blue-2': isSequenceProspectTag },
    { 'bs-tag-green': !isSequenceProspectTag },
  ]);
  const repliedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-green-2': isSequenceProspectTag },
    { 'bs-tag-lime': !isSequenceProspectTag },
  ]);
  const bouncedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-orange-2': isSequenceProspectTag },
    { 'bs-tag-red': !isSequenceProspectTag },
  ]);
  const unsubscribedTagClass = classNames([
    'bs-tag',
    { 'bs-tag-red-2': isSequenceProspectTag },
    { 'bs-tag-gold': !isSequenceProspectTag },
  ]);
  const blacklistTagClass = classNames([
    'bs-tag',
    { 'bs-tag-gray': isSequenceProspectTag },
    { 'bs-tag-gray': !isSequenceProspectTag },
  ]);
  const pausedTagClass = classNames(['bs-tag bs-tag-pause']);

  switch (status) {
    case ContactStatusType.Active:
      return <span className={activeTagClass}>{capitalizedStatus}</span>;
    case ContactStatusType.Finished:
      return <span className={finishedTagClass}>{capitalizedStatus}</span>;
    case ContactStatusType.Replied:
      return <span className={repliedTagClass}>{capitalizedStatus}</span>;
    case ContactStatusType.Bounced:
      return <span className={bouncedTagClass}>{capitalizedStatus}</span>;
    case ContactStatusType.Unsubscribed:
      return <span className={unsubscribedTagClass}>{capitalizedStatus}</span>;
    case ContactStatusType.Blacklisted:
      return <span className={blacklistTagClass}>{capitalizedStatus}</span>;
    case ContactStatusType.Paused:
      return <span className={pausedTagClass}>{capitalizedStatus}</span>;
    default:
      return <span />;
  }
};

export default ContactStatusTag;
