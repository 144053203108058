import React from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from '../../../../../../../shared/app-constants';
import Button from '../../../../../../../shared/design-system/components/atoms/button';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import { redirectWithoutRefresh } from '../../../../../../../shared/utils';
import { getUpgradePlanCTA } from '../../../../../../../shared/utils/get-upgrade-cta';

import type { IProps } from './types';

const RestrictionErrorModal: React.FC<IProps> = ({
  show,
  modalTitle,
  cancelButtonText,
  bodyContent,
  emailBody,
  onClose,
  onHide,
}) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    redirectWithoutRefresh('/settings/billing/subscription');
  };

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal no-seat-confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.UploadCloudBlue} alt="Alert" />
          <h2>{modalTitle || 'Upgrade to import more prospects'}</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmit}
      onClose={onHide || onClose}
      onCancel={onClose}
      submitButtonText={t(`labels.${getUpgradePlanCTA(true)}`)}
      cancelButtonText={cancelButtonText || 'Cancel'}
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
      backdrop="static"
    >
      <div className="confirmation-modal-content">
        {bodyContent.map((text) => (
          <p className="confirmation-modal-text">{text}</p>
        ))}
      </div>
    </Modal>
  );
};

export default RestrictionErrorModal;
