import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FormSection from '../../../form-section';
import ButtonContainer from '../../../button-container';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Alert from '../../../../../../shared/design-system/components/alert';
import { IProps } from './type';
import BrokenLink from '../../../../../../shared/components/images/broken-link';
import AlertContainer from '../../../alert-container';
import Logo from '../../../../../../shared/components/logo';

class ResetPasswordInvalidLinkSuccess extends React.Component<IProps, null> {
  constructor(props) {
    super(props);
    this.redirectToLoginHandler = this.redirectToLoginHandler.bind(this);
  }

  redirectToLoginHandler() {
    const { history } = this.props;
    history.push('/login');
  }

  render() {
    const { message } = this.props;

    return (
      <>
        <Row>
          <Col>
            <div className="invalid-msg-header">
              <Logo
                logoTextStyles={{
                  color: '#212529',
                  fontSize: '1.25rem',
                }}
                isTertiary
              />
            </div>
          </Col>
        </Row>
        <div className="invalid-msg-inner">
          <div className="top-welcome">
            <BrokenLink />
            <h3 className="semibold-7">Done and done!</h3>
          </div>
          <div className="reset-pass-success-message">
            <AlertContainer>
              <Alert
                variant={Alert.Variant.Success}
                iconIdentifier="check-o"
                header="Success!"
                className="reset-pass-success"
              >
                {message}
              </Alert>
            </AlertContainer>
            <p className="reset-success-note regular-2">
              If the email does not arrive soon, check your spam folder.
            </p>
          </div>
          <FormSection>
            <ButtonContainer>
              <Button
                variant={Button.Variant.Primary}
                onClick={this.redirectToLoginHandler}
              >
                Return to Login
              </Button>
            </ButtonContainer>
          </FormSection>
        </div>
      </>
    );
  }
}

export default ResetPasswordInvalidLinkSuccess;
