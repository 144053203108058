import React, { useState } from 'react';
import { OverlayTarget, OverlayTargetProps } from '../overlay-target';
import { Placement } from '../enums';
import { generatePopoverId } from '../helpers';
import { Popover, PopoverProps } from '../../overlay';

export type OverlayTargetPopoverProps = Omit<
  OverlayTargetProps,
  'overlay' | 'children'
> &
  Partial<Pick<PopoverProps, 'id' | 'theme'>> &
  Pick<PopoverProps, 'header' | 'content' | 'rePositionOnChange' | 'className'>;

export const OverlayTargetPopover: React.FC<OverlayTargetPopoverProps> = ({
  id: _id,
  placement,
  header,
  content,
  rePositionOnChange,
  className,
  theme,
  ...rest
}) => {
  const [id] = useState(_id || generatePopoverId());
  const popover = (
    <Popover
      id={id}
      header={header}
      content={content}
      rePositionOnChange={rePositionOnChange}
      className={className}
      theme={theme}
    />
  );

  return (
    <OverlayTarget {...rest} placement={placement}>
      {popover}
    </OverlayTarget>
  );
};

OverlayTargetPopover.defaultProps = {
  placement: Placement.Right,
};
