import React from 'react';
import classNames from 'classnames';
import { isArray } from '../utils';
import Icon from '../design-system/components/atoms/icon/icon';

type ValidationItem = {
  valid?: boolean;
  text?: string;
};

type IProps = {
  columns: (ValidationItem | ValidationItem[])[];
  isDirty: boolean;
};

const ValidationChecklist: React.FC<IProps> = (props) => {
  const { columns: validationColumns, isDirty } = props;
  const columns = validationColumns.map((column) => {
    if (!isArray(column)) {
      column = [column as ValidationItem];
    }
    return column;
  }) as ValidationItem[][];

  const getValidationClassNames = (isValid) =>
    classNames([
      {
        default: !isDirty,
        valid: isValid && isDirty,
        error: !isValid && isDirty,
      },
    ]);

  return (
    <>
      <p className="password-validation-label">Password must contain:</p>
      <div className="password-validation-checklist-container">
        {columns.map((column, columnIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <ul key={columnIndex}>
            {column.map((item, columnItemIndex) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={columnItemIndex}
                className={getValidationClassNames(item.valid)}
              >
                <span>
                  <Icon
                    identifier={
                      item.valid || !isDirty ? 'circle-check' : 'close-o'
                    }
                  />
                </span>
                <p className="regular-1">{item.text}</p>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </>
  );
};

export default ValidationChecklist;
