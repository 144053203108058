import store from '../../store/store';

export const contactAdmin = (emailSubject, emailBody) => {
  const { home } = store.getState();

  const params = new URLSearchParams('');
  params.set('to', home?.adminDetails?.email || '');

  const encodedAdminEmail = params.toString().split('=')[1];

  const mailString = `mailto:${encodedAdminEmail}?subject=${emailSubject}&body=${emailBody}`;

  window.open(mailString, '_blank');
};
