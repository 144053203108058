import { RequestStatus } from '../../../../../../shared/enums/request-status';
import {
  EmailAccount,
  EmailHealthScoreResult,
} from '../../../../types/email-account';
import {
  UpdateEmailAccountRampUpSettingsRequestPayload,
  UpdateEmailAccountGeneralSettingsRequestPayload,
  EmailTimeIntervalSettingsRequest,
} from '../../../../types/request-payload';
import { CustomDomainType } from '../../../../types/custom-domain';
import { EmailAccountSettings } from '../../../../types/email-account-settings';
import {
  EmailAccountMethod,
  EmailAccountType,
} from '../../../../enums/email-account';
import { Status } from '../../../../../../shared/enums/status';
import { AgencyConfig } from '../../../../../home/types';

export enum UpdateEmailTabKeys {
  GENERAL_SETTINGS = 'general-settings',
  SENDING_SETTINGS = 'sending-settings',
  EMAIL_HEALTH_SCORE = 'email-health-score',
}

export interface RouteParams {
  emailAccountId: string;
  tab: string;
  hashId?: string;
}

export type EmailAccountInfo = {
  name: string;
  email: string;
  accountType: string;
  isConnected: number;
  method: EmailAccountMethod;
};

// Sending Settings Props

export type RampUpSettings = {
  rampUpStatus: number;
  rampUpPercent: number;
  initialSendingLimit: number;
  rampUpLimit: string;
  maxSendingLimit: string;
  rampUpLimitReached: boolean;
  minInterval: number;
  maxInterval: number;
};

export type SendingSettingsProps = {
  emailAccountId: number;
  emailAccountStatus: number;
  emailAccountType: EmailAccountType;
  rampUpSettings: RampUpSettings;
  onSave: (payload: UpdateEmailAccountRampUpSettingsRequestPayload) => void;
  rampUpSettingsRequestStatus: string;
  rampUpSettingsRequestMessage: string;
  rampUpSettingsRequestError: any;
  sendGetEmailAccountSettingsRequest: (id: string | number) => void;
  sendEmailTimeIntervalRequest: (
    emailAccountId: number,
    payload: EmailTimeIntervalSettingsRequest,
  ) => void;
  resetEmailAccountRampUpSettingsRequestState: () => void;
  agencyConfig: AgencyConfig;
  emailTimeIntervalSettingsRequestStatus: RequestStatus;
  emailTimeIntervalSettingsRequestMessage: string;
};

// Email Health Score Props

export enum ScoreFactorStatus {
  Valid = 'valid',
  PartiallyValid = 'partially-valid',
  NotValid = 'not-valid',
}

export type EmailHealthScoreProps = {
  emailAccountId: number;
  emailAccountType: EmailAccountType;
  emailHealthScoreResult: EmailHealthScoreResult;
  fetchEmailAccountHealthScoreRequest: RequestStatus;
  onCheckEmailScore: (emailAccountId: number) => void;
  onFetchEmailHealthScore: (emailAccountId: number) => void;
  resetEmailHeathScore: () => void;
  userTimezone: string;
  connectEmailAccountHandler: () => void;
  changeEmailAccountStatus: (status: Status) => void;
  agencyConfig: AgencyConfig;
  healthScore: number;
};

export type EmailHealthScoreResultProps = {
  emailHealthScoreResult: EmailHealthScoreResult;
  onCheckEmailScore: () => void;
  userTimezone: string;
  connectEmailAccountHandler: () => void;
  changeEmailAccountStatus: (status: Status) => void;
  agencyConfig: AgencyConfig;
};

// General Settings Props And State

type StateValues = {
  senderName: string;
  bcc: string;
  signature: string;
};

type StateErrors = { [P in keyof Required<StateValues>]: string };
type StateDirty = { [P in keyof StateErrors]: boolean };

export type GeneralSettingsState = {
  values: StateValues;
  errors: StateErrors;
  dirty: StateDirty;
  selectedCustomDomain: {
    key: number;
    value: string;
  };
};

export type GeneralSettingsProps = {
  emailAccountId: number;
  hashId: string;
  emailAccount: EmailAccount;
  tabKey: UpdateEmailTabKeys | string;
  emailAccountSettings: EmailAccountSettings[];
  sendUpdateEmailAccountRequest: (
    emailAccountId: number,
    payload: UpdateEmailAccountGeneralSettingsRequestPayload,
  ) => void;
  updateEmailAccountRequestStatus: RequestStatus;
  getEmailAccountRequestStatus: RequestStatus;
  customDomainList: CustomDomainType[];
  sendImageUploadRequest: (formData, onUploadProgress) => void;
  t: (x: string) => React.ReactNode;
  onCheckEmailScore: (emailAccountId: number) => void;
  sendGetEmailAccountRequest: (id: string | number) => void;
  sendGetEmailAccountSettingsRequest: (id: string | number) => void;
  sendGetCustomDomainsListRequest: () => void;
};

export type CustomDomainDropdown = CustomDomainType & { key: number };
