import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, fr } from './locales';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    debug: true,
    load: 'languageOnly',
    supportedLngs: ['en', 'fr'],
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
    },
    keySeparator: '.',
  });

export default i18n;
