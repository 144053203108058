import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  getUserSettingsRequest,
  upgradeOnboardingStepRequest,
} from '../../../home/extra-actions';
import { UpgradeOnboardingStepPayload } from '../../../home/types';
import { connectEmailAccountRequest } from '../../../settings/components/email-account/extra-actions';
import { EmailAccountMethod } from '../../../settings/enums/email-account';
// eslint-disable-next-line import/no-cycle
import ConnectEmailAccount from './connect-email-account';

const mapStateToProps = (state: RootState) => ({
  meta: state.home.meta,
  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  authUrl: state.emailAccount.authUrl,
  upgradeOnboardingStepRequestStatus:
    state.home.upgradeOnboardingStepRequest.status,
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
});

const mapDispatchToProps = {
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendUpgradeOnboardingStepRequest: (payload?: UpgradeOnboardingStepPayload) =>
    upgradeOnboardingStepRequest(payload),
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(ConnectEmailAccount));
