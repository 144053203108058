import React from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import ScheduleHeader from './components/schedule-header';
import ScheduleList from './components/schedule-list';
import { IProps, IState } from './types';
import toaster from '../../../../shared/toaster';
import store from '../../../../store';
import { RequestStatus } from '../../../../shared/enums/request-status';
import DeleteSchedule from './components/delete-schedule';
import DeleteScheduleAlert from './components/delete-schedule/delete-schedule-alert';
import HeaderBanner from '../header-banner';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';

class Schedule extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteScheduleModal: false,
      showDeleteScheduleAlert: false,
      deleteAlertMessage: null,
      actionSchedule: null,
    };

    this.handleAddSchedule = this.handleAddSchedule.bind(this);
    this.showDeleteScheduleModal = this.showDeleteScheduleModal.bind(this);
    this.hideDeleteScheduleModal = this.hideDeleteScheduleModal.bind(this);
    this.onDeleteScheduleSubmit = this.onDeleteScheduleSubmit.bind(this);
    this.makeDefaultSchedule = this.makeDefaultSchedule.bind(this);
    this.showDeleteScheduleAlert = this.showDeleteScheduleAlert.bind(this);
    this.hideDeleteScheduleAlert = this.hideDeleteScheduleAlert.bind(this);
  }

  componentDidMount() {
    const { sendGetSchedulesRequest } = this.props;
    sendGetSchedulesRequest();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      isLoading,
      createScheduleRequestStatus,
      createScheduleRequestMessage,
      setDefaultScheduleRequestStatus,
      setDefaultScheduleRequestMessage,
      setDefaultScheduleRequestError,
      deleteScheduleRequestStatus,
      deleteScheduleRequestMessage,
      deleteScheduleRequestError,
      updateScheduleRequestStatus,
      updateScheduleRequestMessage,
      updateScheduleRequestError,
      sendGetSchedulesRequest,
    } = this.props;
    if (isLoading !== prevProps.isLoading) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isLoading ? store.dispatch(showLoading()) : store.dispatch(hideLoading());
    }

    if (createScheduleRequestStatus !== prevProps.createScheduleRequestStatus) {
      if (createScheduleRequestStatus === RequestStatus.Succeeded) {
        toaster.success(createScheduleRequestMessage);
        sendGetSchedulesRequest();
      }
    }

    if (
      setDefaultScheduleRequestStatus !==
      prevProps.setDefaultScheduleRequestStatus
    ) {
      if (setDefaultScheduleRequestStatus === RequestStatus.Succeeded) {
        toaster.success(setDefaultScheduleRequestMessage);
        sendGetSchedulesRequest();
      }

      if (
        setDefaultScheduleRequestStatus === RequestStatus.Failed &&
        setDefaultScheduleRequestError
      ) {
        toaster.error(deleteScheduleRequestError.message);
      }
    }

    if (deleteScheduleRequestStatus !== prevProps.deleteScheduleRequestStatus) {
      if (deleteScheduleRequestStatus === RequestStatus.Succeeded) {
        toaster.success(deleteScheduleRequestMessage);
        this.hideDeleteScheduleModal();
        sendGetSchedulesRequest();
      }

      if (
        deleteScheduleRequestStatus === RequestStatus.Failed &&
        deleteScheduleRequestError
      ) {
        this.hideDeleteScheduleModal();
        if (deleteScheduleRequestError.code === 4001) {
          this.showDeleteScheduleAlert(deleteScheduleRequestError.message);
        } else {
          toaster.error(deleteScheduleRequestError.message);
        }
      }
    }

    if (updateScheduleRequestStatus !== prevProps.updateScheduleRequestStatus) {
      if (updateScheduleRequestStatus === RequestStatus.Succeeded) {
        toaster.success(updateScheduleRequestMessage);
        sendGetSchedulesRequest();
      }

      if (
        updateScheduleRequestStatus === RequestStatus.Failed &&
        updateScheduleRequestError
      ) {
        toaster.error(updateScheduleRequestError.message);
      }
    }
  }

  onDeleteScheduleSubmit() {
    const { sendDeleteScheduleRequest } = this.props;
    const { actionSchedule } = this.state;
    sendDeleteScheduleRequest(actionSchedule.id);
  }

  hideDeleteScheduleAlert() {
    this.setState({ showDeleteScheduleAlert: false, deleteAlertMessage: null });
  }

  hideDeleteScheduleModal() {
    this.setState({
      showDeleteScheduleModal: false,
      actionSchedule: null,
    });
  }

  showDeleteScheduleAlert(alertMessage: string) {
    this.setState({
      showDeleteScheduleAlert: true,
      deleteAlertMessage: alertMessage,
    });
  }

  showDeleteScheduleModal(schedule) {
    this.setState({
      showDeleteScheduleModal: true,
      actionSchedule: schedule,
    });
  }

  handleAddSchedule() {
    const { sendCreateScheduleRequest } = this.props;
    hasResource(ResourceIdentifiers.SCHEDULES_CREATE) &&
      sendCreateScheduleRequest();
  }

  makeDefaultSchedule(schedule) {
    const { sendSetDefaultScheduleRequest } = this.props;
    sendSetDefaultScheduleRequest(schedule.id);
  }

  render() {
    const {
      schedules,
      deleteScheduleRequestStatus,
      updateScheduleRequestStatus,
      subscriptionPlan,
    } = this.props;
    const {
      showDeleteScheduleModal,
      showDeleteScheduleAlert,
      deleteAlertMessage,
    } = this.state;
    const isDeleteRequestPending =
      deleteScheduleRequestStatus === RequestStatus.Pending;
    return (
      <div className="schedule-container">
        <ScheduleHeader
          buttonText="Add new schedule"
          headerName="Schedules"
          clickHandler={this.handleAddSchedule}
          subscriptionPlan={subscriptionPlan}
        />
        {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}

        {schedules.length !== 0 && (
          <div className="schedule-list">
            <ScheduleList
              schedules={schedules}
              onDeleteHandler={this.showDeleteScheduleModal}
              setDefaultHandler={this.makeDefaultSchedule}
              updateScheduleRequestStatus={updateScheduleRequestStatus}
            />
            {showDeleteScheduleModal && (
              <DeleteSchedule
                show={showDeleteScheduleModal}
                onSubmit={this.onDeleteScheduleSubmit}
                onClose={this.hideDeleteScheduleModal}
                isRequestPending={isDeleteRequestPending}
              />
            )}

            {showDeleteScheduleAlert && (
              <DeleteScheduleAlert
                show={showDeleteScheduleAlert}
                message={deleteAlertMessage}
                onClose={this.hideDeleteScheduleAlert}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Schedule;
