import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IProps } from './type';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';

const ReportsContent = React.lazy(() => import('./components/reports-content'));

const Reports: React.FC<IProps> = () => (
  <Container fluid className="reports-container p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/reports" component={ReportsContent} />
          <Redirect to="/reports" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default Reports;
