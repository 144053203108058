import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../store/root-reducer';
import DomainBlacklisting from './domain-blacklisting';
import {
  createBlacklistedDomainRequest,
  deleteBlacklistDomainRequest,
  getBlacklistedDomainsRequest,
} from './extra-actions';
import { resetBlacklistDomainsState } from './domain-blacklisting-slice';

const mapStateToProps = (state: RootState) => ({
  blacklistDomainRequestStatus:
    state.blacklistDomains.createBlacklistedDomainRequest.status,
  blacklistDomainRequestMessage:
    state.blacklistDomains.createBlacklistedDomainRequest.message,
  blacklistDomainRequestError:
    state.blacklistDomains.createBlacklistedDomainRequest.error,

  getBlacklistDomainsStatus:
    state.blacklistDomains.getBlacklistedDomainsRequest.status,
  getBlacklistDomainsMessage:
    state.blacklistDomains.getBlacklistedDomainsRequest.message,
  getBlacklistDomainsError:
    state.blacklistDomains.getBlacklistedDomainsRequest.error,

  deleteBlacklistDomainStatus:
    state.blacklistDomains.deleteBlacklistDomainRequest.status,
  deleteBlacklistDomainMessage:
    state.blacklistDomains.deleteBlacklistDomainRequest.message,
  deleteBlacklistDomainError:
    state.blacklistDomains.deleteBlacklistDomainRequest.error,

  blacklistedDomains: state.blacklistDomains.blacklistedDomainList,
  isLoading: state.blacklistDomains.isLoading,

  subscriptionPlan: state.home.subscription.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
});

const mapDispatchToProps = {
  sendCreateBlacklistDomainRequest: (args) =>
    createBlacklistedDomainRequest(args),
  sendGetBlacklistDomainRequest: () => getBlacklistedDomainsRequest(),
  sendDeleteBlacklistDomainRequest: (args) =>
    deleteBlacklistDomainRequest(args),
  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
  resetBlacklistDomains: () => resetBlacklistDomainsState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(DomainBlacklisting);
