import React from 'react';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import { OverlayTooltip, Placement } from '../../overlay';

export type SequenceNameProps = {
  onClick?: () => void;
  name: string;
};

const sequenceNameJSX = ({ onClick, name }: SequenceNameProps) => (
  <div
    className="text-container"
    {...accessibleOnClick(() => {
      !!onClick && onClick();
    })}
  >
    {name}
  </div>
);

export const SequenceNameField = ({ name, onClick }: SequenceNameProps) => (
  <OverlayTooltip text={name} placement={Placement.Bottom}>
    {sequenceNameJSX({ name, onClick })}
  </OverlayTooltip>
);
