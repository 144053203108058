import React from 'react';
import ValidationChecklist from '../validation-checklist';
import passwordValidator from '../../utils/password-validator';

type IProps = {
  password: string;
  isDirty?: boolean;
};

const PasswordValidationChecklist: React.FC<IProps> = ({
  password,
  isDirty,
}) => (
  <>
    <ValidationChecklist
      isDirty={isDirty}
      columns={[
        [
          {
            valid: passwordValidator.uppercase(password),
            text: 'One uppercase character',
          },
          {
            valid: passwordValidator.lowercase(password),
            text: 'One lowercase character',
          },
        ],
        [
          {
            valid: passwordValidator.numeric(password),
            text: 'One numerical character',
          },
          {
            valid:
              passwordValidator.minLength(password) &&
              passwordValidator.maxLength(password),
            text: 'Characters between 8-64',
          },
        ],
      ]}
    />
  </>
);

export default PasswordValidationChecklist;
