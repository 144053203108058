import React from 'react';
import { OverlayTooltip, Placement } from '../../overlay';
import ContactStatusTag from '../contact-status-tag/contact-status-tag';

const CellCard = ({ list, bulkText }) => {
  let component = [];
  if (list.length < 3) {
    component = list.map(({ value, status }, index) => {
      return (
        <div key={index}>
          {value}
          {status && (
            <span style={{ margin: '1rem' }}>
              <ContactStatusTag status={status} />
            </span>
          )}
        </div>
      );
    });
  } else {
    const nameList = list.map(({ value, index }) => {
      return <div key={index}>{value}</div>;
    });
    component[0] = (
      <OverlayTooltip
        text={nameList}
        placement={Placement.Right}
        className="cell-card__tooltip"
      >
        <div>{`${list.length} ${bulkText}`}</div>
      </OverlayTooltip>
    );
  }
  return <span style={{ display: 'inline-block' }}>{component}</span>;
};

export default CellCard;
