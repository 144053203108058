import React from 'react';
import { Container, Row, ThemeProvider } from 'react-bootstrap';
import { Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import Setting from '../settings';
import Prospect from '../prospect';
import Sequence from '../sequence';
import Reports from '../reports';
import EmailWarmup from '../email-warmup';
import ConfigRoute from '../../shared/components/config-route';
import { RequestStatus } from '../../shared/enums/request-status';
import {
  OnboardingSteps,
  UserSettingCode,
  ProfileProgressSteps,
} from '../../shared/types/user-setting';
import { handleOnboarding } from '../../shared/utils/handle-onboarding-routing';
import type { IProps, IState } from './home-container';
import Templates from '../templates';
import { SubscriptionPlans } from '../../shared/utils/subscription-plans';
import { GettingStartedOnboarding } from '../../shared/components/getting-started-onboarding';
import { PageHeaderAlertTypes } from '../../shared/utils/page-header-alert-types';
import Header from './components/header';
import WatchVideoModal from './components/watch-video-modal';
import { planError } from '../../shared/utils/errors/plan-permission-error/plan-error';
import BlockPage from '../../shared/components/block-page';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../shared/utils/execute-on-request-status';
import { isPlanDowngraded } from './utils/plan-transition';
import { redirectWithRefresh } from '../../shared/utils/redirect';
import MailboxEmails from '../mailbox-emails';
import { getIsRequestPending } from '../sequence/helpers/helper';
import { AuthHelper, identifyAndInitializeRefiner } from '../../shared/utils';
import hasPermission from '../../shared/utils/access-control/has-permission';
import HelmetIcon from '../../shared/components/helmet-icon';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import hasResource from '../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../shared/utils/access-control/enums/resource-identifiers';
import { getNewSequenceName } from '../sequence/components/sequence-list/helper';
import HelpscoutBeaconEvents from '../../shared/enums/helpscout-beacon-events';
import { Source } from '../../shared/enums/source';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import { PaymentActionType } from '../../shared/design-system/components/atoms/banner/banner';
import toaster, { Theme } from '../../shared/toaster';
import { TrialExtendSource } from '../../shared/enums/trial-extend-source';
import growthHub from '../growth-hub';
import { baseUrls } from './utils/helper';
import BillingSubscription from '../settings/components/billing-subscription';
import SendColdEmails from '../send-cold-emails';
import { supportUrls } from '../../shared/utils/urls';

class Home extends React.Component<IProps, IState> {
  private unlisten;

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showWatchVideoModal: false,
      showGettingStarted: false,
      isViewSequenceBtnClicked: false,
    };

    this.updateWatchVideoProfileProgressStep = this.updateWatchVideoProfileProgressStep.bind(
      this,
    );
    this.updateConnectEmailProfileProgressStep = this.updateConnectEmailProfileProgressStep.bind(
      this,
    );
    this.showHideWatchVideoModal = this.showHideWatchVideoModal.bind(this);
    this.showHideGettingStarted = this.showHideGettingStarted.bind(this);
    this.createSequence = this.createSequence.bind(this);
    this.onViewSampleSequence = this.onViewSampleSequence.bind(this);
    this.initializeIntercom = this.initializeIntercom.bind(this);
    this.unlisten = React.createRef();
  }

  componentDidMount() {
    const {
      sendGetUserSettingsRequest,
      sendGetAgencyConfigRequest,
      email,
      location,
      sendCompareAuthTokenRequest,
    } = this.props;
    sendGetUserSettingsRequest();

    const agencyConfigPayload = {
      baseUrl: window.location.origin,
      email,
    };

    if (!Object.values(baseUrls).includes(agencyConfigPayload.baseUrl)) {
      sendGetAgencyConfigRequest(agencyConfigPayload);
    }

    const params = new URLSearchParams(location.search);

    const authToken = params.get('authToken');

    if (authToken) {
      sendCompareAuthTokenRequest({
        authToken,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      sendGetUserSettingsRequest,
      sendGetPostLoadMetaRequest,
      getUserSettingsRequestStatus,
      getSampleSequenceRequestStatus,
      updateProfileProgressRequestStatus,
      location,
      createSequenceFromGettingStartedRequestStatus,
      gettingStatedCreatedSequenceId,
      hasUserConnectedEmailAccount,
      handleHasUserConnectedEmailAccount,
      compareAuthTokenRequestStatus,
      shouldLogoutUser,
      resetCompareAuthTokenRequest,
      history,
      agencyConfig,
      sendGetAgencyConfigRequest,
      firstName,
      lastName,
      email,
      trackingId,
      extendTrialPeriodRequestStatus,
      extendTrialPeriodRequestMessage,
      isPlanSynced,
      resetIsPlanSynced,
    } = this.props;

    this.initializeIntercom();

    if (prevProps.email !== email) {
      const agencyConfigPayload = {
        baseUrl: window.location.origin,
        email,
      };

      if (!Object.values(baseUrls).includes(agencyConfigPayload.baseUrl)) {
        sendGetAgencyConfigRequest(agencyConfigPayload);
      }
    }

    const { showWatchVideoModal, isViewSequenceBtnClicked } = this.state;

    executeOnRequestStatusWithPrevStatusCheck({
      status: getUserSettingsRequestStatus,
      prevStatus: prevProps.getUserSettingsRequestStatus,
      onSuccess: () => {
        sendGetPostLoadMetaRequest();
        // this.redirectToBlankPage();
        this.onCompleteOnboardingProcess();
        this.checkPaymentPendingBanner();

        if (hasUserConnectedEmailAccount) {
          this.updateConnectEmailProfileProgressStep();
        }

        const params = new URLSearchParams(location.search);
        const action = params.get('action');

        if (action === 'extendTrial') {
          this.onTrialExtended();
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: updateProfileProgressRequestStatus,
      prevStatus: prevProps.updateProfileProgressRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
        if (hasUserConnectedEmailAccount) {
          handleHasUserConnectedEmailAccount(false);
        }
      },
    });

    if (isPlanSynced) {
      toaster.success(
        'Congratulations! Your Saleshandy free plan is activated',
        { theme: Theme.New },
      );
      resetIsPlanSynced();
    }

    if (agencyConfig && agencyConfig.colorPalette) {
      // Create Instance of the document element
      const documentInstance = document.documentElement;

      // Loop through the 'colorPalette' object
      Object.keys(agencyConfig.colorPalette).forEach((color) => {
        // Set CSS variables
        documentInstance.style.setProperty(
          `--${color}`,
          agencyConfig.colorPalette[color],
        );
      });
    }
    executeOnRequestStatusWithPrevStatusCheck({
      status: createSequenceFromGettingStartedRequestStatus,
      prevStatus: prevProps.createSequenceFromGettingStartedRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(
          `/sequence/${gettingStatedCreatedSequenceId}/steps`,
        );
        this.showHideWatchVideoModal(false);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getSampleSequenceRequestStatus,
      prevStatus: prevProps.getSampleSequenceRequestStatus,
      onSuccess: () => {
        if (showWatchVideoModal && isViewSequenceBtnClicked) {
          this.onViewSampleSequence();
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: compareAuthTokenRequestStatus,
      prevStatus: prevProps.compareAuthTokenRequestStatus,
      onSuccess: () => {
        const params = new URLSearchParams(location.search);
        params.delete('authToken');
        if (shouldLogoutUser) {
          AuthHelper.logout({
            fallbackTo: location.pathname,
            query: params.toString(),
          });
        } else {
          history.push(location.pathname);
        }
        resetCompareAuthTokenRequest();
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: extendTrialPeriodRequestStatus,
      prevStatus: prevProps.extendTrialPeriodRequestStatus,
      onSuccess: () => {
        toaster.success('Trial has been extended successfully', {
          theme: Theme.New,
        });

        redirectWithRefresh('/sequence');
      },
      onFailed: () => {
        if (extendTrialPeriodRequestMessage) {
          toaster.error(extendTrialPeriodRequestMessage, {
            theme: Theme.New,
          });
        }
      },
    });

    if (window.Intercom) {
      window.Intercom('event', {
        type: HelpscoutBeaconEvents.PAGE_VIEWED,
        url: document.location.href,
        title: document.title,
      });
      window.Intercom(HelpscoutBeaconEvents.SUGGEST);
    }

    if (prevProps.trackingId === trackingId) {
      identifyAndInitializeRefiner({
        id: trackingId,
        name: `${firstName} ${lastName}`,
        email,
      });
    }
  }

  onViewSampleSequence() {
    const { sampleSequence, sendGetSampleSequenceRequest } = this.props;

    if (this.isUserAlreadyInStepPage()) {
      this.showHideWatchVideoModal(false);
      return;
    }

    if (sampleSequence) {
      const sequenceId = sampleSequence?.id;
      redirectWithRefresh(`/sequence/${sequenceId}/steps`);
      this.showHideWatchVideoModal(false);
    } else {
      this.setState({ isViewSequenceBtnClicked: true });
      sendGetSampleSequenceRequest();
    }
  }

  onCompleteOnboardingProcess() {
    const {
      meta,
      sendFinishOnboardingRequest,
      handleHasSignUpCompleted,
      location,
      history,
    } = this.props;
    const { showWatchVideoModal, showGettingStarted } = this.state;

    if (!isEmpty(meta)) {
      const onboardingStep = meta.find(
        (setting) => setting.code === UserSettingCode.Onboarding,
      );

      if (onboardingStep?.value === OnboardingSteps.Step3) {
        sendFinishOnboardingRequest();
        handleHasSignUpCompleted(true);
        this.showHideWatchVideoModal(true);
      }

      const params = new URLSearchParams(location.search);
      if (
        params.get('redirect_to') === 'ltd-checkout' &&
        onboardingStep?.value === OnboardingSteps.Step0
      ) {
        history.push('/LTD-Checkout');
      }

      const currentPath = location.pathname + location.search;
      handleOnboarding(meta, currentPath, location.search);
    } else if (!showWatchVideoModal && !showGettingStarted) {
      this.showHideGettingStarted(true);
    }
  }

  onTrialExtended = () => {
    const {
      isEligibleToExtendTrial,
      sendExtendTrialPeriodRequest,
    } = this.props;

    if (isEligibleToExtendTrial) {
      sendExtendTrialPeriodRequest(TrialExtendSource.Email);
    }
  };

  getPlanTransitionDetails() {
    const { planTransitionDetails } = this.props;
    return planTransitionDetails;
  }

  updateWatchVideoProfileProgressStep = () => {
    const { profileProgress, updateProfileProgressRequest } = this.props;
    if (profileProgress) {
      const watchVideoStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName === ProfileProgressSteps.WatchVideo,
      );
      if (!watchVideoStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.WatchVideo,
          isCompleted: true,
        });
      }
    }
  };

  updateConnectEmailProfileProgressStep = () => {
    const { profileProgress, updateProfileProgressRequest } = this.props;
    if (profileProgress) {
      const connectEmailStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.ConnectEmail,
      );
      if (!connectEmailStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.ConnectEmail,
          isCompleted: true,
        });
      }
    }
  };

  initializeIntercom() {
    const {
      firstName,
      lastName,
      email,
      trackingId,
      planName,
      createdAt,
    } = this.props;

    if (window.Intercom) {
      window.Intercom('boot', {
        api_base: supportUrls.intercomBaseUrl,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: trackingId,
        'First Name': firstName,
        'Last Name': lastName,
        email,
        'Active plan': planName,
        'Signed up': createdAt,
      });
    }
  }

  checkPaymentPendingBanner() {
    const { subscriptionDetails, setPageHeaderBannerAlert, t } = this.props;

    if (subscriptionDetails?.paymentActionRequired) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        if (
          subscriptionDetails?.paymentActionType === PaymentActionType.Initial
        ) {
          setPageHeaderBannerAlert({
            bannerType: PageHeaderAlertTypes.PAYMENT_PENDING,
            paymentActionUrl: subscriptionDetails?.paymentActionUrl,
            bannerMessageOne: t('messages.initial_payment_pending_text_one'),
            bannerMessageTwo: t('messages.initial_payment_pending_text_two'),
            ctaText: t('labels.take_action_now_cta'),
            paymentActionType: subscriptionDetails?.paymentActionType,
          });
        } else {
          setPageHeaderBannerAlert({
            bannerType: PageHeaderAlertTypes.PAYMENT_PENDING,
            paymentActionUrl: subscriptionDetails?.paymentActionUrl,
            bannerMessageOne: t('messages.initial_payment_pending_text_one'),
            bannerMessageTwo: t('messages.recurring_payment_pending_text_two'),
            ctaText: t('labels.take_action_now_cta'),
            paymentActionType: subscriptionDetails?.paymentActionType,
          });
        }
      }
    } else {
      setPageHeaderBannerAlert({
        bannerType: PageHeaderAlertTypes.NONE,
      });
    }
  }

  redirectToBlankPage() {
    const { planTransitionDetails, history } = this.props;

    if (isPlanDowngraded(planTransitionDetails)) {
      history.push('/block');
    }
  }

  isUserAlreadyInStepPage() {
    const { sampleSequence, match } = this.props;
    const { params }: any = match;

    if (params) {
      return sampleSequence?.id === +params?.sequenceId;
    }

    return false;
  }

  showHideGettingStarted(value: boolean) {
    this.setState({ showGettingStarted: value });
  }

  showHideWatchVideoModal(value: boolean) {
    const { showGettingStartedVideoModal } = this.props;

    this.setState({ showWatchVideoModal: value });
    showGettingStartedVideoModal(false);
  }

  createSequence(source: Source) {
    const { subscriptionDetails, sendCreateSequenceRequest } = this.props;
    if (subscriptionDetails?.planCode === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(2002);
      } else {
        planError(3002);
      }
    } else if (!hasResource(ResourceIdentifiers.SEQUENCES_CREATE)) {
      this.showHideWatchVideoModal(false);
    } else {
      sendCreateSequenceRequest(getNewSequenceName(), source);
    }
  }

  render() {
    const {
      getUserSettingsRequestStatus,
      meta,
      subscriptionDetails,
      firstName,
      profileProgress,
      handleActivateSequenceTooltip,
      handleAddEmailAccountModal,
      agencyConfig,
      isGettingStartedVideoModalShow,
      getSampleSequenceRequestStatus,
      createSequenceFromGettingStartedRequestStatus,
    } = this.props;

    const {
      userSubscriptionPlan,
      showWatchVideoModal,
      showGettingStarted,
    } = this.state;

    const isLoading =
      getUserSettingsRequestStatus === RequestStatus.Pending ||
      getUserSettingsRequestStatus === RequestStatus.Ideal;

    if (isLoading && isEmpty(meta) && !subscriptionDetails) {
      return null;
    }

    // Classes
    const homeContainerClass = classNames([
      {
        'home-container': userSubscriptionPlan !== SubscriptionPlans.FREE,
      },
      {
        'home-container show-alert':
          userSubscriptionPlan === SubscriptionPlans.FREE,
      },
    ]);

    return (
      <Container fluid>
        {agencyConfig?.logo?.favicon !== '' && (
          <HelmetIcon icon={agencyConfig?.logo?.favicon} />
        )}
        <ErrorBoundaryWrapper>
          <ThemeProvider prefixes={{ btn: 'btn-solid' }}>
            <Row>
              <main className={homeContainerClass}>
                <Header />
                <Switch>
                  <ConfigRoute
                    path="/sequence"
                    title="Sequence"
                    component={Sequence}
                  />
                  <ConfigRoute
                    path="/settings"
                    title="Settings"
                    component={Setting}
                  />
                  <ConfigRoute
                    path="/prospects"
                    title="Prospects"
                    component={Prospect}
                  />
                  <ConfigRoute
                    path="/templates"
                    title="Template"
                    component={Templates}
                  />
                  <ConfigRoute
                    path="/email-insights"
                    title="1:1 Email Insights"
                    component={MailboxEmails}
                  />
                  <ConfigRoute
                    path="/reports"
                    title="Reports"
                    component={Reports}
                  />
                  <ConfigRoute
                    path="/growth-hub"
                    title="Growth Hub"
                    component={growthHub}
                  />
                  <ConfigRoute
                    path="/warmup"
                    title="Email Warmup"
                    component={EmailWarmup}
                  />
                  <ConfigRoute
                    path="/billing/subscription"
                    title="Billings & Subscriptions"
                    component={BillingSubscription}
                  />
                  <ConfigRoute
                    path="/cold-emails"
                    title="Send Cold Emails"
                    component={SendColdEmails}
                  />
                  <ConfigRoute
                    path="/block"
                    title="Block"
                    component={BlockPage}
                  />
                </Switch>

                {agencyConfig?.showGettingStarted &&
                  hasPermission(Permissions.SEQUENCE_READ) &&
                  false && (
                    <GettingStartedOnboarding
                      firstName={firstName}
                      profileProgress={profileProgress}
                      showHideWatchVideoModal={this.showHideWatchVideoModal}
                      handleActivateSequenceTooltip={
                        handleActivateSequenceTooltip
                      }
                      handleAddEmailAccountModal={handleAddEmailAccountModal}
                      createSequence={() =>
                        this.createSequence(Source.OnboardingChecklist)
                      }
                      showGettingStarted={showGettingStarted}
                    />
                  )}

                {hasPermission(Permissions.SEQUENCE_READ) && (
                  <WatchVideoModal
                    show={showWatchVideoModal || isGettingStartedVideoModalShow}
                    isGettingStartedVideModalShowThroughIcon={
                      showWatchVideoModal
                    }
                    firstName={firstName}
                    onViewSampleSequence={this.onViewSampleSequence}
                    onCreateNewSequence={() =>
                      this.createSequence(Source.OnboardingVideo)
                    }
                    onClose={() => {
                      this.showHideWatchVideoModal(false);
                      this.setState({ isViewSequenceBtnClicked: false });
                    }}
                    updateWatchVideoProfileProgressStep={
                      this.updateWatchVideoProfileProgressStep
                    }
                    isCreateSequenceRequestPending={getIsRequestPending(
                      createSequenceFromGettingStartedRequestStatus,
                    )}
                    isViewSampleSequenceRequestPending={getIsRequestPending(
                      getSampleSequenceRequestStatus,
                    )}
                  />
                )}
              </main>
            </Row>
          </ThemeProvider>
        </ErrorBoundaryWrapper>
      </Container>
    );
  }
}

export default Home;
