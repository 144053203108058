import React from 'react';
import FormSection from '../../../form-section';
import ButtonContainer from '../../../button-container';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import Alert from '../../../../../../shared/design-system/components/alert';
import { IProps } from './types';
import AlertContainer from '../../../alert-container';
import TopWelcome from '../../../top-welcome';

class ChangePasswordSuccess extends React.Component<IProps, null> {
  constructor(props) {
    super(props);
    this.redirectToLoginHandler = this.redirectToLoginHandler.bind(this);
  }

  redirectToLoginHandler() {
    this.props.history.push('/login');
  }

  render() {
    const { message } = this.props;

    return (
      <>
        <TopWelcome>
          <TopWelcome.Header>Done and done!</TopWelcome.Header>
        </TopWelcome>
        <div className="reset-pass-success-message">
          <AlertContainer>
            <Alert
              variant={Alert.Variant.Success}
              iconIdentifier="check-o"
              header="Success!"
              className="reset-pass-success"
            >
              {message}
            </Alert>
          </AlertContainer>
        </div>
        <FormSection>
          <ButtonContainer>
            <Button
              variant={Button.Variant.Primary}
              onClick={this.redirectToLoginHandler}
            >
              Return to Login
            </Button>
          </ButtonContainer>
        </FormSection>
      </>
    );
  }
}

export default ChangePasswordSuccess;
