import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderBanner from '../header-banner';
import AdminSettingToggle from './components/admin-setting-toggle';
import Spinner from '../../../../shared/design-system/components/atoms/spinner';
import toaster, { Theme } from '../../../../shared/toaster';
import { showGeneralErrorNotification } from '../../../../shared/utils/errors';
import {
  executeOnRequestStatus,
  executeOnErrorWithErrorCheck,
} from '../../../../shared/utils/execute-on-request-status';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { ShAccountSettingsCode } from '../../enums/admin-settings';
import {
  getAdminSettingsValues,
  getEmailVerificationModalContent,
  getProspectDuplicationAllowedModalContent,
  getToggleOnOrOffMessage,
} from './utils/helper';
import { IProps } from './types';
import { getIsRequestPending } from '../../../../shared/utils/get-request-status';
import { SpinnerAnimation } from '../../../../shared/design-system/components/atoms/spinner/spinner';
import ConfirmationModalV3 from '../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../shared/design-system/components/atoms/confirmation-modal/enum';

const AdminSettings: React.FC<IProps> = ({
  adminSettings,
  resetAdminSettings,

  getAdminSettingsRequestStatus,
  getAdminSettingsRequestError,
  sendGetAdminDetailsRequest,

  updateAdminSettingsStatus,
  updateAdminSettingsMessage,
  updateAdminSettingsError,
  sendUpdateAdminDetailsRequest,
  resetUpdateAdminSettings,

  subscriptionPlan,
}) => {
  const { t } = useTranslation();

  const [isInitialRequest, setIsInitialRequest] = useState(true);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [prospectDuplicationModal, setProspectDuplicationModal] = useState(
    false,
  );

  const onHideEmailVerificationModal = () => {
    setEmailVerificationModal(false);
  };

  const onHideProspectDuplicationModal = () => {
    setProspectDuplicationModal(false);
  };

  const onToggle = (code: ShAccountSettingsCode) => {
    if (code === ShAccountSettingsCode.IsVerificationActive) {
      setEmailVerificationModal(true);
    }
    if (code === ShAccountSettingsCode.ProspectDuplicationAllowed) {
      setProspectDuplicationModal(true);
    }
  };

  const onSettingToggle = (code: ShAccountSettingsCode, value: boolean) => {
    sendUpdateAdminDetailsRequest({
      settings: [
        {
          code,
          value: value ? '1' : '0',
        },
      ],
    });
  };

  useEffect(() => {
    sendGetAdminDetailsRequest();
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAdminSettingsRequestStatus,
      onSuccess: () => {
        setIsInitialRequest(false);
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getAdminSettingsRequestError,
          onError: () => {
            showGeneralErrorNotification(getAdminSettingsRequestError.message);
          },
        });
      },
    });
  }, [getAdminSettingsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateAdminSettingsStatus,
      onSuccess: () => {
        toaster.success(updateAdminSettingsMessage, { theme: Theme.New });
        sendGetAdminDetailsRequest();
        resetUpdateAdminSettings();
        emailVerificationModal && setEmailVerificationModal(false);
        prospectDuplicationModal && setProspectDuplicationModal(false);
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateAdminSettingsError,
          onError: () => {
            showGeneralErrorNotification(updateAdminSettingsError.message);
            resetUpdateAdminSettings();
          },
        });
      },
    });
  }, [updateAdminSettingsStatus]);

  useEffect(
    () => () => {
      resetAdminSettings();
    },
    [],
  );

  const {
    isVerificationActive,
    prospectDuplicationAllowed,
  } = getAdminSettingsValues(adminSettings);

  return (
    <>
      <div className="admin-settings">
        <div className="admin-settings--header">
          <span className="semibold-3">Admin Settings</span>
        </div>
        {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}

        {getIsRequestPending(getAdminSettingsRequestStatus) &&
        isInitialRequest ? (
          <div className="admin-settings--spinner">
            <Spinner animation={SpinnerAnimation.Border} />
          </div>
        ) : (
          <div className="admin-settings--content">
            <AdminSettingToggle
              checked={isVerificationActive}
              onChange={onToggle}
              code={ShAccountSettingsCode.IsVerificationActive}
              label="Allow verifying prospects while importing"
            />
            <AdminSettingToggle
              checked={prospectDuplicationAllowed}
              onChange={onToggle}
              code={ShAccountSettingsCode.ProspectDuplicationAllowed}
              label="Allow adding one prospect in multiple sequences"
            />
          </div>
        )}
      </div>

      <ConfirmationModalV3
        show={emailVerificationModal}
        title={getToggleOnOrOffMessage(isVerificationActive, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        content={getEmailVerificationModalContent(isVerificationActive, t)}
        onClose={onHideEmailVerificationModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.IsVerificationActive,
            !isVerificationActive,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={prospectDuplicationModal}
        title={getToggleOnOrOffMessage(prospectDuplicationAllowed, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getProspectDuplicationAllowedModalContent(
          prospectDuplicationAllowed,
          t,
        )}
        onClose={onHideProspectDuplicationModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.ProspectDuplicationAllowed,
            !prospectDuplicationAllowed,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />
    </>
  );
};

export default AdminSettings;
