import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import { setPassword } from '../../helpers/auth.api';
import { SetPasswordRequest } from '../../types';

export const setPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  SetPasswordRequest,
  { rejectValue: ResponseErrorWithHandled }
>('auth/setPasswordRequest', async (args, thunkAPI) => {
  try {
    return await setPassword(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
