import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import {
  resetPasswordRequest,
  hideError,
} from '../../../reset-password/reset-password-slice';
import ResetPasswordInvalidLinkForm from './reset-password-invalid-link-form';
import { ResetPasswordCallbackRouteProps } from '../../../reset-password-callback/reset-password-callback-container';

const mapStateToProps = (state: RootState) => ({
  status: state.resetPassword.status,
  error: state.resetPassword.error,
  showError: state.resetPassword.showError,
});

const mapDispatchToProps = {
  hideError: () => hideError(),
  sendResetPasswordRequest: (email: string) => resetPasswordRequest({ email }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  ResetPasswordCallbackRouteProps & {
    showSuccessComponent: () => void;
  };

export default withRouter(connector(ResetPasswordInvalidLinkForm));
