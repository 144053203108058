import React from 'react';
import Select from 'react-select';

import { DropdownIndicator, style } from './select-customizations';

type MultiSelectProps = {
  placeholder;
  components?;
  onChange;
  className;
  classNamePrefix;
  defaultValue;
  options;
  disabled?: boolean;
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  placeholder,
  onChange,
  className,
  classNamePrefix,
  defaultValue,
  options,
  components,
  disabled = false,
}) => (
  <Select
    placeholder={placeholder}
    components={{ DropdownIndicator, ...components }}
    styles={style}
    isMulti
    options={options}
    defaultValue={defaultValue}
    onChange={onChange}
    className={className}
    classNamePrefix={classNamePrefix}
    isDisabled={disabled}
  />
);

export default MultiSelect;
